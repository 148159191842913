
import { Component, Prop, Vue } from 'vue-property-decorator'

import { authModule, discoverModule } from '@/store'
import { useOnboardingStore } from '@/stores/onboarding'
@Component({
  name: 'ImportBtn',
  setup() {
    const onboardingStore = useOnboardingStore()

    return { onboardingStore }
  },
})
class ImportBtn extends Vue {
  /**
   * Handle click internally to automatically import current inspiration on click
   */
  @Prop({ default: true, type: Boolean }) readonly autoImport!: boolean
  @Prop({ default: false, type: Boolean }) readonly dark!: boolean
  @Prop({ default: false, type: Boolean }) readonly large!: boolean
  @Prop({ default: '20px' }) readonly width!: number | string
  @Prop({ default: '20px' }) readonly height!: number | string
  @Prop() readonly labelClass?: string
  @Prop() readonly inspirationId?: string
  @Prop() readonly importLabel?: string

  importIcon = ''
  isLoading = false
  isDisabled = false

  onboardingStore!: ReturnType<typeof useOnboardingStore>

  created() {
    this.importIcon = this.dark ? 'import_shadow.png' : 'import_open.png'
  }

  async handleImport(): Promise<void> {
    if (!this.inspirationId) {
      return
    }

    this.$emit('click:import', this.inspirationId)

    if (!this.autoImport) {
      return
    }

    if (!authModule.loggedIn) {
      this.redirectToAuthenticate()
      return
    }

    this.isLoading = true
    await discoverModule.importInspiration(this.inspirationId)
    this.isLoading = false
    this.isDisabled = true
    this.importIcon = this.dark ? 'import_done.png' : 'import_done_shadow.png'
  }

  redirectToAuthenticate(): void {
    if (this.inspirationId) {
      this.$router.push({
        path:
          this.onboardingStore.previousAuthenticatingRoutePath ?? '/sign-in',
        query: { inspirationId: this.inspirationId },
      })
    }
  }
}
export default ImportBtn
