
import { Component, Watch, Prop, Vue } from 'vue-property-decorator'

import { authModule, discoverModule } from '@/store'
import { Bookmark } from '@/types/discover'
@Component({
  name: 'BookmarkBtn',
})
class BookmarkBtn extends Vue {
  @Prop() readonly inspirationId?: string
  isBookmarked = false

  get bookmarks(): Bookmark[] {
    return discoverModule.bookmarks
  }

  get showBookmarkBtn(): boolean {
    return !!this.inspirationId && authModule.loggedIn
  }

  @Watch('bookmarks')
  async setBookmark(): Promise<void> {
    if (this.inspirationId) {
      this.isBookmarked = await discoverModule.isBookmark(this.inspirationId)
    }
  }

  created() {
    this.setBookmark()
  }

  async handleBookmark(): Promise<void> {
    if (!this.inspirationId) {
      return
    }

    if (this.isBookmarked) {
      await discoverModule.deleteBookmark(this.inspirationId)
    } else {
      await discoverModule.createBookmark(this.inspirationId)
    }
    discoverModule.fetchBookmarks()
  }
}
export default BookmarkBtn
