
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'className',
})
class className extends Vue {
  /**
   * Color of the left border
   * Can be any valid hex string, material color or theme color
   */
  @Prop({ default: 'primary', type: String }) readonly borderColor!: string
  @Prop({ default: true, type: Boolean }) readonly hasBorder!: boolean
  @Prop({ default: true, type: Boolean }) readonly isOutlined!: boolean
  @Prop({ default: false, type: Boolean }) readonly hasTypeIcon!: boolean
  @Prop({ default: true, type: Boolean }) readonly hasArrowIcon!: boolean
  @Prop({ default: false, type: Boolean }) readonly hasDottedLine!: boolean
}
export default className
