
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'LCard',
})
class LCard extends Vue {
  @Prop({ default: false, type: Boolean }) readonly scrollable!: boolean
}

export default LCard
