
import { Component, Vue } from 'vue-property-decorator'
import { transportationModule, tripModule, waypointModule } from '@/store'
import { Trip, Waypoint } from '@/types/trip'

@Component({
  name: 'WaypointLocationForm',
})
class WaypointLocationForm extends Vue {
  isSaving = false
  isAddingNewWaypoint = false
  dates = ''

  get trip(): Trip | null {
    return tripModule.trip
  }

  get origin(): Waypoint | null {
    return transportationModule.currentOrigin
  }

  set origin(waypoint: Waypoint | null) {
    transportationModule.setOriginWaypoint(waypoint)
  }

  get destination(): Waypoint | null {
    return transportationModule.currentDestination
  }

  set destination(waypoint: Waypoint | null) {
    transportationModule.setDestinationWaypoint(waypoint)
  }

  async setOriginWaypoint(waypoint: Waypoint): Promise<void> {
    if (!waypoint._id) {
      this.isAddingNewWaypoint = true
      const newWaypoint = await waypointModule.create({ waypoint })

      if (newWaypoint) {
        waypoint = newWaypoint
      }
      this.isAddingNewWaypoint = false
    }

    if (
      this.trip &&
      this.destination &&
      this.trip.waypoints.findIndex((wp) => wp._id === waypoint._id) >
        this.trip.waypoints.findIndex((wp) => wp._id === this.destination!._id)
    ) {
      const tmp = JSON.parse(JSON.stringify(waypoint))
      this.origin = this.destination
      this.destination = tmp
    } else {
      this.origin = waypoint
    }
  }

  async setDestinationWaypoint(waypoint: Waypoint): Promise<void> {
    if (!waypoint._id) {
      this.isAddingNewWaypoint = true
      const newWaypoint = await waypointModule.create({ waypoint })

      if (newWaypoint) {
        waypoint = newWaypoint
      }
      this.isAddingNewWaypoint = false
    }

    if (
      this.trip &&
      this.origin &&
      this.trip.waypoints.findIndex((wp) => wp._id === this.origin!._id) >
        this.trip.waypoints.findIndex((wp) => wp._id === waypoint._id)
    ) {
      const tmp = JSON.parse(JSON.stringify(waypoint))
      this.destination = this.origin
      this.origin = tmp
    } else {
      this.destination = waypoint
    }
  }
}
export default WaypointLocationForm
