import { render, staticRenderFns } from "./GridMenu.vue?vue&type=template&id=2cba2b82&scoped=true"
import script from "./GridMenu.vue?vue&type=script&lang=ts"
export * from "./GridMenu.vue?vue&type=script&lang=ts"
import style0 from "./GridMenu.vue?vue&type=style&index=0&id=2cba2b82&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cba2b82",
  null
  
)

export default component.exports