import { authModule, tripModule } from '@/store'

export default async ({ params }: any) => {
  if (!authModule.loggedIn) return

  // if there is a trip id in the route, fetch that trip
  if (params.id) {
    await tripModule.fetch(params.id)
  }

  // fetch user and all trips in all cases
  await authModule.fetchUser()
}
