
import { Component, Vue } from 'vue-property-decorator'

import { eventModule } from '@/store'

@Component({
  name: 'ErrorLayout',
  layout: 'default',
  head() {
    return {
      title: `${this.$t('myTrips') as string} — Lambus`, // is allways 'myTrips', since this page only redirects to /trips
    }
  },
})
class ErrorLayout extends Vue {
  mounted() {
    eventModule.newError('error.generic')

    this.$router.replace({
      name: 'trips',
    })
  }
}

export default ErrorLayout
