import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { set } from 'vue'
import { tripModule } from '@/store'
import { Accommodation, AccommodationUpdate } from '@/types/accommodation'

@Module({ name: 'accommodation', stateFactory: true, namespaced: true })
export default class AccommodationModule extends VuexModule {
  private allAccommodations: Accommodation[] = []

  public get accommodations(): Accommodation[] {
    return this.allAccommodations
  }

  @Mutation
  public setAccommodations(payload: Accommodation[]) {
    set(this, 'allAccommodations', payload)
  }

  @Action({ rawError: true })
  public async fetchByTrip(id: string) {
    try {
      const { data } = await this.$axios.get(`/api/trips/${id}/accommodation`)
      this.context.commit('setAccommodations', data.data.accommodation)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.accommodation.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async create(payload: AccommodationUpdate) {
    try {
      if (!tripModule.trip?.id) {
        throw new Error('Cannot create accommodation. Trip is not set.')
      }

      const { data } = await this.$axios.post(
        `/api/trips/${tripModule.trip?.id}/accommodation`,
        payload,
      )

      this.context.dispatch('fetchByTrip', tripModule.trip?.id)

      this.context.dispatch(
        'event/newMessage',
        'success.accommodation.create',
        {
          root: true,
        },
      )

      return data.data.accommodation
    } catch (error) {
      this.context.dispatch('event/newError', 'error.accommodation.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async update(payload: AccommodationUpdate) {
    try {
      if (!tripModule.trip?.id) {
        throw new Error('Cannot update accommodation. Trip is not set.')
      }

      if (!payload._id) {
        throw new Error('Cannot update accommodation. Id is missing.')
      }

      const { data } = await this.$axios.put(
        `/api/trips/${tripModule.trip?.id}/accommodation/${payload._id}`,
        payload,
      )

      this.context.dispatch('fetchByTrip', tripModule.trip?.id)

      this.context.dispatch(
        'event/newMessage',
        'success.accommodation.update',
        {
          root: true,
        },
      )

      return data.data.accommodation
    } catch (error) {
      this.context.dispatch('event/newError', 'error.accommodation.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async delete(id: string) {
    try {
      if (!tripModule.trip?.id) {
        throw new Error('Cannot update accommodation. Trip is not set.')
      }

      await this.$axios.delete(
        `/api/trips/${tripModule.trip?.id}/accommodation/${id}`,
      )

      this.context.dispatch('fetchByTrip', tripModule.trip?.id)

      this.context.dispatch(
        'event/newMessage',
        'success.accommodation.delete',
        {
          root: true,
        },
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.accommodation.delete', {
        root: true,
      })
    }
  }
}
