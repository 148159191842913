
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'LDialog',
})
class LDialog extends Vue {
  @Prop({ default: false, type: Boolean }) readonly value!: boolean
  @Prop({ default: true, type: Boolean }) readonly fullscreenOnMobile!: boolean
  @Prop({ default: false, type: Boolean }) readonly noPaddingTitle!: boolean
  @Prop({ default: false, type: Boolean }) readonly noPaddingText!: boolean
  @Prop({ default: false, type: Boolean }) readonly noCloseBtn!: boolean
  @Prop({ default: false, type: Boolean }) readonly alternateCloseBtn!: boolean
  @Prop({ default: false, type: Boolean }) readonly fluidToggle!: boolean
  @Prop({ default: false, type: Boolean }) readonly confirmBtn!: boolean
  @Prop({ default: false, type: Boolean }) readonly defaultBackground!: boolean
  @Prop() readonly width?: string
  showLDialog = false

  get dialogValue(): boolean {
    return this.value || this.showLDialog
  }

  set dialogValue(value: boolean) {
    this.$emit('update:value', value)
    this.showLDialog = value
    if (this.showLDialog) {
      this.$emit('open')
    } else {
      this.$emit('close')
    }
  }

  get hasNoDefault(): boolean {
    return !this.$slots.default
  }

  get hasActionsSlot(): boolean {
    return !!this.$slots.actions
  }

  get dialogWidth(): string {
    if (this.width) {
      return this.width
    } else if (this.$vuetify.breakpoint.lgAndUp) {
      return '50vw'
    } else if (
      this.$vuetify.breakpoint.md ||
      (this.$vuetify.breakpoint.smAndUp && !this.fullscreenOnMobile)
    ) {
      return '60vw'
    }

    return 'auto'
  }

  public toggleDialog(): void {
    this.dialogValue = !this.dialogValue
  }

  confirm(): void {
    this.$emit('click:confirm')
  }
}

export default LDialog
