
import { Component, Watch, Vue } from 'vue-property-decorator'
import { eventModule } from '@/store'
import Intercom from '@/utils/intercom-manager'

@Component({
  name: 'MessageAlert',
})
class MessageAlert extends Vue {
  showAlert = false

  get alertMessage() {
    return eventModule.message
  }

  get buttonName() {
    return eventModule.buttonName
  }

  get alertValues() {
    return eventModule.values
  }

  get alertColor() {
    return eventModule.color
  }

  get alertWidth() {
    return eventModule.width
  }

  get width() {
    if (this.alertWidth) {
      return this.alertWidth
    }

    return this.$vuetify.breakpoint.mdAndUp ? '50vw' : '90vw'
  }

  @Watch('alertMessage')
  toggleError(val: string): void {
    if (val.length > 0) {
      this.showAlert = true
    }
  }

  @Watch('showAlert')
  errorCountdown() {
    setTimeout(() => {
      this.showAlert = false
      eventModule.resetMessage()
      eventModule.setValues({})
      eventModule.setWidth(null)
    }, 5000)
  }

  openContact() {
    Intercom.show()
  }
}

export default MessageAlert
