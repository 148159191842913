import { Context } from '@nuxt/types'
import { authModule } from '@/store'

// Response code that the server returns when the username or email is invalid
const errorCodeInvalidUsernameOrEmail = 12030202

export default function ({ app: { $axios } }: Context) {
  // Only set bearer token if logged in.
  // Otherwise, the header may be invalid, e.g. "Authorization: Bearer "
  if (authModule.bearerToken) {
    $axios.defaults.headers.Authorization = authModule.bearerToken
  }

  $axios.onRequest((config) => {
    return config
  })

  $axios.onError(async (error: any) => {
    const statusCode = error.response ? error.response.status : -1
    const errorMessage = error.response ? error.response.data.error : ''
    const config = error.config

    if (statusCode === 401 && errorMessage === 'Session expired') {
      try {
        if (!authModule.isRefreshing && !config.isRetry) {
          await authModule.refresh()
          Object.assign(config, { isRetry: true })
          return $axios(config)
        }
      } catch (err) {
        await authModule.logout()
      }
      return
    } else if (
      (errorMessage === 'Not authenticated' && !config.isRetry) ||
      statusCode === 403
    ) {
      if (error.response?.data?.code === errorCodeInvalidUsernameOrEmail) {
        // The validateUsername server endpoint incorrectly returns a 403 if the username is invalid.
        return
      }
      await authModule.logout()
      return
    }

    throw error
  })
}
