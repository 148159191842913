
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
  name: 'LConfirmDialog',
})
class LConfirmDialog extends Vue {
  @Prop({ default: false, type: Boolean }) readonly value!: boolean
  @Prop({ default: false, type: Boolean }) readonly centerContent!: boolean
  @Prop({ default: false, type: Boolean }) readonly destructive!: boolean
  @Prop({ default: false, type: Boolean }) readonly customActions!: boolean
  @Prop({ default: true, type: Boolean }) readonly cancelable!: boolean
  @Prop() readonly title!: string
  @Prop() readonly content?: string
  @Prop() readonly image?: string
  @Prop() readonly action?: string
  @Prop() readonly secondaryAction?: string

  showLConfirmDialog = false

  get imageSrc() {
    if (this.image) {
      return require(`@/assets/images/${this.image}`)
    }
    return undefined
  }

  get confirmBtnText(): string {
    return this.action || (this.$t('confirm') as string)
  }

  get cancelBtnText(): string {
    return this.secondaryAction || (this.$t('cancel') as string)
  }

  get hasConfirmButton(): boolean {
    return !!this.$slots.confirmButton
  }

  @Watch('value')
  setShowLConfirmDialog(): void {
    this.showLConfirmDialog = this.value || false
  }

  @Watch('showLConfirmDialog')
  syncValue(): void {
    if (this.value !== this.showLConfirmDialog) {
      this.$emit('update:value', this.showLConfirmDialog)
    }
  }

  cancel(): void {
    this.$emit('cancel')
    this.toggleDialog()
  }

  confirm(): void {
    this.$emit('confirm')
    this.toggleDialog()
  }

  public toggleDialog(): void {
    this.showLConfirmDialog = !this.showLConfirmDialog
  }
}

export default LConfirmDialog
