
import { Component, Vue } from 'vue-property-decorator'

import AddButton from '@/components/AddButton.vue'
import DateSelectionModal from '@/components/datepicker/DateSelectionModal.vue'
import { authModule, eventModule, userModule } from '@/store'
import { User } from '@/types/user'
import { DatePickerType, TextFieldRules } from '@/utils/enums'
import { formattedDateRange } from '@/utils/date-utils'
@Component({
  name: 'OptionalTripForm',
  components: {
    DateSelectionModal,
    AddButton,
  },
})
class OptionalTripForm extends Vue {
  pickerType = ''
  pickerValues: string[] = []
  inviteeSearchTerm = ''
  invitees: Partial<User>[] = []
  selectedInviteeIndex = -1
  showDeleteDialog = false
  showInviteDialog = false

  get user(): Partial<User> {
    return authModule.user
  }

  get formatDateRange(): string {
    return this.pickerValues.length > 0
      ? formattedDateRange(this.pickerValues, this.pickerType)
      : this.$t('datetimePicker.DATE').toString()
  }

  get inviteeCount(): string {
    return this.invitees.length > 0
      ? `${this.invitees.length + 1} ${this.$t('selected')}`
      : this.$t('travelers').toString()
  }

  public getDates(): {
    type: string
    values: string[]
  } {
    return { type: this.pickerType, values: this.pickerValues }
  }

  public getInvitees(): {
    invitees: Partial<User>[]
  } {
    return { invitees: this.invitees }
  }

  resetInviteeSearchTerm(): void {
    this.inviteeSearchTerm = ''
  }

  saveDates(data?: any) {
    this.pickerType = data?.type || DatePickerType.SPECIFIC
    this.pickerValues =
      data?.type === DatePickerType.SPECIFIC ? data?.dates : data?.months || []
  }

  askToRemove(index: number): void {
    this.selectedInviteeIndex = index
    this.showDeleteDialog = true
  }

  removeSelectedInvitee(): void {
    if (
      this.selectedInviteeIndex < 0 ||
      this.selectedInviteeIndex > this.invitees.length - 1
    ) {
      return
    }

    this.invitees.splice(this.selectedInviteeIndex, 1)
    this.selectedInviteeIndex = -1
  }

  async addInvitee(): Promise<void> {
    this.showInviteDialog = false
    const foundUser = await userModule.searchUser(this.inviteeSearchTerm)

    if (foundUser) {
      const isDuplicated = [this.user, ...this.invitees].some(
        (invitee) => invitee.id === foundUser.id,
      )

      if (isDuplicated) {
        eventModule.newInfo('info.userAlreadyAdded')
      } else {
        this.invitees.push(foundUser)
      }
    } else if (TextFieldRules.EMAIL(this.inviteeSearchTerm) === true) {
      const isDuplicated = [this.user, ...this.invitees].some(
        (invitee) => invitee.email === this.inviteeSearchTerm,
      )

      if (isDuplicated) {
        eventModule.newInfo('info.userAlreadyAdded')
      } else {
        this.invitees.push({
          email: this.inviteeSearchTerm,
        })
      }
    } else {
      eventModule.newError(['error.email_invalid', 'error.email_retry'])
    }

    this.resetInviteeSearchTerm()
  }
}
export default OptionalTripForm
