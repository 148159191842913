
import { Vue, Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import LDialog from '@/global-components/LDialog.vue'
import InspirationPreview from '@/components/onboarding/discover/InspirationPreview.vue'
import InspirationList from '@/components/onboarding/discover/InspirationList.vue'
import { authModule, discoverModule, eventModule } from '@/store'
import { Category, Hashtag, Inspiration } from '@/types/discover'
import { DiscoverSearchQueryType, DiscoverSearchType } from '@/utils/enums'

interface HistoryItem {
  object: Inspiration | Category | Hashtag
  type: number
}

@Component({
  name: 'InspirationModal',
  components: {
    InspirationPreview,
    InspirationList,
  },
})
class InspirationModal extends Vue {
  /**
   * Enables routing to the current inspiration
   */
  @Prop({ default: true, type: Boolean }) readonly itemNavigation!: boolean
  showInspirationList = false
  currentMode: number = DiscoverSearchType.KEYWORDS
  history: HistoryItem[] = []

  get routes(): any {
    return this.$router
  }

  get currentInspiration(): Inspiration | null {
    return discoverModule.currentInspiration
  }

  get currentCategory(): Category | null {
    return discoverModule.category
  }

  get currentHashtag(): Hashtag | null {
    return discoverModule.hashtag
  }

  get isLoggedIn(): boolean {
    return authModule.loggedIn
  }

  get name(): string {
    if (this.currentMode === DiscoverSearchType.HASHTAG) {
      return `#${this.currentHashtag?.value || ''}`
    } else if (this.currentMode === DiscoverSearchType.KEYWORDS) {
      return this.currentInspiration?.name || ''
    } else if (this.currentMode === DiscoverSearchType.CATEGORY) {
      return this.currentCategory?.name || ''
    }
    return ''
  }

  get discoverSearchModal(): boolean {
    return eventModule.discoverSearchModal
  }

  set discoverSearchModal(value: boolean) {
    eventModule.setDiscoverSearchModal(value)
  }

  routeToInspirationDetails(): void {
    if (this.currentInspiration) {
      this.$router.push({
        name: 'discover-inspiration-inspiration',
        params: {
          inspiration: this.currentInspiration.id,
          name: this.name,
        },
      })
    }
  }

  public toggleDialog(mode?: number): void {
    if (!mode) {
      this.showInspirationList = false
      this.addHistoryItem(DiscoverSearchType.KEYWORDS)

      const dialog = this.$refs['inspiration-modal'] as LDialog
      if (dialog) {
        dialog.toggleDialog()
      }
      return
    }

    if (mode !== DiscoverSearchType.KEYWORDS) {
      this.showInspirationList = true
    }

    if (
      this.isLoggedIn &&
      this.$vuetify.breakpoint.mdAndUp &&
      mode === DiscoverSearchType.KEYWORDS
    ) {
      this.routeToInspirationDetails()
      this.discoverSearchModal = false
    } else {
      this.addHistoryItem(mode)

      const dialog = this.$refs['inspiration-modal'] as LDialog
      if (dialog) {
        dialog.toggleDialog()
      }
    }
  }

  addHistoryItem(mode: number): void {
    this.currentMode = mode
    history.replaceState({}, '', `${this.$route.fullPath}`)

    if (
      this.currentMode === DiscoverSearchType.KEYWORDS &&
      this.currentInspiration
    ) {
      this.history.push({
        object: cloneDeep(this.currentInspiration),
        type: this.currentMode,
      })

      if (this.itemNavigation) {
        history.replaceState(
          {},
          '',
          `${this.$route.fullPath}/discover/inspiration/${this.currentInspiration.id}`,
        )
      }
    } else if (
      this.currentMode === DiscoverSearchType.CATEGORY &&
      this.currentCategory
    ) {
      this.history.push({
        object: cloneDeep(this.currentCategory),
        type: this.currentMode,
      })
    } else if (
      this.currentMode === DiscoverSearchType.HASHTAG &&
      this.currentHashtag
    ) {
      this.history.push({
        object: cloneDeep(this.currentHashtag),
        type: this.currentMode,
      })
    }
  }

  removeHistoryItem(): void {
    this.history.pop()

    if (this.history.length === 0) {
      return
    }

    this.currentMode = this.history[this.history.length - 1].type
    history.replaceState({}, '', `${this.$route.fullPath}`)

    if (this.currentMode === DiscoverSearchType.KEYWORDS) {
      discoverModule.setCurrentInspiration(
        this.history[this.history.length - 1].object as Inspiration,
      )

      if (this.currentInspiration && this.itemNavigation) {
        history.replaceState(
          {},
          '',
          `${this.$route.fullPath}/discover/inspiration/${this.currentInspiration.id}`,
        )
      }
    } else if (this.currentMode === DiscoverSearchType.CATEGORY) {
      discoverModule.setCurrentCategory(
        this.history[this.history.length - 1].object as Category,
      )
    } else if (this.currentMode === DiscoverSearchType.HASHTAG) {
      discoverModule.setCurrentHashtag(
        this.history[this.history.length - 1].object as Hashtag,
      )
    }
    this.showInspirationList = this.currentMode !== DiscoverSearchType.KEYWORDS
  }

  async openInspiration(inspiration: Inspiration): Promise<void> {
    if (!inspiration) {
      return
    }

    await discoverModule.fetchInspiration(inspiration.id)

    this.showInspirationList = false
    this.addHistoryItem(DiscoverSearchType.KEYWORDS)
  }

  async openCategory(category: Category): Promise<void> {
    if (!category) {
      return
    }

    discoverModule.setCurrentCategory(category)

    await discoverModule.fetchInspirationsBySearchTerm({
      searchType: DiscoverSearchQueryType.CATEGORY,
      value: category.id,
    })

    this.showInspirationList = true
    this.addHistoryItem(DiscoverSearchType.CATEGORY)
  }
}
export default InspirationModal
