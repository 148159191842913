
import { Vue, Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import LDialog from '@/global-components/LDialog.vue'
import AirportSearch from '@/components/trip/transportation/AirportSearch.vue'
import GoogleLocationSearch from '@/components/GoogleLocationSearch.vue'
import { tripModule, transportationModule } from '@/store'
import { Waypoint } from '@/types/trip'
import { LocationSearchType, FieldType, DatePickerType } from '@/utils/enums'
import {
  formattedLocation,
  formattedLocationFromCoordinates,
} from '@/utils/utility-manager'
import { formattedDateRange } from '@/utils/date-utils'

@Component({
  name: 'LocationSearchModal',
  components: {
    LDialog,
    AirportSearch,
    GoogleLocationSearch,
  },
})
class LocationSearchModal extends Vue {
  @Prop() readonly searchType?: string
  @Prop() readonly screenSource?: string
  @Prop({ default: false, type: Boolean })
  readonly omitTransportationWaypoints?: boolean

  @Prop({ default: false, type: Boolean }) readonly showWaypoints!: boolean
  @Prop({ default: false, type: Boolean }) readonly autofocus!: boolean

  locationSearchType = LocationSearchType
  fieldType = FieldType
  showWaypointChips = true

  get waypoints(): Waypoint[] {
    if (this.omitTransportationWaypoints) {
      const waypoints = cloneDeep(tripModule.trip?.waypoints) || []

      if (this.origin) {
        this.removeWaypoint(waypoints, this.origin._id)
      }

      if (this.destination) {
        this.removeWaypoint(waypoints, this.destination._id)
      }
      return waypoints
    }
    return tripModule.trip?.waypoints || []
  }

  get origin(): Waypoint | null {
    return transportationModule.currentOrigin
  }

  get destination(): Waypoint | null {
    return transportationModule.currentDestination
  }

  get searchModalTitle(): string {
    if (this.searchType === LocationSearchType.LOCATION) {
      return this.$t('selectLocation') as string
    } else if (this.searchType === LocationSearchType.AIRPORT) {
      return this.$t('selectAirport') as string
    } else {
      return ''
    }
  }

  async selectLocation(select: any): Promise<void> {
    if (this.searchType === this.locationSearchType.LOCATION) {
      if (!select.place.id) {
        this.$emit('select', formattedLocationFromCoordinates(select.place))
      } else {
        const location = await formattedLocation(select.place, select.source)
        this.$emit('select', location)
      }
    } else if (this.searchType === this.locationSearchType.AIRPORT) {
      this.$emit('select', select)
    }
    this.toggleDialog()
  }

  setWaypointLocation(waypoint: Waypoint): void {
    this.$emit('select:waypoint', waypoint)
    this.toggleDialog()
  }

  removeWaypoint(waypoints: Waypoint[], waypointId: string): void {
    const index = waypoints.findIndex((wp: Waypoint) => wp._id === waypointId)
    waypoints.splice(index, 1)
  }

  formattedWaypointDate(waypoint: Waypoint): string {
    if (!waypoint.startDate?.value) {
      return this.$t('noDate').toString()
    }

    const waypointDates = [waypoint.startDate.value]

    if (waypoint.endDate?.value) {
      waypointDates.push(waypoint.endDate.value)
    }

    return formattedDateRange(waypointDates, DatePickerType.SPECIFIC)
  }

  public toggleDialog(): void {
    const dialog = this.$refs.locationSearchModal as LDialog
    if (dialog) {
      dialog.toggleDialog()
    }
  }
}
export default LocationSearchModal
