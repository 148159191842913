
import { Component, Vue } from 'vue-property-decorator'
import { tripModule } from '@/store'
import { isBrowser } from '@/utils/utility-manager'

@Component({
  name: 'ShareTripContent',
})
class ShareTripContent extends Vue {
  isFrame = false
  urlCopyBtnIcon = '$vuetify.icons.copy'

  get shareTripLink(): string {
    if (!tripModule.shareTripLink) return ''

    const shortEmbedUrl = this.shortenUrl(tripModule.shareTripLink.embed)
    return this.isFrame ? shortEmbedUrl : tripModule.shareTripLink.url
  }

  get shareTripUrl(): string {
    if (!tripModule.shareTripLink) return ''

    return this.isFrame
      ? tripModule.shareTripLink.embed
      : tripModule.shareTripLink.url
  }

  get shareLinkBtnClass(): string {
    return this.$vuetify.breakpoint.xs
      ? 'rounded-card primary--text xs-font-size share-link-btn'
      : 'rounded-card primary--text share-link-btn'
  }

  shortenUrl(url: string): string {
    const firstEndTagIndex = url.indexOf('>') + 1
    const secondEndTagIndex = url.indexOf('>', firstEndTagIndex)
    const urlArray = url.split(';')

    return `${urlArray[0]};...${url.substring(secondEndTagIndex)}`
  }

  openShareTripPreview(): void {
    if (!tripModule.shareTripLink || !isBrowser()) return

    window.open(tripModule.shareTripLink.url, '_blank')
  }

  async copyToClipBoard(): Promise<void> {
    if (!isBrowser()) return

    await navigator.clipboard.writeText(this.shareTripUrl)
    this.urlCopyBtnIcon = '$vuetify.icons.check'

    setTimeout(() => {
      this.urlCopyBtnIcon = '$vuetify.icons.copy'
    }, 800)
  }

  openInviteYourFriendsPanel(): void {
    this.$emit('click:invite-btn')
  }
}
export default ShareTripContent
