import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { set } from 'vue'
import {
  Feature,
  Inspiration,
  TopList,
  Bookmark,
  Category,
  Hashtag,
  Suggestions,
} from '@/types/discover'
import { DiscoverSearchQueryType } from '@/utils/enums'

@Module({ name: 'discover', stateFactory: true, namespaced: true })
export default class DiscoverModule extends VuexModule {
  private feature: Feature | null = null
  private pInspiration: Inspiration | null = null
  private topLists: TopList[] = []
  private currInspiration: Inspiration | null = null
  private allSuggestions: Suggestions[] = []
  private categoryLists: Inspiration[] = []
  private allCategoryLists: Category[] = []
  private currentCategory: Category | null = null
  private bookmarkList: Bookmark[] = []
  private searchedInspirations: Inspiration[] = []
  private currentHashtag: Hashtag | null = null
  private searchedHashtags: Hashtag[] = []

  get periodicFeature() {
    return this.feature
  }

  get periodicInspiration() {
    return this.pInspiration
  }

  get toplists(): TopList[] {
    return this.topLists
  }

  get currentInspiration() {
    return this.currInspiration
  }

  get categories(): Inspiration[] {
    return this.categoryLists
  }

  get allCategories(): Category[] {
    return this.allCategoryLists
  }

  get category(): Category | null {
    return this.currentCategory
  }

  get bookmarks(): Bookmark[] {
    return this.bookmarkList
  }

  get inspirationsBySearch(): Inspiration[] {
    return this.searchedInspirations
  }

  get suggestions(): Suggestions[] {
    return this.allSuggestions
  }

  get hashtag(): Hashtag | null {
    return this.currentHashtag
  }

  get hashtags(): Hashtag[] {
    return this.searchedHashtags
  }

  @Mutation
  private setFeature(payload: Feature | null) {
    set(this, 'feature', payload)
  }

  @Mutation
  private setpInspiration(payload: Inspiration | null) {
    set(this, 'pInspiration', payload)
  }

  @Mutation
  private setTopLists(payload: TopList[]) {
    set(this, 'topLists', payload)
  }

  @Mutation
  public setCurrentInspiration(payload: Inspiration | null) {
    set(this, 'currInspiration', payload)
  }

  @Mutation
  private setCategories(payload: Inspiration[]) {
    set(this, 'categoryLists', payload)
  }

  @Mutation
  private setAllCategories(payload: Category[]) {
    set(this, 'allCategoryLists', payload)
  }

  @Mutation
  public setCurrentCategory(payload: Category) {
    set(this, 'currentCategory', payload)
  }

  @Mutation
  private setBookmarks(payload: Bookmark[]) {
    set(this, 'bookmarkList', payload)
  }

  @Mutation
  public setSearchedInspirations(payload: Inspiration[]) {
    set(this, 'searchedInspirations', payload)
  }

  @Mutation
  public setCurrentHashtag(payload: Hashtag | null) {
    set(this, 'currentHashtag', payload)
  }

  @Mutation
  public setSearchedHashtags(payload: Hashtag[]) {
    set(this, 'searchedHashtags', payload)
  }

  @Mutation
  private setAllSuggestions(payload: Inspiration[]) {
    set(this, 'allSuggestions', payload)
  }

  @Action({ rawError: true })
  async fetchFeature() {
    try {
      const { data } = await this.$axios.get(
        'api/discover/feature/preview?localized=1',
      )
      this.context.commit('setFeature', data.feature)
      this.context.commit('setpInspiration', data.feature.inspiration)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.inspiration.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async fetchTopLists() {
    try {
      const { data } = await this.$axios.get('api/discover/feed?localized=1')
      this.context.commit('setTopLists', data.feed)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.inspiration.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async fetchInspiration(id: string) {
    try {
      const { data } = await this.$axios.get(
        `api/discover/inspirations/${id}?localized=1`,
      )
      await this.context.commit('setCurrentInspiration', data.inspiration)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.inspiration.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async fetchAllCategories() {
    try {
      const { data } = await this.$axios.get(`api/discover/categories`)
      await this.context.commit('setAllCategories', data.categories)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.category.fetchAll', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async fetchCategoriesById(id: string) {
    try {
      const { data } = await this.$axios.get(
        `api/discover/search/cat/${id}?limit=25&page=1&localized=1`,
      )
      await this.context.commit('setCategories', data.results)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.inspiration.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async fetchBookmarks() {
    try {
      const { data } = await this.$axios.get(
        `api/discover/bookmarks?localized=1`,
      )
      this.context.commit('setBookmarks', data.bookmarks)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.bookmark.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async createBookmark(id: string) {
    try {
      await this.$axios.post(`api/discover/bookmarks`, {
        inspirationId: id,
        type: 'Trip',
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.bookmark.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async deleteBookmark(id: string) {
    try {
      await this.$axios.delete(`api/discover/bookmarks/${id}`)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.bookmark.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async importInspiration(id: string) {
    try {
      await this.$axios.put(`api/discover/${id}/import`)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.import.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async isBookmark(bookmarkId: string): Promise<boolean> {
    if (!this.bookmarks || this.bookmarks.length === 0) {
      return false
    }
    const result = await this.bookmarks
      .map((el) => el.inspiration.id)
      .includes(bookmarkId)
    return result
  }

  /**
   * Fetches inspirations or hashtags by search term
   * @param params.searchType - Type of data, see DiscoverSearchQueryType for options
   * @param params.value - Search term to search by
   * @param params.page - Current Page for pagination
   */
  @Action({ rawError: true })
  async fetchBySearchTerm(params: {
    searchType: string
    value: string
    page: number
  }) {
    try {
      const { data } = await this.$axios.get(
        `api/discover/search?${params.searchType}=${params.value}&limit=25&page=${params.page}&localized=1`,
      )

      if (params.searchType === DiscoverSearchQueryType.KEYWORDS) {
        this.context.commit('setSearchedInspirations', data.results)
      } else if (params.searchType === DiscoverSearchQueryType.HASHTAG) {
        this.context.commit('setSearchedHashtags', data.results)
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.inspiration.fetch', {
        root: true,
      })
    }
  }

  /**
   * Fetches only inspirations search term, category or hashtag
   * @param params.searchType - Type of data, see DiscoverSearchQueryType for options
   * @param params.value - Search term to search by
   */
  @Action({ rawError: true })
  async fetchInspirationsBySearchTerm(params: {
    searchType: string
    value: string
  }) {
    try {
      const { data } = await this.$axios.get(
        `api/discover/search/${params.searchType}/${encodeURIComponent(
          params.value,
        )}?localized=1`,
      )

      this.context.commit('setSearchedInspirations', data.results)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.inspiration.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async fetchSuggestions() {
    try {
      const { data } = await this.$axios.get(
        `api/discover/search/suggestions/v2?limit=4&localized=1`,
      )

      await this.context.commit('setAllSuggestions', data.results)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.inspiration.fetchAll', {
        root: true,
      })
    }
  }
}
