import { Component } from 'vue-property-decorator'

Component.registerHooks([
  // nuxt
  'asyncData',
  'fetch',
  'head',
  'middleware',
  'layout',
  'transition',
  'scrollToTop',
  'validate',

  // vue-router
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
])
