
import { Component, Prop, Vue } from 'vue-property-decorator'
import { EmptyStateImages, EmptyStateType } from '@/utils/enums'

@Component({
  name: 'LEmptyState',
})
class LEmptyState extends Vue {
  /**
   * Determines tranlastion keys and image of the empty state
   */
  @Prop() readonly type?: string

  /**
   * Determines size of the icon / image displayed above the empty state title
   */
  @Prop({ default: 64, type: Number }) readonly iconSize!: number

  /**
   * Determines the positioning of the empty state.
   * If true, empty state is positioned absolute
   * If false, empty state is positioned relative
   */
  @Prop({ default: true, type: Boolean }) readonly absolute!: boolean

  /**
   * Hides the title string of the empty state
   */
  @Prop({ default: true, type: Boolean }) readonly hideTitle!: boolean

  /**
   * Hides the hint string of the empty state
   */
  @Prop({ default: false, type: Boolean }) readonly hideHint!: boolean

  /**
   * If true, the empty state hint will always be the general hint (emptyState.hint.general)
   */
  @Prop({ default: false, type: Boolean }) readonly generalHint!: boolean

  img: string | null = null

  get hint(): string {
    if (!this.generalHint && this.$te('emptyState.hint.' + this.type)) {
      return this.$t('emptyState.hint.' + this.type).toString()
    } else {
      return this.$t('emptyState.hint.general').toString()
    }
  }

  created() {
    const imgKey = Object.keys(EmptyStateType).find(
      (key: string) => (EmptyStateType as any)[key] === this.type,
    )
    if (imgKey) this.img = (EmptyStateImages as any)[imgKey]
  }
}

export default LEmptyState
