import { Module, VuexModule, Action } from 'vuex-module-decorators'
import { authModule } from '@/utils/store-init'

@Module({ name: 'system', stateFactory: true, namespaced: true })
export default class SystemModule extends VuexModule {
  @Action({ rawError: true })
  async ping() {
    try {
      const data = {
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        payload: JSON.stringify({
          device: {
            platform: 'web',
            userAgent: navigator.userAgent,
            cookiesEnabled: navigator.cookieEnabled,
            displayWidth: window.screen.width,
            displayHeight: window.screen.height,
          },
        }),
        locale: navigator.language,
      }

      if (authModule.loggedIn) {
        await this.$axios.post(
          `/api/users/${authModule.user.id}/heuristics`,
          data,
        )
      }
    } catch (error) {
      // NO-OP
    }
  }
}
