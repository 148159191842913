
import { Component, Prop } from 'vue-property-decorator'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import { DatePickerType } from '@/utils/enums'
@Component({
  name: 'SelectionDatePicker',
})
class SelectionDatePicker extends DatePicker {
  @Prop({ default: 'SPECIFIC', type: String }) readonly activePicker!: string

  datePickerType = DatePickerType

  created() {
    this.setMinimumDate()
    this.setDateClasses()
  }

  public saveDates() {
    this.dates.sort(this.ascendingSort)
    this.months.sort(this.ascendingSort)
    this.$emit('save', {
      dates: this.dates,
      months: this.months,
      type: this.activePicker,
    })
  }
}

export default SelectionDatePicker
