import {
  mdiAccount,
  mdiAccountGroup,
  mdiAlertOutline,
  mdiArrowCollapseDown,
  mdiArrowLeft,
  mdiBankOutline,
  mdiBeach,
  mdiBookmark,
  mdiBookmarkMultiple,
  mdiBookmarkOutline,
  mdiCalendar,
  mdiCalendarMonth,
  mdiChat,
  mdiCheck,
  mdiCheckUnderline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiChurchOutline,
  mdiClipboardText,
  mdiClockTimeThreeOutline,
  mdiCloseCircle,
  mdiCloudUpload,
  mdiCog,
  mdiCogOutline,
  mdiCompassOutline,
  mdiContentCopy,
  mdiCurrencyUsd,
  mdiDotsHorizontal,
  mdiDotsVertical,
  mdiDownload,
  mdiEarth,
  mdiEmailOutline,
  mdiExportVariant,
  mdiEye,
  mdiEyeOff,
  mdiFacebook,
  mdiFileDocumentMultiple,
  mdiFilePdfBox,
  mdiFilterVariant,
  mdiFolder,
  mdiFormatAlignCenter,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatBold,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiGoogle,
  mdiHeart,
  mdiHeartOutline,
  mdiImageMultiple,
  mdiInformationOutline,
  mdiInstagram,
  mdiIsland,
  mdiLinkVariant,
  mdiLoading,
  mdiLockOpenOutline,
  mdiLockOutline,
  mdiLogout,
  mdiMagnify,
  mdiMagnifyMinus,
  mdiMagnifyPlus,
  mdiMapMarker,
  mdiMapMarkerDistance,
  mdiMapMarkerOutline,
  mdiMinusCircle,
  mdiNearMe,
  mdiOpenInNew,
  mdiOrderBoolAscendingVariant,
  mdiPencilOutline,
  mdiPhone,
  mdiPinterest,
  mdiPlus,
  mdiPlusCircle,
  mdiReorderHorizontal,
  mdiSend,
  mdiStar,
  mdiStarOutline,
  mdiTrashCanOutline,
  mdiTreeOutline,
  mdiTune,
  mdiViewModule,
  mdiWindowClose,
} from '@mdi/js'

export default {
  icons: {
    iconfont: 'mdiSvg',
    values: {
      account: mdiAccount,
      accountGroup: mdiAccountGroup,
      alert: mdiAlertOutline,
      alignCenter: mdiFormatAlignCenter,
      alignLeft: mdiFormatAlignLeft,
      alignRight: mdiFormatAlignRight,
      back: mdiArrowLeft,
      bankOutline: mdiBankOutline,
      beach: mdiBeach,
      bold: mdiFormatBold,
      bookmark: mdiBookmarkOutline,
      bookmarked: mdiBookmark,
      calendar: mdiCalendar,
      calendarMonth: mdiCalendarMonth,
      cancel: mdiCloseCircle,
      chat: mdiChat,
      check: mdiCheck,
      chevronDown: mdiChevronDown,
      chevronLeft: mdiChevronLeft,
      chevronRight: mdiChevronRight,
      chevronUp: mdiChevronUp,
      churchOutline: mdiChurchOutline,
      compassOutline: mdiCompassOutline,
      copy: mdiContentCopy,
      distance: mdiMapMarkerDistance,
      document: mdiFileDocumentMultiple,
      dotsHorizontal: mdiDotsHorizontal,
      dotsVertical: mdiDotsVertical,
      download: mdiDownload,
      earth: mdiEarth,
      edit: mdiPencilOutline,
      editDocument: mdiClipboardText,
      email: mdiEmailOutline,
      export: mdiExportVariant,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
      facebook: mdiFacebook,
      filter: mdiFilterVariant,
      folder: mdiFolder,
      google: mdiGoogle,
      grid: mdiViewModule,
      heart: mdiHeart,
      heartOutline: mdiHeartOutline,
      images: mdiImageMultiple,
      import: mdiArrowCollapseDown,
      imported: mdiCheckUnderline,
      informationOutline: mdiInformationOutline,
      instagram: mdiInstagram,
      island: mdiIsland,
      link: mdiLinkVariant,
      list: mdiFormatListBulleted,
      loading: mdiLoading,
      lock: mdiLockOutline,
      lockOpen: mdiLockOpenOutline,
      logout: mdiLogout,
      map: mdiMapMarker,
      marker: mdiMapMarkerOutline,
      minus: mdiMinusCircle,
      multiBookmark: mdiBookmarkMultiple,
      nearMe: mdiNearMe,
      numberedList: mdiFormatListNumbered,
      openInNew: mdiOpenInNew,
      packingList: mdiOrderBoolAscendingVariant,
      pdf: mdiFilePdfBox,
      phone: mdiPhone,
      pinterest: mdiPinterest,
      plus: mdiPlus,
      plusCircle: mdiPlusCircle,
      reorder: mdiReorderHorizontal,
      search: mdiMagnify,
      send: mdiSend,
      settings: mdiCog,
      settingsOutlined: mdiCogOutline,
      star: mdiStar,
      starOutline: mdiStarOutline,
      strike: mdiFormatStrikethrough,
      time: mdiClockTimeThreeOutline,
      trash: mdiTrashCanOutline,
      treeOutline: mdiTreeOutline,
      tune: mdiTune,
      underline: mdiFormatUnderline,
      upload: mdiCloudUpload,
      usd: mdiCurrencyUsd,
      xMark: mdiWindowClose,
      zoomIn: mdiMagnifyPlus,
      zoomOut: mdiMagnifyMinus,
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#00a685',
        primary100: '#c4e9dd',
        primary800: '#2D6D58',
        backgroundColor: '#F6F6F9',
        light: '#56DBBA',
        secondary: '#f0ca59',
        error: '#FF615A',
        ripple: '#C4C4C4',
        accommodationRed: '#E27777',
        expenseYellow: '#F0AF3D',
        documentBlue: '#4BA1EC',
        listsYellow: '#ecc736',
        blueBlack: '#050030',
        driveBooTintColor: '#0148a0',
        hoverTint: '#DAD9D9',
        pro: '#e8c22b',
        contentViewBackgroundColorSecondary: '#f3f2f8',
        cancelRed: '#fe6b6c',
        lightPrimaryBackground: '#f6fdfd',
      },
      dark: {
        primary100: '#263232',
        primary800: '#00a685',
        backgroundColor: '#090906',
        accommodationRed: '#E27777',
        expenseYellow: '#F0AF3D',
        listsYellow: '#ecc736',
        driveBooTintColor: '#4a83c9',
        contentViewBackgroundColorSecondary: '#2c2c2e',
      },
    },
    options: {
      customProperties: true,
    },
  },
}
