
import { Component, Vue } from 'vue-property-decorator'
import ExpensePhoto from '@/components/trip/expenses/detail/ExpensePhoto.vue'
import CategorySelect from '@/components/trip/expenses/detail/CategorySelect.vue'
import EditExpenseModal from '@/components/trip/expenses/detail/EditExpenseModal.vue'
import { expenseModule, tripModule } from '@/store'
import { Expense } from '@/types/expense'
import { Trip } from '@/types/trip'
import { formattedPrice, isBrowser } from '@/utils/utility-manager'

@Component({
  name: 'DetailHeader',
  components: {
    ExpensePhoto,
    CategorySelect,
    EditExpenseModal,
  },
})
class DetailHeader extends Vue {
  showEditModal = false

  get currency(): string {
    return this.expense?.foreignCurrency || this.trip?.currency || ''
  }

  get trip(): Trip | null {
    return tripModule.trip
  }

  get expense(): Expense | null {
    return expenseModule.expense
  }

  formattedAmount(): string {
    if (!this.expense || !this.trip || !isBrowser()) {
      return '-'
    }

    let calculatedTotalAmount = 0.0

    this.expense.from.forEach(
      (value) => (calculatedTotalAmount += value.amount),
    )

    const isForeignCurrency = this.trip.currency !== this.currency

    let fullAmountText = formattedPrice(
      window.navigator.language,
      this.currency || this.expense.currency,
      calculatedTotalAmount * (1 / (this.expense.rate || 1)),
    )

    if (isForeignCurrency) {
      fullAmountText += ` (${formattedPrice(
        window.navigator.language,
        this.trip.currency,
        calculatedTotalAmount,
      )})`
    }

    return fullAmountText
  }
}
export default DetailHeader
