
import { Component, Prop, Vue } from 'vue-property-decorator'

import InspirationDetails from '@/components/discover/InspirationDetails.vue'
import DiscoverMap from '@/components/discover/DiscoverMap.vue'
import RelatedCategories from '@/components/discover/RelatedCategories.vue'
import InspirationMedia from '@/components/discover/InspirationMedia.vue'
import ImportBtn from '@/components/discover/ImportBtn.vue'
import { discoverModule } from '@/store'
import { Inspiration } from '@/types/discover'
@Component({
  name: 'InspirationPreview',
  components: {
    InspirationDetails,
    DiscoverMap,
    RelatedCategories,
    InspirationMedia,
    ImportBtn,
  },
})
class InspirationPreview extends Vue {
  @Prop({ default: true, type: Boolean }) readonly autoImport!: boolean
  @Prop() readonly importLabel?: string
  get currentInspiration(): Inspiration | null {
    return discoverModule.currentInspiration
  }

  get mapContainerClass(): string {
    if (!this.currentInspiration?.media) {
      return ''
    }

    return this.currentInspiration.media?.length > 0
      ? 'px-md-12 pt-md-5'
      : 'px-md-12 pt-md-0 mt-md-0'
  }
}
export default InspirationPreview
