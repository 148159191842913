
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Company } from '@/types/discover'

@Component({
  name: 'InspirationCompanyBadge',
})
class InspirationCompanyBadge extends Vue {
  @Prop() readonly company!: Company
}
export default InspirationCompanyBadge
