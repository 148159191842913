import { set } from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { authModule } from '@/store'
import { Price, ProSubscription } from '@/types/subscription'
import { InAppPurchaseProduct } from '@/utils/enums'
import { TextIndex } from '@/types/types'

@Module({ name: 'subscription', stateFactory: true, namespaced: true })
export default class SubscriptionModule extends VuexModule {
  public disableStripePayment = false // global flag to disable stripe payment / purchase page
  private currentSubscription?: ProSubscription
  private allSubscriptions: ProSubscription[] = []
  private currentTripSubscription?: ProSubscription
  private allPrices: Price[] = []

  public get prices(): Price[] {
    return this.allPrices
  }

  public get subscription(): ProSubscription | undefined {
    return this.currentSubscription
  }

  public get tripSubscription(): ProSubscription | undefined {
    return this.currentTripSubscription
  }

  public get yearlySubscription(): ProSubscription | undefined {
    return this.allSubscriptions.find((subscription) =>
      [
        InAppPurchaseProduct.LAMBUS_PRO_12M_SUBSCRIPTION,
        InAppPurchaseProduct.LAMBUS_PRO_12M_SUBSCRIPTION_FREE_TRIAL,
      ].includes(subscription.product),
    )
  }

  public get monthlySubscription(): ProSubscription | undefined {
    return this.allSubscriptions.find(
      (subscription) =>
        subscription.product === InAppPurchaseProduct.LAMBUS_PRO_MONTHLY,
    )
  }

  public get subscriptions(): ProSubscription[] {
    return this.allSubscriptions
  }

  @Mutation
  private setCurrentSubscription(subscription?: ProSubscription): void {
    set(this, 'currentSubscription', subscription)
  }

  @Mutation
  private setAllSubscriptions(subscriptions: ProSubscription[]): void {
    set(this, 'allSubscriptions', subscriptions)
  }

  @Mutation
  private setCurrentTripSubscription(subscription?: ProSubscription): void {
    set(this, 'currentTripSubscription', subscription)
  }

  @Mutation
  private setAllPrices(prices: Price[]): void {
    set(this, 'allPrices', prices)
  }

  @Action({ rawError: true })
  public async fetchUserSubscriptions() {
    try {
      const { data } = await this.$axios.get(`/api/subscriptions`)
      this.context.commit('setAllSubscriptions', data.subscriptions)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.subscriptions.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchTripSubscription(tripId: string) {
    try {
      const { data } = await this.$axios.get(
        `/api/subscriptions/trip/${tripId}`,
      )
      this.context.commit('setCurrentTripSubscription', data.subscription)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.subscriptions.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchPrices() {
    try {
      const { data } = await this.$axios.get(`/api/meta/pro/prices`)
      this.context.commit('setAllPrices', data)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.payment.prices.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async getSession(sessionId: string) {
    try {
      const { data } = await this.$axios.get(
        `/api/meta/pro/stripe-session?sessionId=${sessionId}`,
      )

      return data
    } catch (error) {
      this.context.dispatch('event/newError', 'error.payment.session.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async getSessionId(payload: any) {
    try {
      const { data } = await this.$axios.post(
        `/api/meta/pro/stripe-session`,
        payload,
      )

      return data.id
    } catch (error) {
      this.context.dispatch('event/newError', 'error.payment.session.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchUserProLossCount() {
    try {
      const { data } = await this.$axios.get(
        `/api/users/${authModule.user.id}/proLossCounts`,
      )

      return data
    } catch (error) {
      return { packingListCount: 0, routeCount: 0 }
    }
  }

  @Action({ rawError: true })
  public async cancelSubscription(payload: {
    subscriptionId: string
    cancelReasons: TextIndex[]
  }) {
    try {
      await this.$axios.post(`/api/meta/pro/web/cancel`, payload)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.subscription.cancel', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public setSubscription(subscription?: ProSubscription) {
    this.context.commit('setCurrentSubscription', subscription)
  }
}
