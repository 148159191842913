import format from 'date-fns/format/index'

import { i18n } from '@/plugins/i18n'
import { DateRange } from '@/types/trip'
import {
  getLocalStorageString,
  isBrowser,
  padTo2Digits,
} from '@/utils/utility-manager'

export function dateRange({ dates, type }: any): {
  startDate: DateRange
  endDate?: DateRange
} | null {
  if (type === 'WHOLE_MONTH') {
    dates = dates.map((date: string) => {
      if (date.length < 10) {
        return `${date}-15`
      }
      return date
    })
  }

  // Server expects unset date objects as null
  // startdate and enddate are both set
  if (dates.length > 1) {
    return {
      startDate: {
        value: dates[0],
        type,
      },
      endDate: {
        value: dates[dates.length - 1],
        type,
      },
    }
  }
  // only startdate is set
  else if (dates.length === 1) {
    return {
      startDate: {
        value: dates[0],
        type,
      },
    }
  }

  return null
}

export function formattedDateWithWeekday(date: string | number | Date): string {
  const dateFormatSetting = getLocalStorageString(
    'kLambusPreferredDateFormat',
    'system',
  )

  if (dateFormatSetting !== 'system') {
    return format(new Date(date), dateFormatSetting)
  }

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  return localizedDateWithOptions(date, options)
}

export function formattedDate(date: string | number | Date): string {
  const dateFormatSetting = getLocalStorageString(
    'kLambusPreferredDateFormat',
    'system',
  )

  if (dateFormatSetting !== 'system') {
    return format(new Date(date), dateFormatSetting)
  }

  return localizedDateWithOptions(date)
}

export function formattedDateRange(dates: string[], type: string): string {
  let startDate
  let endDate
  if (type === 'SPECIFIC') {
    if (dates.length > 0) {
      startDate = formattedDate(dates[0])
    }
    if (dates.length > 1) {
      endDate = formattedDate(dates[dates.length - 1])
    }
  } else {
    if (dates.length > 0) {
      startDate = format(parsedDate(dates[0]), 'MMM yyyy')
    }
    if (dates.length > 1) {
      endDate = format(parsedDate(dates[dates.length - 1]), 'MMM yyyy')
    }
  }

  // returns formatted dates as string
  if (startDate && endDate) {
    return startDate + ' - ' + endDate
  } else if (startDate && !endDate) {
    return startDate
  } else if (!startDate && endDate) {
    return endDate
  }

  return ''
}

export function parsedDate(dateString: string): Date {
  const dateParts = dateString.split('-') // with yyyy-mm-dd creates an array where [0] = year, [1] = month, [2] = day
  if (dateParts.length === 2) {
    // if month, eg 2020-03
    dateParts.push('01')
  }
  return new Date(
    parseInt(dateParts[0]),
    parseInt(dateParts[1]) - 1,
    parseInt(dateParts[2].substring(0, 2)), // for legacy support. If the date has a time defined, eg '2020-02-02T23:00:00.000Z', the time is stored in [2]
  )
}

export function formatDateTime(datestring: string): string {
  if (datestring.length <= 10) {
    return formatDate(datestring)
  }

  const datetime = new Date(datestring)

  return localizedDateWithOptions(datetime, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

export function formatTime(datestring: string): string {
  const time = new Date(datestring)
  return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

export function formatDate(datestring: string): string {
  const date = new Date(datestring)
  const locale = window.navigator.language.slice(0, 2)
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}

export function localizedDateString(date: DateRange): string {
  if (date.type === 'WHOLE_MONTH') {
    return format(parsedDate(date.value), 'MMM yyyy')
  } else {
    return formattedDate(date.value)
  }
}

export function localizedDateRange(
  startDate?: DateRange,
  endDate?: DateRange,
): string {
  if (startDate) {
    if (endDate) {
      return `${localizedDateString(startDate)} - ${localizedDateString(
        endDate,
      )}`
    } else {
      return localizedDateString(startDate)
    }
  } else {
    return i18n.t('noDate') as string
  }
}

/**
 * @returns a string either 'ampm' or '24hr', depending on wether the current browser locale uses the 12 hour format
 */
export function timePickerFormat(): string {
  const regex = /[ap]\.?m.?/i
  const locale = isBrowser() ? window.navigator.language : 'default'
  const timeString = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format()

  const is12HrFormat = regex.test(timeString)

  return is12HrFormat ? 'ampm' : '24hr'
}

/**
 * Absolutely time zone safe method to get the today of the client as a Lambus conform string value
 * @returns a string representing the current day in the format YYYY-MM-DD
 */
export function formattedToday(): string {
  const date = new Date()

  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-')
}

/**
 * @param startTime time string in HH:mm with or without am/pm
 * @param endTime time string in HH:mm with or without am/pm
 * @returns localized string 'startTime - endTime' or placeholder
 */
export function formattedTimeRange(
  startTime?: string | null,
  endTime?: string | null,
): string {
  if (startTime) {
    const localizedStartTime = localizedTime(startTime)
    if (endTime) {
      const localizedEndTime = localizedTime(endTime)
      return `${localizedStartTime} - ${localizedEndTime}`
    }

    return localizedStartTime
  }

  return i18n.t('addTime').toString()
}

/**
 * @param time time string in HH:mm or HH.mm with or without am/pm or Date object
 * @param {Object} options options to alter the returned time
 * @param {number} [options.addHours] number of hours added to time
 * @param {boolean} [options.ceilToFullHour] option to return the next greater full hour instead of time itsel
 * @returns localized time signature (24hr or ampm)
 */
export function localizedTime(
  time: string | Date,
  options?: { addHours?: number; ceilToFullHour?: boolean },
): string {
  const date =
    typeof time === 'string'
      ? new Date(`1979/1/1 ${delocalizedTime(time)}`)
      : time

  if (options?.ceilToFullHour) {
    date.setHours(date.getHours() + 1, 0, 0, 0)
  }

  if (options?.addHours) {
    date.setHours(date.getHours() + options.addHours)
  }

  return new Intl.DateTimeFormat(i18n.locale, {
    timeStyle: 'short',
  }).format(date)
}

/**
 * @param time time string in HH:mm or HH.mm with or without am/pm
 * @returns delocalized time signature (always 24hr) if time has valid format. Else time is returned
 */
export function delocalizedTime(time: string): string {
  try {
    const parsedTime = time.replaceAll('.', ':')
    return new Intl.DateTimeFormat('de', { timeStyle: 'medium' }).format(
      new Date(`1979/1/1 ${parsedTime}`),
    )
  } catch (error) {
    // TODO: handle error (e.g. invalid time format)
    return time
  }
}

/**
 * @param time time string in HH:mm with or without am/pm
 * @returns number, representing time in 24hr format (e.g. 1130 = '11:30', 2215 = '22:15')
 */
export function timeStringToInt(time: string): number {
  return parseInt(delocalizedTime(time).substring(0, 5).replace(/\D/g, ''))
}

/**
 * @param dates array of dates
 * @returns truncated list of formatted days. Lists the first two dates and displays the number of remaining dates
 */
export function truncatedDates(dates: string[]): string {
  const formattedDays = (dates as string[]).map((day) => formattedDate(day))

  let dateString = ''
  if (formattedDays.length > 0) {
    dateString = formattedDays[0]

    if (formattedDays.length > 1) {
      dateString = `${dateString}, ${formattedDays[1]}`

      if (formattedDays.length > 2) {
        dateString = `${dateString} (+${formattedDays.length - 2})`
      }
    }
  }

  return dateString
}

export function formattedDuration(seconds: number): string {
  if (!seconds) {
    return 'n/a'
  }

  const numDays = Math.floor((seconds % 31536000) / 86400)
  let numHours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)

  const result = []

  if (numDays > 0) {
    numHours += numDays * 24
  }
  if (numHours > 0) {
    result.push(`${numHours} ${i18n.t('hoursShort')}`)
  }
  if (numMinutes > 0) {
    result.push(`${numMinutes} ${i18n.t('minutesShort')}`)
  }

  if (result.length === 0) {
    return `< 1 ${i18n.t('minutesShort')}`
  }

  return result.join(' ')
}

function localizedDateWithOptions(
  date: string | number | Date,
  options?: Intl.DateTimeFormatOptions,
): string {
  const locale = isBrowser() ? window.navigator.language : i18n.locale

  if (typeof date === 'string') {
    return parsedDate(date).toLocaleDateString(locale, options)
  } else if (typeof date === 'number') {
    return new Date(date).toLocaleDateString(locale, options)
  }

  return date.toLocaleDateString(locale, options)
}

/**
 * Returns a human-readable representation of the time difference between a
 * given timestamp and the current time, expressed in relative terms
 * (e.g., "1 hour ago", "5 minutes ago", "just now").
 *
 * @param timestamp - The timestamp to compare against the current time.
 */
export function formatRelativeTime(timestamp: Date) {
  const diff = Math.floor((new Date().getTime() - timestamp.getTime()) / 1000)
  const minutes = Math.floor(diff / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)
  const rtf = new Intl.RelativeTimeFormat(i18n.locale, { numeric: 'auto' })

  if (years > 0) {
    return rtf.format(-years, 'year')
  } else if (months > 0) {
    return rtf.format(-months, 'month')
  } else if (weeks > 0) {
    return rtf.format(-weeks, 'week')
  } else if (days > 0) {
    return rtf.format(-days, 'day')
  } else if (hours > 0) {
    return rtf.format(-hours, 'hour')
  } else if (minutes > 0) {
    return rtf.format(-minutes, 'minute')
  } else if (diff < 10) {
    return i18n.t('justNow').toString()
  } else {
    return rtf.format(-diff, 'second')
  }
}
