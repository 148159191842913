
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import LDialog from '@/global-components/LDialog.vue'
import {
  eventModule,
  documentModule,
  tripModule,
  waypointModule,
} from '@/store'
import { DocumentType, EmptyStateType } from '@/utils/enums'
import { isPDF, thumbnailImage } from '@/utils/utility-manager'
import { Photo } from '@/types/photo'
import { Document } from '@/types/document.js'

@Component({
  name: 'DocumentListModal',
  components: {
    LDialog,
  },
})
class DocumentListModal extends Vue {
  /**
   * Hides documents linked to the current waypoint if true
   */
  @Prop({ default: false, type: Boolean })
  readonly omitLinkedDocuments!: boolean

  /**
   * Only documents that pass this predicate function are shown in the list.
   */
  @Prop() filter?: (value: Document | Photo) => boolean

  loaded = false
  emptyState: string = EmptyStateType.DOCUMENTS

  get trip() {
    return tripModule.trip
  }

  get waypoint() {
    return waypointModule.waypoint
  }

  get files() {
    const filesWithoutDirs = documentModule.files.filter(
      (file) => file.type === DocumentType.FILE,
    )

    if (this.omitLinkedDocuments && this.waypoint) {
      return filesWithoutDirs.filter(
        (file) => file.reference !== this.waypoint!._id,
      )
    }

    if (this.filter) {
      return filesWithoutDirs.filter(this.filter)
    }

    return filesWithoutDirs
  }

  get showDocumentListModal(): boolean {
    return eventModule.documentListModal
  }

  @Watch('showDocumentListModal')
  toggleDocumentListModal(): void {
    if (!this.showDocumentListModal) {
      return
    }
    this.toggleDialog()
  }

  toggleDialog(): void {
    const dialog = this.$refs.lDialog as LDialog
    dialog.toggleDialog()
  }

  async created() {
    if (this.trip) {
      await documentModule.fetchByTrip(this.trip.id)
      this.loaded = true
    }
  }

  isPDF(url?: string): boolean {
    return isPDF(url)
  }

  thumbnailSrc(url?: string): string {
    return thumbnailImage(url, 320)
  }

  setFile(document: Document): void {
    this.$emit('change:document', document)
    this.toggleDialog()
  }
}

export default DocumentListModal
