
import { useQueryClient, QueryClient } from '@tanstack/vue-query'
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { format } from 'date-fns'
import currency from 'currency.js'

import LCombobox from '@/components/core/Combobox.vue'
import { convertToCurrency } from '@/utils/expense'
import PeoplePicker from '@/components/PeoplePicker.vue'
import CategorySelect from '@/components/trip/expenses/detail/CategorySelect.vue'
import ExpensePhoto from '@/components/trip/expenses/detail/ExpensePhoto.vue'
import GpsBasedCurrenciesModal from '@/components/pro/expenses/GpsBasedCurrenciesModal.vue'
import { User } from '@/types/user'
import { ExpenseFraction, Expense } from '@/types/expense'
import {
  tripModule,
  expenseModule,
  authModule,
  eventModule,
  userModule,
} from '@/store'
import { TextFieldRules, DistributionType } from '@/utils/enums'
import {
  formattedPrice,
  getCurrencyArray,
  getCurrencySymbol,
  isBrowser,
} from '@/utils/utility-manager'
import { formattedDate } from '@/utils/date-utils'
import LDialog from '@/global-components/LDialog.vue'
import AWSManager from '@/utils/aws-manager'
import { tripQueryKeys } from '@/composables/trip'

@Component({
  name: 'EditExpenseModal',
  components: {
    PeoplePicker,
    CategorySelect,
    ExpensePhoto,
    GpsBasedCurrenciesModal,
    LCombobox,
  },
  setup() {
    const queryClient = useQueryClient()
    return { queryClient }
  },
})
class EditExpenseModal extends Vue {
  @Prop({ default: false, type: Boolean }) isPrivate!: boolean

  @Prop({ type: Object, default: () => ({}) }) initialData!: Partial<Expense>

  isLoading = false

  distribution: string = DistributionType.EQUAL

  currencies = getCurrencyArray()

  subject = ''
  from: ExpenseFraction[] = [
    {
      userId: (authModule.user as User).id,
      amount: 0,
    },
  ]

  photo: any = null
  currency = ''
  category = 'general'
  pictures: string[] = []
  payingAttendees: User[] = []
  relatedAttendees: User[] = []
  to: ExpenseFraction[] = []
  createdAt: string | null = null
  activeStep = 0
  exchangeRate = 0
  showDatePicker = false
  rules = TextFieldRules
  showGpsBasedCurrenciesModal = false
  hideLocationRequest = false

  queryClient!: QueryClient

  get expense() {
    return expenseModule.expense
  }

  get cardTitle() {
    return this.expense ? this.$t('updateExpense') : this.$t('createExpense')
  }

  get gpsCurrencyModalShowedOnce(): boolean {
    return eventModule.gpsCurrencyModalShowedOnce
  }

  get trip() {
    return tripModule.trip
  }

  get isPrivateLocal(): boolean {
    return !this.expense?._id && this.isPrivate
  }

  get relatedAttendeesIsEditable(): boolean {
    return this.totalSum !== 0 && this.subject !== '' && this.remainingSum === 0
  }

  get dateIsEditable(): boolean {
    if (this.expense?._id) {
      return !!this.expense?._id && this.relatedAttendeesIsEditable
    }

    return this.relatedAttendeesIsEditable
  }

  get nonPayingAttendees(): User[] {
    return this.trip!.attendees.filter(
      (el) => !this.payingAttendees.find((x) => x.id === el.id),
    )
  }

  get nonRelatedAttendees(): User[] {
    return this.trip!.attendees.filter(
      (el) => !this.relatedAttendees.find((x) => x.id === el.id),
    )
  }

  get totalSum(): number {
    return this.from.reduce((sum, current) => sum + current.amount * 1, 0)
  }

  get remainingSum(): number {
    const remainingSum =
      this.totalSum -
      this.to.reduce((sum, current) => sum + current.amount * 1.0, 0)

    return Math.round(remainingSum * 100) / 100
  }

  get formattedCreationDate(): string {
    if (!this.createdAt) {
      return ''
    }
    return formattedDate(this.createdAt)
  }

  get distributionParams(): [number, string] {
    return [this.totalSum, this.distribution]
  }

  get toBaseCurrency(): number {
    return this.totalSum * this.exchangeRate
  }

  get symbolSlot(): string {
    if (!isBrowser()) return 'append'
    const formattedValue = formattedPrice(
      window.navigator.language,
      this.currency,
    )

    if (formattedValue.indexOf('€') > 0) {
      return 'append'
    } else {
      return 'prepend-inner'
    }
  }

  get userLocation(): GeolocationPosition | null {
    return userModule.userLocation
  }

  @Watch('currency')
  async getExchangeRate(): Promise<void> {
    if (!this.trip || this.trip.currency === this.currency) {
      return
    }

    this.exchangeRate = await expenseModule.exchangeRate({
      tripCurrency: this.trip.currency,
      currency: this.currency,
    })
  }

  @Watch('distributionParams')
  balancedDistribution(): void {
    if (this.distribution === DistributionType.EXACT && this.to.length !== 0)
      return
    this.to = []
    const amounts = currency(this.totalSum).distribute(
      this.relatedAttendees.length,
    )
    for (const [index, attendee] of this.relatedAttendees.entries()) {
      this.to.push({
        userId: attendee.id,
        amount: Number(amounts[index]),
      })
    }
  }

  @Watch('expense')
  initValues(): void {
    if (this.expense) {
      this.relatedAttendees = []
      this.payingAttendees = []

      for (const to of this.expense.to) {
        this.addRelatedAttandee(to.userId, to.amount)
      }

      for (const from of this.expense.from) {
        this.addPayingAttandee(from.userId, from.amount)
      }

      this.from = this.expense.from.map((el) => {
        return { userId: el.userId, amount: el.amount }
      })

      this.to = this.expense.to.map((el) => {
        return { userId: el.userId, amount: el.amount }
      })

      this.subject = this.expense.subject
      this.createdAt = this.expense.createdAt
        ? format(new Date(this.expense.createdAt), 'yyyy-MM-dd')
        : null
      this.currency = this.expense.currency
      this.category = this.expense.category || 'general'
      this.pictures = this.expense.pictures || []
      this.activeStep = 1
      this.showDatePicker = false
    } else {
      this.resetValues()
    }
  }

  created() {
    this.initValues()
  }

  mounted() {
    if (!this.expense && this.initialData.subject) {
      this.subject = this.initialData.subject
    }
  }

  beforeDestroy() {
    expenseModule.resetExpense()
  }

  resetValues(): void {
    Vue.set(this, 'payingAttendees', [authModule.user as User])
    this.from = []
    this.from.push({
      userId: (authModule.user as User).id,
      amount: 0,
    })
    Vue.set(this, 'relatedAttendees', [...this.trip!.attendees])
    this.balancedDistribution()
    this.subject = ''
    this.distribution = DistributionType.EQUAL
    this.activeStep = 1
    this.exchangeRate = 0
    this.showDatePicker = false
    this.currency = this.trip!.currency || 'EUR'

    if (
      this.gpsCurrencyModalShowedOnce &&
      !this.hideLocationRequest &&
      this.userLocation
    ) {
      this.findCurrency(this.userLocation)
    }

    this.createdAt = null
  }

  addPayingAttandee(id: string, amount?: number): void {
    const user = this.trip!.attendees.find((el) => el.id === id)
    if (!user) return
    this.payingAttendees.push(user)
    this.from.push({
      userId: user.id,
      amount: amount || 0,
    })
  }

  removePayingAttandee(id: string): void {
    const index = this.payingAttendees.map((el) => el.id).indexOf(id)
    if (typeof index !== 'number') return
    this.$delete(this.payingAttendees, index)
    this.$delete(this.from, index)
  }

  addRelatedAttandee(id: string, amount?: number): void {
    const user = this.trip!.attendees.find((el) => el.id === id)
    if (!user) return
    this.relatedAttendees.push(user)
    this.to.push({
      userId: user.id,
      amount: amount || 0,
    })
  }

  removeRelatedAttandee(id: string): void {
    const index = this.relatedAttendees.map((el) => el.id).indexOf(id)
    if (typeof index !== 'number') return
    this.$delete(this.relatedAttendees, index)
    this.$delete(this.to, index)
    this.balancedDistribution()
  }

  currencySymbol(code: string): string {
    return getCurrencySymbol(code)
  }

  public toggleDialog(): void {
    const dialog = this.$refs.editExpenseDialog as LDialog
    if (dialog) {
      dialog.toggleDialog()
    }
  }

  toggleDistribution(): void {
    this.distribution =
      this.distribution === DistributionType.EQUAL
        ? DistributionType.EXACT
        : DistributionType.EQUAL
  }

  async saveExpense(): Promise<void> {
    if (!this.trip) {
      return
    }

    this.isLoading = true
    const fromBase = [...this.from]
    const toBase = [...this.to]
    const { reference, onModel } = this.initialData

    if (this.currency !== this.trip.currency) {
      for (const account of fromBase) {
        account.amount = currency(account.amount).multiply(
          this.exchangeRate,
        ).value
      }
      for (const account of toBase) {
        account.amount = currency(account.amount).multiply(
          this.exchangeRate,
        ).value
      }
    }

    const params: Expense = {
      to: this.isPrivateLocal ? fromBase : toBase,
      from: fromBase,
      subject: this.subject,
      currency: this.trip.currency,
      category: this.category,
      foreignCurrency:
        this.currency === this.trip.currency ? undefined : this.currency,
      rate:
        this.currency === this.trip.currency ? undefined : this.exchangeRate,
      createdAt: this.createdAt ? new Date(this.createdAt) : null,
      createdBy: authModule.user.id!,
      isPrivate: this.isPrivateLocal,
      reference,
      onModel,
    }

    if (this.expense) {
      const updatedExpense = await expenseModule.updateExpense({
        tripId: this.trip.id,
        expenseId: this.expense._id,
        payload: params,
      })
      this.$emit('update', updatedExpense)
    } else {
      const newExpense = await expenseModule.createExpense({
        tripId: this.trip.id,
        payload: params,
      })
      this.$emit('create', newExpense)

      if (newExpense && this.trip && this.photo && this.pictures.length > 0) {
        await AWSManager.uploadFile(
          `trips/${this.trip.id}/expenses/${newExpense._id}/${this.pictures[0]}`,
          this.photo,
          () => {
            expenseModule.updateExpense({
              tripId: this.trip?.id,
              expenseId: newExpense._id,
              payload: { pictures: this.pictures },
            })
          },
        )
      }
    }

    this.queryClient.invalidateQueries({
      queryKey: tripQueryKeys.summary(this.trip.id),
    })

    await tripModule.fetch(this.trip.id)
    this.isLoading = false
    this.resetValues()
    this.toggleDialog()
  }

  openFindCurrenciesByGpsModal(): void {
    this.activeStep = 2
    if (this.trip!.activeProSubscription) {
      if (!this.gpsCurrencyModalShowedOnce) {
        this.showGpsBasedCurrenciesModal = true
        eventModule.setDisplayedGpsCurrencyModalOnce(true)
      }
    }
  }

  async findCurrency(location: GeolocationPosition): Promise<void> {
    const lat = location.coords.latitude
    const lon = location.coords.longitude
    const params = { lat, lon }

    this.currency = await expenseModule.findCurrency({
      trip: this.trip!.id,
      params,
    })
  }

  showProHintModal(): void {
    if (!this.trip!.activeProSubscription) {
      eventModule.toggleProHintModal()
    }
  }

  getCurrency(value: number) {
    return convertToCurrency(value)
  }
}
export default EditExpenseModal
