
import { defineComponent } from 'vue'

export default defineComponent({
  head: {
    htmlAttrs: {
      class: 'h-full',
    },
    bodyAttrs: {
      class: 'h-full assistant',
    },
  },
})
