
import { Component, Vue } from 'vue-property-decorator'
import { eventModule } from '@/store'
import { TextFieldRules } from '@/utils/enums'
@Component({
  name: 'FeedbackExpansionPanel',
})
class FeedbackExpansionPanel extends Vue {
  rules = TextFieldRules
  feedback = ''

  async sendFeedback(): Promise<void> {
    try {
      await this.$axios.post('/api/feedback', { content: this.feedback })
      this.feedback = ''
      eventModule.newMessage('success.feedback')
    } catch (error) {
      eventModule.newError('error.feedback')
    }
  }
}
export default FeedbackExpansionPanel
