
import { Component, Prop, Vue } from 'vue-property-decorator'

import NumberedBadge from '@/components/trip/travelPlan/waypoint/pointOfInterest/NumberedBadge.vue'
import { eventModule, waypointModule } from '@/store'
import { PointOfInterest, Waypoint } from '@/types/trip'
import { logEvent } from '@/utils/utility-manager'
import { truncatedDates } from '@/utils/date-utils'

@Component({
  name: 'POIListItem',
  components: {
    NumberedBadge,
  },
})
class POIListItem extends Vue {
  @Prop() readonly poi!: PointOfInterest
  @Prop({ default: false, type: Boolean }) readonly numbered!: boolean
  @Prop({ default: -1, type: Number }) readonly number!: number

  /**
   * Optional. Is used to set as store waypoint on click
   */
  @Prop() readonly waypoint?: Waypoint

  img!: HTMLImageElement
  ctx!: CanvasRenderingContext2D
  canvas!: HTMLCanvasElement
  imgSize = 20

  get showNumberedBadge(): boolean {
    return this.numbered && this.number > 0
  }

  get linkedDays(): string | boolean {
    if (
      this.poi.days &&
      this.poi.days.length > 0 &&
      typeof this.poi.days[0] === 'string'
    ) {
      return truncatedDates(this.poi.days as string[])
    }

    return false
  }

  mounted() {
    if (!this.poi.location || !this.poi.location.icon) {
      return
    }

    this.img = document.createElement('img')
    this.img.onload = () => this.draw()
    this.img.src = this.poi.location.icon

    if (this.poi._id) {
      this.canvas = this.$refs[this.poi._id] as HTMLCanvasElement
    }

    this.ctx = this.canvas.getContext('2d')!
  }

  setCurrentPOI(): void {
    logEvent('current_screen_highlight_details')

    if (this.waypoint) {
      waypointModule.setWaypoint(this.waypoint)
    }

    waypointModule.setPointOfInterest(this.poi)
    eventModule.togglePoiDetails(true)
  }

  draw(): void {
    // draw color
    this.ctx.fillStyle = '#FFC94A'
    this.ctx.fillRect(0, 0, this.imgSize, this.imgSize)

    // set composite mode
    this.ctx.globalCompositeOperation = 'destination-in'

    // draw image
    this.ctx.drawImage(this.img, 0, 0, this.imgSize, this.imgSize)
  }
}
export default POIListItem
