
import { Component, Vue } from 'vue-property-decorator'

import LDialog from '@/global-components/LDialog.vue'
import DetailHeader from '@/components/trip/expenses/detail/DetailHeader.vue'
import ExpenseChart from '@/components/trip/expenses/detail/PieChart.vue'
import AttendeeListItem from '@/components/trip/expenses/detail/AttendeeListItem.vue'
import EditExpenseModal from '@/components/trip/expenses/detail/EditExpenseModal.vue'
import { tripModule, expenseModule } from '@/store'
import { User } from '@/types/user'

@Component({
  name: 'DetailExpenseModal',
  components: {
    DetailHeader,
    ExpenseChart,
    AttendeeListItem,
    EditExpenseModal,
  },
})
class DetailExpenseModal extends Vue {
  showEditModal = false
  showDeletePrompt = false

  get trip() {
    return tripModule.trip
  }

  get expense() {
    return expenseModule.expense
  }

  get receivingAttendees(): User[] {
    return this.getAttendeeData(
      this.expense?.to.map((fraction) => fraction.userId) || [],
    )
  }

  get payingAttendees(): User[] {
    return this.getAttendeeData(
      this.expense?.from.map((fraction) => fraction.userId) || [],
    )
  }

  get chartLabels(): string[] {
    return this.receivingAttendees.map((el) => el.firstName || '')
  }

  public toggleDialog(): void {
    const dialog = this.$refs.lDialog as LDialog
    dialog.toggleDialog()
  }

  getAttendeeData(userIds: string[]) {
    const users: User[] = []

    for (const id of userIds) {
      const userData = this.findAttendee(id)
      if (userData) {
        users.push(userData)
      }
    }

    return users
  }

  findAttendee(userId: string): User | null {
    let user = this.trip?.attendees.find((el) => el.id === userId) || null

    if (user) {
      Object.assign(user, { fullName: `${user.firstName} ${user.lastName}` })
      return user
    }

    user = this.trip?.leftAttendees.find((el) => el.id === userId) || null
    if (user) {
      Object.assign(user, {
        fullName: `${user.firstName} ${user.lastName} (${this.$t('leftTrip')})`,
      })
    }

    return user
  }

  async deleteExpense(): Promise<void> {
    if (!this.expense?._id) return

    await expenseModule.deleteExpense({
      tripId: this.trip!.id,
      expenseId: this.expense._id,
    })

    this.showDeletePrompt = false
    this.toggleDialog()

    tripModule.fetch(this.trip!.id)
  }
}
export default DetailExpenseModal
