
import { Component, Vue } from 'vue-property-decorator'
import WaypointList from '@/components/trip/travelPlan/waypoint/WaypointList.vue'

@Component({
  components: {
    WaypointList,
  },
})
class WaypointListModal extends Vue {
  showDialog = false

  public toggleDialog(): void {
    this.showDialog = !this.showDialog
  }
}
export default WaypointListModal
