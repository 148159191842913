
import { Component, Prop } from 'vue-property-decorator'
import AddWaypointLoader from '@/components/trip/transportation/forms/generic/AddWaypointLoader.vue'
import WaypointLocationField from '@/components/trip/transportation/forms/generic/WaypointLocationField.vue'
import DateTimeField from '@/components/trip/transportation/forms/generic/DateTimeField.vue'
import WaypointLocationForm from '@/components/trip/transportation/forms/generic/WaypointLocationForm.vue'
import { transportationModule } from '@/store'
import { Location } from '@/types/trip'
import { TransportationType } from '@/utils/enums'

@Component({
  name: 'StationForm',
  components: {
    AddWaypointLoader,
    WaypointLocationField,
    DateTimeField,
  },
})
class StationForm extends WaypointLocationForm {
  @Prop({ default: TransportationType.TRAIN, type: String })
  readonly type?: string

  time = ''

  searchType = ''

  originStation: Location | null = null
  originLocationPlaceholder = ''

  destinationStation: Location | null = null
  destinationLocationPlaceholder = ''

  get originSearchPrefill(): string | undefined {
    return this.origin ? `${this.searchType} ${this.origin.name}` : undefined
  }

  get destinationSearchPrefill(): string | undefined {
    return this.destination
      ? `${this.searchType} ${this.destination.name}`
      : undefined
  }

  created() {
    if (this.type === TransportationType.FERRY) {
      this.searchType = this.$t('port').toString()
      this.originLocationPlaceholder = this.$t('plusDeparturePort').toString()
      this.destinationLocationPlaceholder =
        this.$t('plusArrivalPort').toString()
    } else {
      this.searchType = this.$t('train_station').toString()
      this.originLocationPlaceholder = this.$t(
        'plusDepartureStation',
      ).toString()
      this.destinationLocationPlaceholder =
        this.$t('plusArrivalStation').toString()
    }

    if (this.trip?.waypoints.length === 2) {
      this.origin = this.trip.waypoints[0]
      this.destination = this.trip.waypoints[1]
    }
  }

  saveBooking(): void {
    if (!this.trip || !this.origin || !this.destination) {
      return
    }

    this.isSaving = true

    const booking = {
      type: this.type,
      trip: this.trip.id,
      originWaypoint: this.origin,
      destinationWaypoint: this.destination,
    }

    if (this.type === TransportationType.TRAIN) {
      Object.assign(booking, {
        originStation: this.originStation || undefined,
        destinationStation: this.destinationStation || undefined,
      })
    } else if (this.type === TransportationType.BUS) {
      Object.assign(booking, {
        originStation: this.originStation?.name || undefined,
        destinationStation: this.destinationStation?.name || undefined,
      })
    } else if (this.type === TransportationType.FERRY) {
      Object.assign(booking, {
        departurePort: this.originStation?.name || undefined,
        arrivalPort: this.destinationStation?.name || undefined,
      })
    }

    if (this.dates.length > 0) {
      let departureDate = this.dates[0]

      if (this.time?.includes(':')) {
        departureDate += `T${this.time}:00`
      }

      Object.assign(booking, {
        departureDate,
      })

      if (this.dates.length > 1) {
        Object.assign(booking, {
          arrivalDate: this.dates[this.dates.length - 1],
        })
      }
    }

    transportationModule.setBooking(booking)

    this.$emit('click:add', this.type)
    this.isSaving = false
  }
}
export default StationForm
