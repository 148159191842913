import { render, staticRenderFns } from "./ActivityDialog.vue?vue&type=template&id=6dffc971"
import script from "./ActivityDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./ActivityDialog.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ActivityDialog.vue?vue&type=style&index=0&id=6dffc971&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports