
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'ClipboardTextField',
})
class ClipboardTextField extends Vue {
  @Prop({ default: '', type: String }) readonly textValue!: string
  @Prop({ default: '', type: String }) readonly copyValue!: string
  @Prop({ default: '100%', type: String }) readonly width!: string

  tooltipMsg = 'copyTooltip'

  copyToClipboard(): void {
    ;(this.$refs.token as any).blur()
    const selBox = this.$refs.copyValue as HTMLTextAreaElement
    selBox.select()
    document.execCommand('copy')
    setTimeout(() => {
      // This is a workaround. Without the timeout, scroll position jumps to top, because the tooltip gets rerendered
      this.tooltipMsg = 'copySuccess'
    }, 0)
    setTimeout(() => {
      this.tooltipMsg = 'copyTooltip'
    }, 2000)
  }
}

export default ClipboardTextField
