
import { Component, Vue } from 'vue-property-decorator'
import { subscriptionModule } from '@/store'
import { isBrowser } from '@/utils/utility-manager'
@Component({
  name: 'RouteToProButton',
})
class RouteToProButton extends Vue {
  routeToPro(): void {
    if (!isBrowser()) {
      return
    }

    if (subscriptionModule.disableStripePayment) {
      // open Lambus PRO help center article if payment is disabled
      window.open(
        `${process.env.LAMBUS_HELP_CENTER}/en/articles/4941701-about-lambus-pro`,
        '_blank',
      )
    } else {
      // open Lambus PRO purchase page if payment is enabled
      window.open(`${process.env.DASHBOARD_HOME}/pro/features`, '_blank')
    }
  }
}
export default RouteToProButton
