import { useMutation, useQuery } from '@tanstack/vue-query'
import { unref } from 'vue'
import { MaybeRef } from '@vueuse/core'

import { useContext } from '@/composables/context'
import { eventModule } from '@/store'
import { Inspiration } from '@/types/discover'

export const inspirationQueryKeys = {
  all: () => ['inspirations'] as const,
  details: () => [...inspirationQueryKeys.all(), 'detail'] as const,
  detail: (inspirationId: MaybeRef<string>) =>
    [...inspirationQueryKeys.details(), inspirationId] as const,
}

export function useImportInspiration() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (inspirationId: string) => {
      const { data } = await $axios.put<{ success?: boolean }>(
        `api/discover/${inspirationId}/import`,
      )

      return data
    },
    onError: () => {
      eventModule.newError('error.import.create')
    },
  })
}

export function useInspiration(inspirationId: MaybeRef<string>) {
  const { $axios } = useContext()

  return useQuery({
    queryKey: inspirationQueryKeys.detail(inspirationId),
    queryFn: async () => {
      const {
        data: { inspiration },
      } = await $axios.get<{ inspiration: Inspiration }>(
        `/api/discover/inspirations/${unref(inspirationId)}?localized=1`,
      )
      return inspiration
    },
  })
}
