
import { Component, Prop, Vue } from 'vue-property-decorator'
import RouteLocationField from '@/components/pro/directions/RouteLocationField.vue'
import SeparatedDoubleSlot from '@/components/trip/transportation/forms/generic/SeparatedDoubleSlot.vue'
import { Location, Waypoint } from '@/types/trip'
@Component({
  name: 'WaypointLocationField',
  components: {
    RouteLocationField,
    SeparatedDoubleSlot,
  },
})
class WaypointLocationField extends Vue {
  @Prop() readonly waypoint?: Waypoint
  @Prop() readonly location?: Location
  @Prop() readonly locationPlaceholder?: string
  @Prop() readonly searchPrefill?: string

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }
}
export default WaypointLocationField
