
import { Component, Vue } from 'vue-property-decorator'
import LocationForm from '@/components/trip/transportation/forms/generic/LocationForm.vue'
import { TransportationType } from '@/utils/enums'
@Component({
  name: 'BicycleForm',
  components: {
    LocationForm,
  },
})
class BicycleForm extends Vue {
  type: string = TransportationType.WALKING
}
export default BicycleForm
