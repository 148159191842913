
import { Component, Vue } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import { authModule, eventModule } from '@/store'
import AWSManager from '@/utils/aws-manager'
@Component({
  name: 'ProfileHeader',
})
class ProfileHeader extends Vue {
  get user() {
    return authModule.user
  }

  toggleThemeInput(): void {
    const fileInput = this.$refs.fileUploaderProfile as HTMLElement
    fileInput.click()
  }

  async onFileChanged(e: Event): Promise<void> {
    const file = (e.target! as any).files[0]
    if (file !== null) {
      eventModule.newInfo(`info.photo.upload`)
      const fileName = `${uuidv4()}.jpg`
      await AWSManager.uploadFile(
        `profiles/${this.user.id}/picture/${fileName}`,
        file,
        async () => {
          try {
            const updatedUser = await this.$axios.put('/api/users/profile', {
              picture: fileName,
            })

            authModule.setUser(updatedUser.data.profile)
            eventModule.newMessage('success.user.picture')
          } catch (error) {
            eventModule.newError('error.user.picture')
          }
        },
      )
    }
  }
}
export default ProfileHeader
