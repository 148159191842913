
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Pie } from 'vue-chartjs'

import { tripModule, expenseModule } from '@/store'
import { ExpenseFraction, Expense } from '@/types/expense'
import { formattedPrice, isBrowser } from '@/utils/utility-manager'

type RGB = {
  r: number
  g: number
  b: number
}

@Component({
  extends: Pie,
})
class ExpenseChart extends Vue {
  @Prop() readonly data!: ExpenseFraction[]
  @Prop() readonly labels!: string[]
  @Prop({ default: false, type: Boolean }) readonly isExpense!: boolean

  get trip() {
    return tripModule.trip
  }

  get currentExpense(): Expense | null {
    return expenseModule.expense
  }

  renderChart!: (chartData: Object, options: Object) => void

  @Watch('data')
  @Watch('currentExpense')
  configureChart(): void {
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            data: this.data.map((el) => el.amount.toFixed(2)),
            backgroundColor: this.randomColors,
          },
        ],
      },
      {
        legend: {
          display: false,
        },
        onClick: (_evt: any, data: any) => {
          // emits the **translated** label
          if (data[0]?._model?.label) {
            // absolutely no idea how reliable this will be in future updates. Seems sketchy, but there is no other consistent data on the event
            this.$emit('click:label', data[0]._model.label)
          } else {
            throw new Error('cannot find pie chart label')
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem: any, data: any) => {
              if (this.isExpense) {
                let currency = this.trip?.currency
                if (this.currentExpense?.currency !== this.trip?.currency) {
                  currency = this.currentExpense?.foreignCurrency
                }
                const formattedExpense = formattedPrice(
                  isBrowser() ? window.navigator.language : this.$i18n.locale,
                  currency as string,
                  data.datasets[0].data[tooltipItem.index],
                )
                return `${data.labels[tooltipItem.index]}: ${formattedExpense}`
              } else {
                return this.tooltipLabelForIndex(data, tooltipItem.index)
              }
            },
          },
        },
      },
    )
  }

  mounted() {
    this.configureChart()
  }

  get randomColors(): string[] {
    const colors: string[] = [
      '#ffc94a',
      '#ed5b5b',
      '#68e7cc',
      '#d39861',
      '#8b4c53',
      '#7D7D79',
      '#AF6C2D',
    ].map((color) => {
      const rgbColor = this.hexToRGB(color) || { r: 0, g: 0, b: 0 }
      return `rgb(${rgbColor.r},${rgbColor.g},${rgbColor.b})`
    })

    if (this.data.length > colors.length) {
      for (let i = 0; i < this.data.length - colors.length; i++) {
        colors.push(
          `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
            Math.random() * 256,
          )},${Math.floor(Math.random() * 256)})`,
        )
      }
    }

    return colors
  }

  hexToRGB(hex: string): RGB | undefined {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : undefined
  }

  tooltipLabelForIndex(data: any, tooltipItem: number): string {
    return `${data.labels[tooltipItem]}: ${data.datasets[0].data[tooltipItem]}`
  }
}

export default ExpenseChart
