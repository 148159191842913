var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_setup.twMerge(
      'flex items-center',
      _vm.orientation === 'horizontal'
        ? 'flex-row -space-x-2'
        : 'flex-col -space-y-2',
      _vm.twClass,
    )},[_vm._l((_setup.availableAttendees),function(attendee,index){return _c('v-tooltip',{key:`avatar-id-${attendee.id}`,attrs:{"top":_vm.orientation === 'horizontal',"right":_vm.orientation === 'vertical',"z-index":"40"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('l-avatar',_vm._g({attrs:{"tabindex":"0","src":attendee.picture,"tw-class":[
          'relative flex-none transition-transform focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2',
          _vm.size === 'large' ? 'size-9' : '!size-7',
          _vm.orientation === 'horizontal'
            ? 'hover:-translate-y-1'
            : 'hover:translate-x-1',
        ]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.showAttendeePicture(attendee, index)},"click":function($event){$event.preventDefault();return _setup.showAttendeePicture(attendee, index)}}},on))]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_setup.formattedAttendeeName(attendee))+" ")])])}),_vm._v(" "),_vm._t("prepend",function(){return [(_vm.trip && _vm.trip.attendees.length > 3)?_c('button',{staticClass:"relative z-40 flex items-center justify-center rounded-full bg-white transition-transform hover:-translate-y-1 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 focus-visible:ring-offset-white",class:_vm.size === 'large' ? 'size-9' : '!size-7'},[_c('v-icon',{attrs:{"size":_vm.size === 'large' ? 24 : 16,"color":"grey"}},[_vm._v("$vuetify.icons.accountGroup")])],1):_vm._e(),_vm._v(" "),_c('div',[_c(_setup.AttendeeInviteDialog,{attrs:{"trip":_vm.trip},scopedSlots:_vm._u([{key:"toggle",fn:function(){return [_c('button',{staticClass:"relative z-40 flex items-center justify-center rounded-full bg-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2",class:_vm.size === 'large' ? 'size-9' : '!size-7'},[_c('v-icon',{attrs:{"size":_vm.size === 'large' ? 24 : 16,"color":"primary"}},[_vm._v("$vuetify.icons.plus")])],1)]},proxy:true}])})],1)]},{"orientation":_vm.orientation}),_vm._v(" "),_c(_setup.PictureViewer,{attrs:{"value":_setup.showLightBox,"img-urls":_setup.attendeePictureUrls,"img-index":_setup.imageIndex},on:{"update:value":function($event){_setup.showLightBox=$event}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }