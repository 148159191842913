
import { Component, Vue } from 'vue-property-decorator'
import { eventModule } from '@/store'

@Component({ name: 'NavigationAlert' })
class NotificationAlert extends Vue {
  get notificationMessage(): string {
    return eventModule.notificationMessage
  }

  get notificationName(): string {
    return eventModule.notificationName
  }
}
export default NotificationAlert
