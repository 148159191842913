import { Plugin } from '@nuxt/types'
import {
  configure,
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import { email, min, required } from 'vee-validate/dist/rules'
import Vue from 'vue'
import { i18n } from '@/plugins/i18n'

// register validation components
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// Set interacton mode to `eager` for better UX
setInteractionMode('eager')

// install rules
extend('checkbox', {
  validate(value): boolean {
    return value === true || value === 'on'
  },
  message: i18n.t('textfieldRules.checkbox').toString(),
})
extend('email', email)
extend('min', min)
extend('required', required)
extend('customRequired', {
  ...required,
  message: i18n.t('textfieldRules.required').toString(),
})
extend('password', {
  params: ['target'],
  validate(value, { target }: any) {
    return value === target
  },
  message: 'Password confirmation does not match',
})
extend('flightNumber', {
  validate(value): boolean {
    const pattern = /^[A-Z0-9]{2,3}[0-9]{1,4}$/
    return pattern.test(value)
  },
  message: i18n.tc('textfieldRules.flightNumber'),
})
extend('noWhiteSpace', {
  validate(value): boolean {
    const pattern = /^\S+$/
    return pattern.test(value)
  },
  message: i18n.tc('textfieldRules.noWhiteSpace'),
})
extend('inviteEmails', {
  ...email,
  message: i18n.tc('textfieldRules.email'),
})

const veeValidatePlugin: Plugin = ({ app }) => {
  extend('usernameAvailable', {
    lazy: true,
    async validate(value): Promise<boolean> {
      // Don't allow emails as usernames
      const emailPattern =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (emailPattern.test(value)) {
        return false
      }

      try {
        const { data } = await app.$axios.get(
          `/api/users/validateUsername/${value}`,
        )
        return data && data.available === true
      } catch (e) {
        return false
      }
    },
  })

  configure({
    defaultMessage: (field, values): string => {
      if (!values) {
        return '{_field_} is not valid.'
      }

      values._field_ = app.i18n.t(`registrationFields.${field}`)
      return app.i18n.t(`validation.${values._rule_}`, values) as string
    },
  })
}

export default veeValidatePlugin
