
import { Component, Prop, Vue } from 'vue-property-decorator'

import { TimeRange } from '@/types/types'
import { formattedTimeRange } from '@/utils/date-utils'

@Component({
  name: 'TimeChip',
})
class TimeChip extends Vue {
  @Prop() readonly time?: TimeRange
  @Prop({ default: false, type: Boolean }) readonly dense!: boolean

  get hasTimeValue(): boolean {
    return !!this.time?.startTime || !!this.time?.endTime
  }

  get formattedTimeRange(): string {
    return formattedTimeRange(this.time?.startTime, this.time?.endTime)
  }
}

export default TimeChip
