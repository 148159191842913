
import { Component, Vue, Prop } from 'vue-property-decorator'
import AttendeeMenu from '@/components/trip/settings/AttendeeMenu.vue'
import { User } from '@/types/user'

@Component({
  name: 'AttendeeListItem',
  components: {
    AttendeeMenu,
  },
})
class AttendeeListItem extends Vue {
  @Prop() readonly attendee!: User

  showAttendeePicture(): void {
    this.$emit('click:attendeeImg')
  }
}
export default AttendeeListItem
