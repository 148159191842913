
import { Component, Prop, Vue } from 'vue-property-decorator'
import { tripModule } from '@/store'
import { Trip } from '@/types/trip'
import { isBrowser } from '@/utils/utility-manager'

@Component({
  name: 'ProBadge',
})
class ProBadge extends Vue {
  @Prop() readonly trip?: Trip
  @Prop({ default: false, type: Boolean }) enableOpacity!: boolean

  get isActive(): boolean {
    if (!this.enableOpacity) {
      return true
    }

    if (this.trip) {
      return this.trip.activeProSubscription || false
    } else if (tripModule.trip) {
      return tripModule.trip.activeProSubscription || false
    }
    return false
  }

  routeToTripSubscription(): void {
    if (!isBrowser() || !this.trip) {
      return
    }

    if (this.trip.activeProSubscription) {
      window.open(
        `${process.env.DASHBOARD_HOME}/trip/${this.trip.id}/subscriptions`,
        '_blank',
      )
    } else {
      window.open(`${process.env.DASHBOARD_HOME}/pro/features`, '_blank')
    }
  }
}
export default ProBadge
