
import { Vue, Component, Watch } from 'vue-property-decorator'
import { discoverModule } from '@/store'
import { Inspiration, Category } from '@/types/discover'
import RelatedCategoryItem from '@/components/discover/RelatedCategoryItem.vue'

@Component({
  name: 'RelatedCategories',
  components: {
    RelatedCategoryItem,
  },
})
class RelatedCategories extends Vue {
  relatedCategories: Partial<Category>[] = []

  get currentInspiration(): Inspiration {
    return discoverModule.currentInspiration!
  }

  get relatedCategoriesTitle(): string {
    return this.$vuetify.breakpoint.smAndDown
      ? 'large-bold-font text-capitalize'
      : 'large-bold-font text-uppercase'
  }

  @Watch('currentInspiration')
  setRelatedCategories(): void {
    const inspirationCategories = (this.currentInspiration.categories || [])
      .filter((category) => category.related.length > 0)
      .map((category) => category.related)
      .flat()

    inspirationCategories.forEach((item) => {
      const index = this.relatedCategories.findIndex(
        (x) => x.slug === item.slug,
      )
      if (index <= -1) {
        this.relatedCategories.push({
          id: item.id,
          slug: item.slug,
          name: item.name,
        })
      }
    })
  }

  created() {
    this.setRelatedCategories()
  }
}

export default RelatedCategories
