
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'LForm',
})
class LForm extends Vue {
  @Prop() readonly action!: string
  isFormValid = false
}

export default LForm
