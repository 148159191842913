import { set } from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { cloneDeep } from 'lodash'
import { Direction, Route, Waypoint } from '@/types/trip'
import {
  formattedDistance,
  getLocalStorageString,
} from '@/utils/utility-manager'
import { transportationModule, tripModule } from '@/utils/store-init'
import { DistanceUnit, RouteModes } from '@/utils/enums'

@Module({ name: 'routes', stateFactory: true, namespaced: true })
export default class RoutesModule extends VuexModule {
  private routeOrigin: Partial<Waypoint> | null = null
  private routeDestination: Partial<Waypoint> | null = null
  private currentRoute: Route | null = null
  private currentDirections: Direction | null = null // active directions object coming from direction API
  private activeDirectionIndex = -1 // determines the selected polyline/properties from directions. -1 = polyline, >=0 = alternatives[activePolyline]
  private allRoutes: Route[] = [] // Polylines from already existing routes (all routes from active trip)
  private activeMode = RouteModes.DIRECTIONS // determines if directions or routes should be shown
  private routeEditorDate = '' // date selected in the route editor that was used to calculate the current route. Is set on calculate

  public get mode(): string {
    return this.activeMode
  }

  public get currentOrigin(): Partial<Waypoint> | null {
    return this.routeOrigin
  }

  public get currentDestination(): Partial<Waypoint> | null {
    return this.routeDestination
  }

  public get route(): Route | null {
    return this.currentRoute
  }

  public get routes(): Route[] {
    return this.allRoutes
  }

  public get directions(): Direction | null {
    return this.currentDirections
  }

  public get activeDirection(): number {
    return this.activeDirectionIndex
  }

  public get editorDate(): string {
    return this.routeEditorDate
  }

  @Mutation
  private setCurrentOrigin(payload: Partial<Waypoint> | null) {
    set(this, 'routeOrigin', payload)
  }

  @Mutation
  private setCurrentDestination(payload: Partial<Waypoint> | null) {
    set(this, 'routeDestination', payload)
  }

  @Mutation
  private setCurrentRoute(route: Route | null) {
    set(this, 'currentRoute', route)
    this.activeMode = RouteModes.ROUTES
  }

  @Mutation
  private setAllRoutes(routes: Route[]) {
    set(this, 'allRoutes', routes)
  }

  @Mutation
  private setDirections(directions: Direction | null) {
    set(this, 'currentDirections', directions)
    this.activeMode = RouteModes.DIRECTIONS
  }

  @Mutation
  private setActiveDirectionIndex(index: number) {
    this.activeDirectionIndex = index
  }

  @Mutation
  private setRouteEditorDate(dateString: string) {
    this.routeEditorDate = dateString
  }

  @Mutation
  private addNewRoute(route: Route) {
    this.allRoutes.push(route)
  }

  @Action({ rawError: true })
  public async fetchAll() {
    try {
      if (!tripModule.trip) {
        throw new Error('No trip found')
      }
      const { data } = await this.$axios.get(
        `/api/trips/${tripModule.trip.id}/routes`,
      )
      this.context.commit('setAllRoutes', data.routes)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.routes.fetchAll', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetch(id: string) {
    try {
      if (!tripModule.trip) {
        throw new Error('No trip found')
      }
      const { data } = await this.$axios.get(
        `/api/trips/${tripModule.trip.id}/routes/${id}`,
      )
      this.context.commit('setCurrentRoute', data.route)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.routes.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async updateRoute() {
    try {
      if (!tripModule.trip) {
        throw new Error('Cannot update route. No trip found')
      }

      if (!this.route) {
        throw new Error('Cannot update route. Invalid route')
      }

      let payload: any
      // if new directions were calculated, use those, otherwise use the existing route
      const routeData =
        this.mode === RouteModes.ROUTES
          ? cloneDeep(this.route)
          : cloneDeep(this.directions)

      if (!routeData) {
        throw new Error('Cannot update route. Invalid payload')
      }

      // active polyline has not changed
      if (this.activeDirection === -1) {
        payload = routeData
      } else {
        // use the active alternative polyline/properties as polyline/properties and add former polyline/properties to alternatives
        payload = {
          type: routeData.type,
          polyline: routeData.alternatives[this.activeDirection],
          properties: {
            ...routeData.alternativesProperties[this.activeDirection],
            departureDate: routeData.properties.departureDate,
          },
          rawData: routeData.rawData,
        }

        routeData.alternatives.splice(
          this.activeDirection,
          1,
          routeData.polyline,
        )

        routeData.alternativesProperties.splice(
          this.activeDirection,
          1,
          routeData.properties,
        )

        Object.assign(payload, {
          alternatives: routeData.alternatives,
          alternativesProperties: routeData.alternativesProperties,
        })
      }

      if (this.routeOrigin?._id) {
        // existing waypoint
        Object.assign(payload, { origin: this.routeOrigin._id })
      } else if (this.routeOrigin?.location) {
        // new location
        Object.assign(payload, { originLocation: this.routeOrigin.location })
      }

      if (this.routeDestination?._id) {
        // existing waypoint
        Object.assign(payload, {
          destination: this.routeDestination._id,
        })
      } else if (this.routeDestination?.location) {
        // new location
        Object.assign(payload, {
          destinationLocation: this.routeDestination.location,
        })
      }

      if (!payload) {
        throw new Error('Cannot update route. Empty payload')
      }

      const { data } = await this.$axios.put(
        `/api/trips/${tripModule.trip.id}/routes/${this.route?._id}`,
        payload,
      )

      this.fetchAll()
      this.context.commit('setCurrentRoute', data.route)
      this.context.dispatch('event/newMessage', 'success.routes.update', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.routes.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async deleteRoute(id: string) {
    try {
      if (!tripModule.trip) {
        throw new Error('No trip found')
      }
      await this.$axios.delete(`/api/trips/${tripModule.trip.id}/routes/${id}`)
      this.context.dispatch('event/newMessage', 'success.routes.delete', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.routes.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async calculateDirections(payload: {
    option: string
    origin: Partial<Waypoint>
    destination: Partial<Waypoint>
    departureDate: string | Date
  }) {
    try {
      if (!tripModule.trip) {
        throw new Error('No trip found')
      }
      const { data } = await this.$axios.post(
        `/api/trips/${tripModule.trip.id}/routes/calculate`,
        {
          option: payload.option,
          departureDate: payload.departureDate,
          waypoints: {
            to: payload.destination,
            from: payload.origin,
          },
        },
      )

      this.context.commit('setDirections', data.directions)
      this.context.commit('setRouteEditorDate', payload.departureDate)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.routes.calculate', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async saveDirections(bookingId?: string): Promise<void> {
    try {
      if (!tripModule.trip) {
        throw new Error('No trip found')
      }
      if (
        !this.directions ||
        !transportationModule.currentDestination ||
        !transportationModule.currentOrigin
      ) {
        throw new Error('Invalid directions')
      }

      let payload: any
      const routeData = cloneDeep(this.directions)

      if (this.activeDirection === -1) {
        payload = routeData
        Object.assign(payload, {
          origin: transportationModule.currentOrigin._id,
          destination: transportationModule.currentDestination._id,
        })
      } else {
        payload = {
          type: routeData.type,
          polyline: routeData.alternatives[this.activeDirection],
          origin: transportationModule.currentOrigin._id,
          destination: transportationModule.currentDestination._id,
          properties: {
            ...routeData.alternativesProperties[this.activeDirection],
            departureDate: routeData.properties.departureDate,
          },
          rawData: routeData.rawData,
        }

        routeData.alternatives.splice(
          this.activeDirection,
          1,
          routeData.polyline,
        )

        routeData.alternativesProperties.splice(
          this.activeDirection,
          1,
          routeData.properties,
        )

        Object.assign(payload, {
          alternatives: routeData.alternatives,
          alternativesProperties: routeData.alternativesProperties,
        })
      }

      if (bookingId) {
        Object.assign(payload, { booking: bookingId })
      }

      if (!payload) {
        throw new Error('Invalid payload')
      }
      const { data } = await this.$axios.post(
        `/api/trips/${tripModule.trip.id}/routes`,
        payload,
      )

      this.context.commit('addNewRoute', data.route)
      this.context.dispatch('event/newMessage', 'success.routes.create', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.routes.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public setRoute(route: Route): void {
    this.context.commit('setCurrentRoute', route)
  }

  @Action({ rawError: true })
  public setRouteByIndex(index: number): void {
    this.context.commit('setCurrentRoute', this.routes[index])
  }

  @Action({ rawError: true })
  public resetRoute(): void {
    this.context.commit('setCurrentRoute', null)
  }

  @Action({ rawError: true })
  public resetDirections(): void {
    this.context.commit('setActiveDirectionIndex', -1)
    this.context.commit('setDirections', null)
  }

  @Action({ rawError: true })
  public setActiveDirection(index: number): void {
    this.context.commit('setActiveDirectionIndex', index)
  }

  @Action({ rawError: true })
  public async formattedDistance(distanceInMeters: number): Promise<string> {
    return formattedDistance(distanceInMeters / 1000, await this.distanceUnit())
  }

  @Action({ rawError: true })
  public setOrigin(origin: Partial<Waypoint> | null): void {
    this.context.commit('setCurrentOrigin', origin)
  }

  @Action({ rawError: true })
  public setDestination(destination: Partial<Waypoint> | null): void {
    this.context.commit('setCurrentDestination', destination)
  }

  @Action({ rawError: true })
  public distanceUnit(): string {
    const key = `kLambusShowPolylineDistanceMetric_${tripModule.trip!.id}`
    return getLocalStorageString(key, DistanceUnit.KILOMETERS)
  }

  @Action({ rawError: true })
  public routesBetweenWaypoints({ origin, destination }: any): boolean {
    if (origin && destination) {
      return this.routes.some((route: any) => {
        if (route.origin && route.destination) {
          return (
            route.origin?._id === origin &&
            route.destination?._id === destination
          )
        }

        return false
      })
    }

    return false
  }
}
