import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { set } from 'vue'

import { TourOffer } from '@/types/booking'
import { Waypoint } from '@/types/trip'

@Module({ name: 'booking', stateFactory: true, namespaced: true })
export default class BookingModule extends VuexModule {
  private allTours: TourOffer[] = []
  private searchId: string | null = null
  private currentRentalOffersURL = ''

  public get tours(): TourOffer[] {
    return this.allTours
  }

  public get rentalCarOffersURL(): string {
    return this.currentRentalOffersURL
  }

  public get searchPageURL(): string | null {
    return this.searchId && this.tours.length > 20
      ? `https://deals.lambus.com/tours/${this.searchId}`
      : null
  }

  @Mutation
  private setAllTours(payload: TourOffer[]): void {
    set(this, 'allTours', [...payload])
  }

  @Mutation
  private setSearchId(payload: string): void {
    set(this, 'searchId', payload)
  }

  @Mutation
  private setSearchIdValidity(payload: Date): void {
    set(this, 'searchIdValidity', payload)
  }

  @Mutation
  private setRentalCarOffersURL(payload: string): void {
    set(this, 'currentRentalOffersURL', payload)
  }

  @Action({ rawError: true })
  public async fetchTours(payload: {
    currency: string
    lat: number
    lon: number
    locale: string
  }): Promise<void> {
    try {
      const cachedSearchParams = localStorage.getItem(
        `kLambusTourSearchParam${JSON.stringify(payload)}`,
      )

      const parsedSearchParams: {
        validUntil: string
        searchId: string
      } | null = cachedSearchParams ? JSON.parse(cachedSearchParams) : null

      if (
        !parsedSearchParams ||
        new Date(parsedSearchParams.validUntil) < new Date()
      ) {
        // create search session
        const { data } = await this.$axios.post(
          `/bookingApi/tours/search`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${process.env.LAMBUS_BOOKING_KEY}`,
            },
          },
        )

        if (data?.searchId) {
          this.context.commit('setSearchId', data.searchId)
          localStorage.setItem(
            `kLambusTourSearchParam${JSON.stringify(payload)}`,
            JSON.stringify({
              validUntil: data.validUntil,
              searchId: data.searchId,
            }),
          )
        } else {
          throw new Error(
            'Could not create booking proxy search session. Search ID was not received',
          )
        }
      } else if (parsedSearchParams?.searchId) {
        // set search id from cached params
        this.context.commit('setSearchId', parsedSearchParams.searchId)
      }

      if (this.searchId) {
        // fetch tours
        const searchResult = await this.$axios.get(
          `/bookingApi/tours/search/${this.searchId}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.LAMBUS_BOOKING_KEY}`,
            },
          },
        )

        if (searchResult?.data?.offers?.data) {
          this.context.commit('setAllTours', searchResult.data.offers.data)
        }
      }
    } catch (error) {
      // NO-OP, because if there are no offers, it's just not displayed
    }
  }

  @Action({ rawError: true })
  public async fetchRentalCarOffers(waypoint: Waypoint) {
    const origin = `${waypoint.location.latitude},${waypoint.location.longitude}`
    const destination = `${waypoint.location.latitude},${waypoint.location.longitude}`

    try {
      const response = await this.$axios.get(
        `/api/meta/rental-car-search-url?origin=${origin}&destination=${destination}&waypoint=${waypoint._id}`,
      )
      const url = response.data.searchURL
      this.context.commit('setRentalCarOffersURL', url)
    } catch (error) {
      // NO-OP, because if there are no offers, it's just not displayed
    }
  }
}
