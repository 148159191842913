
import { Component, Prop, Vue } from 'vue-property-decorator'

import SuggestionList from '@/components/datepicker/timepicker/SuggestionList.vue'
import TimeRangeFields from '@/components/datepicker/timepicker/TimeRangeFields.vue'
import { delocalizedTime, localizedTime } from '@/utils/date-utils'
import { TimeRange } from '@/types/types'

@Component({
  name: 'TimeRangeForm',
  components: {
    SuggestionList,
    TimeRangeFields,
  },
})
class TimeRangeForm extends Vue {
  @Prop() readonly time?: TimeRange

  start = '14:00:00'
  end = '15:00:00'

  startHasFocus = false
  endHasFocus = false
  isValidForm = false

  get showClearBtn(): boolean {
    return this.start.trim().length > 0 || this.end.trim().length > 0
  }

  get activeSuggestion(): string | null {
    if (this.startHasFocus) {
      return delocalizedTime(this.start)
    }
    if (this.endHasFocus) {
      return delocalizedTime(this.end)
    }

    return null
  }

  mounted() {
    this.reset()
  }

  /**
   * Sets start and end time value based on the clicked time suggestion from the suggestion list
   */
  setTime(time: string): void {
    if (this.endHasFocus) {
      // set end time
      this.end = time
    } else if (this.startHasFocus) {
      // set start time
      this.start = time

      if (this.end.trim().length === 0) {
        // auto-fill end time with start time + 1hr
        this.end = localizedTime(this.start, { addHours: 1 })
      }

      setTimeout(() => {
        // Setting the focus must be done within a timeout, so that the focus is applied after the
        // browser rendered the changes, otherwise the focus will not be set.
        const endField = document.getElementById('end-time')
        if (endField) {
          endField.focus()
        }
      }, 0)
    }
  }

  /**
   * Resets the start and end time to either via prop provided time values or the default time values
   */
  public reset(): void {
    if (this.time?.startTime) {
      if (this.time.startTime.trim().length === 0) {
        this.start = this.time.startTime
      } else {
        this.start = localizedTime(this.time.startTime)
      }

      if (this.time.endTime) {
        if (this.time.endTime.trim().length === 0) {
          this.end = this.time.endTime
        }

        this.end = localizedTime(this.time.endTime)
      }
    } else {
      this.start = localizedTime(this.start)
      this.end = localizedTime(this.start, { addHours: 1 })
    }
  }

  clear(): void {
    this.start = ''
    this.end = ''
  }

  onChange(): void {
    const startTime =
      this.start.trim().length > 0 ? delocalizedTime(this.start) : null
    const endTime =
      this.end.trim().length > 0 ? delocalizedTime(this.end) : null

    this.$emit('click:save')
    this.$emit('update:time', {
      startTime,
      endTime,
    })
  }
}

export default TimeRangeForm
