
import { Component, Vue } from 'vue-property-decorator'
import LDialog from '@/global-components/LDialog.vue'
import ClipboardTextField from '@/components/ClipboardTextField.vue'
import { eventModule, tripModule } from '@/store'
import { Trip } from '@/types/trip'
import { TextFieldRules } from '@/utils/enums'
import { useTripList } from '@/composables/trip'
@Component({
  name: 'InvitationExpansionPanel',
  components: {
    ClipboardTextField,
  },
  setup() {
    const { data: trips } = useTripList()
    return { trips }
  },
})
class InvitationExpansionPanel extends Vue {
  rules = TextFieldRules
  inviteEmail = ''
  inviteEmails: string[] = []
  emailAgreed = false

  trips?: Trip[]

  get trip() {
    return tripModule.trip
  }

  get dashboardLink() {
    return process.env.DASHBOARD_HOME
  }

  inviteHandler(): void {
    if (this.trips && this.trips.length > 0) {
      this.showInvitePrompt()
    } else {
      this.inviteUser(false)
    }
  }

  resetInviteFormValidation(): void {
    const inviteForm = this.$refs.inviteForm as Vue
    const form = inviteForm.$refs.lForm as any
    form.resetValidation()
  }

  addInvite(): void {
    if (this.rules.EMAIL(this.inviteEmail) !== true) return
    this.inviteEmails.push(this.inviteEmail)
    this.inviteEmail = ''
    this.resetInviteFormValidation()
  }

  inviteUser(togglePrompt: boolean): void {
    try {
      if (togglePrompt) this.showInvitePrompt()
      if (this.rules.EMAIL(this.inviteEmail))
        this.inviteEmails.push(this.inviteEmail)
      this.$axios.post('/api/invites', { emails: this.inviteEmails })
      eventModule.newMessage('success.user.invite')
      this.resetInviteForm()
    } catch (error) {
      eventModule.newError('error.user.invite')
    }
  }

  inviteUserToTrip(trip: Trip, togglePrompt?: boolean): void {
    try {
      tripModule.setTrip(trip)
      if (this.rules.EMAIL(this.inviteEmail)) {
        this.inviteEmails.push(this.inviteEmail)
      }

      this.$axios.post('/api/invites', {
        emails: this.inviteEmails,
        tripId: this.trip!.id,
      })

      eventModule.newMessage('success.user.invite')
      this.resetInviteForm()

      if (togglePrompt) {
        this.showTripList(false)
      } else {
        this.showInvitePrompt()
      }
    } catch (error) {
      eventModule.newError('error.user.invite')
    }
  }

  resetInviteForm(): void {
    const inviteForm = this.$refs.inviteForm as Vue
    const form = inviteForm.$refs.lForm as any
    form.reset()
    this.inviteEmails = []
  }

  showInvitePrompt(): void {
    const dialog = this.$refs.invitePrompt as LDialog
    setTimeout(() => {
      if (dialog) dialog.toggleDialog()
    })
  }

  showTripList(togglePrompt: boolean): void {
    if (togglePrompt) {
      this.showInvitePrompt()
    }
    const dialog = this.$refs.tripList as LDialog
    setTimeout(() => {
      if (dialog) dialog.toggleDialog()
    })
  }
}
export default InvitationExpansionPanel
