
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { expenseModule, tripModule } from '@/store'
import { Expense } from '@/types/expense'
import { Trip } from '@/types/trip'

@Component({
  name: 'CategorySelect',
})
class CategorySelect extends Vue {
  /**
   * Expense to use instead of current store expense.
   * E.g. for list views
   */
  @Prop() readonly expense?: Expense

  categories = require('@/assets/json/expense_categories.json')
  activeCategory = this.currentExpense?.category || 'general'

  get currentExpense(): Expense | null {
    return this.expense || expenseModule.expense
  }

  get trip(): Trip | null {
    return tripModule.trip
  }

  get categoryImage(): string {
    return `expense_category_${this.activeCategory}.png`
  }

  @Watch('currentExpense')
  setActiveCategory(): void {
    this.activeCategory = this.currentExpense?.category || 'general'
  }

  async updateExpense(category: string): Promise<void> {
    this.activeCategory = category
    this.$emit('click:category', category)

    if (!this.currentExpense?._id) {
      return
    }

    await expenseModule.updateExpense({
      tripId: this.trip?.id,
      payload: { category },
      expenseId: this.currentExpense._id,
    })

    await tripModule.fetch(this.trip!.id)

    if (this.expense) {
      return
    }

    const currentExpense = this.trip?.expenses.find(
      (expense) => expense._id === this.currentExpense?._id,
    )

    if (currentExpense) {
      expenseModule.setCurrentExpense(currentExpense)
    }
  }
}
export default CategorySelect
