
import { Component, Prop } from 'vue-property-decorator'
import DatePickerModal from '@/components/datepicker/DatePickerModal.vue'
import SelectionDatePicker from '@/components/datepicker/SelectionDatePicker.vue'
import { MenuItem } from '@/types/types'
import { DatePickerType } from '@/utils/enums'

@Component({
  name: 'DateSelectionModal',
  components: {
    SelectionDatePicker,
  },
})
class DateSelectionModal extends DatePickerModal {
  @Prop({ default: 'calender_icon.png', type: String })
  readonly calenderIcon?: string

  @Prop({ default: false, type: Boolean })
  readonly noDatePickerMenuStyles?: boolean

  showDatePicker = false
  showMenu = false

  /**
   * Show dateTypeMenuItems on the menu even if dates are already set
   */
  showDateTypeItems = false

  dateTypeMenuItems: MenuItem[] = [
    {
      title: this.$t('datePicker.SPECIFIC').toString(),
      operation: () => {
        this.toggleDialog(DatePickerType.SPECIFIC)
        this.closeMenu()
      },
    },
    {
      title: this.$t('datePicker.WHOLE_MONTH').toString(),
      operation: () => {
        this.toggleDialog(DatePickerType.WHOLE_MONTH)
        this.closeMenu()
      },
    },
  ]

  editMenuItems: MenuItem[] = [
    {
      title: this.$t('deleteDate').toString(),
      operation: () => {
        this.resetDates()
        this.closeMenu()
      },
    },
    {
      title: this.$t('editDate').toString(),
      operation: () => {
        this.setShowDateTypeItems(true)
      },
    },
    {
      title: this.$t('cancel').toString(),
      operation: () => {
        this.closeMenu()
      },
    },
  ]

  get menuItems() {
    if (
      (this.dates.length > 0 || this.months.length > 0) &&
      !this.showDateTypeItems
    ) {
      return this.editMenuItems
    }

    return this.dateTypeMenuItems
  }

  toggleDialog(pickerType?: string): void {
    if (pickerType) {
      this.type = pickerType
    } else {
      this.type =
        this.dates.length > 0
          ? DatePickerType.SPECIFIC
          : DatePickerType.WHOLE_MONTH
    }
    this.showDatePicker = true
  }

  closeDialog(): void {
    this.showDatePicker = false
    this.type = ''
  }

  closeMenu(): void {
    this.showMenu = false
  }

  saveBtnClicked(): void {
    const datePicker = this.$refs.datePicker as SelectionDatePicker
    datePicker.saveDates()
    this.showDatePicker = false
  }

  saveDates(data: any): void {
    this.dates = data.dates
    this.months = data.months
    this.$emit('save', data)
  }

  resetDates(): void {
    this.dates = []
    this.months = []
    this.type = ''
    this.$emit('save', {
      dates: this.dates,
      months: this.months,
      type: this.type,
    })
  }

  setShowDateTypeItems(value: boolean) {
    this.showDateTypeItems = value
  }
}
export default DateSelectionModal
