
import { Component, Vue } from 'vue-property-decorator'
import TravelPlanList from '@/components/trip/travelPlan/TravelPlanList.vue'

@Component({
  name: 'DiscoverTripPlan',
  components: {
    TravelPlanList,
  },
})
class DiscoverTripPlan extends Vue {}
export default DiscoverTripPlan
