
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Accommodation } from '@/types/accommodation'
import { getAccommodationTitle } from '@/utils/utility-manager'

@Component({
  name: 'AccommodationPlanItem',
})
class AccommodationPlanItem extends Vue {
  @Prop() readonly accommodation?: Accommodation

  get accommodationTitle(): string {
    return getAccommodationTitle(this.accommodation)
  }
}
export default AccommodationPlanItem
