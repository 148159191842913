
import { Component, Vue } from 'vue-property-decorator'

import ProBadge from '@/components/pro/ProBadge.vue'
import { tripModule } from '@/store'
import { Trip } from '@/types/trip'
import { isBrowser } from '@/utils/utility-manager'
@Component({
  name: 'ProExpansionPanel',
  components: {
    ProBadge,
  },
})
class ProExpansionPanel extends Vue {
  get trip(): Trip {
    return tripModule.trip!
  }

  get tripSubscriptionText(): string {
    return this.trip.activeProSubscription
      ? this.$t('pro.subscription.manageSubscription').toString()
      : `${this.$t('learnMore')} …`
  }

  routeToTripSubscription(): void {
    if (!isBrowser()) {
      return
    }

    if (this.trip.activeProSubscription) {
      window.open(
        `${process.env.DASHBOARD_HOME}/trip/${this.trip.id}/subscriptions`,
        '_blank',
      )
    } else {
      window.open(`${process.env.DASHBOARD_HOME}/pro/features`, '_blank')
    }
  }
}
export default ProExpansionPanel
