import { set } from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

@Module({ name: 'event', stateFactory: true, namespaced: true })
export default class EventModule extends VuexModule {
  private currentMessage: string | string[] = []
  private currentButtonName?: string
  private currentValues: any = []
  private currentColor = 'success'
  private currentWidth: string | null = null
  private showNavigationDrawer = false
  private showArchive = false
  private showWaypointDetails = false
  private showPoiDetails = false
  private showRoutesModal = false
  private showTransportationModal = false
  private showTransportationForm = false
  private showFlightStatusModal = false
  private showFlightCompensationModal = false
  private showDocumentListModal = false
  private showProHintModal = false
  private showProCancellationModal = false
  private showHowItWorksModal = false
  private showGpxExportModal = false
  private showExportStartedPrompt = false
  private transportationFormType = ''
  private currentTimeZoneOffset = 0 // is stored here for a lack of a dedicated module. If more "utility-vars" are needed, this should be moved to a dedicated utility or time module.
  private currNotificationMessage = ''
  private currNotificationName = ''
  private displayedGpsCurrencyModalOnce = false
  private showTravelPlan = false
  private showNewWaypointCard = false
  private searchBarFocus = false
  private showSelectWaypointModal = false
  private currWaypointModalType = ''
  private showDiscoverSearchModal = false

  get message() {
    return this.currentMessage
  }

  get buttonName() {
    return this.currentButtonName
  }

  get values() {
    return this.currentValues
  }

  get color() {
    return this.currentColor
  }

  get width() {
    return this.currentWidth
  }

  get navigationDrawer(): boolean {
    return this.showNavigationDrawer
  }

  get archive(): boolean {
    return this.showArchive
  }

  get waypointDetails(): boolean {
    return this.showWaypointDetails
  }

  get poiDetails(): boolean {
    return this.showPoiDetails
  }

  get timeZoneOffset(): number {
    return this.currentTimeZoneOffset
  }

  get routesModal(): boolean {
    return this.showRoutesModal
  }

  get transportationModal(): boolean {
    return this.showTransportationModal
  }

  get transportationForm(): boolean {
    return this.showTransportationForm
  }

  get flightStatusModal(): boolean {
    return this.showFlightStatusModal
  }

  get flightCompensationModal(): boolean {
    return this.showFlightCompensationModal
  }

  get transportationType(): string {
    return this.transportationFormType
  }

  get documentListModal(): boolean {
    return this.showDocumentListModal
  }

  get proHintModal(): boolean {
    return this.showProHintModal
  }

  get proCancellationPrompt(): boolean {
    return this.showProCancellationModal
  }

  get gpsCurrencyModalShowedOnce(): boolean {
    return this.displayedGpsCurrencyModalOnce
  }

  get howItWorksModal(): boolean {
    return this.showHowItWorksModal
  }

  get gpxExportModal(): boolean {
    return this.showGpxExportModal
  }

  get notificationMessage(): string {
    return this.currNotificationMessage
  }

  get notificationName(): string {
    return this.currNotificationName
  }

  get exportStartedPrompt(): boolean {
    return this.showExportStartedPrompt
  }

  get travelPlan(): boolean {
    return this.showTravelPlan
  }

  get newWaypointCard(): boolean {
    return this.showNewWaypointCard
  }

  get waypointSearchBarFocus(): boolean {
    return this.searchBarFocus
  }

  get selectWaypointModal(): boolean {
    return this.showSelectWaypointModal
  }

  get waypointModalType(): string {
    return this.currWaypointModalType
  }

  get discoverSearchModal(): boolean {
    return this.showDiscoverSearchModal
  }

  @Mutation
  private setMessage(payload: string | string[]): void {
    this.currentMessage = typeof payload === 'string' ? payload : [...payload]
  }

  @Mutation
  private setButtonName(buttonName?: string): void {
    set(this, 'currentButtonName', buttonName)
  }

  @Mutation
  private setCurrentValues(payload: any): void {
    set(this, 'currentValues', payload)
  }

  @Mutation
  private setColor(payload: string): void {
    this.currentColor = payload
  }

  @Mutation
  public setWidth(width: string | null): void {
    this.currentWidth = width
  }

  @Mutation
  private setArchive(value?: boolean): void {
    this.showArchive = value || !this.showArchive
  }

  @Mutation
  private setShowNavigationDrawer(value?: boolean): void {
    this.showNavigationDrawer = value || !this.showNavigationDrawer
  }

  @Mutation
  private setShowWaypoint(value: boolean): void {
    this.showWaypointDetails = value
  }

  @Mutation
  private setShowPoiDetails(value: boolean): void {
    this.showPoiDetails = value
  }

  @Mutation
  private setShowRoutesModal(value: boolean): void {
    this.showRoutesModal = value
  }

  @Mutation
  private setShowTransportationModal(value: boolean): void {
    this.showTransportationModal = value
  }

  @Mutation
  private setCurrentTimeZoneOffset(value: number): void {
    this.currentTimeZoneOffset = value
  }

  @Mutation
  private setFlightStatusModal(value: boolean): void {
    this.showFlightStatusModal = value
  }

  @Mutation
  private setFlightCompensationModal(value: boolean): void {
    this.showFlightCompensationModal = value
  }

  @Mutation
  private setTransportationType(searchType: string): void {
    this.transportationFormType = searchType
  }

  @Mutation
  private setTransportationForm(value: boolean): void {
    this.showTransportationForm = value
  }

  @Mutation
  private setDocumentListModal(value: boolean): void {
    this.showDocumentListModal = value
  }

  @Mutation
  private setShowProHintModal(value: boolean): void {
    this.showProHintModal = value
  }

  @Mutation
  private setShowProCancellationModal(value: boolean): void {
    this.showProCancellationModal = value
  }

  @Mutation
  private setHowItWorksModal(value: boolean): void {
    this.showHowItWorksModal = value
  }

  @Mutation
  private setGpxExportModal(value: boolean): void {
    this.showGpxExportModal = value
  }

  @Mutation
  private setNotificationMessage(message: string): void {
    this.currNotificationMessage = message
  }

  @Mutation
  private setNotificationName(name: string): void {
    this.currNotificationName = name
  }

  @Mutation
  public setDisplayedGpsCurrencyModalOnce(value: boolean): void {
    this.displayedGpsCurrencyModalOnce = value
  }

  @Mutation
  private setExportStartedPrompt(value: boolean): void {
    this.showExportStartedPrompt = value
  }

  @Mutation
  private setShowNewWaypointCard(value: boolean): void {
    this.showNewWaypointCard = value
  }

  @Mutation
  private setTravelPlan(value: boolean): void {
    this.showTravelPlan = value
  }

  @Mutation
  private setSearchBarFocus(value: boolean): void {
    this.searchBarFocus = value
  }

  @Mutation
  private setShowSelectWaypointModal(value: boolean): void {
    this.showSelectWaypointModal = value
  }

  @Mutation
  private setCurrWaypointModalType(value: string): void {
    this.currWaypointModalType = value
  }

  @Mutation
  public setDiscoverSearchModal(value: boolean): void {
    this.showDiscoverSearchModal = value
  }

  @Action({ rawError: true })
  newError(payload: string | string[]): void {
    this.context.commit('setColor', 'error')
    this.context.commit('setMessage', payload)
    this.context.commit('setButtonName', 'contactUs')
  }

  @Action({ rawError: true })
  setValues(payload: any): void {
    this.context.commit('setCurrentValues', payload)
  }

  @Action({ rawError: true })
  newMessage(payload: string | string[]): void {
    this.context.commit('setColor', 'success')
    this.context.commit('setMessage', payload)
  }

  @Action({ rawError: true })
  newInfo(payload: string | string[]): void {
    this.context.commit('setColor', 'info')
    this.context.commit('setMessage', payload)
  }

  @Action({ rawError: true })
  newLog(payload: string): void {
    this.$axios.post('/api/meta/clientlogger', { data: payload })
  }

  @Action({ rawError: true })
  resetMessage(): void {
    this.context.commit('setMessage', '')
    this.context.commit('setButtonName', undefined)
  }

  @Action({ rawError: true })
  toggleNavigationDrawer(value?: boolean): void {
    this.context.commit('setShowNavigationDrawer', value)
  }

  @Action({ rawError: true })
  toggleArchive(value?: boolean): void {
    this.context.commit('setArchive', value)
  }

  @Action({ rawError: true })
  toggleWaypointDetails(value: boolean): void {
    this.context.commit('setShowWaypoint', value)
  }

  @Action({ rawError: true })
  togglePoiDetails(value: boolean): void {
    this.context.commit('setShowPoiDetails', value)
    setTimeout(() => {
      this.context.commit('setShowPoiDetails', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleRoutesModal(): void {
    this.context.commit('setShowRoutesModal', true)
    setTimeout(() => {
      this.context.commit('setShowRoutesModal', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleTransportationModal(): void {
    this.context.commit('setShowTransportationModal', true)
    setTimeout(() => {
      this.context.commit('setShowTransportationModal', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleFlightStatusModal(): void {
    this.context.commit('setFlightStatusModal', true)
    setTimeout(() => {
      this.context.commit('setFlightStatusModal', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleFlightCompensationModal(): void {
    this.context.commit('setFlightCompensationModal', true)
    setTimeout(() => {
      this.context.commit('setFlightCompensationModal', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleTransportationForm(transportationType: string): void {
    this.context.commit('setTransportationType', transportationType)
    this.context.commit('setTransportationForm', true)
    setTimeout(() => {
      this.context.commit('setTransportationForm', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleDocumentListModal(): void {
    this.context.commit('setDocumentListModal', true)
    setTimeout(() => {
      this.context.commit('setDocumentListModal', false)
    })
  }

  @Action({ rawError: true })
  toggleProHintModal(): void {
    this.context.commit('setShowProHintModal', true)
    setTimeout(() => {
      this.context.commit('setShowProHintModal', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleProCancellationModal(): void {
    this.context.commit('setShowProCancellationModal', true)
    setTimeout(() => {
      this.context.commit('setShowProCancellationModal', false)
    })
  }

  @Action({ rawError: true })
  toggleHowItWorksModal(): void {
    this.context.commit('setHowItWorksModal', true)
    setTimeout(() => {
      this.context.commit('setHowItWorksModal', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleGpxExportModal(): void {
    this.context.commit('setGpxExportModal', true)
    setTimeout(() => {
      this.context.commit('setGpxExportModal', false)
    }, 100)
  }

  @Action({ rawError: true })
  toggleExportStartedPrompt(): void {
    this.context.commit('setExportStartedPrompt', true)
    setTimeout(() => {
      this.context.commit('setExportStartedPrompt', false)
    }, 100)
  }

  @Action({ rawError: true })
  setNewWaypointCard(value: boolean): void {
    this.context.commit('setShowNewWaypointCard', value)
  }

  @Action({ rawError: true })
  setShowTravelPlan(value: boolean): void {
    this.context.commit('setTravelPlan', value)
  }

  @Action({ rawError: true })
  setTimeZoneOffset(val: number): void {
    this.context.commit('setCurrentTimeZoneOffset', val)
  }

  @Action({ rawError: true })
  newNotificationMessage(message: string): void {
    this.context.commit('setNotificationMessage', message)
  }

  @Action({ rawError: true })
  newNotificationName(name: string): void {
    this.context.commit('setNotificationName', name)
  }

  @Action({ rawError: true })
  setWaypointSearchBarFocus(value: boolean): void {
    this.context.commit('setSearchBarFocus', value)
  }

  @Action({ rawError: true })
  toggleSelectWaypointModal(): void {
    this.context.commit('setShowSelectWaypointModal', true)
    setTimeout(() => {
      this.context.commit('setShowSelectWaypointModal', false)
    }, 100)
  }

  @Action({ rawError: true })
  setWaypointModalType(type: string): void {
    this.context.commit('setCurrWaypointModalType', type)
  }
}
