import { generatedGUID, isBrowser } from '@/utils/utility-manager'

export default class GoogleAPIManager {
  static tokenKey = 'gapiSessionToken'

  static generateSessionToken(): string {
    if (isBrowser()) {
      // check for previously generated / save session token
      const savedToken = sessionStorage.getItem(this.tokenKey)

      if (savedToken) {
        const parsedValue = JSON.parse(savedToken)

        if (
          parsedValue.sessionToken &&
          parsedValue.expiresAt &&
          parsedValue.expiresAt > new Date().getTime()
        ) {
          // return previously saved token
          return parsedValue.sessionToken
        } else {
          // remove expired / faulty value
          this.removeSessionToken()
        }
      }
    }

    const sessionToken = generatedGUID()

    if (isBrowser()) {
      // create value to persist with expiration after 3 minutes (= 180000ms)
      const value = {
        sessionToken,
        expiresAt: new Date().getTime() + 180000,
      }
      sessionStorage.setItem(this.tokenKey, JSON.stringify(value))
    }

    return sessionToken
  }

  static removeSessionToken(): void {
    sessionStorage.removeItem(this.tokenKey)
  }
}
