
import { Component, Vue } from 'vue-property-decorator'

import LButton from '@/components/core/Button.vue'
import NotificationMenu from '@/components/notification/NotificationMenu.vue'
import UserSettings from '@/components/user/settings/UserSettings.vue'
import ProBadge from '@/components/pro/ProBadge.vue'
import NewTripModal from '@/components/home/newTrip/NewTripModal.vue'
import JoinTripModal from '@/components/JoinTripModal.vue'
import UserDropdown from '@/components/navbar/UserDropdown.vue'
import { eventModule, authModule, tripModule } from '@/store'
import { isBrowser } from '@/utils/utility-manager'
import { getLastActiveOrganization } from '@/utils/auth'

@Component({
  name: 'Navbar',
  components: {
    UserSettings,
    ProBadge,
    JoinTripModal,
    NewTripModal,
    UserDropdown,
    NotificationMenu,
    LButton,
  },
})
class Navbar extends Vue {
  logoIsLoaded = false

  get user() {
    return authModule.user
  }

  get isLoggedIn(): boolean {
    return authModule.loggedIn
  }

  get trip() {
    return tripModule.trip
  }

  get hasArchive(): boolean {
    return tripModule.hasArchive
  }

  get showArchiveButton(): boolean {
    return (
      (this.hasArchive || this.showArchive) &&
      !this.showTripNavigation &&
      !this.isDiscoverRoute
    )
  }

  get showNavigationDrawer(): boolean {
    return eventModule.navigationDrawer
  }

  set showNavigationDrawer(value: boolean) {
    eventModule.toggleNavigationDrawer(value)
  }

  get showTripNavigation(): string {
    return this.$route.params.id
  }

  get showArchive(): boolean {
    return this.$route.name === 'archive'
  }

  get isDiscoverRoute(): boolean {
    if (!this.$route.name) return false
    return this.$route.name.startsWith('discover')
  }

  get discoverSearchModal(): boolean {
    return eventModule.discoverSearchModal
  }

  get hasExtension() {
    return !!this.$slots.extension
  }

  get showNewTripButton() {
    if (!Array.isArray(this.user?.memberships)) {
      return false
    }
    const organization = getLastActiveOrganization(this.user as any)
    // Only show the New Trip button for users in the default Lambus org
    return organization.default
  }

  navArchive(): void {
    this.$router.push({ name: this.showArchive ? 'trips' : 'archive' })
  }

  navDiscover(): void {
    this.$router.push({ name: 'discover' })
  }

  navBookmarks(): void {
    this.$router.push({ name: 'discover-bookmarks' })
  }

  showJoinTripPrompt(): void {
    const dialog = this.$refs.joinTripModal as unknown as InstanceType<
      typeof JoinTripModal
    >
    setTimeout(() => {
      if (dialog) dialog.toggleDialog()
    })
  }

  showNewTripModal(): void {
    const dialog = this.$refs.newTripModal as NewTripModal
    setTimeout(() => {
      if (dialog) dialog.toggleDialog()
    })
  }

  routeToPro(): void {
    if (!isBrowser()) {
      return
    }

    window.open(`${process.env.DASHBOARD_HOME}/pro/features`, '_blank')
  }

  showDiscoverSearchModal(): void {
    eventModule.setDiscoverSearchModal(true)
  }
}

export default Navbar
