import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { UploadError } from './enums'
import { eventModule } from '@/store'

const S3_REGION = 'eu-central-1'
const S3_DEFAULT_BUCKET = 'lambus'

const client = new S3Client({
  region: S3_REGION,
  credentials: fromCognitoIdentityPool({
    // Cognito requires an own region config:
    // https://github.com/aws/aws-sdk-js-v3/issues/5052#issuecomment-1665990835
    clientConfig: {
      region: S3_REGION,
    },
    identityPoolId: process.env.AWS_POOL_ID_PROD,
  }),
})

export default class AWSManager {
  static async uploadFile(key, content, callback) {
    const partSize = 25 * 1024 * 1024

    if (content.size <= partSize) {
      try {
        const upload = new Upload({
          client,
          partSize,
          queueSize: 1,
          params: {
            Bucket: S3_DEFAULT_BUCKET,
            Key: key,
            Body: content,
          },
        })
        const data = await upload.done()
        callback(data.Location)
      } catch (err) {
        console.error(err)
        return undefined
      }
    } else {
      eventModule.newError('error.uploadFileSize')
      return UploadError.MAXIMUM_FILE_SIZE_EXCEEDED
    }
  }

  static async getPresignedUrl(key) {
    try {
      const command = new GetObjectCommand({
        Bucket: S3_DEFAULT_BUCKET,
        Key: key,
      })
      return await getSignedUrl(client, command)
    } catch (error) {
      return undefined
    }
  }
}
