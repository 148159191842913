import { createMongoAbility } from '@casl/ability'
import { useMutation } from '@tanstack/vue-query'
import { reactiveComputed } from '@vueuse/core'
import axios from 'axios'
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'

import { useClaimOnboardingSession } from '@/composables/assistant'
import { User as PlainUser } from '@/types/user'
import { useContext } from '@/composables/context'
import { useCreateTrip, useJoinTrip } from '@/composables/trip'
import { useImportInspiration } from '@/composables/inspiration'
import { getLastActiveOrganization } from '@/utils/auth'
import { authModule, eventModule } from '@/store'
import { isBrowser } from '@/utils/utility-manager'
import { SocialLoginType } from '@/utils/enums'
import {
  AuthenticatWithAppleUser,
  AuthenticateWithFacebookOptions,
  AuthenticateWithGoogleOptions,
  AuthorizationResponseData,
  ResetPasswordResponseData,
  SignInCredentials,
  SignUpResponseData,
  SignUpUser,
} from '@/types/auth'
import { useOnboardingStore } from '@/stores/onboarding'
import Intercom from '@/utils/intercom-manager'

export interface User extends PlainUser {
  ability: ReturnType<typeof createMongoAbility>
}

export interface SignedInAuth {
  /**
   * A boolean that returns true if the user is signed in.
   */
  isSignedIn: true
  /**
   * The current user's ID.
   */
  userId: string
  /**
   * The current user's active organization ID.
   */
  orgId: string
}

export interface SignedOutAuth {
  /**
   * A boolean that returns true if the user is signed in.
   */
  isSignedIn: false
  /**
   * The current user's ID.
   */
  userId: undefined
  /**
   * The current user's active organization ID.
   */
  orgId: undefined
}

export type AuthObject = SignedInAuth | SignedOutAuth

export function useAuth() {
  const { store } = useContext()

  const { auth } = store.state

  return reactiveComputed<AuthObject>(() => {
    return {
      isSignedIn: !!auth.currentUser?._id,
      userId: auth.currentUser?._id,
      orgId: auth.currentUser?.lastActiveOrganizationId,
    }
  })
}

/**
 * Returns the currently authenticated user.
 */
export function useUser() {
  const { store } = useContext()

  const { auth } = store.state

  return computed<User>(() => ({
    ...auth.currentUser,
    ability: createMongoAbility(auth.currentUser.permissions),
  }))
}

/**
 * Returns the last active organization of the currently authenticated user
 */
export function useOrganization() {
  const user = useUser()

  return computed(() => getLastActiveOrganization(user.value))
}

export function useSetTokens() {
  const { $axios } = useContext()

  function setTokens(
    authResponse: AuthorizationResponseData,
    socialLoginMethod?: SocialLoginType,
  ) {
    const user = authResponse.user

    // Set authorization header for all axios requests
    $axios.defaults.headers.Authorization = `Bearer ${authResponse.token}`

    authModule.setTokens({
      accessToken: authResponse.token,
      refreshToken: authResponse.refreshToken,
    })

    authModule.setUser(user)

    if (isBrowser()) {
      localStorage.setItem('kLambusAccountType', socialLoginMethod ?? 'form')
    }
  }

  return { setTokens }
}

/**
 * Checks if there are any pending actions from the onboarding process.
 * Like joining a trip or importing an inspiration before the user is
 * authenticated.
 */
export function usePendingActions() {
  const onboardingStore = useOnboardingStore()
  const { mutateAsync: joinTrip } = useJoinTrip()
  const { mutateAsync: importInspiration } = useImportInspiration()
  const { mutateAsync: createTrip } = useCreateTrip()
  const { mutateAsync: claimSession } = useClaimOnboardingSession()
  const router = useRouter()
  const route = useRoute()

  async function handlePendingActions() {
    let redirectPath = '/'

    if (typeof route.query.inspirationId === 'string') {
      await importInspiration(route.query.inspirationId)
    }

    if (typeof route.query.pinCode === 'string') {
      const joinTripResponse = await joinTrip(route.query.pinCode)
      if (joinTripResponse.success && joinTripResponse.trip) {
        redirectPath = `/trip/${joinTripResponse.trip._id}`
      }
    }

    if (onboardingStore.pendingTrip) {
      const createTripResponse = await createTrip(onboardingStore.pendingTrip)
      onboardingStore.resetPendingTrip()

      if (createTripResponse.success && createTripResponse.trip) {
        redirectPath = `/trip/${createTripResponse.trip.id}`
      }
    }

    if (
      typeof route.query.assistant_sid === 'string' &&
      onboardingStore.activeAssistantSession
    ) {
      const { trip } = await claimSession({
        sessionHandle: route.query.assistant_sid,
        secret: onboardingStore.activeAssistantSession.secret,
      })
      redirectPath = `/trip/${trip._id}`
    }

    if (typeof route.query.redirectTo === 'string') {
      redirectPath = route.query.redirectTo
    }
    router.push({ path: redirectPath })
  }

  return { handlePendingActions }
}

/**
 * Sign in the user with the provided credentials.
 * Returns a mutation function to sign in the user.
 */
export function useSignIn() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (credentials: SignInCredentials) => {
      try {
        const { data } = await $axios.post<AuthorizationResponseData>(
          'api/authenticate',
          credentials,
        )
        return data
      } catch (error) {
        handleAuthenticationError(error)
      }
    },
  })
}

/**
 * Sign up the user with the provided credentials.
 * Returns a mutation function to sign up the user.
 */
export function useSignUp() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (newUser: SignUpUser) => {
      try {
        const { data } = await $axios.post<SignUpResponseData>(
          '/api/users',
          newUser,
        )
        return data
      } catch (error) {
        if (!axios.isAxiosError(error)) {
          eventModule.newError('error.generic')
          return
        }
        const emailAlreadyExistsCodes = [12020204, 12020205, 12040106]
        const errorCode = error.response?.data.code ?? -1

        if (emailAlreadyExistsCodes.includes(errorCode)) {
          eventModule.newError('error.user.emailAlreadyExists')
        } else {
          eventModule.newError('error.user.create')
        }
      }
    },
  })
}

// ------------- Single Sign On  -------------

export function useAuthenticateWithApple() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (user: AuthenticatWithAppleUser) => {
      try {
        const { data } = await $axios.post<AuthorizationResponseData>(
          `/api/authenticate/appleid`,
          {
            ...user,
            web: true,
          },
        )
        return data
      } catch (error) {
        handleAuthenticationError(error)
      }
    },
  })
}

export function useAuthenticateWithFacebook() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (options: AuthenticateWithFacebookOptions) => {
      const { fbAccessToken, ...user } = options
      try {
        const { data } = await $axios.post<AuthorizationResponseData>(
          `/api/authenticate/facebook?access_token=${fbAccessToken}`,
          user,
        )
        return data
      } catch (error) {
        handleAuthenticationError(error)
      }
    },
  })
}

export function useAuthenticateWithGoogle() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (options: AuthenticateWithGoogleOptions) => {
      const { googleAccessToken, ...user } = options

      try {
        const { data } = await $axios.post<AuthorizationResponseData>(
          `/api/authenticate/google?access_token=${googleAccessToken}`,
          user,
        )
        return data
      } catch (error) {
        handleAuthenticationError(error)
      }
    },
  })
}

export function useResetPassword() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (email: string) => {
      try {
        const { data } = await $axios.post<ResetPasswordResponseData>(
          '/api/users/resetPassword',
          { email },
        )

        return data
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data.isSocial) {
          eventModule.newInfo('error.user.password_reset_social')
        } else {
          eventModule.newError([
            'error.user.password_reset',
            'error.email_retry',
          ])
        }
      }
    },
    onSuccess() {
      eventModule.newMessage('success.user.password_reset')
    },
  })
}

function handleAuthenticationError(error: unknown) {
  if (!axios.isAxiosError(error)) {
    eventModule.newError('error.generic')
    return
  }
  const errorCode = error.response?.data.code ?? -1
  const socialType = error.response?.data.type ?? ''

  if (errorCode === 13020104) {
    eventModule.newError('loginFailed.alreadyRegisteredWithEmail')
  } else if (errorCode === 13020105) {
    eventModule.setValues({ social: socialType })
    eventModule.newError('loginFailed.isLinkedToSocialAccount')
  } else if (errorCode === 13020102) {
    eventModule.newError('loginFailed.appleSignInError')
    Intercom.show()
  } else {
    eventModule.newError('loginFailed.wrongInput')
  }
}
