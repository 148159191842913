
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SeparatedDoubleSlot',
})
class SeparatedDoubleSlot extends Vue {
  @Prop({ default: 0.5, type: Number }) readonly ratio!: number
  leftCols = 6

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  get leftClass(): string {
    if (!this.$slots.right) {
      return ''
    }
    return this.isMobile ? 'pb-0' : 'pr-0'
  }

  mounted() {
    if (!this.$slots.right) {
      this.leftCols = 12
    } else if (this.ratio > 0) {
      this.leftCols = Math.floor(12 * this.ratio)
    }
  }
}
export default SeparatedDoubleSlot
