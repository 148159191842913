
import { Component, Vue } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import PictureViewer from '@/components/PictureViewer.vue'
import { expenseModule, tripModule, eventModule } from '@/store'
import { Expense } from '@/types/expense'
import { Trip } from '@/types/trip'
import AWSManager from '@/utils/aws-manager'

@Component({
  name: 'ExpensePhoto',
  components: {
    PictureViewer,
  },
})
class ExpensePhoto extends Vue {
  showExpensePhoto = false
  filePath: any = null

  get trip(): Trip | null {
    return tripModule.trip
  }

  get expense(): Expense | null {
    return expenseModule.expense
  }

  get hasPhoto(): boolean {
    return (
      (this.expense?.pictures && this.expense?.pictures.length > 0) || false
    )
  }

  get expensePhoto(): string {
    return this.hasPhoto ? this.expense!.pictures![0] : ''
  }

  get imageSrc() {
    if (this.filePath) return this.filePath
    if (this.hasPhoto) return this.expensePhoto

    return require('@/assets/images/expense_photo_default.jpg')
  }

  get imageUrls(): string[] {
    return [this.imageSrc]
  }

  get showImage(): boolean {
    return this.hasPhoto || (this.filePath && this.filePath.length > 0)
  }

  showFileBrowser(): void {
    const fileInput = this.$refs.expensePhotoUploader as HTMLElement
    fileInput.click()
  }

  async onFileChanged(e: Event): Promise<void> {
    const file = (e.target as any).files[0]
    const partSize = 25 * 1024 * 1024

    if (file.size > partSize) {
      eventModule.newError('error.uploadFileSize')
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      Vue.set(this, 'filePath', (e.target as any).result)
    }

    reader.readAsDataURL(file)

    const fileName = `${uuidv4()}.${file.name.split('.').pop().toLowerCase()}`

    this.$emit('update:file', file)
    this.$emit('update:file-name', fileName)

    if (!this.trip || !this.expense?._id) {
      return
    }
    await AWSManager.uploadFile(
      `trips/${this.trip.id}/expenses/${this.expense._id}/${fileName}`,
      file,
      () => {
        this.updateExpense(fileName)
      },
    )
  }

  async updateExpense(fileName?: string): Promise<void> {
    if (!this.trip || !this.expense?._id) {
      if (!fileName) {
        this.filePath = null
      }
      return
    }

    const payload = {
      pictures: [] as string[],
    }

    if (fileName) {
      payload.pictures.push(fileName)
    } else {
      this.filePath = null
    }

    await expenseModule.updateExpense({
      tripId: this.trip.id,
      payload,
      expenseId: this.expense._id,
    })

    await tripModule.fetch(this.trip.id)

    const currentExpense = this.trip.expenses.find(
      (expense) => expense._id === this.expense?._id,
    )

    if (currentExpense) {
      expenseModule.setCurrentExpense(currentExpense)
    }
  }
}
export default ExpensePhoto
