
import { Component, Vue } from 'vue-property-decorator'
import ProBadge from '@/components/pro/ProBadge.vue'
import { authModule, eventModule } from '@/store'
@Component({
  name: 'PackingListsExpansionPanel',
  components: {
    ProBadge,
  },
})
class PackingListsExpansionPanel extends Vue {
  get user() {
    return authModule.user
  }

  openUserPackingLists(): void {
    if (!this.user.hasActiveLambusPro) {
      eventModule.toggleProHintModal()
    } else {
      this.$router.push({
        name: 'user-packing-lists',
      })
    }
  }
}
export default PackingListsExpansionPanel
