const validation = require('vee-validate/dist/locale/en.json')

module.exports = {
  i: 'I',
  me: 'Me',
  you: 'You',
  someone: 'Someone',
  other: 'other',
  others: 'others',
  otherReason: 'Other reasons',
  fromNumber: 'from',
  by: 'By',
  clickToEdit: 'Click to edit',
  save: 'Save',
  edit: 'Edit',
  add: 'Add',
  addItem: 'Add {item}',
  done: 'Done',
  delete: 'Delete',
  clear: 'Clear',
  download: 'Download',
  cancel: 'Cancel',
  update: 'Update',
  ok: 'OK',
  gotIt: 'Got it!',
  no: 'No',
  and: 'and',
  myTrips: 'My Trips',
  map: 'Map',
  waypoints: 'Stops',
  waypoint: 'Stop',
  waypointCount: 'One stop | {count} stops',
  routeCount: 'one route | {count} routes',
  packingListCount: 'one list | {count} lists',
  packingListTemplateCount: 'one list template | {count} list templates',
  documents: 'Documents',
  expenses: 'Expenses',
  notes: 'Notes',
  photos: 'Photos',
  chat: 'Chat',
  transportation: 'Transportation',
  subscription: 'Subscription',
  myProfile: 'My Profile',
  logout: 'Log out',
  editProfile: 'Edit Profile',
  travelPlan: 'Itinerary',
  settings: 'Settings',
  showMore: 'Show more',
  suggestions: 'Suggestions',
  photoDeletePrompt: 'Do you want to delete this photo?',
  documentDeletePrompt: 'Do you want to delete this document?',
  directoryDeletePrompt: 'Do you want to delete this directory?',
  transportationDeletePrompt: 'Do you want to delete this transportation?',
  changePhoto: 'Change photo',
  documentDelete: 'Delete document',
  directoryDelete: 'Delete directory',
  enterName: 'Enter Name',
  enterNamePlaceholder: 'Enter name …',
  untitled: 'Untitled',
  createFolder: 'New Folder',
  uploadFile: 'Upload Photo',
  uploadDocument: 'Upload Document',
  addDocument: 'Add Document',
  documentEmailHint:
    'Your trip has a unique e-mail address to which you can forward your booking documents. Currently supported formats are PDF, JPG and PNG!',
  addWaypoint: 'Add Stop',
  saveWaypoint: 'Save Stop',
  addingWaypoint: 'Adding stop …',
  addPOI: 'Add Nearby Place',
  nearbyPlace: 'Nearby Place',
  nearbyPlaces: 'Nearby Places',
  nearby: 'Nearby',
  attendees: 'Attendees',
  pendingAttendees: 'Pending Attendees',
  hours: 'Hours',
  hoursShort: 'hr',
  minutes: 'Minutes',
  minutesShort: 'min',
  noDate: 'No Date',
  noTitle: 'Untitled',
  days: 'Days',
  editDate: 'Edit date',
  changeDate: 'Change date',
  deleteDate: 'Delete date',
  changeTime: 'Change time',
  changeToPoi: 'Change to "Nearby place"',
  actionPrompt: 'What do you want to do?',
  ratings: 'Ratings',
  reviews: 'Reviews',
  registrationFields: {
    username: 'Username',
    password: 'Password',
    passwordConfirm: 'Confirm password',
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    summery: 'Summery',
    tosAccepted: 'Aggreed to Terms of Service',
    company: 'Company',
    name: 'Name',
  },
  validation: {
    ...validation.messages,
    usernameAvailable: 'Username {_value_} is not available',
  },
  socket: {
    IS_ONLINE: '{subject} is now online!',
    LEFT_TRIP: '{subject} left the trip!',
    DELETED_TRIP: '{subject} deleted the trip!',
    RENAMED_TRIP: '{subject} renamed the trip!',
    UPDATED_TRIP: '{subject} updated the trip!',
    UPDATED_TRIP_COVER_PHOTO: '{subject} updated the trip cover photo!',
    INVITED_ATTENDEE: '{subject} invited {object} to the trip!',
    REMOVED_ATTENDEE: '{subject} removed {object} from the trip!',
    JOINED_ATTENDEE: '{subject} joined your trip!',
    ADDED_WAYPOINT: '{subject} added a new stop!',
    REARRANGED_WAYPOINT: '{subject} moved a stop!',
    UPDATED_WAYPOINT: '{subject} updated a stop!',
    REMOVED_WAYPOINT: '{subject} removed a stop!',
    ADDED_HIGHLIGHT: '{subject} added a new place!',
    REARRANGED_HIGHLIGHT: '{subject} moved a place!',
    UPDATED_HIGHLIGHT: '{subject} updated a place!',
    REMOVED_HIGHLIGHT: '{subject} removed a place!',
    ADDED_DOCUMENT: '{subject} added a new document!',
    UPDATED_DOCUMENT: '{subject} updated a document!',
    REMOVED_DOCUMENT: '{subject} removed a document!',
    ADDED_EXPENSE: '{subject} added a new expense!',
    UPDATED_EXPENSE: '{subject} updated an expense!',
    REMOVED_EXPENSE: '{subject} removed an expense!',
    ADDED_CHAT_MESSAGE: '{subject}: {object}',
    STARTED_LIVE_LOCATION: '{subject} started sharing a live location!',
    UPDATED_LIVE_LOCATION: '{subject} updated a live location!',
    STOPPED_LIVE_LOCATION: '{subject} stopped sharing a live location!',
    ADDED_GPX: 'The GPX import is completed!',
    FORCE_RELOAD: 'Trip was updated',
    PURCHASED_LAMBUS_PRO: '{subject} purchased Lambus PRO',
    FLIGHT_UPDATE: 'A flight alarm is now set for flight {subject}',
  },
  success: {
    user: {
      password_change: 'Your password has successfully been changed',
      password_reset:
        'Please check your email inbox and follow the instructions to reset your password.',
      invite: 'Your invite has successfully been sent!',
      picture: 'Your photo was uploaded successfully!',
      update: 'Profile updated successfully!',
    },
    trip: {
      create: 'Your trip was created successfully!',
      update: 'Your trip was updated successfully!',
      delete: 'Your trip was deleted successfully!',
      duplicate: 'Your trip was duplicated successfully!',
      invite: 'Your invite has successfully been sent!',
      archive: 'Your trip was archived successfully!',
      unarchive: 'Your trip was unarchived successfully!',
    },
    accommodation: {
      create: 'The accommodation has been successfully saved!',
      update: 'The accommodation was successfully updated!',
      delete: 'The accommodation was successfully deleted!',
    },
    waypoint: {
      create: 'Stop was created successfully!',
      update: 'Stop was updated successfully!',
      delete: 'Stop was deleted successfully!',
      migrate: 'Stop was migrated successfully!',
      dailyPlan: {
        create: 'The element was added to the daily plan!',
        delete: 'The element has been deleted from the daily plan!',
        update: 'The element was successfully updated!',
        rearrange: 'The element has been successfully moved!',
      },
    },
    poi: {
      create: 'Place was created successfully!',
      update: 'Place was updated successfully!',
      delete: 'Place was deleted successfully!',
    },
    activity: {
      create: 'Activity was successfully added to the stop!',
      update: 'Activity was successfully updated!',
      delete: 'Activity has been successfully removed!',
    },
    document: {
      create: 'Your document was uploaded successfully!',
      createMultiple:
        '{successCount} out of {allCount} documents were uploaded successfully!',
      delete: 'Your document was deleted successfully!',
    },
    expense: {
      create: 'Expense was created successfully!',
      update: 'Expense was updated successfully!',
      delete: 'Expense was deleted successfully!',
      exportExpense:
        'Your expenses were exported. Please check your email account.',
      exportTrip:
        'Your trip has been exported. Please check your email account.',
    },
    amount: {
      delete: 'Amount deleted successfully!',
      paid: 'The amount has been marked as paid!',
      ping: 'Your reminder has just been sent!',
    },
    note: {
      create: 'Note was created successfully!',
      update: 'Note was updated successfully!',
      delete: 'Note was deleted successfully!',
    },
    photo: {
      create: 'Your photo was uploaded successfully!',
      createMultiple: '{count} photos were uploaded successfully!',
      delete: 'Your photo was deleted successfully!',
    },
    feedback: 'Your feedback was sent successfully!',
    transportation: {
      create: 'Transportation was created successfully!',
      update: 'Transportation was updated successfully!',
      delete: 'Transportation was deleted successfully!',
      flightStatus: {
        register: 'A flight alarm is now set for flight {flightNumber}!',
      },
    },
    routes: {
      create: 'Your Route was created successfully!',
      update: 'Your Route was updated successfully!',
      delete: 'Route was deleted successfully!',
    },
    packingLists: {
      create: 'Your list was created successfully!',
      delete: 'Your list was deleted successfully!',
    },
    gpx: {
      delete: 'GPX-route was deleted successfully!',
    },
    pro: {
      cancellation:
        'Your Lambus PRO subscription has been cancelled successfully!',
    },
    organization: {
      removeTraveler: 'The traveler was removed.',
    },
  },
  error: {
    generic: 'Oh oh, something went wrong. Please try again!',
    login: {
      apple: 'Could not log in with Apple ID',
      facebook: 'Could not log in with Facebook',
      google: 'Could not log in with Google',
      blocked: {
        title: 'Please deactivate your ad blocker',
        content:
          'We do not use ads, but some social logins are blocked by ad blockers (e.g. Facebook)<br/><ul><li>If you are using an adblocker, please turn it off and try again</li><li>If you are using Firefox, please be aware that the feature "Enhance Tracking Protection" might prevent your login</li></ul>',
      },
    },
    user: {
      password_change: {
        default: 'Your password could not be changed',
        currentPasswordWrong: 'Your current password ist wrong.',
      },
      password_reset: 'Cannot reset password!',
      password_reset_social:
        'Your email is linked to a social media account. Please sign in with your Apple/Google/Facebook account 😇',
      invite: 'Your invite cannot be sent!',
      picture: 'Your photo could not be saved!',
      update: {
        default: 'Could not save profile! Please try again.',
        usernameNotAvailable:
          'Could not save profile! The entered username is not available.',
        emailNotAvailable:
          'Could not save profile! The email address is already in use.',
      },
      delete: 'Cannot delete account',
      create: 'Your registration could not be completed!',
      search: 'User could not be found!',
      emailAlreadyExists:
        'A user with this e-mail address already exists. Please log in or try another e-mail address.',
    },
    trip: {
      create: 'Your trip could not be created!',
      update: 'Your trip could not be updated!',
      delete: 'Your trip could not be deleted!',
      duplicate: 'Your trip could not be duplicated!',
      invite: 'Your invite cannot be sent!',
      join: 'The trip could not be joined',
      validatePinCode: 'The PIN code is invalid',
      leave: 'Could not delete attendee',
      archive: 'Your trip could not be archived.',
      unarchive: 'Your trip could not be unarchived.',
      fetch: 'Trip could not be received!',
      notification_update: 'Your settings could not be updated.',
      notification_fetch: 'Your settings could not be received.',
    },
    accommodation: {
      fetch: 'Your accommodations could not be fetched.',
      create: 'Cannot save accommodation.',
      update: 'Cannot update accommodation.',
      delete: 'Cannot delete accommodation.',
    },
    gpx: {
      fetch: 'Your GPX routes could not be received.',
      delete: 'Your GPX routes could not be deleted.',
    },
    theme: {
      size: 'Your image exceeds the maximum file size of 5mb.',
    },
    waypoint: {
      rearrange: 'Cannot rearrange details!',
      fetch: 'Cannot load stop!',
      create: 'Cannot save stop!',
      update: 'Cannot update stop!',
      delete: 'Cannot delete stop',
      optimize: 'Your itinerary could not be sorted.',
      weather: 'Cannot load weather',
      migrate: 'Cannot migrate stop',
      dailyPlan: {
        fetch: 'Cannot load daily plan!',
        create: 'Cannot add item to daily plan!',
        delete: 'Cannot delete item from daily plan!',
        update: 'Cannot update daily plan!',
        rearrange: 'Cannot not move item!',
      },
      poi: {
        fetch: 'Cannot get places',
      },
    },
    poi: {
      fetch: 'Cannot get place!',
      create: 'Could not add nearby place to stop!',
      update: 'Cannot update place!',
      delete: 'Cannot delete place!',
      preview: 'Cannot load place preview!',
    },
    activity: {
      fetch: 'Cannot get activity!',
      create: 'Could not add activity to stop!',
      update: 'Cannot update activity!',
      delete: 'Cannot delete activity!',
    },
    document: {
      fetch: 'Cannot get documents!',
      get: 'Cannot load document!',
      create: 'Your document could not be saved!',
      update: 'Your document could not be updated!',
      delete: 'Your document could not be deleted!',
      download: 'Your document could not be downloaded!',
    },
    expense: {
      create: 'Expense could not be saved!',
      update: 'Expense could not be updated!',
      delete: 'Expense could not be deleted!',
      rate: 'Currency rates could not be received',
      findCurrency: 'Currency could not be found',
    },
    amount: {
      delete: 'Amount could not be deleted!',
      paid: 'The expense could not be marked as paid!',
      ping: 'Your reminder could not be sent!',
    },
    note: {
      fetch: 'Cannot get notes',
      create: 'Cannot save note',
      update: 'Cannot update note',
      delete: 'Cannot delete note',
    },
    photo: {
      fetch: 'Cannot get photos!',
      create: 'Your photo could not be saved!',
      update: 'Your photo could not be updated!',
      delete: 'Your photo could not be deleted!',
      download: 'Your photo could not be downloaded!',
    },
    message: {
      fetch: 'Could not get messages',
      send: 'Your message could not be sent',
    },
    inspiration: {
      fetch: 'Could not get inspiration',
      fetchAll: 'Could not get inspirations',
    },
    cateogry: {
      fetchAll: 'Could not get categories',
    },
    bookmark: {
      fetch: 'Could not get bookmarks',
      create: 'Could not add bookmark',
      delete: 'Could not delete bookmark',
    },
    import: {
      create: 'Could not import trip',
    },
    category: {
      fetchAll: 'Could not load categories',
    },
    directory_create: 'Cannot create folder',
    feedback: 'Your feedback could not be sent!',
    payment_details: 'Payment details could not be received!',
    email_invalid: 'The email address is invalid.',
    email_retry:
      'Please make sure you entered the correct email address and try again.',
    location_support: 'Your browser does not support GPS-localization.',
    location: 'Your location is not available. Please try again.',
    transportation: {
      fetch: 'Could not get transportation.',
      search: 'Could not perform transportation search.',
      post: 'Could not add transportation.',
      update: 'Could not update transportation.',
      delete: 'Could not delete transportation.',
      searchFlight: 'The flight could not be found.',
      flightStatus: {
        missingFields:
          'We need the following details in order to track your flight: {missingFields}',
        register: 'Could not activate Flight Updates.',
        fetch: 'Could not get flight status.',
      },
    },
    routes: {
      fetchAll: 'Routes could not be loaded.',
      fetch: 'Your route could not be loaded.',
      calculate: 'Could not calculate route.',
      update: 'Your route could not be updated.',
      create: 'Your route could not be saved.',
      delete: 'The route could not be deleted.',
    },
    export: {
      trip: 'Your trip could not be exported.',
      expenses: 'Your travel expenses could not be exported.',
    },
    packingLists: {
      fetch: 'Your list could not be loaded.',
      fetchTemplates: 'List templates could not be loaded.',
      create: 'Your list could not be saved.',
      update: 'Your list could not be updated.',
      delete: 'Your list could not be deleted.',
    },
    subscription: {
      fetch: 'Subscriptions could not be loaded.',
      cancel:
        'Your subscription could not be canceled. Please try again later or contact our support.',
    },
    payment: {
      prices: {
        fetch: 'Prices could not be loaded.',
      },
      session: {
        fetch: 'Payment process could not be initiated.',
      },
    },
    placeRecommendations: {
      fetch: 'Recommended places could not be loaded.',
    },
    uploadFileSize: 'The file can not be larger than 25 MB.',
  },
  info: {
    document: {
      upload: 'Your document will be uploaded in the background …',
      uploadMultiple:
        'Your {count} documents will be uploaded in the background …',
    },
    photo: {
      upload: 'Your photo will be uploaded in the background …',
      uploadMultiple:
        'Your {count} photos will be uploaded in the background …',
    },
    transportation: {
      searchFlight: 'Flight not found',
    },
    userAlreadyAdded: 'Attendee already added',
  },
  emptyState: {
    map: 'Search for new stops, using the search field or the map',
    documents: 'No Documents',
    private_expenses: 'No private Expenses',
    group_expenses: 'No shared Expenses',
    billing: 'Balanced',
    notes: 'No Notes',
    photos: 'No Photos',
    chat: 'No Messages',
    travelPlan: 'No Stops',
    packingLists: 'No lists',
    transportation: 'No transportation',
    hint: {
      general: 'Feel free to add some!',
      travelPlan: 'Please create some stops and return here!',
      expenses: 'All balanced. Tap the + to add an expense',
      chat: 'Write something nice!',
      packingLists: `Make sure you don't forget anything! Tap the + to add a list`,
      accommodation:
        'Where do you stay? Tap the + to add your hotels & apartments',
      search:
        '@:(noResults). Please try again with a different search term. 🙌',
      transportation: 'How do you travel? Tap the + to add your transportation',
      notes: 'So empty! Tap the + to add some notes or links',
      documents: 'No documents. Tap the + to add your travel documents',
      photos: 'No photos to show. Tap the + to save your memories',
    },
  },
  overview: 'Overview',
  copyTooltip: 'Click to copy',
  copySuccess: 'Copied to clipboard!',
  datePicker: {
    SPECIFIC: 'Specific date',
    WHOLE_MONTH: 'Whole month',
  },
  datetimePicker: {
    DATE: 'Date',
    TIME: 'Time',
  },
  dates: {
    title: 'When will you be there? (optional)',
    hint: 'Select date …',
  },
  joinTripPrompt:
    'You received an invitation to a trip? Enter the trip PIN code below to join.',
  joinTripNow: 'Join trip now',
  navBar: {
    discover: 'Discover',
    newTrip: 'New Trip',
    joinTrip: 'Join Trip',
    archive: 'Archive',
    activeTrips: 'Active Trips',
    searchPlaceholder: 'Search for inspirations',
  },
  peoplePickerTitle: 'Select a person',
  youAreInvited: 'You have been invited to join "{tripName}"!',
  acceptInvitation: 'Accept invitation',
  decline: 'Decline',
  destination: 'The destination',
  destinations: 'Destinations',
  suggestedTrips: 'Suggested Trips',
  similarTopics: 'Similar Topics',
  sendImage: 'Send image',
  dropHint: 'Drop your files here to upload.',
  iOwe: 'I owe',
  owe: 'owe',
  iReceive: 'I receive',
  receiveFrom: 'receive from',
  newPrivateExpense: 'New private Expense',
  newGroupExpense: 'New group Expense',
  notInvolved: 'Not involved',
  addNote: 'Add Note',
  newNote: 'New Note',
  saveNote: 'Save Note',
  billing: 'Billing',
  openAccounts: 'Open Accounts',
  paidAccounts: 'Paid Accounts',
  paid: 'Paid',
  received: 'Received',
  createExpense: 'Create Expense',
  updateExpense: 'Update Expense',
  addExpense: 'Add Expense',
  editExpense: 'Edit Expense',
  whoDoesItRelateTo: 'Who does it relate to?',
  whoPaid: 'Who paid?',
  subject: 'Subject',
  subjectPlaceholder: 'Enter Subject …',
  continue: 'Continue',
  balance: 'Balance',
  total: 'Total',
  equal: 'equally',
  exact: 'exactly',
  whenWasItPaid: 'When was it paid?',
  deleteExpensePrompt: 'Do you want to delete this Expense?',
  deleteExpense: 'Delete Expense',
  pingPrompt: 'Ping {_value_} to mark this balance as paid.',
  pingAction: 'Send reminder',
  pingTitle: 'You already paid?',
  enterAmount: 'Enter Amount',
  enterAmountPlaceholder: 'Enter Amount …',
  payWithPayPal: 'Pay with PayPal',
  noPaypalAccountSubject: 'PayPal details not deposited, yet!',
  noPaypalAccountText:
    '{person} has not deposited his/her PayPal information, yet. You can ask him/her to add the details to his/her account and return here',
  markAsPaid: 'Mark as paid',
  alreadyPaid: 'Already paid?',
  noPayPalInformation: 'This user has not set his/her PayPal information, yet',
  group: 'Group',
  myExpenses: 'My Expenses',
  allExpenses: 'All Expenses',
  private: 'Private',
  shared: 'Shared',
  searchHint: 'Search for Places',
  noResults: 'No results',
  airport: 'Airport',
  atm: 'ATM',
  bank: 'Bank',
  bar: 'Bar',
  bus_station: 'Bus Station',
  cafe: 'Cafe',
  city_hall: 'City Hall',
  gas_station: 'Gas Station',
  hotel: 'Hotel',
  hospital: 'Hospital',
  library: 'Library',
  museum: 'Museum',
  night_club: 'Night Club',
  park: 'Park',
  parking: 'Parking',
  pharmacy: 'Pharmacy',
  police: 'Police',
  post_office: 'Post Office',
  restaurant: 'Restaurant',
  shopping_mall: 'Shopping Mall',
  store: 'Store',
  subway_station: 'Subway Station',
  train_station: 'Train Station',
  zoo: 'Zoo',
  showLess: 'Show less',
  radius: 'Radius',
  noPlacesFound:
    'No places could be found in this search. Please try another search term or increase the radius',
  noWorriesYouCanStillAddIt: 'But no worries, you can still add it!',
  howWouldYouLikeToImportThisPlace: 'How would you like to import this place?',
  chooseWaypoint: 'Which stop would you like to add the location to?',
  selectWaypoint: 'Select stop...',
  importAsWaypoint: 'Import as stop',
  addPOIToWaypoint: 'Import as POI',
  whereToStartFrom: 'Where do you want to start?',
  mapSettings: 'Map settings',
  mapTypes: {
    title: 'Map type',
    types: {
      normal: 'Normal',
      satellite: 'Satellite',
      terrain: 'Terrain',
    },
  },
  distanceUnits: {
    title: 'Distance units',
    kilometers: 'Kilometers',
    miles: 'Miles',
  },
  weather: {
    title: 'Temperature',
    celsius: 'Celsius',
    fahrenheit: 'Fahrenheit',
  },
  connectWaypoints: 'Connect stops',
  showDistance: 'Show distance',
  learnMore: 'Learn more',
  deleteNoteDescription: 'Are you sure you want to delete this note?',
  deleteNoteTitle: 'Delete Note',
  noteName: 'Enter a Title …',
  selectNote: 'Select a note …',
  areYouSure: 'Are you sure?',
  removeAttendee: 'Remove attendee',
  showPicture: 'Show profile picture',
  viewPhoto: 'View Photo',
  deletePhoto: 'Delete Photo',
  general: 'General',
  restaurants: 'Restaurants',
  accommodations: 'Accommodations',
  entertainment: 'Entertainment',
  shopping: 'Shopping',
  activities: 'Activities',
  drinks: 'Drinks',
  fuel: 'Refueling / Charging',
  tickets: 'Tickets',
  fees: 'Fees',
  usernameSearchHint: 'Search for username or email …',
  currency: 'Currency',
  currencyHeader: 'Which currency do you use?',
  timePeriod: 'Travel period',
  datesHeader: 'When will you be there?',
  notifications: 'Notifications',
  choose: 'Select',
  selected: 'selected',
  waypointSorting: {
    changeToManual: 'Change sorting to "Manual"',
    title: 'Sorting stops',
    header: 'NEW: Sort stops automatically',
    text: 'It is now possible to sort stops automatically, based on their date. If a stop has no date, it will be appended at the end. If you have already added transportation or routes that may be affected by this change, we will of course let you know!',
  },
  byDate: 'By date',
  manual: 'Manual',
  preview: 'Preview',
  showPreview: 'Show preview',
  inviteYourFriends: 'Invite friends',
  manage: 'Manage',
  tokenHint:
    'You can share the PIN code with your friends and they can join the journey through it.',
  moreActions: 'More actions',
  saveTrip: 'Save trip',
  archiveTrip: 'Archive trip',
  duplicateTrip: 'Duplicate trip',
  duplicateTripConfirmMessage:
    'Do you want to duplicate this trip? A copy of this trip will be created.',
  confirm: 'Confirm',
  enterTripNamePrompt: 'Please enter the new name of the trip.',
  enterTripName: 'Enter trip name …',
  leftTrip: 'left trip',
  leaveTrip: 'Leave trip',
  deleteTrip: 'Delete trip',
  leaveTripPrompt: 'Do you want to leave this trip?',
  deleteTripPrompt: 'Do you want to delete this trip?',
  restoreTrip: 'Unarchive trip',
  themeTooltip: 'Click to change cover photo (max 5 mb)',
  inviteNow: 'Invite now',
  inviteAttendee: 'Invite Attendee',
  search: 'Search',
  skip: 'Skip',
  highlight: 'Nearby Places',
  expense: 'Expenses',
  document: 'Documents',
  photo: 'Photos',
  note: 'Note',
  timeHint: 'Select time …',
  poi: 'Local places',
  deleteWaypointPrompt: 'Are you sure?',
  deleteWaypoint: 'Delete {type}',
  place: 'place',
  changePasswordAction: 'Change password',
  changePassword: 'Change password …',
  oldPassword: 'Enter old password …',
  newPassword: 'Enter password …',
  newPasswordConfirmation: 'Enter password again …',
  resetPasswordTitle: 'Reset password',
  resetPasswordAction: 'Reset now',
  resetHint: 'Please enter your email address to reset your password.',
  enterEmail: 'Enter email address …',
  forgotPassword: 'Forgot password?',
  invitePrompt: 'Would you like to invite your friends directly to your trip?',
  selectTrip: 'Select trip',
  inviteToLambus: 'No, only to Lambus',
  yes: 'Yes',
  yesSelectTrip: 'Yes, select trip',
  profileTooltip: 'Click to change profile picture',
  sendInvite: 'Send invite',
  inviteAggreement:
    'I confirm that the person invited by me has agreed to receive the invitation email from Lambus GmbH',
  inviteHint: 'Invite your friends now and plan your next trip together.',
  socialMedia: 'Social Media',
  youCanFindUsOn: 'You can find us on …',
  feedback: 'Feedback',
  howCanWeHelp: 'How can we help?',
  send: 'Send',
  faq: 'Help / FAQ',
  legal: 'Legal',
  terms: 'Terms',
  privacy: 'Privacy',
  imprint: 'Imprint',
  username: 'Username',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email address',
  company: 'Company',
  gender: 'Gender',
  birthdate: 'Date of Birth',
  female: 'Female',
  male: 'Male',
  neutral: 'Neutral',
  country: 'Country',
  paypalAddress: 'PayPal Email Address',
  paypalMeUsername: 'PayPal.Me Username',
  deleteAccountPrompt:
    'This will erase all your data - including trips, documents, photos and chat history.',
  irrevocablyDelete: 'Irrevocably delete',
  deleteAccount: 'Delete account',
  unknownPlace: 'Unknown Place',
  kilometersUnit: 'km',
  milesUnit: 'mi',
  createTrip: 'Create Trip',
  next: 'CONTINUE',
  back: 'BACK',
  navHint: 'Or press ENTER',
  invite: 'Invite',
  or: 'OR',
  newTripFields: {
    destination: {
      title: 'Where do you want to go?',
      hint: 'e.g. a city, country or region',
    },
    origin: {
      title: 'Where do you want to start? (optional)',
      hint: 'e.g. address, station or airport',
    },
    attendees: {
      hint: 'Who accompanies you? (optional)',
    },
  },
  createNewTrip: 'Create New Trip',
  joinTrip: 'Join Trip',
  welcome: 'Welcome to Lambus!',
  loginTitle: 'Welcome to Lambus!',
  login: 'Log In',
  lambusAllInOneApp: 'Lambus - Your new All-In-One travel app',
  usernameLabel: 'Username / Email',
  passwordLabel: 'Password',
  notRegisteredYet: 'Create Account',
  loginFailed: {
    wrongInput:
      'Either your username/email or password was incorrect. Please try again or click on "Forgot password?", thank you!',
    alreadyRegisteredWithEmail:
      'Problem with your sign in. You already have an account with this email address. Do you want to reset your password',
    isLinkedToSocialAccount:
      'Your email is linked to a social media account. Please sign in with your "{social}" account.',
    appleSignInError:
      'Unfortunately, we could not complete your sign in with Apple now. Please choose an alternative sign in method - in the meantime we are already working on the fix!',
  },
  continueWithoutAccount: 'Continue without profile?',
  usernameNotice:
    "You're almost done! Please enter a username that will be linked to your Lambus account.",
  tosAggreement:
    'Please accept our terms and privacy policy to complete your registration.',
  continueWithApple: 'Continue with Apple',
  continueWithFacebook: 'Continue with Facebook',
  registration: 'Register',
  register: 'Sign Up',
  tosAggreementPlaceholders:
    'Please accept our {0} and {1} to complete your registration.',
  field: 'Field',
  value: 'Value',
  noInspirationFound: 'No inspirations found.',
  importTrip: 'Import trip',
  importedTrip: 'Trip imported',
  myTransportation: 'My transportation',
  addTransportation: 'Add transportation',
  linkedWaypoint: 'Linked stops',
  unlinkTransportationPrompt:
    'Your stop is linked to a transportation. Do you want to unlink it?',
  unlink: 'Unlink',
  departure: 'Departure',
  plusDepartureStation: '+ Station (Departure)',
  plusArrivalStation: '+ Station (Arrival)',
  plusTime: '+ Time',
  transportationCarDestinationTooltip:
    'Pro-tip: Change the destination stop to use the transport for further routes as well!',
  sameLocationDescription:
    'Then we add the stop »{waypoint}« as destination for you.',
  sameLocationTitle: 'Origin = Destination?',
  plusDeparturePort: '+ Port (Departure)',
  plusArrivalPort: '+ Port (Arrival)',
  plusPreciseLocation: '+ Precise location',
  port: 'Port',
  searchFlight: 'Search flight',
  flightNumber: 'Flight no.',
  flightNumberPlaceholder: 'e.g. UA1234',
  FlightBooking: 'Flight',
  TrainBooking: 'Train connection',
  RentalCarBooking: 'Rental car',
  CarBooking: 'Car',
  OwnCar: 'Own car',
  FerryBooking: 'Ferry',
  BusBooking: 'Bus connection',
  BicycleBooking: 'Bicycle',
  WalkingBooking: 'Walking',
  GenericBooking: 'Other',
  subTypePlaceholder: 'e.g. RV, motorcycle, ...',
  taxi: 'Taxi',
  escooter: 'E-Scooter',
  rv: 'RV',
  motorcycle: 'Motorcycle',
  helicopter: 'Helicopter',
  cruise: 'Cruise',
  airportSearch: 'Search airport',
  airportActionPrompt: 'What do you want to do?',
  showOnGoogleMaps: 'Show on google maps',
  selectLocation: 'Select location',
  selectAirport: 'Select airport',
  uploadNewDocument: 'Upload new document',
  selectDocument: 'Select document',
  viewDocument: 'View Document',
  readOnWebsite: 'Read more on <a>{website}</a>',
  textfieldRules: {
    required: 'Required.',
    counter: 'Max 50 characters.',
    pin: 'PIN must be 6 characters long.',
    amount: 'Ivalid amount.',
    email: 'Invalid email.',
    link: 'Invalid link.',
    flightNumber: 'Invalid flight no.',
    timeString: 'Invalid time format',
    password: 'Password must be at least 6 characters long.',
    noWhiteSpace: 'Must not contain any spaces.',
    checkbox: 'The field must be accepted.',
  },
  maxFileSize: 'Maximum file size: {size}',
  tripOfTheDay: 'Trip of the day',
  tripOfTheWeek: 'Trip of the week',
  tripOfTheMonth: 'Trip of the month',
  media: 'Media',
  searchResult: 'Search results',
  noBookmarks: 'No Bookmarks',
  discoverTrips: 'Discover Trips',
  date: 'Date',
  lastUpdated: 'Last updated',
  sortTripsBy: 'Sort trips by …',
  adBlocker: {
    hey: 'Hey!',
    notice: `It looks like you are using an ad blocker. If you'd like to see {contentType} rather than this alert, please deactivate your ad blocker.`,
  },
  pro: {
    unlock: {
      title: '🔓 Unlock this feature',
      description:
        'This feature available in Lambus PRO! Do you want to learn more?',
    },
    checkout: {
      title: 'Upgrade to Lambus PRO',
      description:
        'Unlock additional premium features to make your travel planning even easier! 🎉',
      payPerTrip: {
        title: 'Pay per Trip',
        caption: 'Upgrade one trip',
      },
      yearlySubscription: {
        title: 'Yearly Subscription',
        caption: 'Upgrade all your trips',
        freeTrial: 'TEST 7 DAYS FOR FREE!',
      },
      success: {
        title: 'Lambus PRO: Purchase successful!',
      },
      learnMore: 'Learn more about Lambus PRO',
      upgradeNowUpper: 'UPGRADE NOW 🚀',
      thankYouNotice:
        'Thank you for purchasing Lambus PRO! ❤️ This page will close itself automatically in {time} seconds.',
    },
    subscription: {
      validUntil: 'valid until',
      purchasedBy: 'purchased by',
      exploreProFeatures: 'Explore PRO features',
      exploreLambusPro: 'Explore Lambus PRO',
      manageSubscription: 'Manage subscription',
      mySubscriptions: 'My subscriptions',
      noSubscriptions: 'No subscriptions',
      noActiveSubscriptions: 'You have no active subscriptions',
      areYouTravelingAlot: 'Are you traveling a lot?',
      upgradeToYearlySubscription: 'Then upgrade to a yearly subscription!',
      cancelSubscription: 'Cancel subscription',
      nowWith: 'Now with',
      forever: 'Forever',
      yearlySubscription: 'Yearly Subscription',
      monthlySubscription: 'Monthly Subscription',
      lifetimeSubscription: 'Lifetime Subscription',
    },
    features: {
      routePlanning: {
        title: 'Extended Route Planning',
        description:
          'Calculate the exact duration and distance between all your stops',
      },
      googleMaps: {
        title: 'Google Maps Search',
        description: 'We use Google Maps to find even more stops',
      },
      videoChat: {
        title: 'Video Chat',
        description: 'Chat with other attendees while planning your journey',
      },
      moneyPool: {
        title: 'Money Pool',
        description: 'Manage the money of the trip in one central place',
      },
      exportTripGpx: {
        title: 'Export Trip as GPX',
        description:
          'Export your trip as GPX and use it in other tracking apps',
      },
      currencies: {
        title: 'Automatic Currencies',
        description:
          'Convert foreign currencies to your currency using the current exchange rates',
      },
      weather: {
        title: 'Real Time Weather Updates',
        description:
          'Display the current weather conditions for all your stops',
      },
      exportExpenses: {
        title: 'Expenses Export',
        description: 'Automatically export your expenses to Excel',
      },
      flightStatus: {
        title: 'Real Time Flight Updates',
        description:
          'Get notified if your flight is delayed, cancelled or rescheduled',
      },
      exportTrip: {
        title: 'Trip as PDF Export',
        description:
          'Export your trip as a PDF to have it at hand even without smartphone',
      },
      support: {
        title: 'Support us ❤️',
        description:
          'With your help, Lambus can stay ad-free and without selling data',
      },
      packingList: {
        title: 'Lists (packing lists, to-do lists)',
        description: 'Choose from existing lists or create your own',
      },
      flightCompensation: {
        title: 'Flight Compensation',
        description:
          'Claim compensation automatically if your flight is cancelled',
      },
      privateDocuments: {
        title: 'Private Documents',
        description:
          "Securely store passport, driver's license and more on your device",
      },
      offlineMode: {
        title: 'Offline Mode',
        description:
          'Access your entire trip even without an internet connection',
      },
      calendarSync: {
        title: 'Calendar Sync',
        description: 'Sync your stops and POIs with your calendar',
      },
    },
    cancellation: {
      orderedWith: 'Purchased on {store}',
      cancelWith:
        'Since you purchased Lambus PRO on the {store}, your cancellation is also handled by the {store}',
      reasons: {
        label: {
          pro_cancelation_reason_1: `I don't use the features`,
          pro_cancelation_reason_2: 'I am missing a feature',
          pro_cancelation_reason_3: 'I found a better app',
          pro_cancelation_reason_4: 'The price was too expensive',
          pro_cancelation_reason_5: 'I am testing for a later trip',
        },
        placeholder: {
          pro_cancelation_reason_2: 'What features are you missing?',
        },
      },
      lossHint: {
        youCurrentlyHave: 'You currently have',
        thatWillBeLost:
          'that will be lost by cancelling your yearly Lambus PRO subcription.',
        confirmation: 'I want to cancel my subscription, still.',
        tellUsWhy: `Please let us know what you don't like and we work on it!`,
      },
      talkToUs: 'Want to talk to us? We are happy to help!',
      chatWithUs: 'Chat with us',
      sendUsAnEmail: 'Send us an email',
    },
  },
  routes: {
    myRoutes: 'My routes',
    planRoute: 'Plan directions',
    calculate: 'Calculate route',
    save: 'Save route',
    delete: 'Delete route',
    recommendedRoute: 'Recommended route',
    type: {
      driving: 'Car',
      transit: 'Train',
      bicycling: 'Bicycle',
      flight: 'Flight',
      walking: 'Walking',
    },
  },
  realtimeFlightStatus: {
    isEligibleForCompensation: 'Eligible for refunds if canceled/delayed',
    activateFlightAlert: 'Get Flight Updates',
    flightStatus: 'Flight Status',
    status: {
      active: 'In Air',
      canceled: 'Canceled',
      diverted: 'Diverted',
      landed: 'Landed',
      not_operational: 'Not active',
      redirected: 'Redirected',
      scheduled: 'Scheduled',
      now_tracking: 'Now tracking',
      unknown: 'Unknown',
    },
    compensation: {
      title: 'Claim Flight Compensation',
      subtitle: 'Receive up to 600 € if your flight is delayed or cancelled!',
      howItWorks: 'How it works',
      instructions: {
        step1: '1. Track your flight with Lambus PRO',
        step2:
          '2. We detect when the flight is delayed or cancelled and reach our to you in case your flight is eligible for a compensation!',
      },
    },
  },
  selectDate: 'Select date',
  distance: 'Distance',
  duration: 'Duration',
  later: 'Later',
  exportExpensesPrompt:
    'We can combine the travel expenses of your trip into an Excel file. Do you want to start the export now?',
  exportInfo:
    'You will receive an email with the document shortly. Important: Check your spam mailbox too!',
  exportStarted: 'The export has started',
  exportNow: 'Export now',
  exportAsExcel: 'Export as Excel file',
  howItWorks: 'How it works',
  exportExpenses: 'Excel export of travel expenses',
  howItWorksInfo:
    'With Lambus, all travel expenses are automatically calculated between fellow travellers, which means that if you owe something to one person and that person owes something to another, two people do not have to transfer anything, only one',
  howItWorksPrompt: 'You have more questions or think we made a mistake?',
  contactUs: 'Contact us',
  export: 'Export',
  exportTrip: 'Export trip as PDF',
  exportTripPrompt: 'Do you want to export your trip as a PDF?',
  exportAsGPX: 'Export as GPX file',
  exportTripAsGPXPrompt: 'Do you want to export your trip as a GPX file?',
  exportAsPDF: 'Export as PDF',
  gpxZoomPrompt: 'Should we always zoom to your GPX route first?',
  showGpxRoutes: 'Show GPX route',
  expenseExport: 'Export travel expenses',
  packingLists: {
    useThisList: 'Use this list',
    title: 'List',
    create: 'Create list',
    add: 'Add List',
    delete: 'Delete list',
    deleteSection: 'Delete category',
    deleteSectionPrompt: 'Do you want to delete this category?',
    myPackingLists: 'My lists',
    templatesFromLambus: 'Templates from Lambus',
    createNewList: 'Create new list',
    myTemplates: 'My templates',
    createNewTemplate: 'Create new template',
    templateName: 'Template name',
    orCreateNew: 'Or create new',
  },
  createYourOwn: 'Create own',
  addCategory: 'Add category',
  new: 'new',
  soonUpper: 'SOON',
  gpsBasedCurrencies: {
    title: 'Find currencies automatically',
    info: 'Do you want to detect the currency automatically? Allow us to use your current location for a short time in the browser settings',
  },
  packingListItemCount:
    'No items | {checkedCount}/{itemCount} items | {checkedCount}/{itemCount} items',
  items: 'No items | One item | {count} items',
  lists: 'Lists',
  rename: 'Rename',
  visibility: 'Visibility',
  gpx: {
    files: 'GPX files',
    description:
      'Your trip has a unique email address that you forward your GPX files to. Once you import your .gpx file via the above email address, it will be drawn on your stop map.',
    visibility: {
      always: 'Yes',
      never: 'No',
      askLater: 'Decide later',
    },
  },
  savedPlaces: 'Saved Places & Activities',
  recommendedPlaces: 'Recommended Places',
  pleaseEnterThePDFPassword: 'Please enter the password of the PDF file:',
  cannotOpenPDF: 'PDF file could not be opened. Please contact our support!',
  routeTo: 'Route to',
  dailyPlan: 'Daily Plan',
  daily_plan: 'Daily Plan',
  openRoute: 'Open connection',
  manageTransportations: 'Manage your flights, trains, buses, etc.',
  showAll: 'Show all',
  onboarding: {
    youHaveBeenInvited: `You've been invited?`,
    findInspirations: 'Find Inspirations',
    howDoYouWantToStart: 'Please select how you want to start!',
    allInOneSingleApp:
      'Plan, book and manage your whole trip in one single app!',
    alreadyKnowWhereToGo: 'Already know where you want to go?',
    notSureWhereToGoYet: 'Still unsure where to go?',
    pleaseEnterThePinCodeBelow: 'Please enter the PIN code below!',
    youDontHaveAnyCode: `You don't have any PIN code?`,
    emailInviteNote:
      'You can also be invited by email. Do you want to create an account with your email address now?',
    almostThereExclamation: 'Almost there!',
    registerToJoinTripText: `To join the trip, we kindly ask you to create a Lambus account. Don't worry, we don't sell any data and we won't bug you 🙂`,
    registerToCreateTripText: `To save your trip, we kindly ask you to create a Lambus account. Don't worry, we don't sell any data and we won't bug you 🙂`,
    keywords: 'Keywords',
    category: 'Categories',
    hashtag: 'Hashtags',
    letsGo: `Let's go!`,
    youAlreadyHaveAnAccount: 'You already have an account?',
    searchHint: {
      keywords: 'Search for Inspirations …',
      category: 'Search for Categories …',
      hashtag: 'Search for Hashtags …',
    },
    selectedInspiration: 'Selected inspiration',
    newTrip: 'Your new trip',
  },
  enterNewName: 'Please enter the new name of your stop',
  unnamedLocation: 'Unnamed location',
  dateFormat: 'Date format',
  systemFormat: 'System',
  callVerb: 'Call',
  visitWebsite: 'Visit website',
  savePlace: 'Save place',
  tapToAddSomething: 'Tap to add something',
  nothingPlanedYet: 'Nothing planned yet',
  noAccommodationAddedYet: 'No accommodation added',
  tapToAddAnAccommodation: 'Tap to add a new accommodation',
  noDateSet: 'No date set',
  planYourDaysPerWaypoint: 'Plan your days per stop',
  specifyDate: 'Specify date',
  linkToWaypoint: 'Link with Stop',
  unlinkFromWaypoint: 'Unlink',
  searchStopsPlaceholder: 'Search stops...',
  findRentalCars: 'Find Rental Cars',
  egStartFrom: 'From {waypoint}',
  mobility: 'Mobility',
  directions: 'Directions',
  saveTransportationBeforeApplyingRoute:
    'Please save your transportation to calculate routes, thank you!',
  colors: 'Colors',
  moreInfo: 'More info',
  optional: 'optional',
  travelers: 'Travelers',
  pleaseEnterEitherExistingOrInvite:
    'Please enter the username or email address of the person you would like to invite.',
  ifNoAccountReminderSent:
    'If the attendee does not have an account, an invitation will be sent.',
  statistics: 'Statistic',
  expensesCurrencyNotEditable:
    'The currency of your trip cannot be changed if travel expenses have already been created.',
  expensesBillingIntroTitle: 'About billing in Lambus',
  expensesBillingIntroSubtitle:
    "We minimize the number of open accounts by offsetting them automatically. Example: You owe {person1} $10 and {person2} owes {person3} $10, so you pay {person4} $10 and you're even! 🙂",
  shareTrip: 'Share trip',
  linkToYourMap: 'Link to your map',
  shareTripInfo:
    'You can share your trip with others who are not travelling with you. If you want to invite other travellers to join your trip, tap',
  activateIFrameInfo:
    'Activate to embed the map of your journey in your blog or personal personal website (e.g. Wordpress, Notion or Medium).',
  embedAsIFrame: 'Embed via <iframe>',
  here: 'here',
  accommodation: 'Accommodation',
  addAccommodation: 'Add Accommodation',
  searchAddress: 'Search Address (Hotel, Airbnb, …)',
  addCheckInCheckOut: 'Add Check In / Check Out',
  plusBookingDocument: '+ Booking Document',
  myAccommodation: 'My Accommodation',
  deleteAccommodation: 'Delete accommodation',
  deleteAccommodationDescription:
    'Are you sure you want to delete this accommodation?',
  changeAddress: 'Change address',
  copyAddress: 'Copy address',
  copy: 'Copy',
  openWaypoint: 'Open stop',
  theAddressWasCopiedSuccessfully: 'The address was copied to your clipboard!',
  notLinkedYet: 'Not linked, yet',
  linkedWaypoints: 'Linked stops',
  addTime: 'Add time',
  addedToDate: 'Added to {date}',
  starts: 'Starts',
  ends: 'Ends',
  linkedDays: 'Linked Day | Linked Days',
  sortBy: 'Sort by …',
  displayAs: 'Display as {mode}',
  grid: 'Grid',
  list: 'List',
  name: 'Name',
  fileType: 'Type',
  confirmNoteDiscard:
    'You have unsaved changes in your note. Do you really want to discard it?',
  manageFeatures: 'Manage features',
  whichDataShouldBeExported: 'Which data do you want to export? 😇',
  selectAll: 'Select all',
  deselectAll: 'Deselect all',
  fileSection: {
    document: {
      new: 'Upload new document',
      link: 'Select document',
      emptyState: {
        title: 'No documents added',
        subtitle: 'Tap to add your travel documents',
      },
    },
    photo: {
      new: 'Upload new photo',
      link: 'Select photo',
      emptyState: {
        title: 'No photos added',
        subtitle: 'Tap to save your memories',
      },
    },
  },
  noteSection: {
    emptyState: {
      title: 'No notes added',
      subtitle: 'Tap to collect thoughts, links, tips, …',
    },
  },
  addADate: 'Add a date',
  addDate: '+ Add date',
  addANote: 'Add a note',
  addNotePlaceholder: 'Add your note...',
  address: 'Address',
  plannedFor: 'Planned for',
  collectThoughtsLinksTips: 'Collect thoughts, links, tips …',
  selectPosition: 'Select position',
  insertHere: 'Insert here',
  appendToEnd: 'Append to end',
  appendToStart: 'Append to start',
  appendAfter: 'Append after "{waypoint}"',
  aSmallNote: 'A small note',
  shiftDatesAsk:
    'You have changed the date of your trip. Do you want your itinerary to be automatically shifted by {days} day(s) as well?',
  shiftDates: 'Move stops',
  shiftDatesDiscard: "Don't move stops",
  addActivity: 'Add activity',
  deleteActivity: 'Delete activity',
  deleteActivityDescription: 'Are you sure you want to delete this activity?',
  addMoreInformationPlaceholder: 'Add your further information...',
  unlinkWaypointDayItem: 'Delete item from daily plan',
  unlinkWaypointDayItemDescription:
    'Are you sure you want to delete the item from the daily plan?',
  noAddress: 'No address',
  noResultsFound: 'No results found',
  couponCodeMessage:
    'Ring in the year 2024 with adventures! Get 25% off Lambus Pro with:',
  searchForCurrencies: 'Search currencies ...',
  sortExpensesBy: 'Sort expenses by …',
  expenseCreated: 'Expense created',
  expenseDate: 'Expense date',
  canoe_tour: 'Canoe tour',
  hiking: 'Hiking',
  camping: 'Camping',
  beach: 'Beach',
  swimming: 'Swimming',
  boat_tour: 'Boat tour',
  campfire: 'Campfire',
  party: 'Party',
  bike_tour: 'Bike tour',
  surfing: 'Surfing',
  snorkeling: 'Snorkeling',
  picknick: 'Picknick',
  food: 'Food',
  horse_riding: 'Horse riding',
  sport_event: 'Sport event',
  concert: 'Concert',
  cinema: 'Cinema',
  winter_sports: 'Winter sports',
  searchActivitiesAndPlaces: 'Search your saved places and activities',
  signUp: {
    cta: 'Not on Lambus yet?',
    action: 'Sign up',
    businessCta: 'Are you a business?',
    title: 'Welcome to Lambus!',
    titleActionNewTrip: 'Sign up to save your trip!',
    titleActionJoinTrip: 'Sign up to join the trip!',
    titleActionInspiration: 'Sign up  to save the inspiration!',
  },
  signIn: {
    cta: 'Already have an account?',
    action: 'Sign in',
    title: 'Welcome to Lambus!',
    titleActionNewTrip: 'Sign in to save your trip!',
    titleActionJoinTrip: 'Sign in to join the trip!',
    titleActionInspiration: 'Sign in  to save the inspiration!',
  },
  signUpTitle: 'Create account',
  orContinueWith: 'Or continue with',
  autosavingNote: 'Autosaving note ...',
  lastSaved: 'Last saved',
  justNow: 'Just now',
  deletePackingList: 'Delete packing list',
  deletePackingListDescription:
    'Are you sure you want to delete this packing list?',
  sidebar: {
    close: 'Close sidebar',
    collapse: 'Collapse sidebar',
    expand: 'Expand sidebar',
    tripSection: {
      planning: 'Planning',
      general: 'General',
      travelers: 'Travelers',
    },
  },
  startDate: 'Start date',
  notSaved: 'Not saved',
  reset: 'Reset',
  updates: 'Updates',
  markAllAsRead: 'Mark all as read',
  markAsRead: 'Mark as read',
  hide: 'Hide',
  noNewNotifications: 'No new notifications',
  youAreUpToDate: 'You are up to date',
}
