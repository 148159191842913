
import { Vue } from 'vue-property-decorator'
import { formattedDateRange } from '@/utils/date-utils'
import { DatePickerType } from '@/utils/enums'

class DatePickerModal extends Vue {
  pickerType = ''
  pickerDates: string[] = []
  pickerMonths: string[] = []
  formattedDates = ''

  get type(): string {
    return this.pickerType
  }

  get dates(): string[] {
    return this.pickerDates
  }

  get months(): string[] {
    return this.pickerMonths
  }

  set type(type: string) {
    this.pickerType = type
  }

  set dates(dates: string[]) {
    this.pickerDates = dates
  }

  set months(months: string[]) {
    this.pickerMonths = months
  }

  // Formats a date string depending on calender mode and singular or multiple dates
  get formatDateRange(): string {
    const dates =
      this.pickerType === DatePickerType.SPECIFIC
        ? this.pickerDates
        : this.pickerMonths
    return formattedDateRange(dates, this.pickerType)
  }

  set formatDateRange(value: string) {
    this.formattedDates = value
  }
}
export default DatePickerModal
