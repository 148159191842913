import { set } from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Cookies from 'js-cookie'
import axios from 'axios'
import { useQueryClient } from '@tanstack/vue-query'
import { i18n } from '@/plugins/i18n'

import { User } from '@/types/user'
import { eventModule, tripModule } from '@/store'
import { isBrowser } from '@/utils/utility-manager'
import Intercom from '@/utils/intercom-manager'

@Module({ name: 'auth', stateFactory: true, namespaced: true })
export default class authModule extends VuexModule {
  private token = ''
  private refreshToken = ''
  private currentUser: Partial<User> = {}
  private tokenIsRefreshing = false
  private loginError = ''
  private registerError = ''

  public get user() {
    return this.currentUser
  }

  public get loggedIn(): boolean {
    return this.token.trim().length > 0
  }

  public get _refreshToken(): string {
    return this.refreshToken
  }

  public get isRefreshing(): boolean {
    return this.tokenIsRefreshing
  }

  public get bearerToken(): string | null {
    if (!this.token) {
      return null
    }

    return `Bearer ${this.token}`
  }

  public get authErrorMessage(): string {
    return this.loginError
  }

  public get registrationErrorMessage(): string {
    return this.registerError
  }

  @Mutation
  private setCurrentUser(user: Partial<User>) {
    set(this, 'currentUser', user)
  }

  @Mutation
  private setAccessTokens({ accessToken, refreshToken }: any) {
    this.token = accessToken
    this.refreshToken = refreshToken
  }

  @Mutation
  private clearTokens() {
    this.token = ''
    this.refreshToken = ''
  }

  @Mutation
  private reset() {
    this.token = ''
    this.refreshToken = ''
    this.currentUser = {}
  }

  @Mutation
  private setTokenIsRefreshing(state: boolean) {
    this.tokenIsRefreshing = state
  }

  @Mutation
  private setAuthErrorMessage(message: string) {
    this.loginError = message
  }

  @Mutation
  private setRegistrationErrorMessage(message: string) {
    this.registerError = message
  }

  @Action({ rawError: true })
  public async login({ username, password, organizationId }: any) {
    try {
      this.context.commit('reset')
      const { data } = await this.$axios.post('/api/authenticate', {
        username,
        password,
        organizationId,
      })

      this.context.commit('setAccessTokens', {
        accessToken: data.token,
        refreshToken: data.refreshToken,
      })

      this.context.commit('setCurrentUser', data.user)
      this.$axios.defaults.headers.Authorization = this.bearerToken

      const redirectTo = Cookies.get('redirectTo') || '/'
      Cookies.remove('redirectTo')
      this.store.$router.replace(redirectTo)
    } catch (error) {
      this.handleLoginError(error)
      throw error
    }
  }

  @Action({ rawError: true })
  private handleLoginError(error: unknown) {
    const errorCode = axios.isAxiosError(error)
      ? error.response?.data.code ?? -1
      : -1

    const socialType = axios.isAxiosError(error)
      ? error.response?.data.type ?? ''
      : ''

    if (errorCode === 13020104) {
      this.context.commit(
        'setAuthErrorMessage',
        `${i18n.t('loginFailed.alreadyRegisteredWithEmail')}`,
      )
    } else if (errorCode === 13020105) {
      this.context.commit(
        'setAuthErrorMessage',
        `${i18n.t('loginFailed.isLinkedToSocialAccount')}`,
      )
    } else if (errorCode === 13020102) {
      this.context.commit(
        'setAuthErrorMessage',
        `${i18n.t('loginFailed.appleSignInError', { social: socialType })}`,
      )
      Intercom.show()
    } else {
      this.context.commit(
        'setAuthErrorMessage',
        `${i18n.t('loginFailed.wrongInput')}`,
      )
    }
  }

  @Action({ rawError: true })
  public async logout(): Promise<void> {
    this.context.commit('reset')
    await this.store.$router.replace({ name: 'sign-in' })

    tripModule.resetTrip()
    useQueryClient().clear()

    if (isBrowser()) {
      localStorage.clear()
    }
  }

  @Action({ rawError: true })
  public setTokens({ accessToken, refreshToken }: any) {
    this.context.commit('setAccessTokens', { accessToken, refreshToken })
  }

  @Action({ rawError: true })
  public async refresh() {
    this.context.commit('setTokenIsRefreshing', true)
    // request new accessTokens with the refreshToken
    this.$axios.defaults.headers.Authorization = `Bearer ${this.refreshToken}`
    const { data } = await this.$axios.post('/api/authenticate/refresh', {
      refreshToken: this.refreshToken,
    })

    this.$axios.defaults.headers.Authorization = `Bearer ${data.token}`
    await this.context.dispatch('setTokens', {
      accessToken: data.token,
      refreshToken: data.refreshToken,
    })

    this.context.commit('setTokenIsRefreshing', false)
  }

  @Action({ rawError: true })
  public async fetchUser() {
    try {
      const { data } = await this.$axios.get('/api/users/profile')
      await this.context.dispatch('setUser', data)
    } catch (error) {
      eventModule.newError('error.user.fetch')
    }
  }

  @Action({ rawError: true })
  public setUser(user: Partial<User>) {
    this.context.commit('setCurrentUser', user)
  }

  @Action({ rawError: true })
  public async resetPassword(email: string) {
    const { data } = await this.$axios.post(
      '/api/users/resetPassword',
      {
        email,
      },
      {
        validateStatus: () => true,
      },
    )

    if (data.success) {
      eventModule.newMessage('success.user.password_reset')
    } else if (data.isSocial) {
      eventModule.newInfo('error.user.password_reset_social')
    } else {
      eventModule.newError(['error.user.password_reset', 'error.email_retry'])
    }
  }

  @Action({ rawError: true })
  public async registerUser(form: any) {
    try {
      const { data } = await this.$axios.post('/api/users', form)
      return data
    } catch (error) {
      this.handleRegistrationError(error)
      throw error
    }
  }

  @Action({ rawError: true })
  private handleRegistrationError(error: unknown) {
    const emailAlreadyExistsCodes = [12020204, 12020205, 12040106]
    const errorCode = axios.isAxiosError(error)
      ? error.response?.data.code ?? -1
      : -1

    if (emailAlreadyExistsCodes.includes(errorCode)) {
      this.context.commit(
        'setRegistrationErrorMessage',
        `${i18n.t('error.user.emailAlreadyExist')}`,
      )
      eventModule.newError('error.user.emailAlreadyExists')
    } else {
      this.context.commit(
        'setRegistrationErrorMessage',
        `${i18n.t('error.generic')}`,
      )
      eventModule.newError('error.user.create')
    }
  }

  @Action({ rawError: true })
  public async updateProfile(user: User) {
    try {
      const { data } = await this.$axios.put('/api/users/profile', user)
      this.context.dispatch('setUser', data.profile)
      eventModule.newMessage('success.user.update')
    } catch (error) {
      this.handleUpdateProfileError(error)
    }
  }

  @Action({ rawError: true })
  private handleUpdateProfileError(error: unknown) {
    const errorCode = axios.isAxiosError(error)
      ? error.response?.data.code ?? -1
      : -1

    if (errorCode === 12040106) {
      eventModule.newError('error.user.update.emailNotAvailable')
    } else if (errorCode === 12040108) {
      eventModule.newError('error.user.update.usernameNotAvailable')
    } else {
      eventModule.newError('error.user.update.default')
    }
  }

  @Action({ rawError: true })
  public async updatePassword(params: {
    oldPassword: string
    newPassword: string
  }) {
    try {
      const tokens = await this.$axios.put('/api/users/changePassword', {
        oldPassword: params.oldPassword,
        newPassword: params.newPassword,
      })

      if (tokens.data.success) {
        this.context.dispatch('setTokens', {
          token: tokens.data.token,
          refreshToken: tokens.data.refreshToken,
        })

        this.context.dispatch('setUser', this.user)
      }
      eventModule.newMessage('success.user.password_change')
    } catch (error) {
      this.handleUpdatePasswordError(error)
    }
  }

  @Action({ rawError: true })
  private handleUpdatePasswordError(error: unknown) {
    const errorCode = axios.isAxiosError(error)
      ? error.response?.data.code ?? -1
      : -1

    if (errorCode === 12010504) {
      eventModule.newError('error.user.password_change.currentPasswordWrong')
    } else {
      eventModule.newError('error.user.password_change.default')
    }
  }
}
