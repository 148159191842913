import { Plugin } from '@nuxt/types'
import Pusher from 'pusher-js'

declare module '@nuxt/types' {
  interface Context {
    $pusher: Pusher
  }

  interface NuxtAppOptions {
    $pusher: Pusher
  }
}

const pusherPlugin: Plugin = ({ store, $config }, inject) => {
  const pusher = new Pusher($config.pusherApiKey!, {
    cluster: 'eu',
    authEndpoint: `${$config.baseUrl}/v1.10/socket/auth`,
    auth: {
      headers: {
        Authorization: store.getters['auth/bearerToken'],
        'Lambus-Secret': $config.lambusSecret,
      },
    },
  })
  inject('pusher', pusher)
}

export default pusherPlugin
