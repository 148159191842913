
import { Component, Vue } from 'vue-property-decorator'
import { QueryClient, useQueryClient } from '@tanstack/vue-query'

import LDialog from '@/global-components/LDialog.vue'
import LocationSearch from '@/components/home/newTrip/LocationSearch.vue'
import OptionalTripForm from '@/components/home/newTrip/OptionalTripForm.vue'
import { tripModule, authModule, expenseModule } from '@/store'
import { useOnboardingStore } from '@/stores/onboarding'
import { User } from '@/types/user'
import { WaypointDataSource } from '@/utils/enums'
import { dateRange } from '@/utils/date-utils'
import { tripQueryKeys } from '@/composables/trip'

@Component({
  name: 'NewTripModal',
  components: {
    LocationSearch,
    OptionalTripForm,
  },
  setup() {
    const queryClient = useQueryClient()
    const onboardingStore = useOnboardingStore()
    return { onboardingStore, queryClient }
  },
})
class NewTripModal extends Vue {
  loading = false
  isSearching = false
  isSaving = false
  hasLocation = false
  hasName = false
  timestamp = new Date().getTime()
  onboardingStore!: ReturnType<typeof useOnboardingStore>
  queryClient!: QueryClient

  trip = {
    name: '',
    attendees: authModule.loggedIn ? [this.user.id] : [],
    pendingAttendees: [] as string[],
    currency: 'EUR',
    date: {} as any,
  }

  get user(): Partial<User> {
    return authModule.user
  }

  public toggleDialog(): void {
    this.loading = false
    const dialog = this.$refs.newTripModal as LDialog
    dialog.toggleDialog()
  }

  setTimestamp(): void {
    this.timestamp = new Date().getTime()
  }

  localCurrency(): string {
    const baseCurrency = expenseModule.defaultCurrency.code
    const country = this.user.country?.code

    if (!country) {
      return baseCurrency
    }

    try {
      return (
        require('~/assets/json/country_currency_map.json')[country] ||
        baseCurrency
      )
    } catch (_) {
      return baseCurrency
    }
  }

  // Check for start- and enddate and create a new trip
  async createTrip() {
    const optionalTripForm = this.$refs.newTripOptionalForm as OptionalTripForm
    const locationOriginSearch = this.$refs
      .newTripOriginLocationSearch as LocationSearch
    const locationDestinationSearch = this.$refs
      .newTripDestinationLocationSearch as LocationSearch

    const { values, type } = optionalTripForm.getDates()
    const { invitees } = optionalTripForm.getInvitees()
    const selectedOrigin = locationOriginSearch.getSelection()
    const selectedDestination = locationDestinationSearch.getSelection()

    this.trip.date = dateRange({
      dates: values,
      type,
    })

    this.trip.name = selectedDestination.name
    this.trip.pendingAttendees = invitees.map((invitee) => invitee.id!)
    this.trip.currency = this.localCurrency()

    const originLocation = this.convertSelectionToLocation(selectedOrigin)

    const destinationLocation =
      this.convertSelectionToLocation(selectedDestination)

    if (!authModule.loggedIn) {
      this.onboardingStore.setPendingTrip({
        ...this.trip,
        origin: originLocation,
        destination: destinationLocation,
      })
      this.$router.push({
        path:
          this.onboardingStore.previousAuthenticatingRoutePath ?? '/sign-in',
        query: { newTrip: '1' },
      })
      this.toggleDialog()
      return
    }

    this.loading = true
    await tripModule.create({
      trip: this.trip,
      location: destinationLocation,
      origin: originLocation,
    })
    this.queryClient.invalidateQueries({ queryKey: tripQueryKeys.lists() })

    this.toggleDialog()
  }

  convertSelectionToLocation(
    selection: ReturnType<InstanceType<typeof LocationSearch>['getSelection']>,
  ) {
    let location: { place: string; dataSource: string | number } | undefined = {
      place: '',
      dataSource: '',
    }

    if (selection.inspiration) {
      location.place = selection.inspiration.id
      location.dataSource = 'LAMBUS'
    } else if (selection.place) {
      const matchedSource = Object.entries(WaypointDataSource).find(
        (entry) => entry[0] === selection.place!.dataSource,
      )

      if (matchedSource) {
        location.place = selection.place.id
        location.dataSource = matchedSource[1]
      }
    } else {
      location = undefined
    }

    return location
  }
}
export default NewTripModal
