
import { Component, Prop, Vue } from 'vue-property-decorator'

import { discoverModule } from '@/store'
import { Inspiration } from '@/types/discover'
import { DiscoverSearchQueryType } from '@/utils/enums'
@Component({
  name: 'InspirationDescription',
})
class InspirationDescription extends Vue {
  /**
   * Class string or object to be applied to the inspiration description text
   */
  @Prop({ default: '' }) readonly contentClass!: string | object

  /**
   * Disables router navigation to the clicked inspiration
   * Will still set the current store inspiration to the clicked inspiration when set to true
   */
  @Prop({ default: false, type: Boolean }) readonly disableNavigation!: boolean

  showHashtagMenu = false
  hashtagPosition: { x: number; y: number } = {
    x: 0,
    y: 0,
  }

  lastHashtag: string | null = null

  get inspirations(): Inspiration[] {
    return discoverModule.inspirationsBySearch.filter(
      (inspiration) => inspiration.id !== this.currentInspiration?.id,
    )
  }

  set inspirations(inspirations: Inspiration[]) {
    discoverModule.setSearchedInspirations(inspirations)
  }

  get currentInspiration(): Inspiration | null {
    return discoverModule.currentInspiration
  }

  get description() {
    const description = this.currentInspiration?.description ?? ''
    return description.replace(
      /(#[a-zA-ZÀ-ž\u0370-\u03FF\u0400-\u04FF]*)/g,
      `<a>$1</a>`,
    )
  }

  async handleHashtagClick(event: MouseEvent) {
    const target = event.target as any
    if (target?.innerHTML?.startsWith('#')) {
      const value = target.innerHTML.slice(1, target.innerHTML.length)

      if (this.lastHashtag !== value) {
        await discoverModule.fetchInspirationsBySearchTerm({
          searchType: DiscoverSearchQueryType.HASHTAG,
          value,
        })
      }

      this.lastHashtag = value

      this.hashtagPosition = {
        x: event.x,
        y: event.y,
      }

      this.showHashtagMenu = true
    } else if (this.showHashtagMenu === true) {
      this.showHashtagMenu = false
    }
  }

  openInspiration(inspiration: Inspiration): void {
    this.$emit('click:inspiration', inspiration)

    if (this.disableNavigation) {
      discoverModule.fetchInspiration(inspiration.id)
      return
    }

    this.$router.push({
      name: 'discover-inspiration-inspiration',
      params: {
        inspiration: inspiration.id,
        name: inspiration.name,
      },
    })
  }
}
export default InspirationDescription
