import {
  LocationDataSource,
  LocationDataSourceSlug,
  WaypointDataSource,
} from './enums'
import { getFullAddress } from './utility-manager'
import { Location, LocationAutocompleteResult } from '@/types/trip'

/**
 * Converts a persisted location back to an autocomplete result. Use this to
 * pass a location from the DB as the default value to the LocationSearch
 * component.
 *
 * @param location A persisted location from our DB.
 * @returns The same location converted to an autocomplete result object.
 */
export function locationToAutocompleteResult(
  location: Location,
): LocationAutocompleteResult {
  return {
    id: location.placeId,
    dataSource: (typeof location.dataSource === 'number'
      ? dataSourceNumberToString(location.dataSource)
      : location.dataSource) as keyof typeof WaypointDataSource,
    title: location.name,
    subtitle: getFullAddress({ location }, { excludeName: true }),
    titleHighlights: [],
    subtitleHighlights: [],
    location: {
      dataSource: location.dataSource.toString(),
      label: location.category,
      placeId: location.placeId,
    },
  }
}

function dataSourceNumberToString(input: number) {
  const entry = Object.entries(WaypointDataSource).find(
    ([_key, value]) => value === input,
  )
  if (!entry) {
    throw new Error(`Invalid data source ${input}`)
  }
  return entry[0]
}

export function formatPlaceLocation(place: {
  location: Location
  name: string
  dataSource: LocationDataSource
  externalId: string
}) {
  const {
    latitude,
    longitude,
    street,
    postalCode,
    city,
    state,
    country,
    countryCode,
    icon,
  } = place.location

  const location: Location = {
    name: place.name,
    dataSource: place.dataSource,
    placeId: place.externalId,
    latitude,
    longitude,
    postalCode,
    city,
    state,
    country,
    icon,
    countryCode,
    street,
  }

  return location
}

export function dataSourceToDataSourceSlug(dataSource: LocationDataSource) {
  switch (dataSource) {
    case LocationDataSource.GOOGLE:
      return LocationDataSourceSlug.GOOGLE
    case LocationDataSource.FOURSQUARE:
      return LocationDataSourceSlug.FOURSQUARE
    case LocationDataSource.TRIP_ADVISOR:
      return LocationDataSourceSlug.TRIP_ADVISOR
    case LocationDataSource.MAPBOX:
      return LocationDataSourceSlug.MAPBOX
    case LocationDataSource.APPLE:
      return LocationDataSourceSlug.APPLE
    case LocationDataSource.DB:
      return LocationDataSourceSlug.DB
    case LocationDataSource.GPX:
      return LocationDataSourceSlug.GPX
    default:
      return null
  }
}
