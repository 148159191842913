
import { Component, Prop } from 'vue-property-decorator'

import InspirationCard from '@/components/discover/InspirationCard.vue'
import ImportBtn from '@/components/discover/ImportBtn.vue'
import BookmarkBtn from '@/components/discover/BookmarkBtn.vue'
import { authModule, eventModule } from '@/store'

@Component({
  name: 'InpirationListItem',
  components: {
    ImportBtn,
    BookmarkBtn,
  },
})
class InspirationListCard extends InspirationCard {
  /**
   * Enables routing to inspiration on click
   */
  @Prop({ default: true, type: Boolean }) readonly itemNavigation!: boolean
  @Prop({ default: false, type: Boolean }) readonly largeThumbnail!: boolean
  get isLoggedIn(): boolean {
    return authModule.loggedIn
  }

  get imageCols() {
    if (this.largeThumbnail) {
      return { cols: '4' }
    }
    return { cols: '4', sm: '2', md: '3', lg: '2', xl: '2' }
  }

  get discoverSearchModal(): boolean {
    return eventModule.discoverSearchModal
  }

  set discoverSearchModal(value: boolean) {
    eventModule.setDiscoverSearchModal(value)
  }

  routeToInspirationDetails(): void {
    this.$router.push({
      name: 'discover-inspiration-inspiration',
      params: { inspiration: this.inspiration.id, name: this.inspiration.name },
    })
  }

  onClick(): void {
    if (
      !this.itemNavigation ||
      !this.isLoggedIn ||
      (this.$vuetify.breakpoint.smAndDown && this.discoverSearchModal)
    ) {
      this.$emit('click:inspiration', this.inspiration)
    } else if (this.discoverSearchModal) {
      this.routeToInspirationDetails()
      this.discoverSearchModal = false
    } else {
      this.routeToInspirationDetails()
    }
  }
}

export default InspirationListCard
