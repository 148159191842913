const validation = require('vee-validate/dist/locale/de.json')

module.exports = {
  i: 'Ich',
  me: 'Ich',
  you: 'Du',
  someone: 'Jemand',
  other: 'anderer',
  others: 'andere',
  otherReason: 'Andere Gründe',
  fromNumber: 'ab',
  by: 'Von',
  save: 'Speichern',
  edit: 'Bearbeiten',
  add: 'Hinzufügen',
  addItem: '{item} hinzufügen',
  clickToEdit: 'Zum Bearbeiten klicken',
  done: 'Fertig',
  delete: 'Löschen',
  clear: 'Löschen',
  download: 'Herunterladen',
  cancel: 'Abbrechen',
  update: 'Aktualisieren',
  ok: 'OK',
  gotIt: 'Verstanden!',
  no: 'Keine',
  and: 'und',
  myTrips: 'Meine Reisen',
  map: 'Karte',
  waypoints: 'Stopps',
  waypoint: 'Stopp',
  waypointCount: 'Ein Stopp | {count} Stopps',
  routeCount: 'eine Route | {count} Routen',
  packingListCount: 'eine Liste | {count} Listen',
  packingListTemplateCount: 'eine Listenvorlage | {count} Listenvorlagen',
  documents: 'Dokumente',
  expenses: 'Reisekosten',
  notes: 'Notizen',
  photos: 'Fotos',
  chat: 'Chat',
  transportation: 'Transportmittel',
  subscription: 'Abonnement',
  myProfile: 'Mein Profil',
  logout: 'Abmelden',
  editProfile: 'Profil bearbeiten',
  travelPlan: 'Reiseplan',
  settings: 'Einstellungen',
  showMore: 'Mehr anzeigen',
  suggestions: 'Vorschläge',
  photoDeletePrompt: 'Möchtest du dieses Foto löschen?',
  documentDeletePrompt: 'Möchtest du dieses Dokument löschen?',
  directoryDeletePrompt: 'Möchtest du diesen Ordner löschen?',
  transportationDeletePrompt: 'Möchtest du die Verbindung löschen?',
  changePhoto: 'Foto ändern',
  documentDelete: 'Dokument löschen',
  directoryDelete: 'Ordner löschen',
  enterName: 'Namen eingeben',
  enterNamePlaceholder: 'Namen eingeben …',
  untitled: 'Unbenannt',
  createFolder: 'Neuer Ordner',
  attendees: 'Teilnehmer:in',
  uploadFile: 'Foto hochladen',
  uploadDocument: 'Dokument hochladen',
  addDocument: 'Dokument hinzufügen',
  documentEmailHint:
    'Deine Reise hat eine einzigartige E-Mail-Adresse, an die du deine Buchungsbestätigungen weiterleiten kannst. Aktuell werden die Formate PDF, JPG und PNG unterstützt!',
  addWaypoint: 'Stopp hinzufügen',
  saveWaypoint: 'Stopp speichern',
  addingWaypoint: 'Stopp wird hinzugefügt …',
  addPOI: 'Ort in der Nähe hinzufügen',
  nearbyPlace: 'Ort in der Nähe',
  nearbyPlaces: 'Orte in der Nähe',
  nearby: 'In der Nähe',
  hours: 'Stunden',
  hoursShort: 'h',
  minutes: 'Minuten',
  minutesShort: 'min',
  noDate: 'Kein Datum',
  noTitle: 'Kein Titel',
  days: 'Tage',
  editDate: 'Datum bearbeiten',
  changeDate: 'Datum ändern',
  deleteDate: 'Datum löschen',
  changeTime: 'Uhrzeit ändern',
  changeToPoi: 'Ändern in "Ort in der Nähe"',
  actionPrompt: 'Was möchtest du tun?',
  ratings: 'Bewertungen',
  reviews: 'Bewertungen',
  registrationFields: {
    username: 'Benutzername',
    password: 'Passwort',
    passwordConfirm: 'Passwort bestätigen',
    email: 'E-Mail',
    firstName: 'Vorname',
    lastName: 'Nachname',
    summery: 'Zusammenfassung',
    tosAccepted: 'Nutzungsbedingungen akzeptiert',
    company: 'Unternehmen',
    name: 'Name',
  },
  validation: {
    ...validation.messages,
    usernameAvailable: 'Benutzername {_value_} ist nicht verfügbar',
  },
  socket: {
    IS_ONLINE: '{subject} ist jetzt online!',
    LEFT_TRIP: '{subject} hat die Reise verlassen!',
    DELETED_TRIP: '{subject} hat die Reise gelöscht!',
    RENAMED_TRIP: '{subject} hat die Reise umbenannt!',
    UPDATED_TRIP: '{subject} hat die Reise aktualisiert!',
    UPDATED_TRIP_COVER_PHOTO:
      '{subject} hat das Titelbild der Reise aktualisiert!',
    INVITED_ATTENDEE: '{subject} hat {object} zur Reise eingeladen!',
    REMOVED_ATTENDEE: '{subject} hat {object} von der Reise gelöscht!',
    JOINED_ATTENDEE: '{subject} ist der Reise beigetreten!',
    ADDED_WAYPOINT: '{subject} hat einen neuen Stopp hinzugefügt!',
    REARRANGED_WAYPOINT: '{subject} hat einen Stopp verschoben!',
    UPDATED_WAYPOINT: '{subject} hat einen Stopp aktualisiert!',
    REMOVED_WAYPOINT: '{subject} hat einen Stopp gelöscht!',
    ADDED_HIGHLIGHT: '{subject} hat ein neuen Ort hinzugefügt!',
    UPDATED_HIGHLIGHT: '{subject} hat einen Ort aktualisiert!',
    REARRANGED_HIGHLIGHT: '{subject} hat einen Ort verschoben!',
    REMOVED_HIGHLIGHT: '{subject} hat einen Ort gelöscht!',
    ADDED_DOCUMENT: '{subject} hat ein neues Dokument hinzugefügt!',
    UPDATED_DOCUMENT: '{subject} hat ein Dokument aktualisiert!',
    REMOVED_DOCUMENT: '{subject} hat ein Dokument gelöscht!',
    ADDED_EXPENSE: '{subject} hat eine neue Ausgabe hinzugefügt!',
    UPDATED_EXPENSE: '{subject} hat eine Ausgabe aktualisiert!',
    REMOVED_EXPENSE: '{subject} hat eine Ausgabe gelöscht!',
    ADDED_CHAT_MESSAGE: '{subject}: {object}',
    STARTED_LIVE_LOCATION:
      '{subject} hat angefangen, ein Live-Standort zu teilen!',
    UPDATED_LIVE_LOCATION: '{subject} hat einen Live-Standort aktualisiert!',
    STOPPED_LIVE_LOCATION:
      '{subject} hat aufgehört, einen Live-Standort zu teilen!',
    ADDED_GPX: 'Der GPX-Import ist abgeschlossen!',
    FORCE_RELOAD: 'Reise wurde aktualisiert',
    PURCHASED_LAMBUS_PRO: '{subject} hat Lambus PRO gekauft',
    FLIGHT_UPDATE:
      'Es ist jetzt ein Flugalarm für den Flug {subject} eingerichtet',
  },
  success: {
    user: {
      password_change: 'Dein Passwort wurde erfolgreich geändert',
      password_reset:
        'Bitte schaue in deinem E-Mail-Postfach nach und folge den Anweisungen, um dein Passwort wiederherzustellen.',
      invite: 'Deine Einladung wurde erfolgreich versendet!',
      picture: 'Dein Foto wurde erfolgreich hochgeladen!',
      update: 'Dein Profil wurde erfolgreich aktualisiert!',
    },
    trip: {
      create: 'Deine Reise wurde erfolgreich erstellt!',
      update: 'Deine Reise wurde erfolgreich aktualisiert!',
      delete: 'Deine Reise wurde erfolgreich gelöscht!',
      duplicate: 'Deine Reise wurde erfolgreich dupliziert!',
      invite: 'Deine Einladung wurde erfolgreich versendet!',
      archive: 'Deine Reise wurde erfolgreich archiviert!',
      unarchive: 'Deine Reise wurde erfolgreich wiederhergestellt!',
    },
    accommodation: {
      create: 'Die Unterkunft wurde erfolreich gespeichert!',
      update: 'Die Unterkunft wurde erfolreich aktualisiert!',
      delete: 'Die Unterkunft wurde erfolgreich gelöscht!',
    },
    waypoint: {
      create: 'Stopp wurde erfolgreich hinzugefügt!',
      update: 'Stopp wurde erfolgreich aktualisiert!',
      delete: 'Stopp wurde erfolgreich gelöscht!',
      migrate: 'Stopp wurde erfolgreich migriert!',
      dailyPlan: {
        create: 'Das Element wurde zum Tagesplan hinzugefügt!',
        delete: 'Das Element wurde aus dem Tagesplan gelöscht!',
        update: 'Das Element wurde erfolgreich aktualisiert!',
        rearrange: 'Das Element wurde erfolgreich verschoben!',
      },
    },
    poi: {
      create: 'Ort wurde erfolgreich zum Stopp hinzugefügt!',
      update: 'Ort wurde erfolgreich aktualisiert!',
      delete: 'Ort wurde erfolgreich entfernt!',
    },
    activity: {
      create: 'Aktivität wurde erfolgreich zum Stopp hinzugefügt!',
      update: 'Aktivität wurde erfolgreich aktualisiert!',
      delete: 'Aktivität wurde erfolgreich entfernt!',
    },
    document: {
      create: 'Dein Dokument wurde erfolgreich hochgeladen!',
      createMultiple:
        '{successCount} von {allCount} Dokumente wurden erfolgreich hochgeladen!',
      delete: 'Dein Dokument wurde erfolgreich gelöscht!',
    },
    expense: {
      create: 'Ausgabe wurde erfolgreich erstellt!',
      update: 'Ausgabe wurde erfolgreich aktualisiert!',
      delete: 'Ausgabe wurde erfolgreich gelöscht!',
      exportExpense:
        'Deine Ausgaben wurden erfolgreich exportiert. Bitte überprüfe deine E-Mails.',
      exportTrip:
        'Deine Reise wurde erfolgreich exportiert. Bitte überprüfe deine E-Mails.',
    },
    amount: {
      paid: 'Die Abrechnung wurde als bezahlt markiert!',
      ping: 'Deine Erinnerung wurde soeben gesendet!',
    },
    note: {
      create: 'Notiz wurde erfolgreich gespeichert!',
      update: 'Notiz wurde erfolgreich aktualisiert!',
      delete: 'Notiz wurde erfolgreich gelöscht!',
    },
    photo: {
      create: 'Dein Foto wurde erfolgreich hochgeladen!',
      createMultiple: '{count} Fotos wurden erfolgreich hochgeladen!',
      delete: 'Dein Foto wurde erfolgreich gelöscht!',
    },
    feedback: 'Dein Feedback wurde erfolgreich gesendet!',
    transportation: {
      create: 'Die Reiseverbindung wurde erfolgreich angelegt!',
      update: 'Die Reiseverbindung wurde erfolgreich aktualisiert!',
      delete: 'Die Reiseverbindung wurde erfolgreich gelöscht!',
      flightStatus: {
        register:
          'Es ist jetzt ein Flugalarm für den Flug {flightNumber} eingerichtet!',
      },
    },
    routes: {
      create: 'Deine Route wurde erfolgreich gespeichert!',
      update: 'Deine Route wurde erfolgreich aktualisiert!',
      delete: 'Die Route wurde erfolgreich gelöscht!',
    },
    packingLists: {
      create: 'Deine Liste wurde erfolgreich erstellt!',
      delete: 'Deine Liste wurde erfolgreich gelöscht!',
    },
    gpx: {
      delete: 'Deine GPX-Route wurde erfolgreich gelöscht!',
    },
    pro: {
      cancellation: 'Dein Lambus PRO Abonnement wurde erfolgreich gekündigt!',
    },
    organization: {
      removeTraveler: 'Der Reisende wurd entfernt.',
    },
  },
  error: {
    generic: 'Oh oh, da ist etwas schiefgelaufen. Versuche es nochmal!',
    login: {
      apple: 'Anmeldung mit Apple ID fehlgeschlagen',
      facebook: 'Anmeldung mit Facebook fehlgeschlagen',
      google: 'Anmeldung mit Google fehlgeschlagen',
      blocked: {
        title: 'Bitte deaktiviere deinen Ad-Blocker',
        content:
          'Wir nutzen keine Werbung, aber manche Login-Methoden werden von Ad-Blockern blockiert (z.B. Facebook)<br/> <ul><li>Falls du einen Ad-Blocker nutzt, schalte diesen bitte ab und versuche es erneut</li><li>Falls du Firefox nutzt, achte bitte darauf, dass das Feature "Verbesserter Schutz vor Aktivitätenverfolgung" deinen Login verhindern könnte</li></ul>',
      },
    },
    user: {
      password_change: {
        default: 'Passwort konnte nicht geändert werden!',
        currentPasswordWrong: 'Dein aktuelles Passwort ist falsch',
      },
      password_reset: 'Dein Passwort konnte nicht wiederhergestellt werden',
      password_reset_social:
        'Deine E-Mail-Adresse ist mit einem Social Media Account verknüpft. Bitte melde dich mit deinem Apple/Google/Facebook Account an ',
      invite: 'Deine Einladung konnte nicht gesendet werden!',
      picture: 'Dein Foto konnte nicht gespeichert werden!',
      update: {
        default:
          'Dein Profil konnte nicht gespeichert werden! Bitte versuche es erneut.',
        usernameNotAvailable:
          'Dein Profil konnte nicht gespeichert werden! Der eingegebene Nutzername ist nicht verfügbar.',
        emailNotAvailable:
          'Dein Profil konnte nicht gespeichert werden! Die E-Mail-Adresse wird bereits verwendet.',
      },
      delete: 'Account konnte nicht gelöscht werden',
      create: 'Deine Registrierung konnte nicht abgeschlossen werden!',
      search: 'Nutzer konnte nicht gefunden werden!',
      emailAlreadyExists:
        'Ein:e Nutzer:in mit dieser E-Mail-Adresse existiert bereits. Bitte logge dich ein oder versuche es mit einer anderen E-mail-Adresse.',
    },
    trip: {
      create: 'Deine Reise konnte nicht erstellt werden!',
      update: 'Reise konnte nicht aktualisiert werden!',
      delete: 'Reise konnte nicht gelöscht werden!',
      duplicate: 'Reise konnte nicht dupliziert werden!',
      invite: 'Deine Einladung konnte nicht gesendet werden!',
      join: 'Der Reise konnte nicht beigetreten werden',
      validatePinCode:
        'Es konnte keine Reise mit diesem PIN-Code gefunden werden!',
      leave: 'Teilnehmer:in konnte nicht entfernt werden!',
      archive: 'Deine Reise konnte nicht archiviert werden.',
      unarchive: 'Deine Reise konnte nicht wiederhergestellt werden.',
      fetch: 'Deine Reise konnte nicht geladen werden.',
      notification_update:
        'Deine Einstellungen konnten nicht aktualisiert werden.',
      notification_fetch: 'Deine Einstellungen konnten nicht geladen werden.',
    },
    accommodation: {
      fetch: 'Deine Unterkünfte konnten nicht geladen werden.',
      create: 'Unterkunft konnte nicht hinzugefügt werden.',
      update: 'Unterkunft konnte nicht aktualisiert werden.',
      delete: 'Unterkunft konnte nicht gelöscht werden.',
    },
    gpx: {
      fetch: 'Deine GPX Routen konnten nicht geladen werden.',
      delete: 'Deine GPX Routen konnten nicht gelöscht werden.',
    },
    theme: {
      size: 'Dein Bild überschreitet die maximale Dateigröße von 5mb.',
    },
    waypoint: {
      rearrange: 'Details konnten nicht verschoben werden!',
      fetch: 'Stopp konnte nicht geladen werden',
      create: 'Stopp konnte nicht hinzugefügt werden',
      update: 'Stopp konnte nicht aktualisiert werden!',
      delete: 'Stopp konnte nicht gelöscht werden',
      optimize: 'Dein Reiseplan konnte nicht sortiert werden.',
      weather: 'Wetter konnte nicht geladen werden',
      migrate: 'Stopp konnte nicht migriert werden',
      dailyPlan: {
        fetch: 'Der Tagesplan konnte nicht geladen werden!',
        create: 'Das Element konnte nicht zum Tagesplan hinzugefügt werden!',
        delete: 'Das Element konnte nicht aus dem Tagesplan gelöscht werden!',
        update: 'Der Tagesplan konnte nicht aktualisiert werden!',
        rearrange: 'Das Element konnte nicht verschoben werden!',
      },
      poi: {
        fetch: 'Orte konnte nicht geladen werden!',
      },
    },
    poi: {
      fetch: 'Ort konnte nicht geladen werden!',
      create: 'Ort konnte nicht zum Stopp hinzugefügt werden!',
      update: 'Ort konnte nicht aktualisiert werden!',
      delete: 'Ort konnte nicht entfernt werden!',
      preview: 'Ort Vorschau konnte nicht geladen werden!',
    },
    activity: {
      fetch: 'Aktivität konnte nicht geladen werden!',
      create: 'Aktivität konnte nicht zum Stopp hinzugefügt werden!',
      update: 'Aktivität konnte nicht aktualisiert werden!',
      delete: 'Aktivität konnte nicht entfernt werden!',
    },
    document: {
      fetch: 'Dokumente konnten nicht geladen werden!',
      get: 'Dokument konnte nicht geladen werden!',
      create: 'Dein Dokument konnte nicht gespeichert werden!',
      update: 'Dein Dokument konnte nicht gespeichert werden!',
      delete: 'Dein Dokument konnte nicht gelöscht werden!',
      download: 'Dein Dokument konnte nicht heruntergeladen werden!',
    },
    expense: {
      create: 'Ausgabe konnte nicht erstellt werden!',
      update: 'Ausgabe konnte nicht aktualisiert werden!',
      delete: 'Ausgabe konnte nicht gelöscht werden!',
      rate: 'Währungskurse konnten nicht geladen werden',
      findCurrency: 'Währung konnte nicht gefunden werden.',
    },
    amount: {
      delete: 'Ausgabe konnte nicht gelöscht werden!',
      paid: 'Die Ausgabe konnte nicht als bezahlt markiert werden!',
      ping: 'Deine Erinnerung konnte nicht gesendet werden!',
    },
    note: {
      fetch: 'Notizen konnten nicht geladen werden',
      create: 'Notiz konnte nicht gespeichert werden',
      update: 'Notiz konnte nicht aktualisiert werden',
      delete: 'Notiz konnte nicht gelöscht werden',
    },
    photo: {
      fetch: 'Fotos konnten nicht geladen werden!',
      create: 'Dein Foto konnte nicht gespeichert werden!',
      update: 'Dein Foto konnte nicht aktualisiert werden!',
      delete: 'Dein Foto konnte nicht gelöscht werden!',
      download: 'Dein Foto konnte nicht heruntergeladen werden!',
    },
    message: {
      fetch: 'Nachrichten konnten nicht geladen werden',
      send: 'Deine Nachricht konnte nicht gesendet werden',
    },
    inspiration: {
      fetch: 'Inspiration konnte nicht geladen werden',
      fetchAll: 'Inspirationen konnten nicht geladen werden',
    },
    cateogry: {
      fetchAll: 'Kategorien konnten nicht geladen werden',
    },
    bookmark: {
      fetch: 'Bookmarks konnten nicht geladen werden',
      create: 'Bookmark konnte nicht hinzugefügt werden',
      delete: 'Bookmark konnte nicht entfernt werden',
    },
    import: {
      create: 'Trip konnte nicht importiert werden',
    },
    category: {
      fetchAll: 'Die Kategorien konnten nicht geladen werden',
    },
    directory_create: 'Ordner konnte nicht erstellt werden',
    feedback: 'Dein Feedback konnte nicht gesendet werden!',
    payment_details: 'Zahlungsdaten konnten nicht empfangen werden!',
    email_invalid: 'Die E-Mail-Adresse ist ungültig.',
    email_retry:
      'Bitte stelle sicher, dass du die richtige E-Mail-Adresse eingegeben hast und versuche es erneut.',
    location_support: 'Dein Browser unterstützt keine GPS-Lokalisierung.',
    location: 'Dein Standort ist nicht verfügbar. Bitte versuche es erneut.',
    transportation: {
      fetch: 'Die Reiseverbindung konnte nicht geladen werden.',
      search:
        'Die Suche nach Reiseverbindungen konnte nicht durchgeführt werden.',
      post: 'Die Reiseverbindung konnte nicht hinzugefügt werden.',
      update: 'Die Reiseverbindung konnte nicht aktualisiert werden.',
      delete: 'Die Reiseverbindung konnte nicht gelöscht werden.',
      searchFlight: 'Dein Flug konnte nicht gefunden werden.',
      flightStatus: {
        missingFields:
          'Wir benötigen die folgenden Infos, um deinen Flug tracken zu können: {missingFields}',
        register: 'Flug-Updates konnten nicht aktiviert werden.',
        fetch: 'Flugstatus konnte nicht geladen werden.',
      },
    },
    routes: {
      fetchAll: 'Routen konnten nicht geladen werden.',
      fetch: 'Die Route konnte nicht gelöscht werden.',
      calculate: 'Die Route konnte nicht berechnet werden.',
      update: 'Die Route konnte nicht aktualisiert werden.',
      create: 'Deine Route konnte nicht gespeichert werden.',
      delete: 'Die Route konnte nicht gelöscht werden.',
    },
    export: {
      trip: 'Deine Reise konnte nicht exportiert werden.',
      expenses: 'Deine Reisekosten konnten nicht exportiert werden.',
    },
    packingLists: {
      fetch: 'Deine Liste konnte nicht geladen werden.',
      fetchTemplates: 'Vorlagen für Listen konnten nicht geladen werden.',
      create: 'Deine Liste konnte nicht gespeichert werden.',
      update: 'Deine Liste konnte nicht aktualisiert werden.',
      delete: 'Deine Liste konnte nicht gelöscht werden.',
    },
    subscription: {
      fetch: 'Abonnements konnten nicht geladen werden.',
      cancel:
        'Dein Abonnement konnte nicht gekündigt werden. Bitte versuche es später erneut oder wende dich an unseren Support.',
    },
    payment: {
      prices: {
        fetch: 'Preise konnten nicht geladen werden.',
      },
      session: {
        fetch: 'Bezahlvorgang konnte nicht eingeleitet werden.',
      },
    },
    placeRecommendations: {
      fetch: 'Empfohlene Orte konnten nicht geladen werden.',
    },
    uploadFileSize: 'Die Datei darf nicht größer als 25 MB sein.',
  },
  info: {
    document: {
      upload: 'Dein Dokument wird im Hintergrund hochgeladen …',
      uploadMultiple:
        'Deine {count} Dokumente werden im Hintergrund hochgeladen …',
    },
    photo: {
      upload: 'Dein Foto wird im Hintergrund hochgeladen …',
      uploadMultiple: 'Deine {count} Fotos werden im Hintergrund hochgeladen …',
    },
    transportation: {
      searchFlight: 'Flug nicht gefunden',
    },
    userAlreadyAdded: 'Teilnehmer:in bereits hinzugefügt',
  },
  emptyState: {
    map: 'Nutze das Suchfeld, oder die Karte, um neue Stopps zu finden',
    documents: 'Keine Dokumente',
    private_expenses: 'Keine privaten Ausgaben',
    group_expenses: 'Keine Gruppen-Ausgaben',
    billing: 'Ausgeglichen',
    notes: 'Keine Notizen',
    travelPlan: 'Keine Stopps',
    photos: 'Keine Fotos',
    chat: 'Keine Nachrichten',
    packingLists: 'Keine Listen',
    transportation: 'Keine Transportmittel',
    hint: {
      travelPlan: `Wohin geht's? Tippe auf das +, um deine Stopps & Orte hinzuzufügen`,
      expenses:
        'Alles ausgeglichen? Tippe auf das +, um eine Ausgabe hinzuzufügen',
      general: 'Fühle dich frei, welche zu erstellen!',
      chat: 'Schreibe etwas Nettes!',
      packingLists:
        'Stelle sicher, dass du nichts vergisst. Tippe auf das +, um deine Liste zu erstellen',
      accommodation:
        'Wo schläfst du? Tippe auf das +, um deine Hotels & Wohnungen hinzuzufügen',
      search:
        '@:(noResults). Bitte versuche es mit einem anderen Suchbegriff. 🙌',
      transportation:
        'Wie verreist du? Tippe auf das +, um dein Transportmittel hinzuzufügen',
      notes:
        'So leer! Tippe auf das +, um einige Notizen oder Links hinzuzufügen',
      documents:
        'Keine Dokumente. Tippe auf das + oder ziehe deine Dokumente hier rein, um etwas hochzuladen',
      photos:
        'Noch keine Erinnerungen. Tippe auf das + oder ziehe deine Fotos hier rein, um etwas hochzuladen',
    },
  },
  overview: 'Übersicht',
  copyTooltip: 'Klicken um zu kopieren',
  copySuccess: 'In Zwischenablage kopiert!',
  datePicker: {
    SPECIFIC: 'Bestimmtes Datum',
    WHOLE_MONTH: 'Ganzer Monat',
  },
  datetimePicker: {
    DATE: 'Datum',
    TIME: 'Uhrzeit',
  },
  dates: {
    title: 'Wann wirst du da sein? (optional)',
    hint: 'Datum auswählen',
  },
  joinTripPrompt:
    'Du hast eine Einladung zu einer Reise erhalten? Gebe unten den PIN-Code der Reise ein, um beizutreten.',
  joinTripNow: 'Jetzt beitreten',
  navBar: {
    discover: 'Discover',
    newTrip: 'Neue Reise',
    joinTrip: 'Reise beitreten',
    archive: 'Archiv',
    activeTrips: 'Aktive Reisen',
    searchPlaceholder: 'Nach Inspiration suchen',
  },
  peoplePickerTitle: 'Wähle eine Person aus',
  youAreInvited: 'Du wurdest eingeladen, der Reise "{tripName}" beizutreten!',
  acceptInvitation: 'Einladung annehmen',
  decline: 'Ablehnen',
  destination: 'Das Reiseziel',
  destinations: 'Reiseziele',
  suggestedTrips: 'Vorgeschlagene Reisen',
  similarTopics: 'Ähnliche Themen',
  sendImage: 'Bild senden',
  dropHint: 'Dateien hierher ziehen, um sie hochzuladen.',
  iOwe: 'Ich schulde',
  owe: 'schulde',
  iReceive: 'Ich bekomme',
  receiveFrom: 'bekomme von',
  newPrivateExpense: 'Neue private Ausgabe',
  newGroupExpense: 'Neue Gruppen-Ausgabe',
  notInvolved: 'Nicht beteiligt',
  addNote: 'Notiz hinzufügen',
  newNote: 'Neue Notiz',
  saveNote: 'Notiz speichern',
  billing: 'Abrechnung',
  openAccounts: 'Offene Posten',
  paidAccounts: 'Bezahlte Posten',
  paid: 'Bezahlt',
  received: 'Erhalten',
  createExpense: 'Ausgabe erstellen',
  updateExpense: 'Ausgabe bearbeiten',
  addExpense: 'Ausgabe hinzufügen',
  editExpense: 'Ausgabe bearbeiten',
  whoDoesItRelateTo: 'Wen betrifft die Ausgabe?',
  whoPaid: 'Wer hat bezahlt?',
  subject: 'Betreff',
  subjectPlaceholder: 'Betreff eingeben …',
  continue: 'Weiter',
  balance: 'Restbetrag',
  total: 'Gesamt',
  equal: 'gleichmäßig',
  exact: 'genau',
  whenWasItPaid: 'Wann wurde bezahlt?',
  deleteExpensePrompt: 'Möchtest du diese Ausgabe löschen?',
  deleteExpense: 'Ausgabe löschen',
  pingPrompt: 'Erinnere {_value_} daran, dass du bereits bezahlt hast.',
  pingAction: 'Erinnerung senden',
  pingTitle: 'Hast du bereits bezahlt?',
  enterAmount: 'Betrag eingeben',
  enterAmountPlaceholder: 'Betrag eingeben …',
  payWithPayPal: 'Mit PayPal bezahlen',
  noPaypalAccountSubject: 'PayPal-Details noch nicht hinterlegt!',
  noPaypalAccountText:
    '{person} hat seine/ihre PayPal-Details noch nicht hinterlegt. Du kannst ihn/sie bitten, die Daten zu seinem/ihren Account hinzuzufügen. Probiers anschließend noch einmal!',
  markAsPaid: 'Als bezahlt markieren',
  alreadyPaid: 'Bereits bezahlt?',
  noPayPalInformation:
    'Diese*r Nutzer*in hat noch keine PayPal-Infos hinterlegt',
  group: 'Gruppe',
  myExpenses: 'Meine Ausgaben',
  allExpenses: 'Alle Ausgaben',
  private: 'Privat',
  shared: 'Geteilt',
  searchHint: 'Suche nach Orten',
  noResults: 'Keine Ergebnisse',
  airport: 'Flughafen',
  atm: 'Bankautomat',
  bank: 'Bank',
  bar: 'Bar',
  bus_station: 'Bushaltestelle',
  cafe: 'Café',
  city_hall: 'Rathaus',
  gas_station: 'Tankstelle',
  hotel: 'Hotel',
  hospital: 'Krankenhaus',
  library: 'Bibliothek',
  museum: 'Museum',
  night_club: 'Night Club',
  park: 'Park',
  parking: 'Parkplatz',
  pharmacy: 'Apotheke',
  police: 'Police',
  post_office: 'Post Office',
  restaurant: 'Restaurant',
  shopping_mall: 'Einkaufszentrum',
  store: 'Geschäft',
  subway_station: 'U-Bahn-Station',
  train_station: 'Bahnhof',
  zoo: 'Zoo',
  showLess: 'Weniger anzeigen',
  radius: 'Umkreis',
  noPlacesFound:
    'Es wurden keine Orte zu deiner Suche gefunden. Bitte ändere den Suchbegriff, oder erhöhe den Umkreis',
  noWorriesYouCanStillAddIt:
    'Aber keine Sorge, du kannst es trotzdem hinzufügen!',
  howWouldYouLikeToImportThisPlace:
    'Wie möchtest du diesen Standort importieren?',
  chooseWaypoint: 'Welchem Stopp möchtest du den Ort hinzufügen?',
  selectWaypoint: 'Stopp auswählen...',
  importAsWaypoint: 'Als Stopp importieren',
  addPOIToWaypoint: 'Als POI importieren',
  whereToStartFrom: 'Wo soll es losgehen?',
  mapSettings: 'Karten Einstellungen',
  mapTypes: {
    title: 'Kartentyp',
    types: {
      normal: 'Normal',
      satellite: 'Satellit',
      terrain: 'Gelände',
    },
  },
  distanceUnits: {
    title: 'Einheiten',
    kilometers: 'Kilometer',
    miles: 'Meilen',
  },
  weather: {
    title: 'Temperatur',
    celsius: 'Celsius',
    fahrenheit: 'Fahrenheit',
  },
  connectWaypoints: 'Stopps verbinden',
  showDistance: 'Entfernung anzeigen',
  learnMore: 'Mehr erfahren',
  deleteNoteDescription:
    'Bist du sicher, dass du diese Notiz löschen möchtest?',
  deleteNoteTitle: 'Notiz löschen',
  noteName: 'Titel eingeben …',
  selectNote: 'Wähle eine Notiz …',
  areYouSure: 'Bist du sicher?',
  removeAttendee: 'Teilnehmer:in entfernen',
  pendingAttendees: 'Eingeladene Teilnehmer:Innen',
  showPicture: 'Profilbild anzeigen',
  viewPhoto: 'Foto ansehen',
  deletePhoto: 'Foto löschen',
  general: 'Allgemein',
  restaurants: 'Restaurants',
  accommodations: 'Unterkünfte',
  entertainment: 'Entertainment',
  shopping: 'Shopping',
  activities: 'Aktivitäten',
  drinks: 'Drinks',
  fuel: 'Tanken / Aufladen',
  tickets: 'Tickets',
  fees: 'Gebühren',
  usernameSearchHint: 'Nutzernamen oder E-Mail suchen …',
  currency: 'Währung',
  currencyHeader: 'Welche Währung benutzt du?',
  timePeriod: 'Zeitraum',
  datesHeader: 'Wann wirst du da sein?',
  notifications: 'Mitteilungen',
  choose: 'Auswählen',
  selected: 'ausgewählt',
  waypointSorting: {
    changeToManual: 'Sortierung ändern auf "Manuell"',
    title: 'Sortierung von Stopps',
    header: 'NEU: Sortiere Stopps automatisch',
    text: 'Es ist ab sofort möglich, Stopps automatisch, basierend auf ihrem Datum, zu sortieren. Falls ein Stopp kein Datum hat, wird er am Ende angehängt. Falls du bereits Transportmittel oder Routen hinzugefügt hast, die sich durch die Sortierung ändern, geben wir dir natürlich Bescheid!',
  },
  byDate: 'Nach Datum',
  manual: 'Manuell',
  preview: 'Vorschau',
  showPreview: 'Vorschau anzeigen',
  inviteYourFriends: 'Freunde einladen',
  manage: 'Verwalten',
  tokenHint:
    'Du kannst den PIN-Code mit deinen Freunden teilen und sie können darüber der Reise beitreten.',
  moreActions: 'Weitere Aktionen',
  saveTrip: 'Reise speichern',
  archiveTrip: 'Reise archivieren',
  duplicateTrip: 'Reise duplizieren',
  duplicateTripConfirmMessage:
    'Möchtest du diese Reise duplizieren? Eine Kopie der Reise wird erstellt.',
  confirm: 'Bestätigen',
  enterTripNamePrompt: 'Bitte gebe den neuen Namen deiner Reise ein.',
  enterTripName: 'Name der Reise eingeben …',
  leftTrip: 'Reise verlassen',
  leaveTrip: 'Reise verlassen',
  deleteTrip: 'Reise löschen',
  leaveTripPrompt: 'Möchtest du diese Reise wirklich verlassen?',
  deleteTripPrompt: 'Möchtest du diese Reise wirklich löschen?',
  restoreTrip: 'Reise wiederherstellen',
  themeTooltip: 'Klicken, um das Titelbild zu ändern (max 5 mb)',
  search: 'Suchen',
  skip: 'Überspringen',
  inviteNow: 'Jetzt einladen',
  inviteAttendee: 'Teilnehmer:in einladen',
  highlight: 'Orte in der Nähe',
  expense: 'Reisekosten',
  document: 'Dokumente',
  photo: 'Fotos',
  note: 'Notiz',
  timeHint: 'Uhrzeit auswählen …',
  poi: 'Lokale Orte',
  deleteWaypointPrompt: 'Bist du sicher?',
  deleteWaypoint: '{type} löschen',
  place: 'Ort',
  changePasswordAction: 'Passwort ändern',
  changePassword: 'Passwort ändern …',
  oldPassword: 'Altes Passwort eingeben …',
  newPassword: 'Passwort eingeben …',
  newPasswordConfirmation: 'Passwort erneut eingeben …',
  resetPasswordTitle: 'Passwort wiederherstellen',
  resetPasswordAction: 'Jetzt wiederherstellen',
  resetHint:
    'Bitte gebe deine E-Mail-Adresse ein, um dein Passwort wiederherzustellen.',
  enterEmail: 'E-Mail eingeben …',
  forgotPassword: 'Passwort vergessen?',
  invitePrompt: 'Möchtest du deine Freunde direkt zu deiner Reise einladen?',
  selectTrip: 'Reise auswählen',
  inviteToLambus: 'Nein, nur zu Lambus einladen',
  yes: 'Ja',
  yesSelectTrip: 'Ja, Reise auswählen',
  profileTooltip: 'Klicken um das Profilbild zu ändern',
  sendInvite: 'Einladung absenden',
  inviteAggreement:
    'Ich bestätige, dass die von mir eingeladene Person dem Empfang der Einladungs-E-Mail von der Lambus GmbH zugestimmt hat.',
  inviteHint:
    'Lade jetzt deine Freunde ein und plant gemeinsam eure nächste Reise.',
  socialMedia: 'Social Media',
  youCanFindUsOn: 'Du kannst uns finden auf …',
  feedback: 'Feedback',
  howCanWeHelp: 'Wie können wir helfen?',
  send: 'Senden',
  faq: 'Hilfe / FAQ',
  legal: 'Rechtliches',
  terms: 'AGB',
  privacy: 'Datenschutz',
  imprint: 'Impressum',
  username: 'Nutzername',
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'E-Mail-Adresse',
  company: 'Unternehmen',
  gender: 'Geschlecht',
  birthdate: 'Geburtsdatum',
  female: 'Weiblich',
  male: 'Männlich',
  neutral: 'Divers',
  country: 'Land',
  paypalAddress: 'PayPal E-Mail-Adresse',
  paypalMeUsername: 'PayPal.Me Benutzername',
  deleteAccountPrompt:
    "Dies wird all' deine Daten löschen - einschließlich deiner Reisen, Dokumente, Fotos und Chat-Verläufe.",
  irrevocablyDelete: 'Unwiderruflich löschen',
  deleteAccount: 'Account löschen',
  unknownPlace: 'Unbekannter Ort',
  kilometersUnit: 'km',
  milesUnit: 'mi',
  createTrip: 'Reise erstellen',
  next: 'WEITER',
  back: 'ZURÜCK',
  navHint: 'Oder ↵ ENTER drücken',
  invite: 'Einladen',
  or: 'ODER',
  newTripFields: {
    destination: {
      title: 'Wo möchtest du hin?',
      hint: 'z.B. eine Stadt, Land oder Region',
    },
    origin: {
      title: 'Wo möchtest du starten? (optional)',
      hint: 'z.B. Adresse, Bahnhof oder Flughafen',
    },
    attendees: {
      hint: 'Teilnehmer:in einladen …',
    },
  },
  createNewTrip: 'Neue Reise erstellen',
  joinTrip: 'Reise beitreten',
  welcome: 'Willkommen bei Lambus!',
  loginTitle: 'Willkommen bei Lambus!',
  login: 'Anmelden',
  lambusAllInOneApp: 'Lambus - Deine neue All-In-One Reise-App',
  usernameLabel: 'Benutzername / E-Mail',
  passwordLabel: 'Passwort',
  notRegisteredYet: 'Account erstellen',
  loginFailed: {
    wrongInput:
      'Die von dir eingegebene E-Mail-Adresse oder das Passwort ist falsch. Bitte versuche es erneut oder klicke auf "Passwort vergessen?", danke dir!',
    alreadyRegisteredWithEmail:
      'Problem bei deinem Login. Du hast bereits einen Account mit dieser E-Mail-Adresse. Möchtest du dein Passwort wiederherstellen?',
    isLinkedToSocialAccount:
      'Deine E-Mail-Adresse ist mit einem Social Media Account verknüpft. Bitte melde dich mit deinem "{social}" Account an',
    appleSignInError:
      'Leider konnten wir dein Login mit Apple aktuell nicht abschließen. Bitte wähle eine alternative Login-Method - in der Zwischenzeit arbeiten wir bereits an der Fehlerbehebung!',
  },
  continueWithoutAccount: 'Ohne Profil fortfahren?',
  usernameNotice:
    'Du bist fast fertig! Bitte gebe einen Nutzernamen ein, den du mit deinem Lambus Account verknüpfen willst.',
  tosAggreement:
    'Bitte akzeptiere unsere AGB und Datenschutzrichtlinie, um deine Registrierung abzuschließen',
  continueWithApple: 'Weiter mit Apple',
  continueWithFacebook: 'Weiter mit Facebook',
  registration: 'Account erstellen',
  register: 'Account erstellen',
  tosAggreementPlaceholders:
    'Bitte akzeptiere unsere {0} und {1}, um deine Registrierung abzuschließen.',
  field: 'Feld',
  value: 'Eingabe',
  noInspirationFound: 'Keine Inspirationen gefunden.',
  importTrip: 'Reise importieren',
  importedTrip: 'Reise importiert',
  myTransportation: 'Meine Verbindung',
  addTransportation: 'Transportmittel hinzufügen',
  linkedWaypoint: 'Verknüpfter Stopp',
  unlinkTransportationPrompt:
    'Dein Stopp ist mit einem Verkehrsmittel verbunden. Möchtest du die Verbindung aufheben?',
  unlink: 'Aufheben',
  departure: 'Abflug',
  plusDepartureStation: '+ Bahnhof (Abfahrt)',
  plusArrivalStation: '+ Bahnhof (Ankunft)',
  plusTime: '+ Uhrzeit',
  transportationCarDestinationTooltip:
    'Pro-Tipp: Ändere den Zielort, um das Transportmittel auch für weitere Strecken zu verwenden!',
  sameLocationDescription:
    'Dann fügen wir für dich den Stopp »{waypoint}« als Ziel hinzu.',
  sameLocationTitle: 'Startort = Zielort?',
  plusDeparturePort: '+ Hafen (Abfahrt)',
  plusArrivalPort: '+ Hafen (Ankunft)',
  plusPreciseLocation: '+ Genauer Standort',
  port: 'Hafen',
  searchFlight: 'Flug suchen',
  flightNumber: 'Flugnummer',
  flightNumberPlaceholder: 'z.B. LH1234',
  FlightBooking: 'Flug',
  TrainBooking: 'Bahn',
  RentalCarBooking: 'Mietwagen',
  CarBooking: 'Auto',
  OwnCar: 'Eigenes Auto',
  FerryBooking: 'Fähre',
  BusBooking: 'Bus',
  BicycleBooking: 'Fahrrad',
  WalkingBooking: 'Zu Fuß',
  GenericBooking: 'Anderes',
  subTypePlaceholder: 'z.B. Wohnmobil, Motorrad, ...',
  taxi: 'Taxi',
  escooter: 'E-Scooter',
  rv: 'Wohnmobil',
  motorcycle: 'Motorrad',
  helicopter: 'Helikopter',
  cruise: 'Kreuzfahrt',
  airportSearch: 'Flughafen suchen',
  airportActionPrompt: 'Was möchtest du machen?',
  showOnGoogleMaps: 'In Google Maps anzeigen',
  selectLocation: 'Standort auswählen',
  selectAirport: 'Flughafen auswählen',
  uploadNewDocument: 'Neues Dokument hochladen',
  selectDocument: 'Dokument auswählen',
  viewDocument: 'Dokument anzeigen',
  readOnWebsite: 'Auf <a>{website}</a> weiterlesen',
  textfieldRules: {
    required: 'Erforderlich.',
    counter: 'Max 50 Zeichen.',
    pin: 'PIN muss 6 Zeichen lang sein.',
    amount: 'Ungültiger Betrag.',
    email: 'Ungültige E-Mail.',
    link: 'Ungültiger Link.',
    flightNumber: 'Ungültige Flugnummer.',
    timeString: 'Ungültiges Format.',
    password: 'Passwort muss mindestens 6 Zeichen lang sein.',
    noWhiteSpace: 'Darf keine Leerzeichen enthalten.',
    checkbox: 'Das Feld muss akzeptiert werden.',
  },
  maxFileSize: 'Maximale Dateigröße: {size}',
  tripOfTheDay: 'Reise des Tages',
  tripOfTheWeek: 'Reise der Woche',
  tripOfTheMonth: 'Reise des Monats',
  media: 'Medien',
  searchResult: 'Suchergebnisse',
  noBookmarks: 'Keine Bookmarks',
  discoverTrips: 'Reisen entdecken',
  date: 'Datum',
  lastUpdated: 'Zuletzt aktualisiert',
  sortTripsBy: 'Reisen sortieren nach …',
  adBlocker: {
    hey: 'Hey!',
    notice: `So wie's aussieht, benutzt du einen Ad-Blocker. Wenn du statt dieser Meldung doch lieber {contentType} sehen möchtest, deaktiviere bitte deinen Ad-Blocker.`,
  },
  pro: {
    unlock: {
      title: '🔓 Dieses Feature freischalten',
      description:
        'Dieses Feature ist mit Lambus PRO verfügbar! Möchtest du jetzt mehr erfahren?',
    },
    checkout: {
      title: 'Auf Lambus PRO upgraden',
      description:
        'Schalte nützliche PRO-Features frei, um deine Reiseplanung noch einfacher zu machen! 🎉',
      payPerTrip: {
        title: 'Pay Per Trip',
        caption: 'Upgrade eine Reise',
      },
      yearlySubscription: {
        title: 'Jährliches Abonnement',
        caption: 'Upgrade alle deine Reisen',
        freeTrial: '7 TAGE KOSTENLOS TESTEN!',
      },
      success: {
        title: 'Lambus PRO: Kauf erfolgreich!',
      },
      learnMore: 'Mehr über Lambus PRO erfahren',
      upgradeNowUpper: 'JETZT UPGRADEN 🚀',
      thankYouNotice:
        'Vielen Dank für deinen Kauf von Lambus PRO! ❤️ Diese Seite wird in {time} Sekunden automatisch geschlossen.',
    },
    subscription: {
      validUntil: 'gültig bis',
      purchasedBy: 'erworben von',
      exploreProFeatures: 'PRO-Features entdecken',
      exploreLambusPro: 'Lambus PRO entdecken',
      manageSubscription: 'Abonnement verwalten',
      mySubscriptions: 'Meine Abonnements',
      noSubscriptions: 'Keine Abonnements',
      noActiveSubscriptions: 'Du hast keine aktiven Abonnements',
      areYouTravelingAlot: 'Du verreist viel?',
      upgradeToYearlySubscription:
        'Dann upgrade auf ein jährliches Abonnement!',
      cancelSubscription: 'Abonnement kündigen',
      nowWith: 'Jetzt mit',
      forever: 'Für immer',
      yearlySubscription: 'Jährliches Abonnement',
      monthlySubscription: 'Monatliches Abonnement',
      lifetimeSubscription: 'Lebenslanges Upgrade',
    },
    features: {
      routePlanning: {
        title: 'Erweiterte Routenplanung',
        description:
          'Berechne die genaue Distanz und Dauer zwischen all deinen Stopps',
      },
      googleMaps: {
        title: 'Google Maps Suche',
        description: 'Wir nutzen Google Maps, um noch mehr Stopps zu finden',
      },
      videoChat: {
        title: 'Video-Chat',
        description:
          'Unterhalte dich mit den anderen Teilnehmer:innen, während ihr eure Reise plant',
      },
      moneyPool: {
        title: 'Gruppenkasse',
        description: 'Verwalte das Geld der Reise an einem zentralen Ort',
      },
      exportTripGpx: {
        title: 'GPX-Export der Reise',
        description:
          'Exportiere deine Reise als GPX and nutze sie in anderen Tracking-Apps',
      },
      currencies: {
        title: 'Automatische Währungen',
        description:
          'Rechne Fremdwährungen mit dem aktuellen Kurs in deine Währung um',
      },
      weather: {
        title: 'Echtzeit Wetter-Infos',
        description: 'Zeige das aktuelle Wetter für alle deine Stopps an',
      },
      exportExpenses: {
        title: 'Export von Reisekosten',
        description: 'Exportiere deine Reisekosten automatisch zu Excel',
      },
      flightStatus: {
        title: 'Echtzeit Fluginfos',
        description:
          'Werde benachrichtigt, wenn sich dein Flug ändert (verspätet, verschoben, annuliert)',
      },
      exportTrip: {
        title: 'Reise als PDF-Export',
        description:
          'Exportiere deine Reise als PDF, um sie auch ohne Smartphone griffbereit zu haben',
      },
      support: {
        title: 'Unterstütze uns ❤️',
        description:
          'Mit deiner Hilfe können wir werbefrei bleiben und auf Datenverkauf verzichten',
      },
      packingList: {
        title: 'Listen (Packlisten, To-Do-Listen)',
        description: 'Wähle aus fertigen Listen aus oder erstelle deine eigene',
      },
      flightCompensation: {
        title: 'Flug-Entschädigung',
        description:
          'Beantrage automatisch eine Entschädigung, wenn dein Flug ausfällt',
      },
      privateDocuments: {
        title: 'Private Dokumente',
        description:
          'Speichere Reisepass, Führerschein und mehr sicher auf deinem Gerä',
      },
      offlineMode: {
        title: 'Offline-Modus',
        description:
          'Zugriff auf deine gesamte Reise auch ohne Internetverbindung',
      },
      calendarSync: {
        title: 'Kalender-Sync',
        description: 'Synchronisiere deine Stopps und POIs mit deinem Kalender',
      },
    },
    cancellation: {
      orderedWith: 'Kauf im {store}',
      cancelWith:
        'Da du Lambus PRO über den {store} gekauft hast, erfolgt die Kündigung ebenfalls über den {store}',
      reasons: {
        label: {
          pro_cancelation_reason_1: 'Ich nutze die Funktionen nicht',
          pro_cancelation_reason_2: 'Mir fehlt eine Funktion',
          pro_cancelation_reason_3: 'Ich habe eine bessere App gefunden',
          pro_cancelation_reason_4: 'Der Preis war mir zu teuer',
          pro_cancelation_reason_5: 'Ich teste für eine spätere Reise',
        },
        placeholder: {
          pro_cancelation_reason_2: 'Welche Funktionen fehlen dir?',
        },
      },
      lossHint: {
        youCurrentlyHave: 'Du hast momentan',
        thatWillBeLost:
          ', die gelöscht werden, sobald du dein PRO Abonnement kündigst.',
        confirmation: 'Ich möchte mein Abonnement trotzdem kündigen.',
        tellUsWhy:
          'Bitte teile uns mit, was dir nicht gefällt und wir arbeiten daran!',
      },
      talkToUs: 'Brauchst du Hilfe? Wir helfen gerne!',
      chatWithUs: 'Melde dich bei uns',
      sendUsAnEmail: 'Sende uns eine E-Mail',
    },
  },
  routes: {
    myRoutes: 'Meine Routen',
    planRoute: 'Route planen',
    calculate: 'Route berechnen',
    save: 'Route speichern',
    delete: 'Route löschen',
    recommendedRoute: 'Vorgeschlagene Route',
    type: {
      driving: 'Auto',
      transit: 'Zug',
      bicycling: 'Fahrrad',
      flight: 'Flug',
      walking: 'Zu Fuß',
    },
  },
  realtimeFlightStatus: {
    isEligibleForCompensation: 'Entschädigung bei Verspätung/Ausfall möglich',
    activateFlightAlert: 'Flug-Updates erhalten',
    flightStatus: 'Flug-Status',
    status: {
      active: 'In der Luft',
      canceled: 'Storniert',
      diverted: 'Umgeleitet',
      landed: 'Gelandet',
      not_operational: 'Nicht aktiv',
      redirected: 'Umgeleitet',
      scheduled: 'Planmäßig',
      now_tracking: 'Wird getrackt',
      unknown: 'Unbekannt',
    },
    compensation: {
      title: 'Flug-Entschädigung beantragen',
      subtitle:
        'Erhalte bis zu 600 € wenn dein Flug sich verspätet oder ausfällt!',
      howItWorks: 'Wie funktionierts?',
      instructions: {
        step1: '1. Tracke deinen Flug mit Lambus PRO',
        step2:
          '2. Wir wissen, wenn sich dein Flug verspätet oder ausfllt und melden uns dann bei dir, falls dein Flug für eine Rückerstattung in Frage kommt!',
      },
    },
  },
  selectDate: 'Datum auswählen',
  distance: 'Distanz',
  duration: 'Dauer',
  later: 'Später',
  exportExpensesPrompt:
    'Wir können die Reisekosten deiner Reise zu einer Excel-Datei zusammenfassen. Möchtest du den Export jetzt starten?',
  exportInfo:
    'Du erhälst in Kürze eine E-Mail mit dem Dokument. Wichtig: Überprüfe auch dein Spam-Postfach!',
  exportStarted: 'Der Export hat begonnen',
  exportNow: 'Jetzt exportieren',
  exportAsExcel: 'Als Excel-Datei exportieren',
  howItWorks: 'Wie funktionierts',
  exportExpenses: 'Excel-Export von Reisekosten',
  howItWorksInfo:
    'Bei Lambus werden alle Reisekosten automatisch zwischen den Mitreisenden verrechnet. Das bedeutet, dass wenn du einer Person etwas schuldest und diese Person einer weiteren Person etwas schuldet, müssen nicht zwei Leute etwas überweisen, sondern nur eine.',
  howItWorksPrompt:
    'Du hast noch weitere Fragen oder denkst, wir haben uns verrechnet?',
  contactUs: 'Kontaktiere uns',
  export: 'Exportieren',
  exportTrip: 'Reise als PDF exportieren',
  exportTripPrompt: 'Möchtest du deine Reise als PDF exportieren?',
  exportAsGPX: 'Als GPX-Datei exportieren',
  exportTripAsGPXPrompt: 'Möchtest du deine Reise als GPX-Datei exportieren?',
  exportAsPDF: 'Als PDF exportieren',
  gpxZoomPrompt: 'Sollen wir zukünftig immer auf deine GPX-Route zoomen?',
  showGpxRoutes: 'GPX-Route anzeigen',
  expenseExport: 'Reisekosten exportieren',
  packingLists: {
    useThisList: 'Diese Liste verwenden',
    title: 'Listen',
    create: 'Liste erstellen',
    add: 'Liste hinzufügen',
    delete: 'Liste löschen',
    deleteSection: 'Kategorie löschen',
    deleteSectionPrompt: 'Möchtest du diese Kategorie löschen?',
    myPackingLists: 'Meine Listen',
    templatesFromLambus: 'Vorlagen von Lambus',
    createNewList: 'Neue Liste erstellen',
    myTemplates: 'Meine Vorlagen',
    createNewTemplate: 'Neue Vorlage erstellen',
    templateName: 'Vorlagenname',
    orCreateNew: 'Oder Neue erstellen',
  },
  createYourOwn: 'Eigene erstellen',
  addCategory: 'Kategorie hinzufügen',
  new: 'neu',
  soonUpper: 'BALD',
  gpsBasedCurrencies: {
    title: 'Finde Währungen automatisch',
    info: 'Möchtest du die Währung automatisch erkennen lassen? Erlaube uns in den Browser-Einstellungen deine aktuellen Standort kurzzeitig zu nutzen.',
  },
  packingListItemCount:
    'Keine Gegenstände | {checkedCount}/{itemCount} Gegenstand | {checkedCount}/{itemCount} Gegenstände',
  items: 'Keine Gegenstände | Ein Gegenstand | {count} Gegenstände',
  lists: 'Listen',
  rename: 'Umbenennen',
  visibility: 'Sichtbarkeit',
  gpx: {
    files: 'GPX-Dateien',
    description:
      'Deine Reise hat eine einzigartige E-Mail-Adresse, an die du deine .gpx-Dateien weiterleiten kannst. Sobald deine .gpx-Datei über die E-Mail-Adresse oben importiert ist, wird sie auf deine Karten von Stopps eingezeichnet.',
    visibility: {
      always: 'Ja',
      never: 'Nein',
      askLater: 'Später entscheiden',
    },
  },
  savedPlaces: 'Gespeicherte Orte & Aktivitäten',
  recommendedPlaces: 'Empfohlene Orte',
  pleaseEnterThePDFPassword: 'Bitte gib das Passwort der PDF-Datei ein:',
  cannotOpenPDF:
    'PDF-Datei konnte nicht geöffnet werden. Bitte melde dich bei unserem Support!',
  routeTo: 'Route nach',
  dailyPlan: 'Tagesplan',
  daily_plan: 'Tagesplan',
  openRoute: 'Verbindung öffnen',
  manageTransportations: 'Verwalte deine Flüge, Züge, Busse, etc.',
  showAll: 'Alle anzeigen',
  onboarding: {
    youHaveBeenInvited: 'Du wurdest eingeladen?',
    findInspirations: 'Finde Inspirationen',
    howDoYouWantToStart: 'Bitte wähle aus, wie du starten möchtest!',
    allInOneSingleApp:
      'Plane, buche verwalte deine gesamte Reise in einer App!',
    alreadyKnowWhereToGo: 'Du weißt bereits, wo es hingehen soll?',
    notSureWhereToGoYet: 'Noch unsicher, wo es hingehen soll?',
    pleaseEnterThePinCodeBelow: 'Bitte gib den PIN-Code unten ein!',
    youDontHaveAnyCode: 'Du hast keinen PIN-Code?',
    emailInviteNote:
      'Du kannst auch per E-Mail eingeladen werden. Möchtest du jetzt einen Account mit deiner E-Mail-Adresse erstellen?',
    almostThereExclamation: 'Fast geschafft!',
    registerToJoinTripText:
      'Um der Reise beizutreten, benötigst du einen Lambus-Account. Keine Sorge, wir verkaufen keine Daten und nerven dich nicht 🙂',
    registerToCreateTripText:
      'Um die Reise zu speichern, benötigst du einen Lambus-Account. Keine Sorge, wir verkaufen keine Daten und nerven dich nicht 🙂',
    keywords: 'Stichwörter',
    category: 'Kategorien',
    hashtag: 'Hashtags',
    letsGo: 'Los gehts!',
    youAlreadyHaveAnAccount: 'Du hast bereits einen Account?',
    searchHint: {
      keywords: 'Nach Inspirationen suchen …',
      category: 'Nach Kategorien suchen …',
      hashtag: 'Nach Hashtags suchen …',
    },
    selectedInspiration: 'Ausgewählte Inspiration',
    newTrip: 'Deine neue Reise',
  },
  enterNewName: 'Bitte gib den neuen Namen deines Stopps ein',
  unnamedLocation: 'Unbenannter Ort',
  dateFormat: 'Datumsformat',
  systemFormat: 'System',
  callVerb: 'Anrufen',
  visitWebsite: 'Webseite besuchen',
  savePlace: 'Ort speichern',
  tapToAddSomething: 'Tippen, um etwas hinzuzufügen',
  nothingPlanedYet: 'Noch nichts geplant',
  noAccommodationAddedYet: 'Keine Unterkünfte hinzugefügt',
  tapToAddAnAccommodation: 'Tippen, um eine neue Unterkunft hinzuzufügen',
  noDateSet: 'Kein Datum gesetzt',
  planYourDaysPerWaypoint: 'Plane deine Tage pro Stopp',
  specifyDate: 'Zeitraum angeben',
  linkToWaypoint: 'Mit Stopp verknüpfen',
  unlinkFromWaypoint: 'Verknüpfung aufheben',
  searchStopsPlaceholder: 'Stopp suchen ...',
  findRentalCars: 'Mietwagen finden',
  egStartFrom: 'Ab {waypoint} starten',
  mobility: 'Mobilität',
  directions: 'Routenplanung',
  saveTransportationBeforeApplyingRoute:
    'Bitte speichere dein Transportmittel, um Routen zu berechnen, danke!',
  colors: 'Farben',
  moreInfo: 'Mehr Infos',
  optional: 'optional',
  travelers: 'Mitreisende',
  pleaseEnterEitherExistingOrInvite:
    'Bitte gib den Nutzernamen oder die E-Mail-Adresse der Person ein, die du einladen möchtest.',
  ifNoAccountReminderSent:
    'Wenn der Gast noch keinen Account besitzt, wird eine Einladung gesendet.',
  statistics: 'Statistik',
  expensesCurrencyNotEditable:
    'Die Währung deiner Reise kann nicht geändert werden, wenn bereits Reisekosten angelegt wurden.',
  expensesBillingIntroTitle: 'Über die Abrechnung in Lambus',
  expensesBillingIntroSubtitle:
    'Wir minimieren die Anzahl der offenen Beträge, indem wir sie automatisch miteinander verrechnen. Beispiel: Du schuldest {person1} 10 € und {person2} schuldet {person3} 10 €, also zahlst du {person4} 10 € und ihr seid quitt! 🙂',
  shareTrip: 'Reise teilen',
  linkToYourMap: 'Link zu deiner Karte',
  shareTripInfo:
    'Du kannst deine Reise mit anderen teilen, die nicht mit dir unterwegs sind. Wenn du andere Reisende zu deiner Reise einladen möchtest, tippe stattdessen',
  activateIFrameInfo:
    'Aktivieren, um die Karte deiner Reise in deinen Blog oder deiner persönlichen Webseite einzubinden (z.B Wordpress, Notion oder Medium)',
  embedAsIFrame: 'Als <iframe> einbetten',
  here: 'hier',
  accommodation: 'Unterkünfte',
  addAccommodation: 'Unterkunft hinzufügen',
  searchAddress: 'Adresse suchen (Hotel, Airbnb, …)',
  addCheckInCheckOut: 'Check-In / Check-Out hinzufügen',
  plusBookingDocument: '+ Buchungs-Dokument',
  myAccommodation: 'Meine Unterkunft',
  deleteAccommodation: 'Unterkunft löschen',
  deleteAccommodationDescription:
    'Bist du sicher, dass du diese Unterkunft löschen möchtest?',
  changeAddress: 'Adresse ändern',
  copyAddress: 'Adresse kopieren',
  copy: 'Kopieren',
  openWaypoint: 'Stopp öffnen',
  theAddressWasCopiedSuccessfully:
    'Die Adresse wurde in deine Zwischenablage kopiert!',
  notLinkedYet: 'Noch nicht verknüpft',
  linkedWaypoints: 'Verknüpfte Stopps',
  addTime: 'Zeit hinzufügen',
  addedToDate: 'Zum {date} hinzugefügt',
  starts: 'Startet',
  ends: 'Endet',
  linkedDays: 'Verknüpfter Tag | Verknüpfte Tage',
  sortBy: 'Sortieren nach …',
  displayAs: 'Als {mode} anzeigen',
  grid: 'Gitter',
  list: 'Liste',
  name: 'Name',
  fileType: 'Art',
  confirmNoteDiscard:
    'Du hast ungespeicherte Änderungen in deiner Notiz. Möchtest du diese wirklich verwerfen?',
  manageFeatures: 'Features verwalten',
  whichDataShouldBeExported: 'Welche Daten möchtest du exportieren? 😇',
  selectAll: 'Alles auswählen',
  deselectAll: 'Alles abwählen',
  fileSection: {
    document: {
      new: 'Neues Dokument hochladen',
      link: 'Dokument auswählen',
      emptyState: {
        title: 'Keine Dokumente hinzugefügt',
        subtitle: 'Tippe, um deine Reisedokumente hinzuzufügen',
      },
    },
    photo: {
      new: 'Neues Foto hochladen',
      link: 'Foto auswählen',
      emptyState: {
        title: 'Keine Fotos hinzugefügt',
        subtitle: 'Tippe, um deine Erinnerungen zu speichern',
      },
    },
  },
  noteSection: {
    emptyState: {
      title: 'Keine Notizen hinzugefügt',
      subtitle: 'Tippe, um deine Gedanken, Links, Tipps, etc. zu sammeln',
    },
  },
  addADate: 'Datum hinzufügen',
  addDate: '+ Datum hinzufügen',
  addANote: 'Notiz hinzufügen',
  addNotePlaceholder: 'Füge deine Notiz hinzu...',
  address: 'Adresse',
  plannedFor: 'Geplant für',
  collectThoughtsLinksTips: 'Sammle Gedanken, Links, Tipps, etc.',
  selectPosition: 'Position auswählen',
  insertHere: 'Hier einfügen',
  appendToEnd: 'Am Ende einfügen',
  appendToStart: 'Am Anfang einfügen',
  appendAfter: 'Nach "{waypoint}" einfügen',
  aSmallNote: 'Ein kleiner Hinweis',
  shiftDatesAsk:
    'Du hast das Datum deiner Reise geändert. Möchtest du, dass dein Reiseplan auch automatisch um {days} Tag(e) verschoben wird?',
  shiftDates: 'Stopps anpassen',
  shiftDatesDiscard: 'Stopps nicht anpassen',
  addActivity: 'Aktivität hinzufügen',
  deleteActivity: 'Aktivität löschen',
  deleteActivityDescription:
    'Bist du sicher, dass du diese Aktivität löschen möchtest?',
  addMoreInformationPlaceholder: 'Füge deine weiteren Informationen hinzu…',
  unlinkWaypointDayItem: 'Element aus Tagesplan löschen',
  unlinkWaypointDayItemDescription:
    'Bist du sicher, dass du das Element aus dem Tagesplan löschen möchtest?',
  noAddress: 'Keine Adresse',
  noResultsFound: 'Keine Ergebnisse gefunden',
  couponCodeMessage:
    'Läute das Jahr 2024 mit Abenteuern ein! Sichere dir 25% auf Lambus Pro mit:',
  searchForCurrencies: 'Währungen suchen ...',
  sortExpensesBy: 'Ausgaben sortieren nach …',
  expenseCreated: 'Ausgabe erstellt',
  expenseDate: 'Datum der Ausgabe',
  canoe_tour: 'Kanutour',
  camping: 'Camping',
  beach: 'Strand',
  swimming: 'Schwimmen',
  boat_tour: 'Bootstour',
  campfire: 'Lagerfeuer',
  party: 'Party',
  bike_tour: 'Fahrradtour',
  surfing: 'Surfen',
  snorkeling: 'Schnorcheln',
  picknick: 'Picknick',
  food: 'Essen',
  horse_riding: 'Reiten',
  sport_event: 'Sportveranstaltung',
  concert: 'Konzert',
  cinema: 'Kino',
  winter_sports: 'Wintersport',
  searchActivitiesAndPlaces: 'Suche deine gespeicherten Orten und Aktivitäten',
  signUp: {
    cta: 'Noch nicht bei Lambus?',
    action: 'Account erstellen',
    businessCta: 'Sind Sie ein Unternehmen?',
    title: 'Willkommen bei Lambus!',
    titleActionNewTrip: 'Registriere dich, um  deine Reise zu speichern!',
    titleActionJoinTrip: 'Registriere dich, um der Reise beizutreten!',
    titleActionInspiration:
      'Registriere dich, um die Inspiration zu speichern!',
  },
  signIn: {
    cta: 'Du hast schon einen Acccount?',
    action: 'Anmelden',
    title: 'Willkommen bei Lambus!',
    titleActionNewTrip: 'Melde dich an, um  deine Reise zu speichern!',
    titleActionJoinTrip: 'Melde dich an, um der Reise beizutreten!',
    titleActionInspiration: 'Melde dich an, um die Inspiration zu speichern!',
  },
  signUpTitle: 'Account erstellen',
  orContinueWith: 'Oder fortfahren mit',
  autosavingNote: 'Notiz wird gespeichert ...',
  lastSaved: 'Zuletzt gespeichert',
  justNow: 'Gerade eben',
  deletePackingList: 'Packliste löschen',
  deletePackingListDescription:
    'Bist du sicher, dass du diese Packliste löschen möchtest?',
  sidebar: {
    close: 'Seitenleiste schließen',
    collapse: 'Seitenleiste einklappen',
    expand: 'Seitenleiste ausklappen',
    tripSection: {
      planning: 'Reiseplanung',
      general: 'Allgemein',
      travelers: 'Mitreisende',
    },
  },
  startDate: 'Startdatum',
  notSaved: 'Nicht gespeichert',
  reset: 'Zurücksetzen',
  updates: 'Updates',
  markAllAsRead: 'Alle als gelesen markieren',
  markAsRead: 'Als gelesen markieren',
  hide: 'Ausblenden',
  noNewNotifications: 'Keine neuen Benachrichtigungen',
  youAreUpToDate: 'Du bist auf dem neuesten Stand!',
}
