import { set } from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { cloneDeep } from 'lodash'

import { waypointModule } from '@/store'
import { Document } from '@/types/document'
import {
  downloadFile,
  isBrowser,
  isValidGUID,
  nameBasedSorting,
  sortByCreatedAt,
} from '@/utils/utility-manager'
import { DocumentSortMode, GridContentType } from '@/utils/enums'
import { i18n } from '@/plugins/i18n'

@Module({ name: 'document', stateFactory: true, namespaced: true })
export default class DocumentModule extends VuexModule {
  private allDocuments: Document[] = []
  private currentDocument: Document | null = null
  private currentDirectoryId: string | null = null
  private currentSortingMode = DocumentSortMode.BY_DATE

  public get file() {
    return this.currentDocument
  }

  public get files(): Document[] {
    const files = cloneDeep(this.allDocuments)
    if (this.sortingMode === DocumentSortMode.BY_DATE) {
      files.sort((a, b) => sortByCreatedAt(a, b))
    } else if (this.sortingMode === DocumentSortMode.BY_NAME) {
      files.sort((a, b) => {
        const name1 =
          a.originName || a.url?.substring(a.url.lastIndexOf('/') + 1) || ''
        const name2 =
          b.originName || b.url?.substring(b.url.lastIndexOf('/') + 1) || ''

        const value1 = isValidGUID(name1)
          ? i18n.t('untitled').toString()
          : name1
        const value2 = isValidGUID(name2)
          ? i18n.t('untitled').toString()
          : name2

        return nameBasedSorting(value1, value2)
      })
    } else if (this.sortingMode === DocumentSortMode.BY_FILE_TYPE) {
      files.sort((a, b) => {
        if (a.type && b.type) {
          return nameBasedSorting(a.type, b.type)
        }

        return -1
      })
    }

    return files
  }

  public get currentDirectory() {
    return this.currentDirectoryId
  }

  public get filesByWaypoint() {
    if (!waypointModule.waypoint) {
      return []
    }

    return this.allDocuments.filter(
      (document) =>
        document.reference &&
        document.reference === waypointModule.waypoint?._id,
    )
  }

  public get sortingMode(): string {
    return this.currentSortingMode
  }

  @Mutation
  private setDocument(payload: Document) {
    set(this, 'currentDocument', payload)
  }

  @Mutation
  private setParentDirectoryId(payload: {
    documentId: string
    newParentDirectoryId: string | null
  }) {
    const documentIndex = this.allDocuments.findIndex(
      (file) => file._id === payload.documentId,
    )

    if (documentIndex > -1) {
      set(
        this.allDocuments[documentIndex],
        'parentDirectoryId',
        payload.newParentDirectoryId,
      )
    }
  }

  @Mutation
  private updateDocument(document: Document) {
    const documentIndex = this.allDocuments.findIndex(
      (file) => file._id === document._id,
    )

    if (documentIndex > -1) {
      this.allDocuments.splice(documentIndex, 1, document)
    }
  }

  @Mutation
  private removeDocument(fileId: string) {
    const documentIndex = this.allDocuments.findIndex(
      (file) => file._id === fileId,
    )

    if (documentIndex > -1) {
      this.allDocuments.splice(documentIndex, 1)
    }
  }

  @Mutation
  private setDocuments(payload: Document[]) {
    set(this, 'allDocuments', [...payload])
  }

  @Mutation
  private setDirectory(payload: string | null) {
    set(this, 'currentDirectoryId', payload)
  }

  @Mutation
  private addDocument(document: Document) {
    this.allDocuments.push(document)
  }

  @Mutation
  public setSortingMode(mode: string) {
    set(this, 'currentSortingMode', mode)

    if (isBrowser()) {
      localStorage.setItem(
        `kLambusPreferredDocumentSortMode_${GridContentType.DOCUMENT}`,
        mode,
      )
    }
  }

  @Action({ rawError: true })
  public async fetch({ id, fileId }: any) {
    try {
      const { data } = await this.$axios.get(`/api/trips/${id}/documents`)
      this.context.commit(
        'setDocument',
        data.documents.find((el: Document) => el._id === fileId),
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.document.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchByTrip(id: string) {
    try {
      const { data } = await this.$axios.get(`/api/trips/${id}/documents`)
      this.context.commit('setDocuments', data.documents)
    } catch (error) {
      this.changeDir()
      this.context.dispatch('event/newError', 'error.document.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async create({ id, params, showSuccessMessage = true }: any) {
    try {
      const { data } = await this.$axios.post(
        `/api/trips/${id}/documents`,
        params,
      )
      this.context.commit('addDocument', data.document)
      this.context.commit('setDocument', data.document)

      if (showSuccessMessage) {
        this.context.dispatch('event/newMessage', 'success.document.create', {
          root: true,
        })
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.document.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async createNewFolder({ id, params }: any) {
    try {
      const { data } = await this.$axios.post(
        `/api/trips/${id}/documents`,
        params,
      )
      this.context.commit('setDocument', data.document)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.directory_create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async update({ id, fileId, params }: any) {
    try {
      const { data } = await this.$axios.put(
        `/api/trips/${id}/documents/${fileId}`,
        params,
      )
      this.context.commit('updateDocument', data.document)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.document.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async move({ id, params }: any) {
    try {
      await this.$axios.put(`/api/trips/${id}/documents/move/bulk`, params)

      for (const documentId of params.documentIds) {
        this.context.commit('setParentDirectoryId', {
          documentId,
          newParentDirectoryId: params.parentDirectoryId?._id || null,
        })
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.document.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async delete({ id, fileId }: any) {
    try {
      await this.$axios.delete(`/api/trips/${id}/documents/${fileId}`)

      this.context.commit('removeDocument', fileId)

      if (this.files.length > 0) {
        this.context.commit('setDocument', this.files[0])
      } else {
        this.context.commit('setDocument', {})
      }
      this.context.dispatch('event/newMessage', 'success.document.delete', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.document.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public changeDir(id?: string) {
    id
      ? this.context.commit('setDirectory', id)
      : this.context.commit('setDirectory', null)
  }

  @Action({ rawError: true })
  public setDummy(dummy: Document) {
    this.context.commit('setDocument', dummy)
  }

  @Action({ rawError: true })
  public setFile(file: Document) {
    this.context.commit('setDocument', file)
  }

  @Action({ rawError: true })
  public download() {
    downloadFile(this.$axios, 'documents', this.currentDocument!)
  }
}
