import { User } from '@/types/user'

export enum ExpenseType {
  OWE = 'owe',
  RECEIVE = 'receive',
}

export interface Amount {
  _id: string
  userId: User
  amount: number
}
export interface ExpenseAccount {
  _id?: string
  userId: User
  type: ExpenseType
  amount: Amount[]
}
export interface ExpenseFraction {
  _id?: string
  amount: number
  userId: string
}

export interface ExpenseLabel {
  text: string
  color: string
}

export interface PaidExpense {
  _id: string
  from: User
  to: User
  amount: number
}

export interface Expense {
  _id?: string
  createdAt: Date | null
  createdBy: string
  currency: string
  category: string
  foreignCurrency?: string
  rate?: number
  from: ExpenseFraction[]
  to: ExpenseFraction[]
  isPrivate: boolean
  subject: string
  pictures?: string[]
  reference?: string
  onModel?: string
  created_at?: string
}

export interface FormattedExpense {
  _id: string
  amountLabel: ExpenseLabel
  amountFullLabel?: string
  subjectLabel: string
  dateLabel: string
  profileImage?: string
  category: string
  created_at?: string
}

export interface BillingPosition {
  expense: string
  stateLabel?: ExpenseLabel
  amountLabel: ExpenseLabel
  fromNameLabel: string
  fromPicture?: string
  toNameLabel: string
  toPicture?: string
  type?: string
}

export interface BillingUser {
  id: string
  name: string
  _id: string
  picture?: string
}

export interface OpenPosition {
  id: string
  type: string
  amount: string
  from: BillingUser
  to: BillingUser
  _id: string
}

export interface StatisticsExpense {
  _id: string
  subject: string
  category: string
  createdAt: Date | null
  isPrivate: boolean
  relatedAmount: number
  totalAmount: number
  profile: User
  currency: string
  rate: number
}

/* eslint-disable camelcase */
export interface Currency {
  symbol: string
  name: string
  symbol_native?: string
  decimal_digits?: 2
  code: string
  name_plural?: string
}
/* eslint-enable camelcase */

export interface TripSummaryUserBalance {
  amount: string
  currency: string
}

export interface TripSummaryBilling extends OpenPosition {
  currency: string
}

export interface TripSummaryExpense {
  userBalance: TripSummaryUserBalance
  billing: TripSummaryBilling[]
}
