
import { Component, Watch, Vue } from 'vue-property-decorator'

import LDialog from '@/global-components/LDialog.vue'
import RouteToProButton from '@/components/pro/RouteToProButton.vue'
import { eventModule } from '@/store'

@Component({
  name: 'ProHintModal',
  components: {
    RouteToProButton,
  },
})
class ProHintModal extends Vue {
  get showProHintModal(): boolean {
    return eventModule.proHintModal
  }

  @Watch('showProHintModal')
  toggleshowProHintModal(): void {
    if (!this.showProHintModal) {
      return
    }
    this.toggleDialog()
  }

  toggleDialog(): void {
    const dialog = this.$refs.proHintModal as LDialog
    dialog.toggleDialog()
  }
}
export default ProHintModal
