
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { tripModule } from '@/store'
import { Accommodation } from '@/types/accommodation'
import { Trip } from '@/types/trip'
import { circlePath, openInGoogleMaps } from '@/utils/utility-manager'

declare let google: any

@Component({
  name: 'AccommodationMap',
})
class AccommodationMap extends Vue {
  @Prop() readonly accommodation?: Accommodation

  map: any = null
  accommodationMarker: any = null

  mapOptions = {
    styles: require('~/assets/mapstyle/mapstyle.json'),
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    draggableCursor: 'pointer',
    disableDefaultUI: true,
    gestureHandling: 'none',
  }

  get trip(): Trip | null {
    return tripModule.trip
  }

  @Watch('accommodation')
  updateMarker(): void {
    if (this.accommodationMarker) {
      this.accommodationMarker?.setMap(null)
    }
    this.initMarker()
  }

  async mounted() {
    await this.initMap()
    this.initMarker()
  }

  initMarker(): void {
    if (!this.accommodation || !this.accommodation.location) {
      return
    }

    this.accommodationMarker = new google.maps.Marker({
      position: {
        lat: this.accommodation.location.latitude,
        lng: this.accommodation.location.longitude,
      },
      icon: {
        labelOrigin: new google.maps.Point(0, 0),
        path: circlePath,
        fillColor: this.$vuetify.theme.currentTheme.accommodationRed,
        strokeColor: this.$vuetify.theme.currentTheme.accommodationRed,
        fillOpacity: 1,
      },
      label: {
        text: '',
        className: 'accommodation-marker-label',
      },
      map: this.map,
    })

    this.map.setZoom(11)
    this.map.setCenter(
      new google.maps.LatLng(
        this.accommodation.location.latitude,
        this.accommodation.location.longitude,
      ),
    )
  }

  initMap(): void {
    this.map = new google.maps.Map(
      document.getElementById('accommodationMap'),
      {
        ref: 'accommodationMap',
        zoom: 3,
        center: { lat: 0, lng: 0 },
        options: this.mapOptions,
      },
    )
  }

  openGoogleMaps(): void {
    if (!this.accommodation) {
      return
    }

    openInGoogleMaps(this.accommodation.location)
  }
}
export default AccommodationMap
