import axios from 'axios'
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/vue-query'
import { computed, unref } from 'vue'
import { MaybeRef } from '@vueuse/core'

import { useContext } from '@/composables/context'
import { eventModule, tripModule } from '@/store'
import {
  TripSummary,
  Trip,
  ValidatePinCodeResponse,
  CreateTripResponse,
  JoinTripResponse,
} from '@/types/trip.js'
import { isBrowser, logEvent } from '@/utils/utility-manager'
import { PendingTrip } from '@/types/onboarding'

export interface UseTripListOptions {
  page: MaybeRef<number>
  search: MaybeRef<string>
  filters?: MaybeRef<Record<string, string[]>>
}

export const tripQueryKeys = {
  all: () => ['trips'] as const,
  lists: () => [...tripQueryKeys.all(), 'list'] as const,
  list: () => [...tripQueryKeys.lists()] as const,
  listAdvanced: (options: UseTripListOptions) =>
    [...tripQueryKeys.lists(), 'advanced', options] as const,
  archive: () => [...tripQueryKeys.all(), 'archive'] as const,
  summaries: () => [...tripQueryKeys.all(), 'summary'] as const,
  summary: (tripId: MaybeRef<string>) =>
    [...tripQueryKeys.summaries(), tripId] as const,
}

export function useAdvancedTripList(options: UseTripListOptions) {
  const { page, search } = options

  const { $axios } = useContext()
  return useQuery({
    queryKey: tripQueryKeys.listAdvanced(options),
    queryFn: async () => {
      const params: Record<string, string | number> = {
        page: unref(page),
        query: unref(search),
      }

      const rawFilters = unref(options.filters)

      if (rawFilters?.date && rawFilters.date.length > 0) {
        params.date = rawFilters.date.join(',')
      }

      const { data } = await $axios.get(`/api/trips/advanced`, {
        params,
      })
      return data
    },
    keepPreviousData: true,
  })
}

export function useCreateTrip() {
  const { $axios } = useContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (newTrip: PendingTrip) => {
      const { data } = await $axios.post<CreateTripResponse>('/api/trips', {
        ...newTrip,
      })

      if (isBrowser()) {
        logEvent('created_trip')
      }
      return data
    },
    onSuccess: () => {
      eventModule.newMessage('success.trip.create')
      queryClient.invalidateQueries({ queryKey: tripQueryKeys.lists() })
    },
    onError: () => {
      eventModule.newError('error.trip.create')
    },
  })
}

export function useDeleteTrip() {
  const { $axios } = useContext()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (tripId: string) => {
      await $axios.delete(`/api/trips/${tripId}`)
    },
    onSuccess: async () => {
      eventModule.newMessage('success.trip.delete')
      await queryClient.invalidateQueries({ queryKey: tripQueryKeys.all() })
    },
  })
}

export function useTripSummary(tripId: string) {
  const { $axios } = useContext()
  return useQuery({
    queryKey: tripQueryKeys.summary(tripId),
    queryFn: async () => {
      const {
        data: { summary },
      } = await $axios.get<{ summary: TripSummary }>(
        `/api/trips/${tripId}/summary`,
      )
      return summary
    },
  })
}

export function useTripList(enabled: MaybeRef<boolean> = true) {
  const { $axios } = useContext()

  return useQuery({
    queryKey: tripQueryKeys.lists(),
    queryFn: async () => {
      const {
        data: { trips, hasArchive },
      } = await $axios.get<{ trips: Trip[]; hasArchive: boolean }>(
        `/api/trips`,
        {},
      )
      tripModule.updateHasArchive(hasArchive)
      return trips
    },
    enabled: unref(enabled),
  })
}

export interface TripArchiveResponse {
  hasArchive: boolean
  trips: Trip[]
}

export function useTripArchiveList(enabled: MaybeRef<boolean> = true) {
  const { $axios } = useContext()

  return useQuery({
    queryKey: tripQueryKeys.archive(),
    queryFn: async () => {
      const {
        data: { trips },
      } = await $axios.get<TripArchiveResponse>(`/api/trips/archive`, {})
      return trips
    },
    enabled: unref(enabled),
  })
}

export function useJoinTrip() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (pinCode: string) => {
      const { data } = await $axios.put<JoinTripResponse>(`/api/joinTrip`, {
        pinCode,
      })

      return data
    },
    onSuccess() {
      logEvent('joined_trip')
      logEvent('joined_trip_code')
    },
    onError() {
      eventModule.newError('error.trip.join')
    },
  })
}

export interface UseTripDetailsOptions {
  pinCode: MaybeRef<string>
  queryOptions?: UseQueryOptions<boolean>
}

export function useValidatePinCode() {
  const { $axios } = useContext()
  const validatePinCode = async (pinCode: string) => {
    try {
      const { data } = await $axios.get<ValidatePinCodeResponse>(
        `/api/joinTrip/${unref(pinCode)}/validate`,
      )
      return 'available' in data
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        eventModule.newError('error.generic')
        return
      }
      const errorCode = error.response?.data.code ?? -1

      if (errorCode === 11011201 || errorCode === 11011102) {
        eventModule.newError('error.trip.validatePinCode')
      } else {
        eventModule.newError('error.generic')
      }
    }
  }
  return {
    validatePinCode,
  }
}

export function useSelectedTrip() {
  const trip = computed(() => {
    if (!tripModule.trip) {
      throw new Error('No trip selected')
    }
    return tripModule.trip
  })
  return trip
}
