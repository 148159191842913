// Credits: https://github.com/quilljs/quill/issues/1225

import Delta, { Op } from 'quill-delta'

type ClipboardMatcherCallback = (node: any, delta: Delta) => Delta
type ClipboardMatcherNode = string | number

export const MSWORD_MATCHERS: Array<
  [ClipboardMatcherNode, ClipboardMatcherCallback]
> = [
  ['p.MsoListParagraphCxSpFirst', matchMsWordList],
  ['p.MsoListParagraphCxSpMiddle', matchMsWordList],
  ['p.MsoListParagraphCxSpLast', matchMsWordList],
  ['p.MsoListParagraph', matchMsWordList],
  ['p.msolistparagraph', matchMsWordList],
  ['p.MsoNormal', maybeMatchMsWordList],
]

export function matchMsWordList(node: any, delta: Delta) {
  // Clone the operations
  const ops = delta.ops.map((op: Op) => Object.assign({}, op))

  // Trim the front of the first op to remove the bullet/number
  const bulletOp = ops.find(
    (op: Op) => typeof op.insert === 'string' && op.insert.trim().length,
  )
  if (!bulletOp) {
    return delta
  }

  bulletOp.insert =
    typeof bulletOp.insert === 'string' ? bulletOp.insert.trimStart() : ''
  const listPrefix = bulletOp.insert.match(/^.*?(^·|\.)/) || bulletOp.insert[0]
  bulletOp.insert = bulletOp.insert
    .substring(listPrefix[0].length, bulletOp.insert.length)
    .trimStart()

  // remove the op with only spaces between bullet/number and text
  if (typeof ops[1].insert === 'string' && !ops[1].insert.trim()) {
    ops[1].insert = ops[1].insert.trim()
  }

  // Trim the newline off the last op
  const last = ops[ops.length - 1]
  if (typeof last.insert === 'string') {
    last.insert = last.insert.substring(0, last.insert.length - 1)
  }

  // Determine the list type
  const listType = listPrefix[0].length === 1 ? 'bullet' : 'ordered'

  // Determine the list indent
  const style = node.getAttribute('style').replace(/\n+/g, '')
  const levelMatch = style.match(/level(\d+)/)
  const indent = levelMatch ? levelMatch[1] - 1 : 0

  // Add the list attribute
  ops.push({ insert: '\n', attributes: { list: listType, indent } })

  return new Delta(ops)
}

export function maybeMatchMsWordList(node: any, delta: Delta) {
  if (
    typeof delta.ops[0].insert === 'string' &&
    delta.ops[0].insert.trimStart()[0] === '·'
  ) {
    return matchMsWordList(node, delta)
  }

  return delta
}
