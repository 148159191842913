
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SeparatedDoubleSlot from '@/components/trip/transportation/forms/generic/SeparatedDoubleSlot.vue'
import { tripModule } from '@/store'
import { Trip } from '@/types/trip'
import {
  formattedDate,
  formattedDateRange,
  timePickerFormat,
} from '@/utils/date-utils'
import { DatePickerType } from '@/utils/enums'

@Component({
  name: 'DateTimeField',
  components: {
    SeparatedDoubleSlot,
  },
})
class DateTimeField extends Vue {
  @Prop({ default: true, type: Boolean }) readonly range!: boolean
  @Prop({ default: false, type: Boolean }) readonly hideTimePicker!: boolean
  showDatePicker = false
  showTimePicker = false

  pickerDates: string[] = []
  pickerDate = ''
  pickerTime = ''

  get trip(): Trip | null {
    return tripModule.trip
  }

  get showDatePickerModal(): boolean {
    return this.showDatePicker || this.showTimePicker
  }

  get hasDateSet(): boolean {
    return (
      (!this.range && this.pickerDate.trim().length > 0) ||
      this.pickerDates.length > 0
    )
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  get dateFieldClass(): string {
    if (this.hideTimePicker) {
      return 'rounded-lg'
    }

    return this.isMobile
      ? 'rounded-b-0 rounded-t-lg'
      : 'rounded-r-0 rounded-l-lg'
  }

  get datePickerValue(): string | string[] {
    return this.range ? this.pickerDates : this.pickerDate
  }

  set datePickerValue(value: string | string[]) {
    if (this.range) {
      Vue.set(this, 'pickerDates', value)
    } else {
      Vue.set(this, 'pickerDate', value)
    }
  }

  get formattedDate(): string {
    if (this.range) {
      return formattedDateRange(this.pickerDates, DatePickerType.SPECIFIC)
    } else if (this.pickerDate.trim().length >= 10) {
      return formattedDate(this.pickerDate)
    }

    return ''
  }

  get timePickerFormat(): string {
    return timePickerFormat()
  }

  @Watch('showDatePicker')
  resetPickerDate(): void {
    if (!this.range && this.pickerDate.trim().length === 0) {
      this.pickerDate = this.trip?.startDate?.value || ''
      return
    }

    if (this.pickerDates.length === 0) {
      if (this.trip?.startDate?.value) {
        this.pickerDates.push(this.trip.startDate.value)
      }

      if (this.trip?.endDate?.value) {
        this.pickerDates.push(this.trip.endDate.value)
      }
    }
  }

  public clearPickerDate(): void {
    if (!this.range) {
      this.pickerDate = ''
    } else {
      this.pickerDates = []
    }
  }

  hideDatePickerModal(): void {
    this.showDatePicker = false
    this.showTimePicker = false
  }

  syncPickerValues(): void {
    if (this.range) {
      this.$emit('update:dates', this.pickerDates)
    } else {
      this.$emit('update:date', this.pickerDate)
    }
    this.$emit('update:time', this.pickerTime)
    this.hideDatePickerModal()
  }

  resetPickerValues(): void {
    this.pickerDates = []
    this.pickerTime = ''
  }
}
export default DateTimeField
