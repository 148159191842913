
import { Component, Vue } from 'vue-property-decorator'

import Navbar from '@/components/navbar/Navbar.vue'
import DiscoverSearchModal from '@/components/onboarding/discover/DiscoverSearchModal.vue'

@Component({
  name: 'DiscoverLayout',
  components: {
    Navbar,
    DiscoverSearchModal,
  },
})
class DiscoverLayout extends Vue {
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  }
}
export default DiscoverLayout
