
import { Component, Vue, Watch } from 'vue-property-decorator'

import SlideOver from '@/components/core/SlideOver.vue'
import TripSidebar from '@/components/trip/sidebar/TripSidebar.vue'
import TripSidebarMobile from '@/components/trip/sidebar/TripSidebarMobile.vue'
import Navbar from '@/components/navbar/Navbar.vue'
import MessageAlert from '@/components/MessageAlert.vue'
import NotificationAlert from '@/components/NotificationAlert.vue'
import ProHintModal from '@/components/pro/ProHintModal.vue'
import TripSettings from '@/components/trip/settings/TripSettings.vue'
import ExportStartedPrompt from '@/components/pro/export/ExportStartedPrompt.vue'
import SelectWaypointModal from '@/components/trip/map/SelectWaypointModal.vue'
import {
  eventModule,
  authModule,
  tripModule,
  documentModule,
  systemModule,
  transportationModule,
  routesModule,
} from '@/store'
import { TripRelation } from '@/utils/enums'
import {
  hasLocalStorageBoolean,
  isBrowser,
  setLocalStorageBoolean,
} from '@/utils/utility-manager'
import Intercom from '@/utils/intercom-manager'

interface MenuItem {
  icon: string
  title: string
  path: string
  isProFeature?: boolean
}

@Component({
  name: 'DefaultLayout',
  components: {
    SlideOver,
    TripSidebar,
    TripSidebarMobile,
    Navbar,
    TripSettings,
    MessageAlert,
    ProHintModal,
    ExportStartedPrompt,
    SelectWaypointModal,
  },
  middleware: ['magic-link', 'auth'],
})
class DefaultLayout extends Vue {
  activePusherChannel: string | null = null

  isSidebarExpanded = hasLocalStorageBoolean('kLambusSidebarExpanded')

  get expandSidebar() {
    return this.isSidebarExpanded
  }

  set expandSidebar(value: boolean) {
    setLocalStorageBoolean('kLambusSidebarExpanded', value)
    this.isSidebarExpanded = value
  }

  menuItems: MenuItem[] = [
    {
      icon: '$vuetify.icons.map',
      title: 'waypoints',
      path: 'waypoints-waypoints',
    },
    {
      icon: '$vuetify.icons.usd',
      title: 'expenses',
      path: 'expenses',
    },
    {
      icon: '$vuetify.icons.editDocument',
      title: 'notes',
      path: 'notes-notes',
    },
    {
      icon: '$vuetify.icons.packingList',
      title: 'packingLists.title',
      path: 'packing-lists',
      isProFeature: true,
    },
    {
      icon: '$vuetify.icons.document',
      title: 'documents',
      path: 'documents-file',
    },
    {
      icon: '$vuetify.icons.images',
      title: 'photos',
      path: 'photos-file',
    },
    {
      icon: '$vuetify.icons.chat',
      title: 'chat',
      path: 'chat',
    },
  ]

  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  }

  get showTripNavigation() {
    return this.$route.params.id
  }

  get trip() {
    return tripModule.trip!
  }

  get selectWaypointModal(): boolean {
    return eventModule.selectWaypointModal
  }

  get sidebarOffset() {
    if (!this.$route.matched.some(({ path }) => path.startsWith('/trip/:id'))) {
      return ''
    }

    if (this.$vuetify.breakpoint.mdAndUp && this.expandSidebar) {
      return 'pl-64'
    } else if (this.$vuetify.breakpoint.mdAndUp) {
      return 'pl-14'
    } else {
      return ''
    }
  }

  get settingsDialogWidth(): string | null {
    return this.$vuetify.breakpoint.lgAndUp
      ? '32vw'
      : this.$vuetify.breakpoint.mdAndUp
        ? '50vw'
        : 'auto'
  }

  get isTripRoute(): boolean {
    return this.$route.name === 'trip-id'
  }

  @Watch('selectWaypointModal')
  toggleSelectWaypointModal(): void {
    if (!this.selectWaypointModal) {
      return
    }
    const dialog = this.$refs.selectWaypointModal as SelectWaypointModal
    if (dialog) dialog.toggleDialog()
  }

  handleSocketMessage(data: any, trip: any) {
    if (
      data.type !== TripRelation.ADDED_CHAT_MESSAGE &&
      tripModule.trip?.id === trip.id
    ) {
      tripModule.fetch(trip.id!)
    }

    if (!Object.values(TripRelation).includes(data.type)) {
      return
    }

    const updatesWithoutNotification = [
      TripRelation.UPDATED_EXPENSE,
      TripRelation.UPDATED_LIVE_LOCATION,
      TripRelation.FORCE_RELOAD,
      TripRelation.ADDED_CHAT_MESSAGE,
    ] // A list of types that should not be displayed as a toast

    const isMe = data.relation?.subject?.identifier === authModule.user.id

    const subject = isMe
      ? this.$t('i')
      : data.relation.subject.name || this.$t('someone')

    const object = data.relation.object || this.$t('someone')

    const key =
      Object.keys(TripRelation)[Object.values(TripRelation).indexOf(data.type)]

    if (
      key.endsWith('_DOCUMENT') &&
      (this.$route.name === 'trip-id-documents-file' ||
        this.$route.name === 'trip-id')
    ) {
      // refetch documents if one was added and app is currently displaying documents
      documentModule.fetchByTrip(this.trip.id)
    }

    if (
      !isMe &&
      !updatesWithoutNotification.includes(data.type) &&
      this.$te(`socket.${key}`)
    ) {
      eventModule.newNotificationMessage(
        this.$t(`socket.${key}`, {
          subject,
          object,
        }) as string,
      )

      eventModule.newNotificationName(trip.name! as string)

      this.$toast(NotificationAlert, {
        timeout: 5000,
        toastClassName: 'notification-alert',
      })
    }

    if (data.type === TripRelation.UPDATED_BOOKING && this.trip) {
      transportationModule.fetchAllBookings(this.trip)
      routesModule.fetchAll()
    }
  }

  @Watch('trip')
  handleTripChanged(): void {
    if (this.trip && !this.activePusherChannel) {
      // If we have a trip, but not listing to messages, yet -> subscribe
      this.subscribeToChannel()
    } else if (!this.trip && this.activePusherChannel) {
      // If we have no trip anymore (e.g. trip closed), but listing to messages -> unsubscribe
      this.unsubscribeFromChannel()
    } else if (
      this.trip &&
      this.activePusherChannel &&
      this.activePusherChannel !== `private-${this.trip.id!}`
    ) {
      // If we have a trip and are already listening to messages (e.g. changed trip via navbar) -> unsubscribe from old trip + subscribe to new trip
      this.unsubscribeFromChannel()
      this.subscribeToChannel()
    }
  }

  mounted() {
    if (!authModule.loggedIn || !isBrowser()) return

    // Ping server
    systemModule.ping()

    // set up intercom
    const user = authModule.user
    const fullName = user.lastName
      ? `${user.firstName} ${user.lastName[0]}.`
      : user.firstName

    Intercom.launch({
      name: fullName,
      email: `${user.email}`,
      user_id: `${user.id}`,
      username: `${user.username}`,
      has_active_lambus_pro: `${user.hasActiveLambusPro}`,
      is_demo_account: `${user.isDemo}`,
      is_social_account: `${user.isSocial}`,
      created_at: `${user.createdAt}`,
      custom_launcher_selector: '#intercom_launcher',
      hide_default_launcher: true,
    })

    this.handleTripChanged()
  }

  beforeDestroy() {
    Intercom.shutdown()
  }

  isActiveNavLink(path: string): boolean {
    return this.$route.name?.includes(`trip-id-${path}`) || false
  }

  unsubscribeFromChannel(): void {
    if (!this.activePusherChannel) {
      return
    }

    this.$pusher.unsubscribe(this.activePusherChannel)
    this.$pusher.unbind('trip')

    this.activePusherChannel = null
  }

  subscribeToChannel(): void {
    this.activePusherChannel = `private-${this.trip.id!}`

    this.$pusher
      .subscribe(this.activePusherChannel)
      .bind('trip', (data: any) => {
        this.handleSocketMessage(data, this.trip)
      })
  }

  routeToItemPage(item: MenuItem): void {
    if (item.isProFeature && !this.trip.activeProSubscription) {
      eventModule.toggleProHintModal()
      return
    }

    this.$router.push({
      name: `trip-id-${item.path}`,
      params: { id: this.trip.id },
    })
  }
}

export default DefaultLayout
