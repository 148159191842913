import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { set } from 'vue'
import { i18n } from '@/plugins/i18n'

import { PackingList, PackingListTemplate } from '@/types/packingList'
import { authModule, tripModule } from '@/store'
import { findName } from '@/utils/utility-manager'
import { PackingListVisibility } from '@/utils/enums'
import { KVLiteral } from '@/types/types'

@Module({ name: 'packingList', stateFactory: true, namespaced: true })
export default class PackingListModule extends VuexModule {
  private allPackingListTemplates: PackingListTemplate[] = []
  private currentPackingListTemplate: PackingListTemplate | null = null

  private allUserPackingLists: PackingListTemplate[] = []
  private currentUserPackingList: PackingListTemplate | null = null

  private allTripPackingLists: PackingList[] = []
  private currentTripPackingList: PackingList | null = null

  private isUpdatingPackingList = false

  public get packingListTemplates(): PackingListTemplate[] {
    return this.allPackingListTemplates
  }

  public get packingListTemplate(): PackingListTemplate | null {
    return this.currentPackingListTemplate
  }

  public get tripPackingLists(): PackingList[] {
    return this.allTripPackingLists
  }

  public get tripPackingList(): PackingList | null {
    return this.currentTripPackingList
  }

  public get userPackingLists(): PackingListTemplate[] {
    return this.allUserPackingLists
  }

  public get userPackingList(): PackingListTemplate | null {
    return this.currentUserPackingList
  }

  public get userPackingListName(): string {
    if (!this.userPackingList) {
      return ''
    }

    return localizedName(this.userPackingList.name)
  }

  public get tripHasPackingList(): boolean {
    return (
      (this.tripPackingList &&
        this.tripPackingList.trip === tripModule.trip?.id &&
        this.tripPackingList.user === authModule.user.id) ||
      false
    )
  }

  public get isUpdating(): boolean {
    return this.isUpdatingPackingList
  }

  @Mutation
  private setAllPackingListTemplates(templates: PackingListTemplate[]): void {
    set(this, 'allPackingListTemplates', templates)
  }

  @Mutation
  private setCurrentPackingListTemplate(
    template: PackingListTemplate | null,
  ): void {
    set(this, 'currentPackingListTemplate', template)
  }

  @Mutation
  private addPackingListSection(): void {
    if (!this.currentTripPackingList) {
      return
    }

    this.currentTripPackingList.items.unshift({
      name: '',
      items: [],
    })
  }

  @Mutation
  private updatePackingListSection({ section, index }: any): void {
    if (!this.currentTripPackingList) {
      return
    }

    if (index < 0) {
      // new section
      this.currentTripPackingList.items.unshift(section)
    } else if (section.name.trim().length === 0) {
      // clearing a sections name shall delete it
      this.currentTripPackingList.items.splice(index, 1)
    } else {
      // update existing section
      this.currentTripPackingList.items.splice(index, 1, section)
    }
  }

  @Mutation
  private updateUserPackingListSection({ section, index }: any): void {
    if (!this.currentUserPackingList) {
      return
    }

    if (index < 0) {
      // new section
      this.currentUserPackingList.items.unshift(section)
    } else if (section.name.trim().length === 0) {
      // clearing a sections name shall delete it
      this.currentUserPackingList.items.splice(index, 1)
    } else {
      // update existing section
      this.currentUserPackingList.items.splice(index, 1, section)
    }
  }

  @Mutation
  private setCurrentTripPackingList(packingList: PackingList | null): void {
    set(this, 'currentTripPackingList', packingList)
  }

  @Mutation
  private addTripPackingList(packingList: PackingList): void {
    this.allTripPackingLists.push(packingList)
  }

  @Mutation
  public setAllTripPackingLists(payload: PackingList[]): void {
    set(this, 'allTripPackingLists', payload)
  }

  @Mutation
  private setCurrentUserPackingList(
    packingList: PackingListTemplate | null,
  ): void {
    set(this, 'currentUserPackingList', packingList)
  }

  @Mutation
  private setAllUserPackingLists(payload: PackingListTemplate[]): void {
    set(this, 'allUserPackingLists', payload)
  }

  @Mutation
  private toggleIsUpdatingPackingList(state?: boolean): void {
    set(this, 'isUpdatingPackingList', state || !this.isUpdatingPackingList)
  }

  @Action({ rawError: true })
  public setPackingListTemplate(
    packingListTemplate: PackingListTemplate | null,
  ): void {
    this.context.commit('setCurrentPackingListTemplate', packingListTemplate)
  }

  @Action({ rawError: true })
  public setPackingList(packingList: PackingList | null): void {
    this.context.commit('setCurrentTripPackingList', packingList)
  }

  @Action({ rawError: true })
  public setUserPackingList(packingList: PackingListTemplate | null): void {
    this.context.commit('setCurrentUserPackingList', packingList)
  }

  @Action({ rawError: true })
  public addNewSection(): void {
    this.context.commit('addPackingListSection')
  }

  @Action({ rawError: true })
  public updateSection({ section, index }: any): void {
    this.context.commit('updatePackingListSection', { section, index })
  }

  @Action({ rawError: true })
  public updateUserListSection({ section, index }: any): void {
    this.context.commit('updateUserPackingListSection', { section, index })
  }

  @Action({ rawError: true })
  public async fetchTripPackingLists(): Promise<void> {
    try {
      if (!tripModule.trip) {
        throw new Error('Invalid trip id')
      }
      const { data } = await this.$axios.get(
        `/api/trips/${tripModule.trip.id}/packing-lists`,
      )
      this.context.commit('setAllTripPackingLists', data.data.packingLists)
    } catch (error) {
      this.context.commit('event/newError', 'error.packingLists.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchUserPackingLists(): Promise<void> {
    try {
      const { data } = await this.$axios.get(
        `/api/users/${authModule.user.id}/packing-list-templates`,
      )
      this.context.commit(
        'setAllUserPackingLists',
        data.data.packingListTemplates,
      )
    } catch (error) {
      this.context.commit('event/newError', 'error.packingLists.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchTemplates(): Promise<void> {
    try {
      const { data } = await this.$axios.get('/api/packing-list-templates')
      this.context.commit(
        'setAllPackingListTemplates',
        data.data.packingListTemplates,
      )
    } catch (error) {
      this.context.commit(
        'event/newError',
        'error.packingLists.fetchTemplates',
        {
          root: true,
        },
      )
    }
  }

  /**
   * Clones a list template and returns id of new list
   */
  @Action({ rawError: true })
  public async cloneTemplate(): Promise<string | undefined> {
    try {
      if (!tripModule.trip || !this.packingListTemplate) {
        throw new Error('Invalid parameters')
      }

      const { data } = await this.$axios.post(
        `/api/trips/${tripModule.trip.id}/packing-lists/clone/${this.packingListTemplate._id}`,
        {
          name: localizedName(this.packingListTemplate.name),
        },
      )
      this.context.commit('setCurrentTripPackingList', data.data.packingList)
      this.context.commit('addTripPackingList', data.data.packingList)
      this.context.dispatch('event/newMessage', 'success.packingLists.create', {
        root: true,
      })

      return data.data.packingList._id
    } catch (error) {
      this.context.commit('event/newError', 'error.packingLists.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async create(payload?: PackingList): Promise<void> {
    try {
      if (!tripModule.trip) {
        throw new Error('Invalid parameters')
      }

      if (!payload && this.tripPackingList) {
        payload = {
          name:
            this.tripPackingList.name ||
            i18n.t('packingLists.title').toString(),
          items: this.tripPackingList.items,
          user: authModule.user.id!,
          visibility: PackingListVisibility.PRIVATE,
        }
      }

      const { data } = await this.$axios.post(
        `/api/trips/${tripModule.trip?.id}/packing-lists`,
        payload,
      )

      this.context.commit('setCurrentTripPackingList', data.data.packingList)
      this.context.dispatch('event/newMessage', 'success.packingLists.create', {
        root: true,
      })
    } catch {
      this.context.dispatch('event/newError', 'error.packingLists.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async createUserList(
    payload?: Partial<PackingListTemplate>,
  ): Promise<void> {
    try {
      if (!payload && this.userPackingList) {
        payload = {
          name: this.userPackingListName,
          items: this.userPackingList.items,
        }
      }

      const { data } = await this.$axios.post(
        `/api/users/${authModule.user.id}/packing-list-templates`,
        payload,
      )

      this.context.commit('setCurrentUserPackingList', data.data.packingList)
      this.context.dispatch('event/newMessage', 'success.packingLists.create', {
        root: true,
      })
    } catch {
      this.context.dispatch('event/newError', 'error.packingLists.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async update(payload?: Partial<PackingList>): Promise<void> {
    try {
      if (!tripModule.trip || !this.tripPackingList) {
        throw new Error('Invalid parameters')
      }

      if (!payload && this.tripPackingList) {
        payload = {
          name: this.tripPackingList.name || '',
          items: this.tripPackingList.items,
          user: authModule.user.id!,
        }
      }

      const { data } = await this.$axios.put(
        `/api/trips/${tripModule.trip?.id}/packing-lists/${this.tripPackingList._id}`,
        payload,
      )

      this.context.commit('setCurrentTripPackingList', data.data.packingList)
    } catch {
      this.context.dispatch('event/newError', 'error.packingLists.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async updateUserList(
    payload?: Partial<PackingListTemplate>,
  ): Promise<void> {
    try {
      if (!this.userPackingList) {
        throw new Error('Invalid parameters')
      }

      if (!payload && this.userPackingList) {
        payload = {
          name: this.userPackingListName,
          items: this.userPackingList.items.map((item) => ({
            name: localizedName(item.name),
            items: item.items.map((subItem) => ({
              name: localizedName(subItem.name),
            })),
          })),
        }
      }

      const { data } = await this.$axios.put(
        `/api/users/${authModule.user.id}/packing-list-templates/${this.userPackingList._id}`,
        payload,
      )

      this.context.commit('setCurrentUserPackingList', data.data.packingList)
      // Required because the user can navigate from a trip to the user's packing list,
      // otherwise when the user navigates back to the trip (back button), the packing list will not
      // be updated.
      this.context.commit(
        'setCurrentPackingListTemplate',
        data.data.packingList,
      )
    } catch {
      this.context.dispatch('event/newError', 'error.packingLists.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async delete(): Promise<void> {
    try {
      if (!tripModule.trip || !this.tripPackingList) {
        throw new Error('Invalid parameters')
      }

      await this.$axios.delete(
        `/api/trips/${tripModule.trip.id}/packing-lists/${this.tripPackingList._id}`,
      )

      this.context.commit('setCurrentTripPackingList', null)
      this.context.dispatch('event/newMessage', 'success.packingLists.delete', {
        root: true,
      })
    } catch {
      this.context.dispatch('event/newError', 'error.packingLists.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async deleteUserList(): Promise<void> {
    try {
      if (!this.userPackingList) {
        throw new Error('Invalid parameters')
      }

      await this.$axios.delete(
        `/api/users/${authModule.user.id}/packing-list-templates/${this.userPackingList._id}`,
      )

      this.context.commit('setCurrentUserPackingList', null)
      this.context.dispatch('event/newMessage', 'success.packingLists.delete', {
        root: true,
      })
    } catch {
      this.context.dispatch('event/newError', 'error.packingLists.delete', {
        root: true,
      })
    }
  }
}

function localizedName(name: KVLiteral[] | string) {
  if (typeof name === 'string') {
    return name
  }

  return findName(name, i18n.locale)
}
