
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'FaqExpansionPanel',
})
class FaqExpansionPanel extends Vue {
  openFAQ(): void {
    window.open(process.env.LAMBUS_HELP_CENTER, '_blank')
  }
}
export default FaqExpansionPanel
