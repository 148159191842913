
import { Vue, Component } from 'vue-property-decorator'
import { discoverModule } from '@/store'
import { Inspiration } from '@/types/discover'
import { thumbnailImage } from '@/utils/utility-manager'

@Component({
  name: 'InspirationMedia',
})
class InspirationMedia extends Vue {
  showLightBox = false
  imgIndex = 0

  get titleClass(): string {
    return this.$vuetify.breakpoint.smAndDown
      ? 'text-capitalize pt-0'
      : 'text-uppercase'
  }

  get currentInspiration(): Inspiration {
    return discoverModule.currentInspiration!
  }

  get inspirationImgs(): string[] {
    return this.currentInspiration.media.map((media) => media.url)
  }

  getThumbnail(image: string, size: number): string {
    return thumbnailImage(image, size)
  }

  showLightbox(imgIndex: number): void {
    this.imgIndex = imgIndex
    this.showLightBox = true
  }

  hideLightBox(): void {
    this.showLightBox = false
  }
}

export default InspirationMedia
