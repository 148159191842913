import { useContext } from './context'
import { UnsplashSearchPhotoResponseData } from '@/types/types'

export function useDailyPlan() {
  const { $axios } = useContext()

  async function getEmojiFromText(value: string) {
    try {
      const { data } = await $axios.get<{ data: { emoji: string } }>(
        `/api/meta/daily-plan/activities/text-to-emoji?q=${value}`,
      )

      return data.data.emoji ?? undefined
    } catch (error) {
      return undefined
    }
  }

  async function getSearchUnsplashPhotos(keyword: string) {
    try {
      const { data } = await $axios.get<UnsplashSearchPhotoResponseData>(
        `search/photos?page=1&limit=1&query=${keyword.toLowerCase()}&client_id=${
          process.env.UNSPLASH_API_KEY
        }`,
        {
          baseURL: 'https://api.unsplash.com/',
        },
      )

      if (data.results.length > 0) {
        return data.results[0].urls.small
      }
      return undefined
    } catch (error) {
      return undefined
    }
  }

  return {
    getEmojiFromText,
    getSearchUnsplashPhotos,
  }
}
