import { set } from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { logEvent, getAnalytics } from 'firebase/analytics'

import {
  waypointModule,
  authModule,
  transportationModule,
  tripModule,
} from '@/store'
import {
  Trip,
  GpxRoute,
  TripPredictions,
  TripLink,
  TripUpdateRelation,
  DashboardFeature,
} from '@/types/trip'
import { isBrowser } from '@/utils/utility-manager'
import { TripRelation } from '@/utils/enums'
import { ProfileSubject } from '@/types/user'

@Module({ name: 'trip', stateFactory: true, namespaced: true })
export default class TripModule extends VuexModule {
  private hasArchivedTrips = false
  private currentTrip: Trip | null = null
  private allGpxRoutes: GpxRoute[] = []
  private allPolylines: any[] = []
  private tripsSortedByDate: Partial<Trip>[] = []
  private tripLink: TripLink | null = null

  private defaultDashboardComponents: DashboardFeature[] = [
    {
      name: 'map-card',
      md: 6,
      icon: 'map-dashboard-icon',
      title: 'map',
      isDisplayed: true,
    },
    {
      name: 'expense-card',
      md: 3,
      icon: 'expense-dashboard-icon',
      title: 'expenses',
      isDisplayed: true,
    },
    {
      name: 'photo-card',
      md: 3,
      icon: 'photos-dashboard-icon',
      title: 'photos',
      isDisplayed: true,
    },
    {
      name: 'accommodation-card',
      md: 3,
      icon: 'accommodation-dashboard-icon',
      title: 'accommodation',
      isDisplayed: true,
    },
    {
      name: 'transportation-card',
      md: 3,
      icon: 'transportation-dashboard-icon',
      title: 'transportation',
      isDisplayed: true,
    },
    {
      name: 'note-card',
      md: 3,
      icon: 'notes-dashboard-icon',
      title: 'notes',
      isDisplayed: true,
    },
    {
      name: 'document-card',
      md: 3,
      icon: 'documents-dashboard-icon',
      title: 'documents',
      isDisplayed: true,
    },
  ]

  private dashboardComponents: DashboardFeature[] = []

  public get user() {
    return authModule.user
  }

  public get hasArchive() {
    return this.hasArchivedTrips
  }

  public get trip() {
    return this.currentTrip
  }

  public get polylines(): any[] {
    return this.allPolylines
  }

  public get gpxRoutes(): GpxRoute[] {
    return this.allGpxRoutes
  }

  public get myExpenseAccount() {
    if (!this.trip || !this.user) {
      return null
    }

    for (const account of this.trip.accounts) {
      if (account.userId.id === this.user.id) {
        return account
      }
    }

    return null
  }

  public get datePickerValues() {
    if (!this.trip) return null

    if (this.trip.startDate && this.trip.endDate) {
      return {
        pickerType: this.trip.startDate.type,
        pickerDates: [this.trip.startDate.value, this.trip.endDate.value],
        pickerMonths: [this.trip.startDate.value, this.trip.endDate.value],
      }
    } else if (this.trip.startDate && !this.trip.endDate) {
      return {
        pickerType: this.trip.startDate.type,
        pickerDates: [this.trip.startDate.value],
        pickerMonths: [this.trip.startDate.value],
      }
    } else {
      return null
    }
  }

  public get tripsByDate(): Partial<Trip>[] {
    return this.tripsSortedByDate
  }

  public get shareTripLink(): TripLink | null {
    return this.tripLink
  }

  public get dashboardFeatures(): DashboardFeature[] {
    return this.dashboardComponents
  }

  public get defaultDashboardFeatures(): DashboardFeature[] {
    return this.defaultDashboardComponents
  }

  @Mutation
  private setHasArchivedTrips(payload: boolean) {
    set(this, 'hasArchivedTrips', payload)
  }

  @Mutation
  private setCurrentTrip(payload: Trip | null) {
    set(this, 'currentTrip', payload)
  }

  @Mutation
  private setAllPolylines(polylines: any[]) {
    set(this, 'allPolylines', polylines)
  }

  @Mutation
  private setAllGpxRoutes(routes: GpxRoute[]) {
    set(this, 'allGpxRoutes', routes)
  }

  @Mutation
  private setShareTripLink(link: TripLink) {
    this.tripLink = link
  }

  @Mutation
  public setDashboardFeatures(features: DashboardFeature[]) {
    if (
      features.length > 0 &&
      !Object.prototype.hasOwnProperty.call(features[0], 'icon')
    ) {
      const mappedFeatures = features.map((feature) => {
        const component = this.dashboardComponents.find(
          (component) => component.name === feature.name,
        )
        return component
      })
      set(this, 'dashboardComponents', mappedFeatures)
    } else {
      set(this, 'dashboardComponents', features)
    }
  }

  @Action({ rawError: true })
  public async fetch(id: string) {
    try {
      const currentWaypoint = waypointModule.waypoint
      const currentPOI = waypointModule.pointOfInterest
      const currentTripId = tripModule.trip?.id

      const { data } = await this.$axios.get(`/api/trips/${id}`)
      this.context.dispatch('setTrip', data.trip)

      // keep waypoint and poi if it was selected before fetch / use updated waypoint and poi
      if (currentWaypoint && this.trip) {
        const updatedWaypoint = this.trip.waypoints.find(
          (el) => el._id === currentWaypoint._id,
        )
        await waypointModule.setWaypoint(updatedWaypoint || currentWaypoint)

        if (currentPOI && updatedWaypoint) {
          const updatedPOI = updatedWaypoint.pointOfInterests.find(
            (el) => el._id === currentPOI._id,
          )
          waypointModule.setPointOfInterest(updatedPOI || currentPOI)
        }
      }

      if (this.trip && this.trip.id !== currentTripId) {
        transportationModule.fetchAllBookings(this.trip)
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.trip.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchGpxRoutes() {
    try {
      if (!this.trip) {
        throw new Error('invalid parameters')
      }

      const { data } = await this.$axios.get(`/api/trips/${this.trip.id}/gpx`)
      this.context.commit('setAllGpxRoutes', data.routes)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.gpx.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async create(payload: { trip: any; location?: any; origin?: any }) {
    try {
      // create new trip
      const { data } = await this.$axios.post('/api/trips', {
        location: payload.location,
        origin: payload.origin,
        ...payload.trip,
      })
      this.context.dispatch('setTrip', data.trip)

      this.context.dispatch('fetchAll')
      this.context.dispatch('event/newMessage', 'success.trip.create', {
        root: true,
      })

      if (isBrowser()) {
        logEvent(getAnalytics(), 'created_trip')
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.trip.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async join(pinCode: string) {
    try {
      await this.$axios.put('/api/joinTrip', { pinCode })

      if (isBrowser()) {
        logEvent(getAnalytics(), 'joined_trip')
        logEvent(getAnalytics(), 'joined_trip_code')
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.trip.join', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async respondToInvite(accept: boolean) {
    try {
      await this.$axios.put(`/api/trips/${this.trip!.id}/invite/respond`, {
        accept,
      })

      if (accept) {
        if (isBrowser()) {
          logEvent(getAnalytics(), 'joined_trip')
          logEvent(getAnalytics(), 'joined_trip_in_app')
        }
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.trip.respond', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async leave(uid: string) {
    try {
      await this.$axios.post(`/api/trips/${this.trip!.id}/leave/${uid}`)

      const isMe = uid === this.user.id
      const isLastAttendee =
        this.trip?.attendees.length === 1 &&
        this.trip.pendingAttendees.length === 0

      const type = isLastAttendee
        ? TripRelation.DELETED_TRIP
        : isMe
          ? TripRelation.LEFT_TRIP
          : TripRelation.REMOVED_ATTENDEE

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type },
        {
          root: true,
        },
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.trip.leave', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async duplicate() {
    try {
      await this.$axios.post(`/api/trips/${this.trip!.id}/duplicate`)
      this.context.dispatch('event/newMessage', 'success.trip.duplicate', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.trip.duplicate', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async invite(params: { tripId: string; value: string }) {
    try {
      await this.$axios.post(`/api/trips/${params.tripId}/invite`, {
        value: params.value,
      })

      this.context.dispatch('event/newMessage', 'success.trip.invite', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.trip.invite', {
        root: true,
      })
    }
    this.context.dispatch('fetch', params.tripId)
  }

  @Action({ rawError: true })
  public async updateTrip(values: any) {
    try {
      const { data } = await this.$axios.put(
        `/api/trips/${this.trip!.id}`,
        values,
      )
      this.context.commit('setCurrentTrip', data.trip)

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.UPDATED_TRIP },
        {
          root: true,
        },
      )

      if (Object.keys(values).includes('archived')) {
        if (values.archived) {
          this.context.dispatch('event/newMessage', 'success.trip.archive', {
            root: true,
          })

          this.context.dispatch(
            'trip/postSocketTrigger',
            { type: TripRelation.ARCHIVED_TRIP },
            {
              root: true,
            },
          )
        }
        if (!values.archived) {
          this.context.dispatch('event/newMessage', 'success.trip.unarchive', {
            root: true,
          })
        }
      } else {
        this.context.dispatch('event/newMessage', 'success.trip.update', {
          root: true,
        })
      }
    } catch (error) {
      if (Object.keys(values).includes('archived')) {
        if (values.archived) {
          this.context.dispatch('event/newError', 'error.trip.archive', {
            root: true,
          })
        }
        if (!values.archived) {
          this.context.dispatch('event/newError', 'error.trip.unarchive', {
            root: true,
          })
        }
      } else if (Object.keys(values).includes('waypointAlgorithm')) {
        this.context.dispatch('event/newError', 'error.waypoint.optimize', {
          root: true,
        })
      } else {
        this.context.dispatch('event/newError', 'error.trip.update', {
          root: true,
        })
      }
    }
  }

  @Action({ rawError: true })
  public updateNotifications(payload: any) {
    try {
      this.$axios.post(
        `/api/trips/${this.trip!.id}/settings/notifications`,
        payload,
      )
    } catch (error) {
      this.context.dispatch(
        'event/newError',
        'error.trip.notification_update',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public async notifications() {
    try {
      const { data } = await this.$axios.get(
        `/api/trips/${this.trip!.id}/settings/notifications`,
      )
      return data.settings
    } catch (error) {
      this.context.dispatch('event/newError', 'error.trip.notification_fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async messages() {
    try {
      const { data } = await this.$axios.get(
        `/api/messages/Trip/${this.trip!.id}`,
      )

      return data.messages
    } catch (error) {
      this.context.dispatch('event/newError', 'error.message.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async sendMessage(payload: any) {
    try {
      const { data } = await this.$axios.post(`/api/messages/`, payload)
      return data.message
    } catch (error) {
      this.context.dispatch('event/newError', 'error.message.send', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public setTrip(trip: Trip | null) {
    const currentTripId: string | undefined = this.currentTrip?.id
    this.context.commit('setCurrentTrip', trip)

    if (trip?.id !== currentTripId) {
      this.fetchPolylines()
      transportationModule.fetchAllBookings(trip!)
    }

    if (this.trip?.hasRoutes) {
      this.fetchGpxRoutes()
    } else {
      this.context.commit('setAllGpxRoutes', [])
    }
  }

  @Action({ rawError: true })
  public resetTrip() {
    this.context.commit('setCurrentTrip', null)
    this.context.commit('setAllGpxRoutes', [])
  }

  @Action({ rawError: true })
  public async exportTrip(payload: { tripId: string; params: any }) {
    try {
      await this.$axios.post(
        `api/trips/${payload.tripId}/export/v2`,
        payload.params,
      )
      this.context.dispatch('event/newMessage', 'success.expense.exportTrip', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.export.trip', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async exportTripAsGpx(trip: string) {
    try {
      await this.$axios.get(`api/trips/${trip}/export/gpx`)
      this.context.dispatch('event/newMessage', 'success.expense.exportTrip', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.export.trip', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async deleteGpxRoute(routeId: string) {
    try {
      if (!this.trip) {
        throw new Error('invalid paramters')
      }
      await this.$axios.delete(`api/trips/${this.trip.id}/gpx/${routeId}`)
      this.context.dispatch('fetchGpxRoutes')
      this.context.dispatch('event/newMessage', 'success.gpx.delete', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.gpx.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchPolylines(): Promise<void> {
    try {
      if (!this.trip) {
        throw new Error('invalid paramters')
      }
      const { data } = await this.$axios.get(
        `/api/trips/${this.trip.id}/polyline`,
      )

      this.context.commit('setAllPolylines', data.polylines)
    } catch (error) {
      // NO-OP, do not display polylines
    }
  }

  @Action({ rawError: true })
  public async fetchCreateTripLocationSearch(params: {
    sessionToken: string
    query: string
  }): Promise<TripPredictions | null> {
    try {
      const { data } = await this.$axios.get(
        `/api/trips/create/locations/search?token=${params.sessionToken}&q=${params.query}`,
      )

      return data.data.predictions
    } catch (error) {
      return null
    }
  }

  @Action({ rawError: true })
  public async getTripShare() {
    try {
      const { data } = await this.$axios.get(
        `/api/trips/${this.trip?.id}/share`,
      )
      this.context.commit('setShareTripLink', data.data)
    } catch (error) {}
  }

  @Action({ rawError: true })
  public postSocketTrigger(opts: { type: number; object?: ProfileSubject }) {
    if (!this.trip || !this.user.id || !this.user.firstName) {
      return
    }

    const payload: TripUpdateRelation = {
      type: opts.type,
      tripId: this.trip.id,
      relation: {
        subject: {
          identifier: this.user.id,
          name: this.user.firstName,
        },
        object: opts.object || null,
      },
    }

    this.$axios.post('api/socket/trigger', payload)
  }

  @Action({ rawError: true })
  public resetDashboardOrder(): void {
    this.context.commit('setDashboardFeatures', this.defaultDashboardComponents)
  }

  @Action({ rawError: true })
  public updateHasArchive(value: boolean) {
    this.context.commit('setHasArchivedTrips', value)
  }
}
