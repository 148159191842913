import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/vue-query'
import { MaybeRef } from '@vueuse/core'
import { unref } from 'vue'

import { useContext } from '@/composables/context'
import { eventModule } from '@/store'
import {
  WaypointDayItem,
  WaypointDayItemCreate,
  WaypointDayItemUpdate,
  RearrangeWaypointDayItem,
  PlaceRecommendation,
  PointOfInterestPreview,
  PointOfInterest,
} from '@/types/trip'
import { ActivityCreate, ActivityUpdate, Activity } from '@/types/activity'
import { formattedDate } from '@/utils/date-utils'
import { LocationDataSource } from '@/utils/enums'
import { dataSourceToDataSourceSlug } from '@/utils/location'

export interface PointOfInterestKeysPreviewOptions {
  dataSource: MaybeRef<LocationDataSource>
  externalId: MaybeRef<string>
}

export const activityKeys = {
  all: () => ['activities'] as const,
  details: () => [...activityKeys.all(), 'detail'] as const,
  detail: (activityId: MaybeRef<string>) =>
    [...activityKeys.details(), activityId] as const,
}

export const placeQueryKeys = {
  all: () => ['places'] as const,
  recommendations: () => [...placeQueryKeys.all(), 'recommendations'] as const,
  recommendationLists: () =>
    [...placeQueryKeys.recommendations(), 'list'] as const,
  recommendationList: (waypointId: MaybeRef<string>) =>
    [...placeQueryKeys.recommendationLists(), waypointId] as const,
}

export const pointOfInterestQueryKeys = {
  all: () => ['pointsOfInterest'] as const,
  previews: () => [...pointOfInterestQueryKeys.all(), 'previews'] as const,
  preview: (options: PointOfInterestKeysPreviewOptions) =>
    [...pointOfInterestQueryKeys.previews(), options] as const,
}

export function useCreateWaypointDayItem(
  tripId: MaybeRef<string>,
  waypointId: MaybeRef<string>,
) {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (payload: {
      waypointDayId: string
      waypointDayDate: string
      newItem: WaypointDayItemCreate
    }) => {
      const {
        data: { item },
      } = await $axios.post<{ item: WaypointDayItem }>(
        `/api/trips/${unref(tripId)}/waypoints/${unref(waypointId)}/days/${payload.waypointDayId}/item`,
        payload.newItem,
      )
      return item
    },
    onSuccess: (_data, variables, _context) => {
      eventModule.setValues({ date: formattedDate(variables.waypointDayDate) })
      eventModule.newMessage('addedToDate')
    },
    onError: () => {
      eventModule.newError('error.waypoint.dailyPlan.create')
    },
  })
}

export function useUpdateWaypointDayItem(tripId: string, waypointId: string) {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (payload: {
      waypointDayId: string
      itemId: string
      updateItem: WaypointDayItemUpdate
    }) => {
      if (payload.updateItem.endTime && !payload.updateItem.startTime) {
        payload.updateItem.startTime = payload.updateItem.endTime
        payload.updateItem.endTime = null
      }

      await $axios.put(
        `/api/trips/${tripId}/waypoints/${waypointId}/days/${payload.waypointDayId}/item/${payload.itemId}`,
        payload.updateItem,
      )
    },
    onSuccess: () => {
      eventModule.newMessage('success.waypoint.dailyPlan.update')
    },
    onError: () => {
      eventModule.newError('error.waypoint.dailyPlan.update')
    },
  })
}

export function useDeleteWaypointDayItem(tripId: string, waypointId: string) {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (payload: { waypointDayId: string; itemId: string }) => {
      await $axios.delete(
        `/api/trips/${tripId}/waypoints/${waypointId}/days/${payload.waypointDayId}/item/${payload.itemId}`,
      )
    },
    onSuccess: () => {
      eventModule.newMessage('success.waypoint.dailyPlan.delete')
    },
    onError: () => {
      eventModule.newError('error.waypoint.dailyPlan.delete')
    },
  })
}

export function useRearrangeWaypointDayItem(
  tripId: string,
  waypointId: string,
) {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (payload: RearrangeWaypointDayItem) => {
      await $axios.put(
        `/api/trips/${tripId}/waypoints/${waypointId}/days/rearrange`,
        payload,
      )
    },
    onSuccess: () => {
      eventModule.newMessage('success.waypoint.dailyPlan.rearrange')
    },
    onError: () => {
      eventModule.newError('error.waypoint.dailyPlan.rearrange')
    },
  })
}

export function useActivity(
  tripId: string,
  waypointId: string,
  activityId: string,
) {
  const { $axios } = useContext()

  return useQuery({
    queryKey: activityKeys.detail(activityId),
    queryFn: async () => {
      const {
        data: { data },
      } = await $axios.get<{ data: Activity }>(
        `/api/trips/${tripId}/waypoints/${waypointId}/activities/${activityId}`,
      )
      return data
    },
  })
}

export function useCreateActivity(tripId: string, waypointId: string) {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (newItem: ActivityCreate) => {
      const {
        data: { data },
      } = await $axios.post<{ data: Activity }>(
        `/api/trips/${tripId}/waypoints/${waypointId}/activities/`,
        newItem,
      )
      return data
    },
    onSuccess: () => {
      eventModule.newMessage('success.activity.create')
    },
    onError: () => {
      eventModule.newError('error.activity.create')
    },
  })
}

export function useUpdateActivity(tripId: string, waypointId: string) {
  const { $axios } = useContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (activity: ActivityUpdate) => {
      await $axios.put(
        `/api/trips/${tripId}/waypoints/${waypointId}/activities/${activity._id}/`,
        activity,
      )
    },
    onSuccess: async (_data, activity, _context) => {
      if (activity._id) {
        await queryClient.invalidateQueries({
          queryKey: activityKeys.detail(activity._id),
        })
      }

      eventModule.newMessage('success.activity.update')
    },
    onError: () => {
      eventModule.newError('error.activity.update')
    },
  })
}

export function useDeleteActivity(tripId: string) {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (payload: { waypointId: string; activityId: string }) => {
      await $axios.delete(
        `/api/trips/${tripId}/waypoints/${payload.waypointId}/activities/${payload.activityId}`,
      )
    },
    onSuccess: () => {
      eventModule.newMessage('success.activity.delete')
    },
    onError: () => {
      eventModule.newError('error.activity.delete')
    },
  })
}

export function usePlaceRecommendations(tripId: string, waypointId: string) {
  const { $axios } = useContext()

  return useQuery({
    queryKey: placeQueryKeys.recommendationList(waypointId),
    queryFn: async () => {
      const {
        data: { places },
      } = await $axios.get<{ places: PlaceRecommendation[] }>(
        `/api/trips/${tripId}/waypoints/${waypointId}/recommendations`,
      )

      return places
    },
  })
}

export function useAddPointOfInterestToWaypoint(
  tripId: string,
  waypointId: string,
) {
  const { $axios } = useContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (pointOfInterest: PointOfInterest) => {
      await $axios.post(
        `/api/trips/${tripId}/waypoints/${waypointId}/pointOfInterests`,
        { pointOfInterest },
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: placeQueryKeys.recommendationList(waypointId),
      })
      eventModule.newMessage('success.poi.create')
    },
    onError: () => {
      eventModule.newError('error.poi.create')
    },
  })
}

export interface UsePointOfInterestPreviewOptions {
  tripid: string
  waypointId: string
  dataSource: LocationDataSource
  externalId: string
  queryOptions?: UseQueryOptions<PointOfInterest>
}

export function usePointOfInterestPreview(
  options: UsePointOfInterestPreviewOptions,
) {
  const { tripid, waypointId, dataSource, externalId, queryOptions } = options
  const { $axios } = useContext()

  const dataSourceSlug = dataSourceToDataSourceSlug(dataSource)

  if (!dataSourceSlug) {
    throw new Error(`Invalid dataSource: ${dataSource}`)
  }
  return useQuery({
    queryKey: pointOfInterestQueryKeys.preview({ dataSource, externalId }),
    queryFn: async () => {
      const {
        data: { place },
      } = await $axios.get<{ place: PointOfInterestPreview }>(
        `/api/trips/${tripid}/waypoints/${waypointId}/pointOfInterests/preview/${dataSourceSlug}/${externalId}`,
      )

      const pointOfInterestPreview: PointOfInterest = {
        name: place.name,
        metadata: place,
      }
      return pointOfInterestPreview
    },
    ...queryOptions,
  })
}
