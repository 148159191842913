
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'NumberedBadge',
})
class NumberedBadge extends Vue {
  @Prop({ default: 1 }) readonly number!: number | string
}

export default NumberedBadge
