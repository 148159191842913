
import { Component, Prop, Vue } from 'vue-property-decorator'
import { QueryClient, useQueryClient } from '@tanstack/vue-query'

import { useUploadFiles } from '@/composables/file'
import { tripModule } from '@/store'
import DocumentModule from '@/store/document'
import PhotoModule from '@/store/photo'
import { GridContentType } from '@/utils/enums'
import { tripQueryKeys } from '@/composables/trip'

@Component({
  name: 'DropZone',
  setup() {
    const queryClient = useQueryClient()
    return {
      queryClient,
    }
  },
})
class DropZone extends Vue {
  @Prop() readonly referenceId?: string
  @Prop() readonly contentType!: GridContentType
  @Prop() readonly storeModule?: DocumentModule | PhotoModule
  @Prop({ default: true, type: Boolean }) readonly absolute!: boolean
  @Prop({ default: false, type: Boolean }) readonly modal!: boolean
  @Prop({ default: false, type: Boolean }) readonly hasContent!: boolean
  @Prop({ default: false, type: Boolean }) readonly noContentPadding!: boolean
  @Prop({ default: false, type: Boolean }) readonly allowClick!: boolean

  queryClient!: QueryClient

  showDropzone = false

  get trip() {
    return tripModule.trip!
  }

  get currentDirectory() {
    return this.storeModule!.currentDirectory
  }

  dropHandler(evt: DragEvent): void {
    evt.preventDefault()
    const file = evt.dataTransfer!.files[0]
    if (this.contentType === GridContentType.CHAT || !this.storeModule) {
      this.$emit('dropped', file)
    } else {
      this.uploadDroppedFiles(evt)
    }
  }

  async uploadDroppedFiles(evt: DragEvent) {
    if (!evt.dataTransfer || !this.storeModule) {
      return
    }
    const files = Array.from(evt.dataTransfer.files).filter(this.isValidType)

    await useUploadFiles({
      files,
      contentType: this.contentType,
      storeModule: this.storeModule,
      tripId: this.trip.id,
      currentDirectory: this.currentDirectory,
      referenceId: this.referenceId,
    })

    this.storeModule.fetchByTrip(this.trip.id)
    this.queryClient.invalidateQueries({
      queryKey: tripQueryKeys.summary(this.trip.id),
    })

    this.showDropzone = false
  }

  isValidType(file: File): boolean {
    if (!file) return false
    return !(
      this.contentType === GridContentType.PHOTO &&
      !(file.type.split('/')[0] === 'image')
    )
  }

  handleClick(event: Event) {
    if (!this.allowClick) {
      event.preventDefault()
    }
  }
}

export default DropZone
