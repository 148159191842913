
import { Component, Vue } from 'vue-property-decorator'
import AttendeeInviteForm from '@/components/trip/settings/AttendeeInviteForm.vue'
@Component({
  name: 'AttendeeInviteDialog',
  components: {
    AttendeeInviteForm,
  },
})
class AttendeeInviteDialog extends Vue {}
export default AttendeeInviteDialog
