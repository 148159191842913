
import { Component, Vue } from 'vue-property-decorator'

import TimeChip from '@/components/datepicker/timepicker/TimeChip.vue'
import TimeRangeForm from '@/components/datepicker/timepicker/TimeRangeForm.vue'

@Component({
  name: 'TimeRangePicker',
  components: {
    TimeChip,
    TimeRangeForm,
  },
})
class TimeRangePicker extends Vue {
  showMenu = false
  showBottomSheet = false

  /**
   * Resets the time range forms values
   */
  triggerReset(value: boolean): void {
    if (!value) {
      const ref = this.$vuetify.breakpoint.mdAndUp
        ? 'time-range-picker-desktop'
        : 'time-range-picker-mobile'

      const timeForm = this.$refs[ref] as TimeRangeForm

      if (timeForm) {
        timeForm.reset()
      }
    }
  }
}

export default TimeRangePicker
