
import { Component, Watch, Prop, Vue } from 'vue-property-decorator'

import DocumentViewer from '@/components/DocumentViewer.vue'
import GridMenu from '@/components/trip/contentView/GridMenu.vue'
import { tripModule, eventModule } from '@/store'
import DocumentModule from '@/store/document'
import PhotoModule from '@/store/photo'
import { Document } from '@/types/document'
import { Photo } from '@/types/photo'
import { DocumentType, GridContentType, EmptyStateType } from '@/utils/enums'
import {
  formatFileName,
  isPDF,
  navigateToItem,
  thumbnailImage,
} from '@/utils/utility-manager'
import AWSManager from '@/utils/aws-manager'

@Component({
  components: {
    GridMenu,
  },
})
class ContentView extends Vue {
  /**
   * Determines if the empty state shows the general hint or the hint specific to current contentType
   */
  @Prop({ default: false, type: Boolean })
  readonly generalEmptyStateHint!: boolean

  /**
   * Determines if the empty state title is shown
   */
  @Prop({ default: true, type: Boolean }) readonly hideEmptyStateTitle!: boolean
  @Prop({ default: false, type: Boolean }) readonly card!: boolean
  @Prop({ default: false, type: Boolean }) readonly large!: boolean
  @Prop({ default: false, type: Boolean }) readonly filterByWaypoint!: boolean
  @Prop() readonly referenceId?: string
  @Prop() readonly contentType!: string
  @Prop() readonly storeModule!: DocumentModule | PhotoModule
  @Prop() filter?: (value: Document | Photo) => boolean

  pdfjsLib: any = null
  pdfjsWorker: any = null

  emptyState: string =
    this.contentType === GridContentType.PHOTO
      ? EmptyStateType.PHOTOS
      : EmptyStateType.DOCUMENTS

  isFetched = false
  showDropzone = false
  showLightBox = false
  parentDirectories: Array<string | null> = []
  currentContentId?: string
  types = DocumentType
  pictureUrl = ''
  documentUrl = ''
  documentTitle = ''
  imgIndex = 0

  get trip() {
    return tripModule.trip!
  }

  get files(): Document[] | Photo[] {
    if (this.filter) {
      return this.storeModule.files.filter((value) => this.filter?.(value))
    }
    return this.filterByWaypoint
      ? this.storeModule.filesByWaypoint
      : this.storeModule.files
  }

  public get parentDirectoryId(): string | null {
    return this.currentDirectory || null
  }

  get currentDirectory(): string | null {
    return this.storeModule.currentDirectory
  }

  get cols(): number {
    return !this.card && !this.large ? 1 : 4
  }

  get filteredContent(): Document[] | Photo[] {
    return this.files.filter((element: Document | Photo) => {
      return (
        !!this.referenceId ||
        element.parentDirectoryId === this.currentDirectory ||
        (!element.parentDirectoryId && !this.currentDirectory)
      )
    })
  }

  get imageUrls(): string[] {
    const content = this.filteredContent
      .filter((file) => file.url !== null && file.type === DocumentType.FILE)
      .map((img) => img.url)
    return content as string[]
  }

  get directories(): Document[] | Photo[] {
    return this.filteredContent.filter(
      (element: Document | Photo) => element.type === this.types.DIRECTORY,
    )
  }

  @Watch('trip')
  resetCurrentDir(): void {
    this.storeModule.changeDir()
  }

  created() {
    this.pdfjsLib = require('pdfjs-dist/legacy/build/pdf')
    this.pdfjsWorker = require('pdfjs-dist/legacy/build/pdf.worker.entry.js')
    this.pdfjsLib.GlobalWorkerOptions.workerSrc = this.pdfjsWorker
  }

  async mounted() {
    const sortMode = localStorage.getItem(
      `kLambusPreferredDocumentSortMode_${this.contentType}`,
    )

    if (sortMode) {
      this.storeModule.setSortingMode(sortMode)
    }

    await this.storeModule.fetchByTrip(this.trip.id)
    if (this.$route.params.file) {
      const object = this.storeModule.files.find(
        (object) => object._id === this.$route.params.file,
      )

      if (object?.type === this.types.DIRECTORY) {
        this.openDir(this.$route.params.file)
      } else if (object?.type === this.types.FILE && object.url) {
        if (isPDF(object.url)) {
          this.toggleDocumentViewer(
            object.url,
            this.formatName(object.originName!),
          )
        } else {
          this.togglePictureViewer(object.url)
        }
      }
    }
    window.addEventListener('dragenter', this.enableDropzone)
    window.addEventListener('dragover', this.enableDropzone)
    window.addEventListener('dragleave', this.disableDropzone)
    window.addEventListener('drop', this.disableDropzone)
    this.isFetched = true
  }

  beforeDestroy() {
    window.removeEventListener('dragenter', this.enableDropzone)
    window.removeEventListener('dragover', this.enableDropzone)
    window.removeEventListener('dragleave', this.disableDropzone)
    window.removeEventListener('drop', this.disableDropzone)
  }

  enableDropzone(evt: Event): void {
    evt.preventDefault()
    if (typeof this.currentContentId === 'string') return
    this.showDropzone = true
  }

  disableDropzone(evt: Event): void {
    evt.preventDefault()
    setTimeout(() => {
      this.showDropzone = false
    }, 500)
  }

  setFile(payload: Document | Photo): void {
    this.storeModule.setFile(payload)
  }

  togglePictureViewer(newUrl?: string): void {
    if (!newUrl) {
      return
    }

    this.pictureUrl = newUrl
    this.showLightBox = true
    this.imgIndex = this.imageUrls.findIndex((element) => element === newUrl)
  }

  toggleDocumentViewer(newUrl?: string, newTitle?: string): void {
    if (!newUrl) {
      return
    }

    this.documentTitle = newTitle || ''
    this.documentUrl = newUrl
    const viewer = this.$refs.documentViewer as DocumentViewer
    viewer.toggleDialog()
  }

  async openPDF(url: string): Promise<void> {
    const signedUrl = await AWSManager.getPresignedUrl(url.split('.com/').pop())
    if (signedUrl) {
      window.open(signedUrl, '_blank')
    } else {
      eventModule.newError('error.document.get')
    }
  }

  formatName(name: string): string {
    return formatFileName(name)
  }

  isPDF(url?: string): boolean {
    return isPDF(url)
  }

  openDir(newDir?: string): void {
    this.parentDirectories.push(this.currentDirectory)
    this.changeDir(newDir)
  }

  changeDir(newDir?: string): void {
    this.storeModule.changeDir(newDir)

    if (!this.card) {
      navigateToItem(
        this.$route.path,
        `${this.contentType}s`,
        newDir || '',
        true,
      )
    }
  }

  clickHandler(doc: any): void {
    if (doc.type === DocumentType.DIRECTORY) {
      this.openDir(doc._id)
    } else if (doc.type === DocumentType.FILE && this.isPDF(doc.url)) {
      this.toggleDocumentViewer(doc.url, this.formatName(doc.originName))
    } else if (doc.type === DocumentType.FILE && !this.isPDF(doc.url)) {
      this.togglePictureViewer(doc.url)
    }
  }

  dragHandler(contentId?: string): void {
    this.currentContentId = contentId || undefined
  }

  dragEnterHandler(evt: any, doc?: Document): void {
    if (
      doc?.type !== DocumentType.DIRECTORY ||
      doc?._id === this.currentContentId
    ) {
      return
    }

    evt.stopPropagation()
    evt.target!.style.backgroundColor = 'rgba(0, 0, 0, 0.2)'
    const draggable = this.$refs[`file-${this.currentContentId}`] as any

    if (!draggable) {
      return
    }
    if (draggable[0].$el) {
      draggable[0].$el.style.cursor = 'copy'
    } else {
      draggable[0].style.cursor = 'copy'
    }
  }

  dragLeaveHandler(evt: any): void {
    evt.stopPropagation()
    evt.target!.style.backgroundColor = 'transparent'
    const draggable = this.$refs[`file-${this.currentContentId}`] as any

    if (!draggable) {
      return
    }
    if (draggable[0].$el) {
      draggable[0].$el.style.cursor = 'default'
    } else {
      draggable[0].style.cursor = 'default'
    }
  }

  thumbnailSrc(url?: string): string {
    return thumbnailImage(url, 320)
  }

  moveUp(evt: Event): void {
    this.storeModule.move({
      id: this.trip.id,
      params: {
        parentDirectoryId:
          this.parentDirectories.length <= 1
            ? null
            : this.parentDirectories[this.parentDirectories.length - 1],
        documentIds: [this.currentContentId],
      },
    })
    this.dragLeaveHandler(evt)
    this.disableDropzone(evt)
  }

  async moveToDir(directory: Document, evt: Event): Promise<void> {
    if (
      directory.type !== this.types.DIRECTORY ||
      directory._id === this.currentContentId
    ) {
      this.dragLeaveHandler(evt)
      return
    }

    const params = {
      parentDirectoryId: this.directories.find(
        (el: Document | Photo) => el._id === directory._id,
      ),
      documentIds: [this.currentContentId],
    }

    await this.storeModule.move({
      id: this.trip.id,
      params,
    })

    this.storeModule.fetchByTrip(this.trip.id)

    this.dragLeaveHandler(evt)
    this.disableDropzone(evt)
  }

  public get currentDirName(): string {
    const dir = this.files.find(
      (doc) =>
        doc._id === this.currentDirectory && doc.type === this.types.DIRECTORY,
    )
    if (dir) {
      return this.formatName(dir.originName as string)
    } else {
      return ''
    }
  }
}

export default ContentView
