
import { Component, Vue } from 'vue-property-decorator'

import MessageAlert from '@/components/MessageAlert.vue'
import Intercom from '@/utils/intercom-manager'
import { systemModule } from '@/store'

@Component({
  name: 'CenteredLayout',
  components: {
    MessageAlert,
  },
  middleware: ['guest'],
})
class CenteredLayout extends Vue {
  mounted() {
    // Ping server
    systemModule.ping()

    Intercom.launch()
  }

  beforeDestroy() {
    Intercom.shutdown()
  }
}

export default CenteredLayout
