
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'LSpinningLoader',
})
class LSpinningLoader extends Vue {
  @Prop({ default: 'white', type: String }) readonly color!: string
  @Prop({ default: false, type: Boolean }) readonly small!: boolean
  @Prop({ default: true, type: Boolean }) readonly absolute!: boolean
  @Prop() readonly size?: number

  get loaderSize(): number {
    if (this.size) {
      return this.size
    }

    return this.small ? 30 : 70
  }
}
export default LSpinningLoader
