
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { localizedTime, timeStringToInt } from '@/utils/date-utils'

@Component({
  name: 'SuggestionList',
})
class SuggestionList extends Vue {
  /**
   * If true, time suggestions are displayed
   * If false, empty placeholder list is displayed
   */
  @Prop({ default: false, type: Boolean }) readonly value!: boolean

  /**
   * Time string as HH:mm to filter time suggestions
   */
  @Prop() readonly min?: string

  /**
   * Time string as HH:mm to filter time suggestions
   */
  @Prop() readonly max?: string

  /**
   * Currently active time, which is focused / scrolled to when a value is set. Formatted in 24hr (HH:mm)
   */
  @Prop() readonly activeValue?: string

  suggestions = this.createSuggestionList()

  createSuggestionList() {
    const suggestions: string[] = []
    for (let hour = 0; hour < 24; hour++) {
      suggestions.push(`${hour.toString().padStart(2, '0')}:00:00`)
      suggestions.push(`${hour.toString().padStart(2, '0')}:30:00`)
    }
    return suggestions
  }

  /**
   * Returns time suggestions between min and max props (if both props are set)
   */
  get filteredSuggestions(): string[] {
    if (!this.max || !this.min) {
      return this.suggestions
    }

    let maxValue = 0
    let minValue = 0

    maxValue = timeStringToInt(this.max)
    minValue = timeStringToInt(this.min)

    return this.suggestions.filter((time) => {
      const parsedValue = timeStringToInt(time)
      return minValue <= parsedValue && maxValue >= parsedValue
    })
  }

  /**
   * Turns filteredSuggestions into localized time strings in the current locales respective time format
   * e.g. en-US -> ampm, de-DE -> 24hr
   */
  get localizedSuggestions(): string[] {
    return this.filteredSuggestions.map((time) => localizedTime(time))
  }

  /**
   * Centers the active value on the suggestion list
   */
  @Watch('value')
  scrollToActiveValue(): void {
    this.$nextTick(() => {
      if (this.value && !!this.activeValue) {
        const activeItem = this.$refs[
          `time-suggestion-${this.activeValue}`
        ] as any

        if (activeItem && activeItem[0]?.$el) {
          activeItem[0].$el.scrollIntoView({ block: 'center' })
        }
      }
    })
  }

  setTime(index: number): void {
    this.$emit('select', this.localizedSuggestions[index])
  }
}

export default SuggestionList
