import { TextHighlight } from '@/types/notification'

/**
 * Returns the initials for the given first and last name.
 */
export function initials(firstName: string, lastName: string) {
  if (!firstName) {
    return
  }

  // If no first name was provided during registration we use the username and
  // prefix it with an `@` which doesn't look nice as the avatar fallback.
  firstName = firstName.replace('@', '')
  return firstName && lastName
    ? `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
    : firstName.charAt(0).toUpperCase()
}

/**
 * Generates an HTML string with specified text sections highlighted.
 *
 * This function processes a given text and an array of highlight objects to produce
 * an HTML string where the specified text sections are wrapped in `<strong>` tags, making them bold.
 */
export function highlightTextSections(
  text: string,
  highlights: TextHighlight[],
) {
  let resultHTML = ''
  let currentIndex = 0

  highlights.forEach(({ length, offset }) => {
    // add text section before highlight
    if (currentIndex < offset) {
      resultHTML += text.substring(currentIndex, offset)
    }

    // highlight text section
    resultHTML += `<strong>${text.substring(offset, offset + length)}</strong>`

    currentIndex = offset + length
  })

  // add remaining text
  if (currentIndex < text.length) {
    resultHTML += text.substring(currentIndex)
  }

  return resultHTML
}
