
import { Component, Prop, Watch } from 'vue-property-decorator'
import DatePickerModal from '@/components/datepicker/DatePickerModal.vue'
import TabbedDatePicker from '@/components/datepicker/TabbedDatePicker.vue'
import { tripModule } from '@/store'
import { DateRange } from '@/types/trip'
import { DatePickerType } from '@/utils/enums'
import { i18n } from '@/plugins/i18n'

@Component({
  name: 'DateRangePickerModal',
  components: {
    TabbedDatePicker,
  },
})
class DateRangePickerModal extends DatePickerModal {
  @Prop() readonly currentDates?: DateRange[]

  @Prop({ default: i18n.t('dates.hint'), type: String })
  readonly placeholder?: string

  @Prop({ default: true, type: Boolean }) readonly clearable!: boolean

  showCalendar = false

  get trip() {
    return tripModule.trip
  }

  get pickerValues(): { type: string; dates: string[] } {
    return {
      type: this.type,
      dates: this.type === DatePickerType.SPECIFIC ? this.dates : this.months,
    }
  }

  @Watch('currentDates')
  setDates(): void {
    if (
      this.currentDates === undefined ||
      !this.currentDates ||
      this.currentDates.length === 0
    ) {
      this.type = DatePickerType.SPECIFIC
      this.dates = []
      this.months = []
    } else if (this.currentDates.length > 1) {
      this.type = this.currentDates[0].type
      this.dates = [this.currentDates[0].value, this.currentDates[1].value]
      this.months = [this.currentDates[0].value, this.currentDates[1].value]
    } else if (this.currentDates.length === 1) {
      this.type = this.currentDates[0].type
      this.dates = [this.currentDates[0].value]
      this.months = [this.currentDates[0].value]
    }
  }

  created() {
    this.setDates()
    // Only use trip dates, if trip has dates and there are no dates passed from parent
    if (!this.trip || !this.trip.startDate || this.currentDates !== undefined)
      return

    this.type = this.trip.startDate.type
    if (this.trip.startDate && this.trip.endDate) {
      this.dates = [this.trip.startDate.value, this.trip.endDate.value]
      this.months = [this.trip.startDate.value, this.trip.endDate.value]
    } else if (this.trip.startDate && !this.trip.endDate) {
      this.dates = [this.trip.startDate.value]
      this.months = [this.trip.startDate.value]
    }
  }

  saveDates(data: any): void {
    this.type = data.type
    this.dates = data.dates
    this.months = data.months
    this.showCalendar = false
    this.$emit('update', data)
  }

  deleteDate(): void {
    this.$emit('delete')
  }
}

export default DateRangePickerModal
