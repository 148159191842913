
import { Component, Prop } from 'vue-property-decorator'
import AddWaypointLoader from '@/components/trip/transportation/forms/generic/AddWaypointLoader.vue'
import WaypointLocationForm from '@/components/trip/transportation/forms/generic/WaypointLocationForm.vue'
import WaypointLocationField from '@/components/trip/transportation/forms/generic/WaypointLocationField.vue'
import DateTimeField from '@/components/trip/transportation/forms/generic/DateTimeField.vue'
import { transportationModule } from '@/store'
import { Location } from '@/types/trip'
import { TransportationType } from '@/utils/enums'

@Component({
  name: 'LocationForm',
  components: {
    AddWaypointLoader,
    WaypointLocationField,
    DateTimeField,
  },
})
class LocationForm extends WaypointLocationForm {
  @Prop({ default: TransportationType.BICYCLE, type: String })
  readonly type?: string

  @Prop() readonly subType?: string

  originLocation: Location | null = null
  destinationLocation: Location | null = null

  created() {
    if (this.trip?.waypoints.length === 2) {
      this.origin = this.trip.waypoints[0]
      this.destination = this.trip.waypoints[1]
    }
  }

  isValid(): boolean {
    return (
      !!this.origin &&
      !!this.destination &&
      (this.type !== TransportationType.GENERIC || !!this.subType)
    )
  }

  saveBooking(): void {
    if (!this.trip || !this.isValid()) {
      return
    }

    this.isSaving = true

    const booking = {
      type: this.type,
      trip: this.trip.id,
      originWaypoint: this.origin,
      departureLocation: this.originLocation,
      destinationWaypoint: this.destination,
      arrivalLocation: this.destinationLocation,
    }

    if (this.type === TransportationType.GENERIC) {
      Object.assign(booking, {
        subType: this.subType,
        name: this.$t(this.subType!),
      })
    }

    if (this.dates.length > 0) {
      Object.assign(booking, { departureDate: this.dates[0] })

      if (this.dates.length > 1) {
        Object.assign(booking, { arrivalDate: this.dates[1] })
      }
    }

    transportationModule.setBooking(booking)
    this.$emit('click:add', this.type)
    this.isSaving = false
  }
}
export default LocationForm
