
import { Component, Prop, Vue } from 'vue-property-decorator'
import LConfirmDialog from '@/global-components/LConfirmDialog.vue'
import DetailExpenseModal from '@/components/trip/expenses/detail/DetailExpenseModal.vue'
import EditExpenseModal from '@/components/trip/expenses/detail/EditExpenseModal.vue'
import PictureViewer from '@/components/PictureViewer.vue'
import { tripModule } from '@/store'
import { User } from '@/types/user'
import { MenuItem } from '@/types/types'

@Component({
  name: 'AttendeeMenu',
  components: {
    DetailExpenseModal,
    EditExpenseModal,
    PictureViewer,
    LConfirmDialog,
  },
})
class AttendeeMenu extends Vue {
  @Prop() readonly user!: User

  get trip() {
    return tripModule.trip!
  }

  items: MenuItem[] = [
    {
      title: 'removeAttendee',
      operation: () => {
        const dialog = this.$refs.confirmDialog as LConfirmDialog
        setTimeout(() => {
          if (dialog) dialog.toggleDialog()
        })
      },
    },
    {
      title: 'showPicture',
      operation: () => {
        this.$emit('click:img')
      },
    },
  ]

  async removeAttendee() {
    await tripModule.leave(this.user.id)
    tripModule.fetch(this.trip.id)
  }
}

export default AttendeeMenu
