
import { Component, Prop, Vue } from 'vue-property-decorator'
import { expenseModule, tripModule } from '@/store'
import { Expense } from '@/types/expense'
import { User } from '@/types/user'
import { formattedPrice, isBrowser } from '@/utils/utility-manager'
import { Trip } from '@/types/trip'

@Component({
  name: 'AttendeeListItem',
})
class AttendeeListItem extends Vue {
  @Prop({ required: true }) readonly attendee?: User
  @Prop({ required: true }) readonly amount?: number
  @Prop({ default: 'primary', type: String }) readonly amountColor!: string

  get currency(): string {
    return this.expense?.foreignCurrency || this.trip?.currency || ''
  }

  get trip(): Trip | null {
    return tripModule.trip
  }

  get expense(): Expense | null {
    return expenseModule.expense
  }

  formattedAmount(amount: number, currency: string): string {
    if (!isBrowser() || !this.expense) {
      return '-'
    }

    return formattedPrice(window.navigator.language, currency, amount)
  }
}
export default AttendeeListItem
