import createPersistedState from 'vuex-persistedstate'
import {
  get as getCookie,
  set as setCookie,
  remove as removeCookie,
} from 'js-cookie'
import cookie from 'cookie'
import { isBrowser } from '@/utils/utility-manager'

export default ({ store, req, isDev }: any) => {
  // persist auth state as cookie
  createPersistedState({
    key: 'auth_local',
    paths: ['auth.token', 'auth.refreshToken'],
    storage: {
      getItem: (key) =>
        isBrowser()
          ? JSON.parse(getCookie(key) || '{}')
          : cookie.parse(req.headers.cookie || '')[key],
      setItem: (key, value) =>
        setCookie(key, value, { expires: 365, secure: !isDev }),
      removeItem: (key) => removeCookie(key),
    },
  })(store)
}
