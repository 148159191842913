module.exports = {
  pro: {
    checkout: {
      title: 'Tingkatkan ke Lambus PRO',
      description:
        'Aktifkan beberapa fitur tambahan untuk membuat perencanaan perjalananmu lebih mudah lagi! 🎉',
      payPerTrip: {
        title: 'Bayar per perjalanan',
        caption: 'Tingkatkan perjalanan satu (untuk semua)',
      },
      yearlySubscription: {
        title: 'Langganan Tahunan',
        caption: 'Tingkatkan seluruh perjalananmu',
        freeTrial: 'UJI 7 HARI GRATIS!',
      },
      success: {
        title: 'Lambus PRO: Pembelian berhasil!',
      },
      learnMore: 'Pelajari lebih lanjut tentang Lambus PRO',
      upgradeNowUpper: 'TINGKATKAN SEKARANG 🚀',
      thankYouNotice:
        'Terima kasih telah membeli Lambus PRO! ❤️ Halaman ini akan menutup sendiri secara otomatis dalam {time} detik.',
    },
    features: {
      routePlanning: {
        title: 'Perpanjang Perencanaan Rute',
        description: `Misalnya durasi &amp; jarak antara titik jalan`,
      },
      googleMaps: {
        title: 'Pencarian Google Maps',
        description:
          'Kami menggunakan Google Maps untuk menemukan lebih banyak titik arah',
      },
      videoChat: {
        title: 'Obrolan Video',
        description:
          'Ngobrol dengan peserta lain sambil merencanakan perjalanan Anda',
      },
      moneyPool: {
        title: 'Kumpulan Uang',
        description: 'Kelola uang perjalanan di satu tempat terpusat',
      },
      exportTripGpx: {
        title: 'Ekspor perjalanan sebagai GPX',
        description: 'Ekspor sebagai GPX dan gunakan dalam aplikasi pelacakan',
      },
      currencies: {
        title: 'Mata uang otomatis',
        description:
          'Konversikan mata uang asing menggunakan nilai tukar saat ini',
      },
      weather: {
        title: 'Pembaharuan cuaca secara langsung',
        description: 'Untuk semua titik jalan perjalanan anda',
      },
      exportExpenses: {
        title: 'Ekspor pengeluaran',
        description: 'Sebagai Excel- atau file PDF lewat Email',
      },
      flightStatus: {
        title: 'Peringatan penerbangan',
        description:
          'Misalnya, check-in, perubahan penerbangan atau gerbang baru',
      },
      exportTrip: {
        title: 'Ekspor perjalanan sebagai PDF',
        description:
          'Ekspor perjalanan Anda sebagai PDF agar tersedia bahkan tanpa smartphone',
      },
      support: {
        title: 'Dukung kami ❤️',
        description:
          'Dengan bantuan Anda, Lambus dapat tetap bebas iklan dan bagus',
      },
      packingList: {
        title: 'Daftar (pengepakan, tugas)',
        description: 'Pilih dari daftar yang ada atau buat daftar Anda sendiri',
      },
      flightCompensation: {
        title: 'Kompensasi Penerbangan',
        description:
          'Klaim kompensasi secara otomatis jika penerbangan Anda dibatalkan',
      },
      privateDocuments: {
        title: 'Dokumen Pribadi',
        description:
          'Simpan paspor, SIM, dan lainnya dengan aman di perangkat Anda',
      },
      offlineMode: {
        title: 'Mode Offline',
        description:
          'Akses seluruh perjalanan Anda bahkan tanpa koneksi internet',
      },
      calendarSync: {
        title: 'Sinkronisasi Kalender',
        description:
          'Menyinkronkan titik arah dan POI Anda dengan kalender Anda',
      },
    },
  },
  sortExpensesBy: 'Urutkan pengeluaran berdasarkan …',
  expenseCreated: 'Pengeluaran dibuat',
  expenseDate: 'Tanggal pengeluaran',
  canoe_tour: 'Tur kano',
  hiking: 'Mendaki',
  camping: 'Kemah',
  beach: 'Pantai',
  swimming: 'Renang',
  boat_tour: 'Tur perahu',
  campfire: 'Api unggun',
  party: 'Pesta',
  bike_tour: 'Tur sepeda',
  surfing: 'Selancar',
  snorkeling: 'Snorkeling',
  picknick: 'Piknik',
  food: 'Makanan',
  horse_riding: 'Berkuda',
  sport_event: 'Acara olahraga',
  concert: 'Konser',
  cinema: 'Bioskop',
  winter_sports: 'Olahraga musim dingin',
  searchActivitiesAndPlaces: 'Mencari tempat dan aktivitas yang Anda simpan',
}
