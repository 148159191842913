var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t('inviteYourFriends'))+" ")])],1)],1),_vm._v(" "),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_vm._v("\n        "+_vm._s(_vm.$t('inviteHint'))+"\n      ")])],1),_vm._v(" "),_c('l-form',{ref:"inviteForm",attrs:{"action":_vm.$t('sendInvite')},on:{"save":_vm.inviteHandler}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"placeholder":_vm.$t('enterEmail').toString().toUpperCase(),"rules":_vm.inviteEmails.length > 0 && _vm.inviteEmail.length === 0
                ? []
                : [_vm.rules.EMAIL]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addInvite.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',[_vm._v("$vuetify.icon.email")])]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"disabled":_vm.rules.EMAIL(_vm.inviteEmail) !== true &&
                  _vm.inviteEmails.length === 0,"fab":"","depressed":"","height":"24px","width":"24px","color":"primary"},on:{"click":_vm.addInvite}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("$vuetify.icon.plus")])],1)]},proxy:true}]),model:{value:(_vm.inviteEmail),callback:function ($$v) {_vm.inviteEmail=$$v},expression:"inviteEmail"}})],1)],1),_vm._v(" "),_c('v-row',{staticClass:"email-list pt-0"},[_c('v-col',{staticClass:"pt-0"},_vm._l((_vm.inviteEmails),function(email,index){return _c('v-text-field',{key:email,staticClass:"email-item",attrs:{"readonly":"","dense":"","solo":"","flat":"","hide-details":"","value":email},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{on:{"click":function($event){return _vm.inviteEmails.splice(index, 1)}}},[_vm._v("\n                $vuetify.icon.delete\n              ")])]},proxy:true}],null,true)})}),1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('v-checkbox',{staticClass:"invite-checkbox",attrs:{"id":"inviteCheckbox","color":"primary","rules":[_vm.rules.REQUIRED]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.$t('inviteAggreement'))+"\n            ")]},proxy:true}]),model:{value:(_vm.emailAgreed),callback:function ($$v) {_vm.emailAgreed=$$v},expression:"emailAgreed"}})],1)],1)],1),_vm._v(" "),_c('v-row',{staticClass:"py-6"},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('clipboard-text-field',{attrs:{"text-value":_vm.dashboardLink,"copy-value":_vm.dashboardLink,"width":"230px"}})],1)],1),_vm._v(" "),_c('l-dialog',{ref:"invitePrompt",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t('inviteYourFriends'))+"\n      ")]},proxy:true},{key:"content",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t('invitePrompt'))+"\n        "),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[(_vm.trips)?_c('v-list-item',{on:{"click":function($event){_vm.trips.length === 1
                  ? _vm.inviteUserToTrip(_vm.trips[0])
                  : _vm.showTripList(true)}}},[_vm._v("\n              "+_vm._s(_vm.trips.length === 1
                  ? `${_vm.$t('yes')} (${_vm.trips[0].name})`
                  : `${_vm.$t('yesSelectTrip')}`)+"\n            ")]):_vm._e(),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.inviteUser(true)}}},[_vm._v("\n              "+_vm._s(_vm.$t('inviteToLambus'))+"\n            ")])],1)],1)]},proxy:true}])}),_vm._v(" "),_c('l-dialog',{ref:"tripList",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-container',{staticClass:"text-capitalize pa-0"},[_vm._v("\n          "+_vm._s(_vm.$t('selectTrip'))+"\n        ")])]},proxy:true},{key:"content",fn:function(){return [_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.trips),function(trip){return _c('v-list-item',{key:trip.id,on:{"click":function($event){return _vm.inviteUserToTrip(trip, true)}}},[_vm._v("\n              "+_vm._s(trip.name)+"\n            ")])}),1)],1)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }