
import { Component, Prop, Vue } from 'vue-property-decorator'

import LButton from '@/components/core/Button.vue'
import ClipboardTextField from '@/components/ClipboardTextField.vue'
import LConfirmDialog from '@/global-components/LConfirmDialog.vue'
import { tripModule, userModule } from '@/store'
import { Trip } from '@/types/trip'
import { User } from '@/types/user'
@Component({
  name: 'AttendeeInviteForm',
  components: {
    ClipboardTextField,
    LConfirmDialog,
    LButton,
  },
})
class AttendeeInviteForm extends Vue {
  @Prop() readonly trip?: Trip

  userSearch = ''
  invitee: User | null = null

  get currentTrip(): Trip | null {
    return this.trip || tripModule.trip
  }

  get joinLink(): string {
    if (!this.currentTrip) {
      return ''
    }

    return (
      process.env.LAMBUS_HOMEPAGE +
      '/joinTrip/' +
      this.currentTrip.token.split('@')[0]
    )
  }

  async toggleInvitePrompt(): Promise<void> {
    await this.searchUser()

    if (this.invitee) {
      const dialog = this.$refs.invitePrompt as LConfirmDialog
      dialog.toggleDialog()
    }
  }

  inviteAttendee(): void {
    if (!this.currentTrip) {
      return
    }

    tripModule.invite({ tripId: this.currentTrip.id, value: this.userSearch })
  }

  async searchUser() {
    if (this.userSearch.trim().length === 0) {
      Vue.set(this, 'invitee', null)
      return
    }
    const foundUser = await userModule.searchUser(this.userSearch)

    Vue.set(this, 'invitee', foundUser)
  }
}
export default AttendeeInviteForm
