import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { isBrowser } from '@/utils/utility-manager'

export default () => {
  if (!isBrowser()) {
    return
  }

  const app = initializeApp({
    apiKey: process.env.GOOGLE_API_KEY,
    authDomain: 'lambus-app.firebaseapp.com',
    databaseURL: 'https://lambus-app.firebaseio.com',
    projectId: 'lambus-app',
    storageBucket: 'lambus-app.appspot.com',
    messagingSenderId: '359560359971',
    appId: '1:359560359971:web:0f325d654051187bbdc196',
    measurementId: 'G-EQBDS880LL',
  })

  getAnalytics(app)
}
