
import { Vue, Component, Prop } from 'vue-property-decorator'
import { authModule, eventModule } from '@/store'
import { Category } from '@/types/discover'

@Component({
  name: 'RelatedCategoryItem',
})
class RelatedCategoryItem extends Vue {
  @Prop() readonly topic!: Category

  get discoverSearchModal(): boolean {
    return eventModule.discoverSearchModal
  }

  set discoverSearchModal(value: boolean) {
    eventModule.setDiscoverSearchModal(value)
  }

  routeToCategoriesGrid(): void {
    this.$router.push({
      name: 'discover-categories-categories',
      params: {
        categories: this.topic.id,
        name: this.topic.name,
      },
    })
  }

  onClick(): void {
    if (!this.topic.id || !this.topic.name) {
      return
    }

    if (
      !authModule.loggedIn ||
      (this.$vuetify.breakpoint.smAndDown && this.discoverSearchModal)
    ) {
      this.$emit('click:item', this.topic)
    } else if (this.discoverSearchModal) {
      this.routeToCategoriesGrid()
      this.discoverSearchModal = false
    } else {
      this.routeToCategoriesGrid()
    }
  }
}

export default RelatedCategoryItem
