import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'

import { useContext } from '@/composables/context'
import { Notification } from '@/types/notification'

const notificationQueryKeys = {
  all: () => ['notifications'] as const,
  lists: () => [...notificationQueryKeys.all(), 'list'] as const,
  list: () => [...notificationQueryKeys.lists()] as const,
}

export function useNotificationList() {
  const { $axios } = useContext()

  return useQuery({
    queryKey: notificationQueryKeys.list(),
    queryFn: async () => {
      const { data } = await $axios.get<{ notifications: Notification[] }>(
        `/api/notifications`,
      )
      return data
    },
  })
}

export function useMarkAllNotificationsAsRead() {
  const { $axios } = useContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      await $axios.put(`/api/notifications/read`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(notificationQueryKeys.lists())
    },
  })
}

export function useMarkNotificationAsRead() {
  const { $axios } = useContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notificationId: string) => {
      await $axios.put(`/api/notifications/${notificationId}/read`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(notificationQueryKeys.lists())
    },
  })
}

export function useHideNotification() {
  const { $axios } = useContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notificationId: string) => {
      await $axios.put(`/api/notifications/${notificationId}/hide`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(notificationQueryKeys.lists())
    },
  })
}
