
import { Component, Prop, Vue } from 'vue-property-decorator'
import Intercom from '@/utils/intercom-manager'

@Component({
  name: 'DocumentViewer',
})
class DocumentViewer extends Vue {
  @Prop() readonly url!: string
  @Prop() readonly title?: string
  showDialog = false
  showLoader = false
  numPages = 1
  zoom = 1.5
  loadingTask: any = null
  pdf: any = null
  pdfjsLib: any = null
  pdfjsWorker: any = null

  created() {
    this.pdfjsLib = require('pdfjs-dist/legacy/build/pdf')
    this.pdfjsWorker = require('pdfjs-dist/legacy/build/pdf.worker.entry.js')
    this.pdfjsLib.GlobalWorkerOptions.workerSrc = this.pdfjsWorker
  }

  get docSrc(): string {
    return this.showDialog ? this.url : ''
  }

  renderPDF(fullscreen?: boolean): void {
    for (let currentPage = 1; currentPage <= this.numPages; currentPage++) {
      this.pdf.getPage(currentPage).then((page: any) => {
        if (fullscreen) {
          this.zoom = window.innerWidth / page._pageInfo.view[3] + 0.15
        }
        const viewport = page.getViewport({
          scale: this.zoom,
        })
        const canvas = document.getElementById(
          `pdf-canvas-${currentPage}`,
        ) as any
        const context = canvas.getContext('2d')

        canvas.height = viewport.height
        canvas.width = viewport.width

        const renderContext = {
          canvasContext: context,
          viewport,
        }
        page.render(renderContext)
      })
    }
    this.showLoader = false
  }

  public toggleDialog(): void {
    this.showDialog = true
    this.showLoader = true

    this.$nextTick(() => {
      this.loadingTask = this.pdfjsLib.getDocument({ url: this.url })
      this.loadingTask.onPassword = (cb: any, reason: any) => {
        if (reason === 1) {
          const password = window.prompt(
            this.$t('pleaseEnterThePDFPassword') as string,
          )
          if (!password) {
            throw new Error('Empty password')
          }
          cb(password)
        } else {
          throw new Error('Wrong password')
        }
      }
      this.loadingTask.promise
        .then((pdf: any) => {
          this.pdf = pdf
          this.numPages = pdf.numPages
          this.renderPDF(true)
          this.showLoader = false
        })
        .catch((_: any) => {
          this.showLoader = false
          this.closeDialog()
          setTimeout(() => {
            alert(this.$t('cannotOpenPDF').toString())
            Intercom.show()
          }, 500)
        })
    })
  }

  zoomIn(): void {
    this.zoom += 0.25
    this.renderPDF()
  }

  zoomOut(): void {
    this.zoom -= 0.25
    this.renderPDF()
  }

  closeDialog(): void {
    this.showDialog = false
    this.numPages = 0
  }

  saveDocument() {
    window.open(this.docSrc, '_blank')
  }
}

export default DocumentViewer
