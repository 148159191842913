import { fetchEventSource } from '@microsoft/fetch-event-source'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { type MaybeRef } from '@vueuse/core'
import { computed, ref, unref } from 'vue'
import { nanoid } from 'nanoid'

import { useContext } from '@/composables/context'
import { tripQueryKeys } from '@/composables/trip'
import { eventModule } from '@/store'
import type {
  DataMessage,
  Message,
  Place,
  Text,
  TextDelta,
} from '@/types/assistant'
import { isBrowser, logEvent } from '@/utils/utility-manager'

export type AssistantStatus =
  | 'streaming_message'
  | 'awaiting_assistant_message'
  | 'awaiting_user_message'

type StandardEventSchema = {
  data: Record<string, any>
}

type StandardEventSchemas = Record<string, StandardEventSchema>

type EventPayload<TData = any> = {
  name: string
  data: TData
}

/**
 * A helper type to convert input schemas into the format expected by the
 * `EventSchemas` class, which ensures that each event contains all pieces
 * of information required.
 *
 * It purposefully uses slightly more complex (read: verbose) mapped types to
 * flatten the output and preserve comments.
 *
 * @public
 */
export type StandardEventSchemaToPayload<T> = {
  [K in keyof T & string]: {
    [K2 in keyof (Omit<EventPayload, keyof T[K]> & T[K] & { name: K })]: (Omit<
      EventPayload,
      keyof T[K]
    > &
      T[K] & { name: K })[K2]
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class EventSchemas<Schemas extends Record<string, EventPayload>> {
  fromRecord<EventSchemas extends StandardEventSchemas>() {
    return new EventSchemas<StandardEventSchemaToPayload<EventSchemas>>()
  }
}

type GetEvents<TInput> =
  TInput extends EventSchemas<infer U> ? U : Record<string, EventPayload>

type SendEventPayload<Events extends Record<string, EventPayload>> = {
  [K in keyof Events]: Events[K]
}[keyof Events]

export type JSONValue =
  | null
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>

export type OnTextHandler = (options: {
  content: Text
  delta?: TextDelta
  done: boolean
}) => void

export interface UseAssistantOptions<
  TSchemas extends EventSchemas<Record<string, EventPayload>>,
> {
  /**
   * The API endpoint that accepts one ore more messages, starts a new thread run and
   * returns an `AssistantResponse` stream.
   */
  api: MaybeRef<string>

  /**
   * Additional headers to send to the API endpoint.
   */
  headers?: Record<string, string>

  schemas?: TSchemas

  /**
   * Callback function for when a new message is created
   */
  onMessage?: (message: Message<unknown>) => void

  /**
   * Callback function to handle updates of text based message blocks.
   */
  onText?: OnTextHandler

  /**
   * Callback function for data messages.
   */
  onData?: (message: DataMessage<SendEventPayload<GetEvents<TSchemas>>>) => void

  /**
   * Callback function for when a new place annotation is available.
   */
  onPlace?: (place: Place) => void
}

export function useAssistant<
  TSchemas extends EventSchemas<Record<string, EventPayload>>,
>(options: UseAssistantOptions<TSchemas>) {
  const { api, headers, onMessage, onText, onPlace } = options
  const isRunning = ref(false)
  const status = ref<AssistantStatus>('awaiting_user_message')

  function sendMessage(content: string) {
    isRunning.value = true

    status.value = 'awaiting_assistant_message'

    fetchEventSource(unref(api), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify({
        messages: [
          {
            id: nanoid(),
            role: 'user',
            content,
          },
        ],
      }),
      onmessage(event) {
        const data = JSON.parse(event.data)
        switch (event.event) {
          case 'message.created': {
            onMessage?.(data)
            break
          }
          case 'text.created': {
            status.value = 'streaming_message'
            onText?.({
              content: data,
              done: false,
            })
            break
          }
          case 'text.delta': {
            onText?.({
              content: data.snapshot,
              delta: data.delta,
              done: false,
            })
            break
          }
          case 'place.done': {
            onPlace?.(data.place)
          }
        }
      },
      onclose() {
        isRunning.value = false
        status.value = 'awaiting_user_message'
      },
      onerror(err) {
        isRunning.value = false
        throw err
      },
    })
  }

  return {
    sendMessage,
    isRunning,
    status,
  }
}

export function useCreateOnboardingSession() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async () => {
      const { data } = await $axios.post<{
        sessionHandle: string
        secret: string
        conversation: {
          _id: string
          trip: {
            _id: string
          }
        }
      }>('/api/assistant/onboarding')

      return data
    },
  })
}

export function useAddPlace() {
  const { $axios } = useContext()

  return useMutation({
    mutationFn: async (
      payload:
        | { placeId: string; tripId: string }
        | { placeId: string; sessionHandle: string },
    ) => {
      await $axios.post('/api/assistant/places', payload)
    },
  })
}

export function useClaimOnboardingSession() {
  const { $axios } = useContext()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (payload: { sessionHandle: string; secret: string }) => {
      const { data } = await $axios.post<{ trip: { _id: string } }>(
        '/api/assistant/onboarding/claim',
        payload,
      )

      if (isBrowser()) {
        logEvent('assistant_onboarding_claimed_session', {
          sessionHandle: payload.sessionHandle,
        })
      }

      return data
    },
    onSuccess: () => {
      eventModule.newMessage('success.trip.create')
      queryClient.invalidateQueries({ queryKey: tripQueryKeys.lists() })
    },
    onError: () => {
      eventModule.newError('error.trip.create')
    },
  })
}

export function usePlaceIcon(placeRef: MaybeRef<Place>) {
  const icon = computed(() => {
    const place = unref(placeRef)
    if (place.status !== 'active') {
      return '$marker'
    }

    if (place.type === 'location') {
      return '$marker'
    }

    if (place.categories.includes('beach')) {
      return '$beach'
    } else if (place.categories.includes('church')) {
      return '$churchOutline'
    } else if (place.categories.includes('island')) {
      return '$island'
    } else if (place.categories.includes('museum')) {
      return '$bankOutline'
    } else if (place.categories.includes('park')) {
      return '$treeOutline'
    }

    return '$marker'
  })

  return icon
}
