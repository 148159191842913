
import { Component, Vue } from 'vue-property-decorator'

import TripExportModal from '@/components/pro/export/TripExportModal.vue'
import ExpenseExportModal from '@/components/pro/export/ExpenseExportModal.vue'
import GpxExportModal from '@/components/pro/export/GpxExportModal.vue'
import ProBadge from '@/components/pro/ProBadge.vue'
import { eventModule, tripModule } from '@/store'
import { Trip } from '@/types/trip'
@Component({
  name: 'ExportExpansionPanel',
  components: {
    TripExportModal,
    ExpenseExportModal,
    GpxExportModal,
    ProBadge,
  },
})
class ExportExpansionPanel extends Vue {
  showExpenseExportConfirmModal = false
  showGpxExportConfirmModal = false
  showTripExportModal = false

  get trip(): Trip {
    return tripModule.trip!
  }

  openTripExportModal(): void {
    this.showTripExportModal = true
  }

  toggleTripExportConfirmModal(): void {
    if (this.trip?.activeProSubscription) {
      this.openTripExportModal()
    } else {
      eventModule.toggleProHintModal()
    }
  }

  toggleExpenseExportConfirmModal(): void {
    if (this.trip.activeProSubscription) {
      this.showExpenseExportConfirmModal = true
    } else {
      eventModule.toggleProHintModal()
    }
  }

  toggleGpxExportConfirmModal(): void {
    if (this.trip.activeProSubscription) {
      this.showGpxExportConfirmModal = true
    } else {
      eventModule.toggleProHintModal()
    }
  }
}
export default ExportExpansionPanel
