
import { Component, Vue } from 'vue-property-decorator'

import FlightNumberField from '@/components/trip/transportation/FlightNumberField.vue'
import DateTimeField from '@/components/trip/transportation/forms/generic/DateTimeField.vue'
import { tripModule, transportationModule } from '@/store'
import { Trip } from '@/types/trip'
import { FlightBooking, FlightDetails } from '@/types/transportation'
import { TransportationType } from '@/utils/enums'

@Component({
  name: 'FlightForm',
  components: {
    FlightNumberField,
    DateTimeField,
  },
})
class FlightForm extends Vue {
  isSearchingFlight = false
  isValidForm = false
  date = ''
  flightNumber = ''

  get trip(): Trip | null {
    return tripModule.trip
  }

  get flightDetails(): FlightDetails | null {
    return transportationModule.flightDetails
  }

  get disableButton(): boolean {
    return (
      !this.isValidForm ||
      this.flightNumber.trim().length === 0 ||
      this.date.trim().length === 0
    )
  }

  resetValues(): void {
    this.flightNumber = ''

    const dateField = this.$refs.flightFormDateField as DateTimeField
    if (dateField) {
      dateField.clearPickerDate()
    }

    const flightField = this.$refs.flightFormFlightField as FlightNumberField
    if (flightField) {
      flightField.resetValidation()
    }

    const form = this.$refs.flightForm as any
    if (form) {
      form.resetValidation()
    }
  }

  async searchFlight(): Promise<void> {
    if (!this.trip) {
      return
    }

    this.isSearchingFlight = true

    await transportationModule.searchFlight({
      flightNumber: this.flightNumber,
      departureDate: this.date,
    })

    this.isSearchingFlight = false

    if (!this.flightDetails) {
      transportationModule.setCurrentFlightDetails({
        departureDateFlight: this.date,
        flightNumber: this.flightNumber,
      })
    }

    const newBooking: Omit<FlightBooking, '_id' | 'summary'> = {
      type: TransportationType.FLIGHT,
      departureDate: this.date,
      flightNumber: this.flightNumber,
      trip: this.trip.id,
    }

    if (transportationModule.currentOrigin) {
      newBooking.originWaypoint = transportationModule.currentOrigin

      if (transportationModule.currentDestination) {
        newBooking.destinationWaypoint = transportationModule.currentDestination
      }
    }

    transportationModule.setBooking(newBooking)

    this.$emit('click:add', TransportationType.FLIGHT)
    this.resetValues()
  }

  skipSearch(): void {
    this.$emit('click:skip', TransportationType.FLIGHT)
    this.resetValues()
  }
}
export default FlightForm
