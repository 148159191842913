import { Booking, GroupedBooking } from '@/types/transportation'
import { Trip, Waypoint } from '@/types/trip'

/**
 * Groups bookings with same orgin & destination.
 *
 * @param bookings A list of bookings that should be grouped.
 * @param trip The trip the bookings belong to.
 */
export function groupBookings(bookings: Booking[], trip: Trip) {
  /* link bookings with same origin & destination */
  const groupedBookings: GroupedBooking[] = []

  for (const booking of bookings) {
    // search current booking in groupedBookings
    const index = groupedBookings.findIndex((el: any) => {
      return el.origin && el.destination
        ? booking.originWaypoint &&
            booking.destinationWaypoint &&
            el.origin._id === booking.originWaypoint._id &&
            el.destination._id === booking.destinationWaypoint._id
        : false
    })
    // if booking does not exist in groupedBookings
    if (index === -1) {
      // get origin of current booking
      const origin = trip.waypoints.find((element: Waypoint) => {
        return (
          booking.originWaypoint && element._id === booking.originWaypoint._id
        )
      })
      // get destination of current booking
      const destination = trip.waypoints.find(
        (element: Waypoint) =>
          booking.destinationWaypoint &&
          element._id === booking.destinationWaypoint._id,
      )

      // create a new object with origin, destination, booking
      if (origin && destination) {
        groupedBookings.push({
          _id: booking._id,
          origin,
          destination,
          data: [booking],
        })
      } else {
        groupedBookings.push({
          _id: booking._id,
          origin: null,
          destination: null,
          data: [booking],
        })
      }
    } else {
      // if booking found, push the booking to groupedBookings.data
      groupedBookings[index].data.push(booking)
    }
  }

  return groupedBookings
}
