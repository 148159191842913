import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _03890ed4 = () => interopDefault(import('../pages/discover/index.vue' /* webpackChunkName: "pages/discover/index" */))
const _163fc505 = () => interopDefault(import('../pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _52f3def2 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _7a4ff2bb = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _e2336d9e = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _4d4ea84e = () => interopDefault(import('../pages/assistant/onboarding/index.vue' /* webpackChunkName: "pages/assistant/onboarding/index" */))
const _24287b74 = () => interopDefault(import('../pages/business/sign-up.vue' /* webpackChunkName: "pages/business/sign-up" */))
const _709dec67 = () => interopDefault(import('../pages/business/travelers.vue' /* webpackChunkName: "pages/business/travelers" */))
const _1cc7ce41 = () => interopDefault(import('../pages/discover/bookmarks.vue' /* webpackChunkName: "pages/discover/bookmarks" */))
const _397065c9 = () => interopDefault(import('../pages/pro/features.vue' /* webpackChunkName: "pages/pro/features" */))
const _54e9259a = () => interopDefault(import('../pages/pro/subscriptions.vue' /* webpackChunkName: "pages/pro/subscriptions" */))
const _50bc8527 = () => interopDefault(import('../pages/pro/success.vue' /* webpackChunkName: "pages/pro/success" */))
const _241175da = () => interopDefault(import('../pages/user/packing-lists/index.vue' /* webpackChunkName: "pages/user/packing-lists/index" */))
const _153721ed = () => interopDefault(import('../pages/user/settings.vue' /* webpackChunkName: "pages/user/settings" */))
const _8d1b3550 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c2dab6c0 = () => interopDefault(import('../pages/assistant/onboarding/_session.vue' /* webpackChunkName: "pages/assistant/onboarding/_session" */))
const _ca16eb98 = () => interopDefault(import('../pages/discover/categories/_categories.vue' /* webpackChunkName: "pages/discover/categories/_categories" */))
const _b3760dc8 = () => interopDefault(import('../pages/discover/inspiration/_inspiration.vue' /* webpackChunkName: "pages/discover/inspiration/_inspiration" */))
const _308abb4c = () => interopDefault(import('../pages/discover/search/_search.vue' /* webpackChunkName: "pages/discover/search/_search" */))
const _c378b260 = () => interopDefault(import('../pages/user/packing-lists/_packingList.vue' /* webpackChunkName: "pages/user/packing-lists/_packingList" */))
const _1a5e1686 = () => interopDefault(import('../pages/trip/_id/index.vue' /* webpackChunkName: "pages/trip/_id/index" */))
const _4e7af13d = () => interopDefault(import('../pages/trip/_id/chat.vue' /* webpackChunkName: "pages/trip/_id/chat" */))
const _06169900 = () => interopDefault(import('../pages/trip/_id/expenses.vue' /* webpackChunkName: "pages/trip/_id/expenses" */))
const _e3b4e9a8 = () => interopDefault(import('../pages/trip/_id/notes.vue' /* webpackChunkName: "pages/trip/_id/notes" */))
const _681ffea2 = () => interopDefault(import('../pages/trip/_id/notes/index.vue' /* webpackChunkName: "pages/trip/_id/notes/index" */))
const _cac8c496 = () => interopDefault(import('../pages/trip/_id/notes/_notes.vue' /* webpackChunkName: "pages/trip/_id/notes/_notes" */))
const _05b03adf = () => interopDefault(import('../pages/trip/_id/packing-lists/index.vue' /* webpackChunkName: "pages/trip/_id/packing-lists/index" */))
const _243a3c3e = () => interopDefault(import('../pages/trip/_id/subscriptions.vue' /* webpackChunkName: "pages/trip/_id/subscriptions" */))
const _0f1a7e40 = () => interopDefault(import('../pages/trip/_id/packing-lists/template/index.vue' /* webpackChunkName: "pages/trip/_id/packing-lists/template/index" */))
const _27519cc7 = () => interopDefault(import('../pages/trip/_id/packing-lists/template/_template.vue' /* webpackChunkName: "pages/trip/_id/packing-lists/template/_template" */))
const _24a3136f = () => interopDefault(import('../pages/trip/_id/documents/_file.vue' /* webpackChunkName: "pages/trip/_id/documents/_file" */))
const _c459bbaa = () => interopDefault(import('../pages/trip/_id/packing-lists/_packingList.vue' /* webpackChunkName: "pages/trip/_id/packing-lists/_packingList" */))
const _f0b2d49c = () => interopDefault(import('../pages/trip/_id/photos/_file.vue' /* webpackChunkName: "pages/trip/_id/photos/_file" */))
const _2324d495 = () => interopDefault(import('../pages/trip/_id/waypoints/_waypoints.vue' /* webpackChunkName: "pages/trip/_id/waypoints/_waypoints" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/discover",
    component: _03890ed4,
    name: "discover"
  }, {
    path: "/onboarding",
    component: _163fc505,
    name: "onboarding"
  }, {
    path: "/password-reset",
    component: _52f3def2,
    name: "password-reset"
  }, {
    path: "/sign-in",
    component: _7a4ff2bb,
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _e2336d9e,
    name: "sign-up"
  }, {
    path: "/assistant/onboarding",
    component: _4d4ea84e,
    name: "assistant-onboarding"
  }, {
    path: "/business/sign-up",
    component: _24287b74,
    name: "business-sign-up"
  }, {
    path: "/business/travelers",
    component: _709dec67,
    name: "business-travelers"
  }, {
    path: "/discover/bookmarks",
    component: _1cc7ce41,
    name: "discover-bookmarks"
  }, {
    path: "/pro/features",
    component: _397065c9,
    name: "pro-features"
  }, {
    path: "/pro/subscriptions",
    component: _54e9259a,
    name: "pro-subscriptions"
  }, {
    path: "/pro/success",
    component: _50bc8527,
    name: "pro-success"
  }, {
    path: "/user/packing-lists",
    component: _241175da,
    name: "user-packing-lists"
  }, {
    path: "/user/settings",
    component: _153721ed,
    name: "user-settings"
  }, {
    path: "/",
    component: _8d1b3550,
    name: "index"
  }, {
    path: "/assistant/onboarding/:session",
    component: _c2dab6c0,
    name: "assistant-onboarding-session"
  }, {
    path: "/discover/categories/:categories?",
    component: _ca16eb98,
    name: "discover-categories-categories"
  }, {
    path: "/discover/inspiration/:inspiration?",
    component: _b3760dc8,
    name: "discover-inspiration-inspiration"
  }, {
    path: "/discover/search/:search?",
    component: _308abb4c,
    name: "discover-search-search"
  }, {
    path: "/user/packing-lists/:packingList?",
    component: _c378b260,
    name: "user-packing-lists-packingList"
  }, {
    path: "/trip/:id",
    component: _1a5e1686,
    name: "trip-id"
  }, {
    path: "/trip/:id?/chat",
    component: _4e7af13d,
    name: "trip-id-chat"
  }, {
    path: "/trip/:id?/expenses",
    component: _06169900,
    name: "trip-id-expenses"
  }, {
    path: "/trip/:id?/notes",
    component: _e3b4e9a8,
    children: [{
      path: "",
      component: _681ffea2,
      name: "trip-id-notes"
    }, {
      path: ":notes",
      component: _cac8c496,
      name: "trip-id-notes-notes"
    }]
  }, {
    path: "/trip/:id?/packing-lists",
    component: _05b03adf,
    name: "trip-id-packing-lists"
  }, {
    path: "/trip/:id?/subscriptions",
    component: _243a3c3e,
    name: "trip-id-subscriptions"
  }, {
    path: "/trip/:id?/packing-lists/template",
    component: _0f1a7e40,
    name: "trip-id-packing-lists-template"
  }, {
    path: "/trip/:id?/packing-lists/template/:template",
    component: _27519cc7,
    name: "trip-id-packing-lists-template-template"
  }, {
    path: "/trip/:id?/documents/:file?",
    component: _24a3136f,
    name: "trip-id-documents-file"
  }, {
    path: "/trip/:id?/packing-lists/:packingList?",
    component: _c459bbaa,
    name: "trip-id-packing-lists-packingList"
  }, {
    path: "/trip/:id?/photos/:file?",
    component: _f0b2d49c,
    name: "trip-id-photos-file"
  }, {
    path: "/trip/:id?/waypoints/:waypoints?",
    component: _2324d495,
    name: "trip-id-waypoints-waypoints"
  }, {
    path: "/trips",
    component: _8d1b3550,
    name: "trips"
  }, {
    path: "/archive",
    component: _8d1b3550,
    name: "archive"
  }, {
    path: "/trip/:id/waypoints/:waypoints/places/:places",
    component: _2324d495,
    name: "places"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
