import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { PendingTrip } from '@/types/onboarding'

interface AssistantOnboardingSession {
  sessionHandle: string
  secret: string
}

export const useOnboardingStore = defineStore('onboarding', {
  state: () => ({
    pendingTrip: null as PendingTrip | null,
    previousAuthenticatingRoutePath: '/sign-in' as string | null,
    assistantSessions: useLocalStorage<AssistantOnboardingSession[]>(
      'onboarding:assistant-sessions',
      [],
    ),
    activeAssistantSessionHandle: null as string | null,
    activeAssistantSession: null as AssistantOnboardingSession | null,
  }),
  actions: {
    setPendingTrip(pendingTrip: PendingTrip) {
      this.pendingTrip = pendingTrip
    },

    resetPendingTrip() {
      this.pendingTrip = null
    },
    addAssistantSession(session: AssistantOnboardingSession) {
      this.assistantSessions.push(session)
    },
    activateAssistantSession(sessionHandle: string) {
      this.activeAssistantSessionHandle = sessionHandle
      const session = this.assistantSessions.find(
        (session) => session.sessionHandle === sessionHandle,
      )
      if (session) {
        this.activeAssistantSession = session
      }
    },
  },
  hydrate(state) {
    // @ts-expect-error The state's type is unrefed
    state.assistantSessions = useLocalStorage<AssistantOnboardingSession[]>(
      'onboarding:assistant-sessions',
      [],
    )

    const session = state.assistantSessions.find(
      (session) => session.sessionHandle === state.activeAssistantSessionHandle,
    )
    if (session) {
      state.activeAssistantSession = session
    }
  },
})
