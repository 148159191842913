
import { defineComponent } from 'vue'

import BusinessTabs from '@/components/business/BusinessTabs.vue'
import Navbar from '@/components/navbar/Navbar.vue'
import MessageAlert from '@/components/MessageAlert.vue'

export default defineComponent({
  components: {
    BusinessTabs,
    Navbar,
    MessageAlert,
  },
})
