
import { Component, Vue, Watch } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import { useQueryClient, QueryClient } from '@tanstack/vue-query'

import ClipboardTextField from '@/components/ClipboardTextField.vue'
import { tripModule, eventModule } from '@/store'
import { Trip } from '@/types/trip'
import AWSManager from '@/utils/aws-manager'
import { TripRelation } from '@/utils/enums'
import { tripQueryKeys } from '@/composables/trip'

@Component({
  name: 'SettingsHeader',
  components: { ClipboardTextField },
  setup() {
    const queryClient = useQueryClient()
    return { queryClient }
  },
})
class SettingsHeader extends Vue {
  tripName: string = this.trip.name

  queryClient!: QueryClient

  get trip(): Trip {
    return tripModule.trip!
  }

  get tripTheme() {
    return this.trip.theme.startsWith('home')
      ? require(`~/assets/themes/${this.trip.theme}.png`)
      : this.trip.theme
  }

  @Watch('trip')
  setName(): void {
    this.tripName = this.trip.name
  }

  async saveName() {
    await tripModule.updateTrip({ name: this.tripName })

    tripModule.postSocketTrigger({ type: TripRelation.RENAMED_TRIP })

    this.queryClient.invalidateQueries({
      queryKey: tripQueryKeys.lists(),
    })
  }

  toggleThemeInput(): void {
    const fileInput = this.$refs.fileUploaderSettings as HTMLElement
    fileInput.click()
  }

  async onFileChanged(e: Event): Promise<void> {
    const file = (e.target! as any).files[0]
    if (file.size > 5000000) {
      eventModule.newError('error.theme.size')
      return
    }

    if (file !== null) {
      eventModule.newInfo(`info.photo.upload`)
      const fileName = `${uuidv4()}.jpg`
      await AWSManager.uploadFile(
        `trips/${this.trip.id}/cover_photo/${fileName}`,
        file,
        () => tripModule.updateTrip({ theme: fileName }),
      )
    }
  }
}
export default SettingsHeader
