
import { Component, Prop } from 'vue-property-decorator'
import DatePicker from '@/components/datepicker/DatePicker.vue'
import { DatePickerType } from '@/utils/enums'

@Component({
  name: 'TabbedDatePicker',
})
class TabbedDatePicker extends DatePicker {
  @Prop() readonly pickerValues?: any
  activeTab = 0

  // values for different calendar modes on the date-range-picker
  tabs = [
    {
      index: 0,
      value: DatePickerType.SPECIFIC,
      type: 'date',
      format: 'yyyy-MM-dd',
    },
    {
      index: 1,
      value: DatePickerType.WHOLE_MONTH,
      type: 'month',
      format: 'yyyy-MM',
    },
  ]

  get btnClass(): string {
    return this.$vuetify.breakpoint.xsOnly ? 'pa-0 mobile-btn-group' : 'pa-0'
  }

  created() {
    this.setMinimumDate()

    if (!this.pickerValues) return

    if (this.pickerValues.dates) {
      this.dates = this.pickerValues.dates
      this.months = this.pickerValues.dates
    }

    if (this.pickerValues.type) {
      this.activeTab =
        this.pickerValues.type === DatePickerType.SPECIFIC ? 0 : 1
    }
    this.setDateClasses()
  }

  saveDates() {
    this.dates.sort(this.ascendingSort)
    this.months.sort(this.ascendingSort)

    this.$emit('save', {
      type: this.tabs[this.activeTab].value,
      dates: this.dates,
      months: this.months,
    })
  }
}

export default TabbedDatePicker
