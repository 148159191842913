
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TextFieldRules } from '@/utils/enums'

@Component({
  name: 'LNameField',
})
class LNameField extends Vue {
  @Prop({ default: true, type: Boolean }) readonly required!: boolean
  rules = TextFieldRules

  get appliedRules(): ((value: string) => string | true)[] {
    return this.required ? [this.rules.REQUIRED] : []
  }
}

export default LNameField
