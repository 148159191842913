const middleware = {}

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['guest'] = require('../middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['magic-link'] = require('../middleware/magic-link.ts')
middleware['magic-link'] = middleware['magic-link'].default || middleware['magic-link']

export default middleware
