import { render, staticRenderFns } from "./LStepper.vue?vue&type=template&id=22e31488"
var script = {}
import style0 from "./LStepper.vue?vue&type=style&index=0&id=22e31488&prod&lang=sass"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports