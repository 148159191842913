
import { Vue, Component } from 'vue-property-decorator'
import AddTransportationTabs from '@/components/trip/transportation/AddTransportationTabs.vue'
import CardListItem from '@/components/trip/dashboard/CardListItem.vue'

@Component({
  name: 'AddTransportationAlert',
  components: {
    AddTransportationTabs,
    CardListItem,
  },
})
class AddTransportationAlert extends Vue {}

export default AddTransportationAlert
