
import { Component, Vue } from 'vue-property-decorator'

import { eventModule, authModule, tripModule } from '@/store'
@Component({
  name: 'ActionsExpansionPanel',
})
class ActionsExpansionPanel extends Vue {
  showDuplicateTripConfirmDialog = false
  showDeleteTripConfirmDialog = false
  isArchiving = false

  get trip() {
    return tripModule.trip!
  }

  get isCreator(): boolean {
    return this.trip.createdBy === authModule.user.id!
  }

  async archiveTrip(): Promise<void> {
    this.isArchiving = true
    eventModule.toggleArchive()
    await tripModule.updateTrip({
      archived: !this.trip.archived,
    })
    this.isArchiving = false
  }

  async duplicateTrip(): Promise<void> {
    await tripModule.duplicate()
    this.$router.replace({ path: '/' })
  }

  async leaveTrip(): Promise<void> {
    await tripModule.leave(authModule.user.id!)
    this.$router.replace({ path: '/' })
  }
}
export default ActionsExpansionPanel
