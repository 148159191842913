
import { Vue, Prop } from 'vue-property-decorator'
import { Inspiration } from '@/types/discover'
import { thumbnailImage, formattedCountriesText } from '@/utils/utility-manager'

class InspirationCard extends Vue {
  @Prop() readonly inspiration!: Inspiration

  get countryByLocale(): string {
    return formattedCountriesText(
      this.inspiration,
      this.$i18n.locale,
      `${this.$t('other') as string}`,
      `${this.$t('others') as string}`,
    )
  }

  getThumbnail(image: string, size: number): string {
    return thumbnailImage(image, size)
  }

  handleClick(inspiration: Inspiration) {
    this.$router.push({
      name: 'discover-inspiration-inspiration',
      params: {
        inspiration: inspiration.id,
        name: inspiration.name,
      },
    })
  }
}

export default InspirationCard
