import { isBrowser } from './utility-manager'

export interface IntercomConfigParams {
  name?: string
  email?: string
  username?: string
  /* eslint-disable camelcase */
  user_id?: string
  created_at?: string
  has_active_lambus_pro?: string
  is_demo_account?: string
  is_social_account?: string
  custom_launcher_selector?: string
  hide_default_launcher?: boolean
  /* eslint-enable camelcase */
}

export default class Intercom {
  static launch(options?: IntercomConfigParams): void {
    if (!isBrowser()) return

    // set up intercom
    const windowLocal = window as any
    windowLocal.intercomSettings = Object.assign(
      { app_id: 'ymgyjdbg' },
      options,
    )

    const ic = windowLocal.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', windowLocal.intercomSettings)
    } else {
      const d = document
      const i = function () {
        i.c(arguments)
      } as any
      i.q = []
      i.c = function (args: any) {
        i.q.push(args)
      }
      windowLocal.Intercom = i
      const s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/ymgyjdbg'
      s.id = 'intercom'
      const x = d.getElementsByTagName('script')[0]
      if (x.parentNode) {
        x.parentNode.insertBefore(s, x)
      }
    }
  }

  static shutdown(): void {
    if (!isBrowser()) return

    // remove intercom
    const windowLocal = window as any
    const ic = windowLocal.Intercom
    if (ic) {
      ic('shutdown') // removes dom elements
    }

    const elem = document.getElementById('intercom')
    if (elem && elem.parentNode) {
      elem.parentNode.removeChild(elem) // removes script tag from head
    }
  }

  static show(): void {
    if (!isBrowser()) return

    const windowLocal = window as any
    const ic = windowLocal.Intercom
    if (ic) {
      ic('show')
    }
  }
}
