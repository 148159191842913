import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Store } from 'vuex'
import { getModule, VuexModule } from 'vuex-module-decorators'

import AuthModule from '@/store/auth'
import UserModule from '@/store/user'
import TripModule from '@/store/trip'
import WaypointModule from '@/store/waypoint'
import ExpenseModule from '@/store/expense'
import PackingListModule from '@/store/packingList'
import DocumentModule from '@/store/document'
import PhotoModule from '@/store/photo'
import EventModule from '@/store/event'
import DiscoverModule from '@/store/discover'
import TransportationModule from '@/store/transportation'
import AccommodationModule from '@/store/accommodation'
import RoutesModule from '@/store/routes'
import SubscriptionModule from '@/store/subscription'
import SystemModule from '@/store/system'
import BookingModule from '@/store/booking'

/* eslint-disable import/no-mutable-exports */
let authModule: AuthModule
let userModule: UserModule
let tripModule: TripModule
let waypointModule: WaypointModule
let expenseModule: ExpenseModule
let packingListModule: PackingListModule
let documentModule: DocumentModule
let photoModule: PhotoModule
let eventModule: EventModule
let discoverModule: DiscoverModule
let accommodationModule: AccommodationModule
let transportationModule: TransportationModule
let routesModule: RoutesModule
let subscriptionModule: SubscriptionModule
let systemModule: SystemModule
let bookingModule: BookingModule
/* eslint-enable import/no-mutable-exports */

type ConstructorOf<C> = { new (...args: any[]): C }

export function initializeModules(
  store: Store<any>,
  $axios: NuxtAxiosInstance,
): void {
  function initModule<M extends VuexModule>(moduleClass: ConstructorOf<M>): M {
    const moduleInstance = getModule(moduleClass, store)
    moduleInstance.$axios = $axios
    return moduleInstance
  }

  authModule = initModule(AuthModule)
  userModule = initModule(UserModule)
  tripModule = initModule(TripModule)
  waypointModule = initModule(WaypointModule)
  expenseModule = initModule(ExpenseModule)
  packingListModule = initModule(PackingListModule)
  documentModule = initModule(DocumentModule)
  photoModule = initModule(PhotoModule)
  eventModule = initModule(EventModule)
  discoverModule = initModule(DiscoverModule)
  accommodationModule = initModule(AccommodationModule)
  transportationModule = initModule(TransportationModule)
  routesModule = initModule(RoutesModule)
  subscriptionModule = initModule(SubscriptionModule)
  systemModule = initModule(SystemModule)
  bookingModule = initModule(BookingModule)
}

export {
  authModule,
  userModule,
  tripModule,
  waypointModule,
  expenseModule,
  packingListModule,
  documentModule,
  photoModule,
  eventModule,
  discoverModule,
  accommodationModule,
  transportationModule,
  routesModule,
  subscriptionModule,
  systemModule,
  bookingModule,
}
