import { getCurrentInstance } from 'vue'

/**
 * Returns the Nuxt context.
 */
export function useContext() {
  const vm = getCurrentInstance()?.proxy
  if (!vm) {
    throw new Error('useContext can only be used inside a setup function')
  }

  return vm.$nuxt.context
}
