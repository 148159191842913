
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import TimeRangePicker from '@/components/datepicker/TimeRangePicker.vue'
import { eventModule, waypointModule } from '@/store'
import { LinkedDay, RearrangeWaypointDayItem } from '@/types/trip'
import { TimeRange } from '@/types/types'

export interface NewLinkedDay {
  date: string
  dayId: string
  startTime?: string | null
  endTime?: string | null
}

@Component({
  name: 'LinkedDayDatePickerModal',
  components: {
    TimeRangePicker,
  },
})
class LinkedDayDatePickerModal extends Vue {
  @Prop() readonly day?: LinkedDay
  @Prop({ default: false, type: Boolean }) readonly value!: boolean
  time: TimeRange = {
    startTime: this.day?.startTime,
    endTime: this.day?.endTime,
  }

  localDate = this.waypointStartDate ?? null

  get waypointStartDate(): string | undefined {
    return waypointModule.waypoint?.startDate?.value
  }

  get waypointEndDate(): string | undefined {
    return waypointModule.waypoint?.endDate?.value
  }

  get itemTime(): TimeRange {
    return this.time
  }

  set itemTime(newTime: TimeRange) {
    this.time = {
      startTime: newTime.startTime,
      endTime: newTime.endTime,
    }
  }

  @Watch('value')
  setDate(): void {
    this.localDate = this.day?.date ?? this.waypointStartDate ?? null
    this.itemTime = {
      startTime: this.day?.startTime,
      endTime: this.day?.endTime,
    }
  }

  async updateTime() {
    if (!this.day) {
      this.$emit('close')
      return
    }
    if (this.$listeners.updateTime) {
      this.$emit('updateTime', this.itemTime)
      return
    }

    await waypointModule.updateWaypointDayItem({
      itemId: this.day.itemId,
      item: this.itemTime,
    })

    waypointModule.fetchPOI(waypointModule.pointOfInterest!._id!)
    this.$emit('close')
  }

  /**
   * Handles the user action after submitting the changes. This method is responsible for managing
   * the behavior based on the selected day and time.
   *
   */
  onSubmit() {
    const waypointDay = waypointModule.waypointDays.find(
      (day) => day.date === this.localDate,
    )

    if (!waypointDay || !waypointModule.waypoint) {
      this.$emit('close')
      return
    }

    if (!this.day) {
      this.linkItemToDay(waypointModule.waypoint._id, waypointDay._id)
    } else if (this.day.date !== this.localDate) {
      this.rearrangeWaypointDayItem(
        waypointModule.waypoint._id,
        waypointDay._id,
      )
    } else if (
      this.day.startTime !== this.itemTime.startTime ||
      this.day.endTime !== this.itemTime.endTime
    ) {
      this.updateTime()
    }
  }

  async linkItemToDay(
    waypointId: string,
    waypointDayId: string,
  ): Promise<void> {
    if (this.day || !this.localDate) {
      return
    }

    if (this.$listeners.linkItemToDay) {
      const newLinkedDay: NewLinkedDay = {
        date: this.localDate,
        dayId: waypointDayId,
        ...this.itemTime,
      }
      this.$emit('linkItemToDay', newLinkedDay)
      return
    }

    if (!waypointModule.pointOfInterest) {
      this.$emit('close')
      return
    }
    await waypointModule.linkNearbyPlaceToDay({
      waypointId,
      waypointDayId,
      payload: {
        nearbyPlace: waypointModule.pointOfInterest._id,
        ...this.itemTime,
      },
    })

    if (waypointModule.pointOfInterest) {
      waypointModule.fetchPOI(waypointModule.pointOfInterest._id!)
    }
  }

  async rearrangeWaypointDayItem(waypointId: string, waypointDayId: string) {
    try {
      const sourceDay = waypointModule.waypointDays.find(
        (day) => day.date === this.day!.date,
      )

      if (!sourceDay) {
        throw new Error('Cannot link place to day. Cannot find source day')
      }

      if (
        !this.day?.itemId &&
        this.$listeners.replaceLinkedDay &&
        this.localDate
      ) {
        // needed to handle the edgeCase where a linkedDay for a new activity is rearranged
        const newLinkedDay: NewLinkedDay = {
          date: this.localDate,
          dayId: waypointDayId,
          ...this.itemTime,
        }
        this.$emit('replaceLinkedDay', newLinkedDay)
        return
      }
      const itemIndex = sourceDay?.items?.findIndex(
        (item) => item._id === this.day?.itemId,
      )

      if (itemIndex === undefined || itemIndex < 0) {
        throw new Error('Cannot link place to day. Cannot find item index')
      }

      const payload: RearrangeWaypointDayItem = {
        sourceDayId: sourceDay?._id,
        targetDayId: waypointDayId,
        sourceItemIndex: itemIndex,
        targetItemIndex: 0,
      }

      if (this.$listeners.rearrangeWaypointDayItem) {
        this.$emit('rearrangeWaypointDayItem', payload)
        return
      }

      await waypointModule.rearrangeNearbyPlaceInWaypointDay({
        waypointId,
        payload,
      })

      if (waypointModule.pointOfInterest) {
        waypointModule.fetchPOI(waypointModule.pointOfInterest._id!)
      }
    } catch (error) {
      eventModule.newError('error.generic')
    }
  }

  async unlinkDay(): Promise<void> {
    if (!this.day || !waypointModule.waypoint) {
      this.$emit('close')
      return
    }

    if (this.$listeners.unlinkItemFromDay) {
      this.$emit('unlinkItemFromDay')
      return
    }

    await waypointModule.unlinkNearbyPlaceToDay({
      waypointId: waypointModule.waypoint._id,
      waypointDayId: this.day.dayId,
      itemId: this.day.itemId,
    })

    waypointModule.fetchPOI(waypointModule.pointOfInterest!._id!)
  }
}
export default LinkedDayDatePickerModal
