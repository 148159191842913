
import { Component, Vue } from 'vue-property-decorator'
import StationForm from '@/components/trip/transportation/forms/generic/StationForm.vue'
import { TransportationType } from '@/utils/enums'
@Component({
  name: 'BusForm',
  components: {
    StationForm,
  },
})
class BusForm extends Vue {
  type: string = TransportationType.BUS
}
export default BusForm
