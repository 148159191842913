
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Waypoint } from '@/types/trip'
import DiscoverTripPlan from '@/components/discover/DiscoverTripPlan.vue'
import { waypointModule } from '@/store'

declare let google: any

@Component({
  name: 'DiscoverMap',
  components: {
    DiscoverTripPlan,
  },
})
class DiscoverMap extends Vue {
  @Prop() readonly locations!: Waypoint[]

  map: any = null
  markers: Array<any> = []
  mapOptions = {
    styles: require('~/assets/mapstyle/mapstyle.json'),
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    draggableCursor: 'pointer',
    disableDefaultUI: true,
    gestureHandling: 'cooperative',
  }

  get currentWaypoint(): Waypoint | null {
    return waypointModule.waypoint
  }

  @Watch('currentWaypoint')
  zoomLocation(waypoint: Waypoint): void {
    if (!this.currentWaypoint) {
      return
    }
    const index = this.getIndexByWID(this.currentWaypoint._id)
    this.markers.forEach((el, index_) => {
      if (index_ === index) {
        el.setIcon({
          labelOrigin: new google.maps.Point(11, 10),
          url: require('~/assets/mapstyle/pin-waypoint-selected@2x.png'),
        })
      } else {
        el.setIcon({
          labelOrigin: new google.maps.Point(10, 10),
          url: require('~/assets/mapstyle/pin-waypoint@2x.png'),
        })
      }
    })
    this.map.panTo(
      new google.maps.LatLng(
        waypoint.location.latitude,
        waypoint.location.longitude,
      ),
    )
    this.map.setZoom(10)
  }

  mounted() {
    this.initMap()
    this.initMarkers()
  }

  initMap(): void {
    this.map = new google.maps.Map(document.getElementById('map'), {
      ref: 'disovermap',
      zoom: 3,
      center: { lat: 0, lng: 0 },
      options: this.mapOptions,
    })
  }

  initMarkers(): void {
    let maxLat = -90
    let minLat = 90
    let maxLng = -180
    let minLng = 180

    this.locations.forEach((waypoint: any, index: number) => {
      if (this.locations.length > 1) {
        maxLat =
          waypoint.location.latitude > maxLat
            ? waypoint.location.latitude
            : maxLat
        minLat =
          waypoint.location.latitude < minLat
            ? waypoint.location.latitude
            : minLat
        maxLng =
          waypoint.location.longitude > maxLng
            ? waypoint.location.longitude
            : maxLng
        minLng =
          waypoint.location.longitude < minLng
            ? waypoint.location.longitude
            : minLng
      }

      const marker = new google.maps.Marker({
        position: {
          lat: waypoint.location.latitude,
          lng: waypoint.location.longitude,
        },
        icon: {
          labelOrigin: new google.maps.Point(10, 10),
          url: require('~/assets/mapstyle/pin-waypoint@2x.png'),
        },
        label: {
          color: '#fff',
          text: (index + 1).toString(),
        },
        map: this.map,
      })

      marker.setMap(this.map)
      this.markers.push(marker)
    })

    if (this.locations.length === 1) {
      this.map.setZoom(8)
      this.map.panTo({
        lat: this.locations[0].location.latitude,
        lng: this.locations[0].location.longitude,
      })
    } else {
      this.map.fitBounds({
        east: maxLng,
        north: maxLat,
        west: minLng,
        south: minLat,
      })
    }
  }

  getIndexByWID(wID: string): number {
    return this.locations.findIndex((el) => el._id === wID)
  }
}
export default DiscoverMap
