
import { Component, Prop } from 'vue-property-decorator'
import ContentView from '@/components/trip/contentView/ContentView.vue'
import DocumentListItem from '@/components/trip/documents/DocumentListItem.vue'
import DocumentViewer from '@/components/DocumentViewer.vue'
import PictureViewer from '@/components/PictureViewer.vue'

@Component({
  components: {
    DocumentListItem,
    DocumentViewer,
    PictureViewer,
  },
})
class DocumentList extends ContentView {
  @Prop({ default: false, type: Boolean }) readonly showMenu!: boolean
  @Prop({ default: true, type: Boolean })
  readonly showWaypointLinkOption!: boolean

  @Prop({ default: false, type: Boolean }) readonly hideEmptyState!: boolean
}

export default DocumentList
