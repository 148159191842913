
import { Component, Prop, Vue } from 'vue-property-decorator'

import { tripModule } from '@/store'
import { Trip } from '@/types/trip'
import { hasLocalStorageBoolean } from '@/utils/utility-manager'
@Component({
  name: 'Tooltip',
})
class Tooltip extends Vue {
  @Prop() readonly storageKey?: string
  /**
   * The background-color of the tooltip. Only accepts css-valid color codes (no material colors)
   */
  @Prop({ default: 'white', type: String }) readonly color!: string
  @Prop({ default: 'start', type: String }) readonly justify!: string
  /**
   * The direction of the arrow
   * @values top, bottom
   */
  @Prop({ default: 'top', type: String }) readonly direction!: string

  showTooltip = false

  get trip(): Trip | null {
    return tripModule.trip
  }

  get sheetClass(): string {
    return `tooltip-justify-${this.justify}`
  }

  mounted() {
    if (!this.storageKey || !this.trip) {
      return
    }

    this.showTooltip = !hasLocalStorageBoolean(
      `kLambusTooltip${this.storageKey}_${this.trip.id}`,
    )
  }

  dismiss(): void {
    if (!this.storageKey || !this.trip) {
      return
    }

    localStorage.setItem(
      `kLambusTooltip${this.storageKey}_${this.trip.id}`,
      'true',
    )
    this.showTooltip = false
  }
}
export default Tooltip
