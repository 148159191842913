import { IncomingMessage } from 'http'
import { TLSSocket } from 'tls'
import { Context } from '@nuxt/types'
import cookie from 'cookie'

import { authModule } from '@/store'

export default async ({ $axios, $config, isDev, req, res }: Context) => {
  if (process.client || !$config.enableMagicLink) {
    return
  }

  const url = createUrlFromRequest(req)
  if (
    !url.pathname.endsWith('pro/features') ||
    !url.searchParams.has('authorize')
  ) {
    return
  }

  authModule.setTokens({
    accessToken: '',
    refreshToken: url.searchParams.get('authorize'),
  })
  await authModule.refresh()
  $axios.defaults.headers.Authorization = authModule.bearerToken
  const expires = new Date()
  expires.setFullYear(expires.getFullYear() + 1)
  res.setHeader(
    'Set-Cookie',
    cookie.serialize(
      'auth_local',
      JSON.stringify({
        auth: {
          token: authModule.bearerToken?.replace('Bearer ', ''),
          refreshToken: authModule._refreshToken,
        },
      }),
      { expires, secure: !isDev, path: '/' },
    ),
  )
}

/**
 * Creates a new `URL` object from the given HTTP request.
 *
 * @param req
 * @returns
 */
function createUrlFromRequest(req: IncomingMessage) {
  let protocol = 'http'
  if ((req.socket as TLSSocket).encrypted) {
    protocol = 'https'
  }

  const baseUrl = `${protocol}://${req.headers.host}`

  if (!req.url) {
    return new URL(baseUrl)
  }

  const parsedUrl = new URL(req.url, `${protocol}://${req.headers.host}`)
  return parsedUrl
}
