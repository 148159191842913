
import { Component, Prop, Vue } from 'vue-property-decorator'

import GoogleLocationSearch from '@/components/GoogleLocationSearch.vue'
import LocationSearchModal from '@/components/trip/transportation/LocationSearchModal.vue'
import { userModule, waypointModule } from '@/store'
import { Location, Waypoint } from '@/types/trip'
import { LocationSearchType } from '@/utils/enums'
import {
  formattedLocation,
  formattedLocationFromCoordinates,
} from '@/utils/utility-manager'

@Component({
  name: 'RouteLocationField',
  components: {
    GoogleLocationSearch,
    LocationSearchModal,
  },
})
class RouteLocationField extends Vue {
  @Prop() readonly waypoint?: Waypoint
  @Prop() readonly location?: Location
  @Prop() readonly symbol?: string
  @Prop() readonly placeholder?: string
  @Prop() readonly locationSearchPrefill?: string

  @Prop({ default: false, type: Boolean }) readonly hideUserLocation!: boolean
  @Prop({ default: false, type: Boolean }) readonly noPadding!: boolean
  @Prop({ default: false, type: Boolean }) readonly neverActive!: boolean
  @Prop({ default: false, type: Boolean }) readonly lean!: boolean
  @Prop({ default: false, type: Boolean }) readonly keepSearchTitle!: boolean
  @Prop({ default: true, type: Boolean }) readonly roundedLeft!: boolean
  @Prop({ default: true, type: Boolean }) readonly roundedRight!: boolean
  @Prop({ default: true, type: Boolean }) readonly roundedTop!: boolean
  @Prop({ default: true, type: Boolean }) readonly roundedBottom!: boolean
  @Prop({ default: false, type: Boolean })
  readonly omitTransportationWaypoints?: boolean

  locationSearchType = LocationSearchType
  showGoogleLocationSearch = false

  get userLocation(): any {
    return userModule.userLocationPlace
  }

  get locationIsUserLocation(): boolean {
    if (!this.waypoint) {
      return false
    }

    return (
      this.waypoint.location.latitude === this.userLocation?.latitude &&
      this.waypoint.location.longitude === this.userLocation?.longitude
    )
  }

  get waypointName(): string {
    return this.waypoint?.name || this.location?.name || ''
  }

  get textFieldPlaceholder(): string {
    return this.placeholder || this.$t('selectWaypoint').toString()
  }

  getLocation(): void {
    if (this.locationIsUserLocation) {
      return
    }

    if (this.userLocation) {
      this.setLocation(this.userLocation)
    } else {
      userModule.getUserLocation(async (position: GeolocationPosition) => {
        const result = await waypointModule.getPlaceGeocode({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })

        userModule.setUserLocationPlace(result)
        this.setLocation(result)
      })
    }
  }

  async updateLocation(select: { place: any; source: number }): Promise<void> {
    if (!select.place.id) {
      this.$emit(
        'update:location',
        formattedLocationFromCoordinates(select.place),
      )
    } else {
      const location = await formattedLocation(select.place, select.source)
      if (this.keepSearchTitle) {
        location.name = select.place.title
      }
      this.$emit('update:location', location)
    }

    this.showGoogleLocationSearch = false
  }

  setLocation(location: any): void {
    const newWaypoint: Partial<Waypoint> = {
      name: location.name,
      location,
    }
    this.$emit('update:waypoint', newWaypoint)
  }

  showLocationSearchModal(): void {
    if (this.lean) {
      this.showGoogleLocationSearch = true
      return
    }

    const locationSearchModal = this.$refs[
      `${this.symbol}-routes-search-modal`
    ] as LocationSearchModal

    if (locationSearchModal) {
      locationSearchModal.toggleDialog()
    }
  }
}
export default RouteLocationField
