
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'

import NearbyPlacesModal from '@/components/trip/map/NearbyPlacesModal.vue'
import { eventModule, tripModule, waypointModule } from '@/store'
import { Waypoint } from '@/types/trip'

@Component({
  name: 'GoogleLocationSearch',
  components: {
    NearbyPlacesModal,
  },
})
class GoogleLocationSearch extends Vue {
  @Prop({ default: false, type: Boolean }) readonly large!: boolean
  @Prop({ default: false, type: Boolean }) readonly alternative!: boolean
  @Prop({ default: false, type: Boolean }) readonly autofocus!: boolean
  @Prop({ default: false }) readonly attach!: any
  @Prop() readonly symbol?: string
  @Prop() readonly prefill?: string
  @Prop() readonly screenSource?: string
  @Prop({ default: false, type: Boolean })
  readonly isLocationSearch!: boolean

  places: any = []
  searchTerm: string | null = ''
  select: any = null
  loading = false
  source = -1
  isFocused = false
  nearbyPlacesModalIsShown = false

  get waypointDetails(): boolean {
    return eventModule.waypointDetails
  }

  get currentWaypoint(): Waypoint | null {
    return waypointModule.waypoint
  }

  searchGoogle = _.debounce(
    async (val: string, context: Vue, screenSource?: string): Promise<void> => {
      if (val) {
        const data = await waypointModule.search({
          searchTerm: val,
          screenSource,
        })
        this.$set(context, 'places', data.predictions)
        this.$set(context, 'source', data.source)
      }
      this.$set(context, 'loading', false)
    },
    1000,
  )

  get menuContentClass(): { contentClass: string } {
    if (this.alternative) {
      return {
        contentClass:
          'elevation-0 rounded-b-lg search-result-content__alternative',
      }
    }
    let classString = 'rounded-pro elevation-pro mt-4'

    if (this.attach) {
      classString = this.large
        ? 'elevation-0 search-result-content'
        : 'elevation-1 modal-search-content-mobile'
    }

    return { contentClass: classString }
  }

  get searchOverlay(): boolean {
    return eventModule.waypointSearchBarFocus
  }

  get trip() {
    return tripModule.trip
  }

  get searchBarClass(): string {
    if (this.alternative) {
      if (this.places.length > 0) {
        return 'rounded-t-lg rounded-b-0'
      }
      return 'rounded-lg'
    }
    return this.large ? 'my-4 mx-2 waypoint-search-bar' : 'my-4 mx-2'
  }

  get searchHintText(): string {
    if (this.waypointDetails) {
      return this.$t('addPOI') as string
    }

    return this.large
      ? (this.$t('addWaypoint') as string)
      : (this.$t('searchHint') as string)
  }

  get travelPlan(): boolean {
    return eventModule.travelPlan
  }

  get progressCircularClass(): string {
    return this.travelPlan ? 'progress-circular text-center' : 'text-center'
  }

  @Watch('searchTerm')
  searchPlaces(val: string): void {
    if (val?.trim().length > 0) {
      this.loading = true
      this.searchGoogle(val, this, this.screenSource)
    } else {
      this.places.splice(0, this.places.length)
    }
  }

  @Watch('prefill')
  setSearchTermByPrefill(): void {
    if (this.prefill) {
      const autocomplete = this.$refs.waypointSearchbar as HTMLElement

      if (autocomplete) {
        this.$nextTick(() => {
          autocomplete.focus()
          this.searchTerm = this.prefill || null
        })
      }
    }
  }

  @Watch('searchOverlay')
  focusWaypointSearchBar(): void {
    const waypointSearchbar = this.$refs.waypointSearchbar as HTMLElement

    if (waypointSearchbar && this.searchOverlay && this.large) {
      waypointSearchbar.focus()
    }
  }

  @Watch('select')
  selectNewWaypoint(): void {
    if (!this.select) {
      return
    }

    this.$emit('select', { place: this.select, source: this.source })
    this.searchTerm = ''
    eventModule.setWaypointSearchBarFocus(false)
    this.$nextTick(() => {
      this.select = null
      this.searchTerm = null
      this.source = -1
    })
  }

  mounted() {
    this.setSearchTermByPrefill()
  }

  showNearbyPlacesModal(): void {
    if (this.waypointDetails && !this.isLocationSearch) {
      this.nearbyPlacesModalIsShown = true
    }
  }

  closeNearbyPlacesModal(): void {
    this.nearbyPlacesModalIsShown = false
  }

  showSearchOverlay(): void {
    if (!this.waypointDetails || this.isLocationSearch) {
      if (this.large) {
        eventModule.setWaypointSearchBarFocus(true)
      }

      this.isFocused = true
      if (this.searchTerm && this.searchTerm.trim().length > 0) {
        this.$emit('focus:text-field')
      } else {
        this.$emit('blur:text-field')
      }
    } else {
      const waypointSearchbar = this.$refs.waypointSearchbar as HTMLElement
      waypointSearchbar.blur()
    }
  }

  closeSearchOverlay(): void {
    if (!this.waypointDetails) {
      if (this.large) {
        eventModule.setWaypointSearchBarFocus(false)
      }

      this.isFocused = false
      this.$emit('blur:text-field')
    }
  }
}

export default GoogleLocationSearch
