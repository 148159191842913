module.exports = {
  pro: {
    checkout: {
      title: '升级为Lambus PRO',
      description: '解锁更多高级功能以让您的旅程更加轻易规划！ 🎉',
      payPerTrip: {
        title: '按次付费',
        caption: '升级一个行程 (对全部人)',
      },
      yearlySubscription: {
        title: '年度订阅',
        caption: '升级您全部行程',
        freeTrial: '免费七天试用！',
      },
      success: {
        title: 'Lambus PRO:购买成功!',
      },
      learnMore: '了解更多Lambus PRO',
      upgradeNowUpper: '立即升级 🚀',
      thankYouNotice: '感谢你购买Lambus PRO! ❤️本页面将在{time}秒内自动关闭。',
    },
    features: {
      routePlanning: {
        title: '已延长路线规划',
        description: '准确计算您所有航点的时长与距离',
      },
      googleMaps: {
        title: '谷歌地图搜索',
        description: '我们使用谷歌地图来寻找更多的航点',
      },
      videoChat: {
        title: '视频通话',
        description: '在计划行程的同时与参与者聊天',
      },
      moneyPool: {
        title: '资金池',
        description: '在一个集中的地方管理旅行资金',
      },
      exportTripGpx: {
        title: '导出行程为GPX',
        description: '导出您的行程为GPX并使用于其他追踪应用程序',
      },
      currencies: {
        title: '自动货币',
        description: '使用货币转换器转换外币成您的货币',
      },
      weather: {
        title: '实时天气预告通知',
        description: '显示您所有航点当前的天气情况',
      },
      exportExpenses: {
        title: '消费导出',
        description: '自动导出您的消费至Excel',
      },
      flightStatus: {
        title: '实时航班通知',
        description: '获得通知若航班延迟、取消或改期',
      },
      exportTrip: {
        title: '导出行程为PDF',
        description:
          '导出您的行程为PDF，您就能轻松在各种设备里查看您的行程了！',
      },
      support: {
        title: '支持我们❤️',
        description: '在您的支持下，Lambus可以继续运行而不显示广告',
      },
      packingList: {
        title: '清单（包装清单、待办事项清单）',
        description: '从现有列表中选择或创建你自己的列表',
      },
      flightCompensation: {
        title: '飞行补偿',
        description: '自动索赔倘若您的航班被取消',
      },
      privateDocuments: {
        title: '私人文件',
        description: '在你的设备上安全地存储护照、驾照和更多的东西',
      },
      offlineMode: {
        title: '离线模式',
        description: '即使没有互联网连接，也可以访问你的整个行程',
      },
      calendarSync: {
        title: '日历同步',
        description: '将航点和 POI 与日历同步',
      },
    },
  },
  sortExpensesBy: '按…排序支出',
  expenseCreated: '支出已创建',
  expenseDate: '支出日期',
  canoe_tour: '独木舟之旅',
  hiking: '徒步旅行',
  camping: '露营',
  beach: '海滩',
  swimming: '游泳',
  boat_tour: '船之旅',
  campfire: '营火',
  party: '派对',
  bike_tour: '自行车游',
  surfing: '冲浪',
  snorkeling: '浮潜',
  picknick: '野餐',
  food: '食物',
  horse_riding: '骑马',
  sport_event: '体育赛事',
  concert: '音乐会',
  cinema: '电影院',
  winter_sports: '冬季运动',
  searchActivitiesAndPlaces: '搜索您保存的地点和活动',
}
