
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import LTextPrompt from '@/global-components/LTextPrompt.vue'
import LConfirmDialog from '@/global-components/LConfirmDialog.vue'
import WaypointListModal from '@/components/trip/travelPlan/waypoint/WaypointListModal.vue'
import { tripModule } from '@/store'
import DocumentModule from '@/store/document'
import PhotoModule from '@/store/photo'
import { Document } from '@/types/document'
import { Photo } from '@/types/photo'
import { MenuItem } from '@/types/types'
import { Waypoint } from '@/types/trip'
import { TextFieldRules, GridContentType, DocumentType } from '@/utils/enums'

@Component({
  name: 'GridMenu',
  components: {
    WaypointListModal,
  },
})
class GridMenu extends Vue {
  @Prop() readonly file?: Document | Photo
  @Prop() readonly contentType!: string
  @Prop() readonly storeModule!: DocumentModule | PhotoModule
  @Prop({ default: false, type: Boolean }) readonly absolute!: boolean
  @Prop({ default: true }) readonly showWaypointLink!: boolean
  showDeleteDialog = false
  rules = TextFieldRules

  optionsGetter: MenuItem[] = []

  get trip() {
    return tripModule.trip
  }

  get activatorClass() {
    if (this.absolute) return 'menu-activator-absolute'
    else return 'menu-activator-relative'
  }

  get translationPrefix() {
    if (this.file?.type === DocumentType.DIRECTORY) {
      return this.file?.type
    } else {
      return this.contentType
    }
  }

  @Watch('file')
  setOptionsGetter(): void {
    this.optionsGetter.splice(0, this.optionsGetter.length)

    if (this.contentType === GridContentType.PHOTO) {
      if (this.file?.type === DocumentType.FILE) {
        const filteredItems = this.items.filter((el) =>
          ['delete', 'download'].includes(el.title),
        )

        this.optionsGetter.push(...filteredItems)
      }

      if (this.file?.type === DocumentType.DIRECTORY) {
        this.optionsGetter.push(...this.items)
      }
    } else if (this.contentType === GridContentType.DOCUMENT) {
      this.optionsGetter.push(...this.items)
    }

    if (
      this.showWaypointLink &&
      !this.file?.reference &&
      this.file?.type === DocumentType.FILE
    ) {
      this.optionsGetter.splice(this.optionsGetter.length - 1, 0, {
        title: 'linkToWaypoint',
        operation: () => {
          this.toggleWaypointListModule()
        },
      })
    } else if (this.showWaypointLink && this.file?.type === DocumentType.FILE) {
      this.optionsGetter.splice(this.optionsGetter.length - 1, 0, {
        title: 'unlinkFromWaypoint',
        operation: () => {
          this.updateFile({ reference: null, onModel: null })
        },
      })
    }

    if (this.$listeners?.unlink) {
      this.optionsGetter.splice(this.optionsGetter.length - 1, 0, {
        title: 'unlinkFromWaypoint',
        operation: () => {
          if (this.file) {
            this.$emit('unlink', this.file._id)
          }
        },
      })
    }
  }

  items: MenuItem[] = [
    {
      title: 'download',
      operation: () => {
        this.storeModule.download()
      },
    },
    {
      title: 'rename',
      operation: () => {
        const dialog = this.$refs.lTextPrompt as unknown as InstanceType<
          typeof LTextPrompt
        >

        setTimeout(() => {
          if (dialog) dialog.toggleDialog()
        })
      },
    },
    {
      title: 'delete',
      operation: () => {
        const dialog = this.$refs.confirmDialog as LConfirmDialog
        setTimeout(() => {
          if (dialog) dialog.toggleDialog()
        })
      },
    },
  ]

  created() {
    this.setOptionsGetter()
  }

  toggleWaypointListModule(): void {
    const dialog = this.$refs.waypointListModal as WaypointListModal
    setTimeout(() => {
      if (dialog) dialog.toggleDialog()
    })
  }

  deleteFile(): void {
    if (!this.trip || !this.file) return

    this.storeModule.delete({
      id: this.trip.id,
      fileId: this.file._id,
    })
  }

  updateFile(params: any): void {
    if (!this.trip || !this.file) return

    this.storeModule.update({
      id: this.trip.id,
      fileId: this.file._id,
      params,
    })
  }

  saveEnteredName(value: string): void {
    this.updateFile({ originName: value })
  }

  linkToWaypoint(waypoint: Waypoint): void {
    this.toggleWaypointListModule()
    this.updateFile({ reference: waypoint._id, onModel: 'Waypoint' })
  }
}

export default GridMenu
