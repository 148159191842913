import Vue from 'vue'
import VueI18n from 'vue-i18n'
import type { NuxtI18nInstance } from 'nuxt-i18n'

import en from '@/i18n/messages/en'
import de from '@/i18n/messages/de'
import pt from '@/i18n/messages/pt-br'
import es from '@/i18n/messages/es'
import fr from '@/i18n/messages/fr'
import id from '@/i18n/messages/id'
import it from '@/i18n/messages/it'
import zh from '@/i18n/messages/zh'
import { isBrowser } from '@/utils/utility-manager'

declare module '@nuxt/types' {
  interface Context {
    $i18n: NuxtI18nInstance
  }

  interface NuxtAppOptions {
    $i18n: NuxtI18nInstance
  }
}

Vue.use(VueI18n)

const locale = isBrowser() ? navigator.language : 'en'

export const i18n = new VueI18n({
  locale: locale.substring(0, 2),
  fallbackLocale: 'en',
  messages: { en, de, pt, es, fr, id, it, zh },
})
