const validation = require('vee-validate/dist/locale/en.json')

module.exports = {
  i: 'Je',
  me: 'Moi',
  you: 'Vous',
  someone: "Quelqu'un ",
  other: 'Autre',
  others: 'Autres',
  otherReason: 'Autre raison',
  fromNumber: 'De',
  by: 'Par',
  clickToEdit: 'Cliquer pour éditer',
  save: 'Enregistrer',
  edit: 'Modifier',
  add: 'Ajouter',
  addItem: 'Ajouter {item}',
  done: 'Terminé',
  delete: 'Supprimer',
  clear: 'Effacer',
  download: 'Télécharger',
  cancel: 'Annuler',
  update: 'Mettre à jour',
  ok: 'OK',
  gotIt: 'Compris !',
  no: 'Non',
  and: 'et',
  myTrips: 'Mes voyages',
  map: 'Carte',
  waypoints: 'Étapes',
  waypoint: 'Étape',
  waypointCount: 'Une étape | {count} étapes',
  routeCount: 'Un itinéraire | {count} itinéraires',
  packingListCount: 'Une liste | {count} listes',
  packingListTemplateCount: 'Un modèle de liste | {count} modèles de liste',
  documents: 'Documents',
  expenses: 'Dépenses',
  notes: 'Notes',
  photos: 'Photos',
  chat: 'Discussion',
  transportation: 'Transport',
  subscription: 'Abonnement',
  myProfile: 'Mon profil',
  logout: 'Se déconnecter',
  editProfile: 'Modifier le profil',
  travelPlan: 'Itinéraire',
  settings: 'Paramètres',
  showMore: 'Voir plus',
  suggestions: 'Suggestions',
  photoDeletePrompt: 'Voulez-vous supprimer cette photo ?',
  documentDeletePrompt: 'Voulez-vous supprimer ce document ?',
  directoryDeletePrompt: 'Voulez-vous supprimer ce répertoire ?',
  transportationDeletePrompt: 'Voulez-vous supprimer ce transport ?',
  changePhoto: 'Changer de photo',
  documentDelete: 'Supprimer le document',
  directoryDelete: 'Supprimer le répertoire',
  enterName: 'Saisissez le nom',
  enterNamePlaceholder: 'Saisissez le nom …',
  untitled: 'Sans titre',
  createFolder: 'Nouveau dossier',
  uploadFile: 'Télécharger une photo',
  uploadDocument: 'Télécharger un document',
  addDocument: 'Ajouter un document',
  documentEmailHint:
    "Votre voyage dispose d'une adresse e - mail unique à laquelle vous pouvez transférer vos documents de réservation.Les formats pris en charge sont actuellement PDF, JPG et PNG!",
  addWaypoint: 'Ajouter une étape',
  saveWaypoint: "Enregistrer l'étape ",
  addingWaypoint: "Ajout de l'étape …",
  addPOI: 'Ajouter un lieu à proximité',
  nearbyPlace: 'Lieu à proximité',
  nearbyPlaces: 'Lieux à proximité',
  nearby: 'À proximité',
  attendees: 'Participants',
  pendingAttendees: 'Participants en attente',
  hours: 'Heures',
  hoursShort: 'h',
  minutes: 'Minutes',
  minutesShort: 'min',
  noDate: 'Pas de date',
  noTitle: 'Sans titre',
  days: 'Jours',
  editDate: 'Modifier la date',
  changeDate: 'Changer la date',
  deleteDate: 'Supprimer la date',
  changeTime: "Changer l'heure ",
  changeToPoi: 'Changer pour "Lieu à proximité"',
  actionPrompt: 'Que voulez-vous faire?',
  ratings: 'Notes',
  reviews: 'Commentaires',
  registrationFields: {
    username: "Nom d'utilisateur ",
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le mot de passe',
    email: 'E-mail',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    summery: 'Résumé',
    tosAccepted: "Accepté les conditions d'utilisation ",
    company: 'Entreprise',
    name: 'Nom',
  },
  validation: {
    ...validation.messages,
    usernameAvailable: "Le nom d'utilisateur {_value_} n'est pas disponible",
  },
  socket: {
    IS_ONLINE: '{subject} est maintenant en ligne !',
    LEFT_TRIP: '{subject} a quitté le voyage !',
    DELETED_TRIP: '{subject} a supprimé le voyage !',
    RENAMED_TRIP: '{subject} a renommé le voyage !',
    UPDATED_TRIP: '{subject} a mis à jour le voyage !',
    UPDATED_TRIP_COVER_PHOTO:
      '{subject} a mis à jour la photo de couverture du voyage !',
    INVITED_ATTENDEE: '{subject} a invité {object} au voyage !',
    REMOVED_ATTENDEE: '{subject} a retiré {object} du voyage !',
    JOINED_ATTENDEE: '{subject} a rejoint votre voyage !',
    ADDED_WAYPOINT: '{subject} a ajouté un nouvel arrêt !',
    REARRANGED_WAYPOINT: '{subject} a déplacé un arrêt !',
    UPDATED_WAYPOINT: '{subject} a mis à jour un arrêt !',
    REMOVED_WAYPOINT: '{subject} a supprimé un arrêt !',
    ADDED_HIGHLIGHT: '{subject} a ajouté un nouveau lieu !',
    REARRANGED_HIGHLIGHT: '{subject} a déplacé un lieu !',
    UPDATED_HIGHLIGHT: '{subject} a mis à jour un lieu !',
    REMOVED_HIGHLIGHT: '{subject} a supprimé un lieu !',
    ADDED_DOCUMENT: '{subject} a ajouté un nouveau document !',
    UPDATED_DOCUMENT: '{subject} a mis à jour un document !',
    REMOVED_DOCUMENT: '{subject} a supprimé un document !',
    ADDED_EXPENSE: '{subject} a ajouté une nouvelle dépense !',
    UPDATED_EXPENSE: '{subject} a mis à jour une dépense !',
    REMOVED_EXPENSE: '{subject} a supprimé une dépense !',
    ADDED_CHAT_MESSAGE: '{subject} : {object}',
    STARTED_LIVE_LOCATION:
      '{subject} a commencé à partager une localisation en direct !',
    UPDATED_LIVE_LOCATION:
      '{subject} a mis à jour une localisation en direct !',
    STOPPED_LIVE_LOCATION:
      '{subject} a arrêté de partager une localisation en direct !',
    ADDED_GPX: "L'importation GPX est terminée!",
    FORCE_RELOAD: 'Le voyage a été mis à jour',
    PURCHASED_LAMBUS_PRO: '{subject} a acheté Lambus PRO',
    FLIGHT_UPDATE:
      'Une alarme de vol est désormais définie pour le vol {subject}',
  },
  success: {
    user: {
      password_change: 'Votre mot de passe a été modifié avec succès',
      password_reset:
        'Veuillez consulter votre boîte de réception et suivre les instructions pour réinitialiser votre mot de passe.',
      invite: 'Votre invitation a été envoyée avec succès !',
      picture: 'Votre photo a été téléchargée avec succès !',
      update: 'Profil mis à jour avec succès !',
    },
    trip: {
      create: 'Votre voyage a été créé avec succès !',
      update: 'Votre voyage a été mis à jour avec succès !',
      delete: 'Votre voyage a été supprimé avec succès !',
      duplicate: 'Votre voyage a été dupliqué avec succès !',
      invite: 'Votre invitation a été envoyée avec succès !',
      archive: 'Votre voyage a été archivé avec succès !',
      unarchive: 'Votre voyage a été désarchivé avec succès !',
    },
    accommodation: {
      create: "L'hébergement a été enregistré avec succès!",
      update: "L'hébergement a été mis à jour avec succès!",
      delete: "L'hébergement a été supprimé avec succès!",
    },
    waypoint: {
      create: "L'arrêt a été créé avec succès!",
      update: "L'arrêt a été mis à jour avec succès!",
      delete: "L'arrêt a été supprimé avec succès!",
      migrate: "L'arrêt a été migré avec succès!",
      dailyPlan: {
        create: "L'élément a été ajouté au plan journalier!",
        delete: "L'élément a été supprimé du plan journalier!",
        update: "L'élément a été mis à jour avec succès!",
        rearrange: "L'élément a été déplacé avec succès!",
      },
    },
    poi: {
      create: 'Le lieu a été créé avec succès !',
      update: 'Le lieu a été mis à jour avec succès !',
      delete: 'Le lieu a été supprimé avec succès !',
    },
    activity: {
      create: "L'activité a été ajoutée avec succès à l'arrêt!",
      update: "L'activité a été mise à jour avec succès!",
      delete: "L'activité a été supprimée avec succès!",
    },
    document: {
      create: 'Votre document a été téléchargé avec succès !',
      createMultiple:
        '{successCount} documents sur {allCount} documents ont été téléchargés avec succès !',
      delete: 'Votre document a été supprimé avec succès !',
    },
    expense: {
      create: 'La dépense a été créée avec succès !',
      update: 'La dépense a été mise à jour avec succès !',
      delete: 'La dépense a été supprimée avec succès !',
      exportExpense:
        'Vos dépenses ont été exportées. Veuillez vérifier votre compte e-mail.',
      exportTrip:
        'Votre voyage a été exporté. Veuillez vérifier votre compte e-mail.',
    },
    amount: {
      delete: 'Montant supprimé avec succès !',
      paid: 'Le montant a été marqué comme payé !',
      ping: "Votre rappel vient d'être envoyé!",
    },
    note: {
      create: 'La note a été créée avec succès !',
      update: 'La note a été mise à jour avec succès !',
      delete: 'La note a été supprimée avec succès !',
    },
    photo: {
      create: 'Votre photo a été téléchargée avec succès !',
      createMultiple: '{count} photos ont été téléchargées avec succès !',
      delete: 'Votre photo a été supprimée avec succès !',
    },
    feedback: 'Votre retour a été envoyé avec succès !',
    transportation: {
      create: 'Le transport a été créé avec succès!',
      update: 'Le transport a été mis à jour avec succès!',
      delete: 'Le transport a été supprimé avec succès!',
      flightStatus: {
        register:
          'Une alarme de vol est maintenant définie pour le vol {flightNumber}!',
      },
    },
    routes: {
      create: 'Votre itinéraire a été créé avec succès!',
      update: 'Votre itinéraire a été mis à jour avec succès!',
      delete: "L'itinéraire a été supprimé avec succès!",
    },
    packingLists: {
      create: 'Votre liste a été créée avec succès!',
      delete: 'Votre liste a été supprimée avec succès!',
    },
    gpx: {
      delete: 'Le fichier GPX a été supprimé avec succès!',
    },
    pro: {
      cancellation: 'Votre abonnement Lambus PRO a été annulé avec succès!',
    },
    organization: {
      removeTraveler: 'Le voyageur a été retiré',
    },
  },
  error: {
    generic: "Oh oh, une erreur s'est produite. Essayez à nouveau !",
    login: {
      apple: 'Authentification Apple ID impossible',
      facebook: 'Authentification Facebook impossible',
      google: 'Authentification Google impossible',
      blocked: {
        title: 'Merci de désactiver votre bloqueur de publicités',
        content:
          'Nous n\'utilisons pas de publicité, mais certains réseaux sociaux sont bloqués par ces outils (e.g. Facebook)<br/><ul><li>If you are using an adblocker, please turn it off and try again</li><li>If you are using Firefox, please be aware that the feature "Enhance Tracking Protection" might prevent your login</li></ul>',
      },
    },
    user: {
      password_change: {
        default: 'Votre mot de passe ne peut pas être modifié',
        currentPasswordWrong: 'Votre mot de passe actuel est incorrect.',
      },
      password_reset: 'Impossible de mettre à jour le mot de passe!',
      password_reset_social:
        'Votre email est lié à un réseau social. Merci de vous authentifier avec votre compte Apple/Google/Facebook 😇',
      invite: 'Votre invitation ne peut pas être envoyée!',
      picture: 'Votre photo ne peut pas être enregistrée!',
      update: {
        default: 'Impossible de sauvegarder! Essayez à nouveau.',
        usernameNotAvailable:
          "Impossible de sauvegarder! Votre nom d'utilisateur n'est pas valide",
        emailNotAvailable:
          'Impossible de sauvegarder! Cette adresse mail est déjà utilisée.',
      },
      delete: 'Impossible de supprimer le compte',
      create: "Votre adhésion n'a pas été enregistré",
      search: 'Utilisateur inconnu!',
      emailAlreadyExists:
        'Un utilisateur avec cet email existe déjà. Essayez avec un autre email.',
    },
    trip: {
      create: 'Votre voyage ne peut pas être créé!',
      update: 'Votre voyage ne peut pas être mis à jour!',
      delete: 'Votre voyage ne peut pas être supprimé!',
      duplicate: 'Votre voyage ne peut pas être dupliqué!',
      invite: 'Votre invitation ne peut pas être envoyée!',
      join: 'Le voyage ne peut pas être joint',
      validatePinCode: "Le code PIN n'est pas valide",
      leave: 'Nous ne pouvons pas supprimé le voyageur',
      archive: 'Votre voyage ne peut pas être archivé',
      unarchive: 'Votre voyage ne peut pas être restauré',
      fetch: 'Votre voyage ne peut pas être reçu!',
      notification_update: 'Vos paramètres ne peuvent pas être enregistrés.',
      notification_fetch: 'Vos paramètres ne peuvent pas être reçus.',
    },
    accommodation: {
      fetch: 'Votre hébergement ne peut pas être ajouté.',
      create: "Impossible d'ajouter l'hébergement.",
      update: "Impossible de mettre à jour l'hébergement",
      delete: "Impossible de supprimer l'hébergement",
    },
    gpx: {
      fetch: 'Votre itinéraire GPX ne peut pas être importé.',
      delete: 'Votre itinéraire GPX ne peut pas être supprimé.',
    },
    theme: {
      size: "L'image dépasse les 5MB",
    },
    waypoint: {
      rearrange: 'Impossible de trier les détails!',
      fetch: 'Impossible de charger les arrêts!',
      create: "Impossible de sauver l'arrêt!",
      update: "Impossible de sauvenr l'arret!",
      delete: "Impossible de supprimer l'arrêt",
      optimize: 'Votre itinéraire ne peut pas être trié.',
      weather: 'Météo indisponible',
      migrate: "Impossible de migrer l'arrêt",
      dailyPlan: {
        fetch: 'Impossible de charger le plan journalier!',
        create: "Impossible d'ajouter un élément au programme journalier !",
        delete: 'Impossible de supprimer un élément du programme journalier',
        update: 'Impossible de mettre à jour le plan journalier !',
        rearrange: "L'élément n'a pas pu être déplacé!",
      },
      poi: {
        fetch: "Impossible d'obtenir des emplacements",
      },
    },
    poi: {
      fetch: "Impossible d'obtenir l'emplacement",
      create: "Impossible d'ajouter un lieu à proximité de l'arrêt",
      update: "Impossible de mettre à jour l'emplacement ",
      delete: "Impossible de supprimer l'emplacement ",
      preview: "Impossible de charger l'aperçu de l'emplacement",
    },
    activity: {
      fetch: "Impossible d'obtenir l'activité",
      create: "Impossible d'ajouter un activité à l'arrêt",
      update: "Impossible de mettre à jour l'activité ",
      delete: "Impossible de supprimer l'activité ",
    },
    document: {
      fetch: "Impossible d'obtenir les documents!",
      get: 'Impossible de charger le document !',
      create: "Votre document n'a pas pu être enregistré!",
      update: "Votre document n'a pas pu être mis à jour!",
      delete: "Votre document n'a pas pu être supprimé!",
      download: "Votre document n'a pas pu être téléchargé!",
    },
    expense: {
      create: "Impossible d'enregistrer les dépenses!",
      update: 'Impossible de mettre à jour les dépenses !',
      delete: 'Impossible de supprimer les dépenses !',
      rate: 'Impossible de recevoir les taux de change',
      findCurrency: 'Impossible de trouver la devise',
    },
    amount: {
      delete: 'Impossible de supprimer le montant !',
      paid: 'Impossible de marquer les dépenses comme payées !',
      ping: "Impossible d'envoyer votre rappel!",
    },
    note: {
      fetch: "Impossible d'obtenir les notes ",
      create: "Impossible d'enregistrer la note ",
      update: 'Impossible de mettre à jour la note',
      delete: 'Impossible de supprimer la note',
    },
    photo: {
      fetch: "Impossible d'obtenir les photos!",
      create: "Votre photo n'a pas pu être enregistrée!",
      update: "Votre photo n'a pas pu être mise à jour!",
      delete: "Votre photo n'a pas pu être supprimée!",
      download: "Votre photo n'a pas pu être téléchargée!",
    },
    message: {
      fetch: 'Impossible de récupérer les messages',
      send: "Votre message n'a pas pu être envoyé ",
    },
    inspiration: {
      fetch: "Impossible de récupérer l'inspiration ",
      fetchAll: 'Impossible de récupérer toutes les inspirations',
    },
    category: {
      fetchAll: 'Impossible de récupérer toutes les catégories',
    },
    bookmark: {
      fetch: 'Impossible de récupérer les favoris',
      create: "Impossible d'ajouter le favori ",
      delete: 'Impossible de supprimer le favori',
    },
    import: {
      create: "Impossible d'importer le voyage ",
    },
    directory_create: 'Impossible de créer le dossier',
    feedback: "Votre commentaire n'a pas pu être envoyé!",
    payment_details: 'Impossible de récupérer les détails de paiement!',
    email_invalid: "L'adresse e - mail est invalide.",
    email_retry:
      'Veuillez vérifier que vous avez entré la bonne adresse e-mail et réessayer.',
    location_support:
      'Votre navigateur ne supporte pas la géolocalisation GPS.',
    location: "Votre emplacement n'est pas disponible.Veuillez réessayer.",
    transportation: {
      fetch: 'Impossible de récupérer les informations de transport.',
      search: 'Impossible de rechercher un transport.',
      post: "Impossible d'ajouter un transport.",
      update: 'Impossible de mettre à jour le transport.',
      delete: 'Impossible de supprimer le transport.',
      searchFlight: "Le vol n'a pas pu être trouvé.",
      flightStatus: {
        missingFields:
          'Nous avons besoin des informations suivantes pour suivre votre vol : {missingFields}',
        register: "Impossible d'activer les mises à jour de vol.",
        fetch: "Impossible d'obtenir le statut de vol.",
      },
    },
    routes: {
      fetchAll: 'Impossible de charger les itinéraires.',
      fetch: 'Impossible de charger votre itinéraire.',
      calculate: "Impossible de calculer l'itinéraire.",
      update: 'Impossible de mettre à jour votre itinéraire.',
      create: "Impossible d'enregistrer votre itinéraire.",
      delete: "Impossible de supprimer l'itinéraire.",
    },
    export: {
      trip: "Impossible d'exporter votre voyage.",
      expenses: "Impossible d'exporter vos dépenses de voyage.",
    },
    packingLists: {
      fetch: "Votre liste n'a pas pu être chargée.",
      fetchTemplates: "Les modèles de liste n'ont pas pu être chargés.",
      create: "Votre liste n'a pas pu être enregistrée.",
      update: "Votre liste n'a pas pu être mise à jour.",
      delete: "Votre liste n'a pas pu être supprimée.",
    },
    subscription: {
      fetch: "Les abonnements n'ont pas pu être chargés.",
      cancel:
        "Votre abonnement n'a pas pu être annulé. Veuillez réessayer plus tard ou contacter notre support.",
    },
    payment: {
      prices: {
        fetch: "Les prix n'ont pas pu être chargés.",
      },
      session: {
        fetch: "Le processus de paiement n'a pas pu être initié.",
      },
    },
    placeRecommendations: {
      fetch: "Les endroits recommandés n'ont pas pu être chargés.",
    },
    uploadFileSize: 'Le fichier ne peut pas être plus grand que 25 Mo.',
  },
  info: {
    document: {
      upload: 'Votre document sera téléchargé en arrière-plan…',
      uploadMultiple:
        'Vos {count} documents seront téléchargés en arrière-plan…',
    },
    photo: {
      upload: 'Votre photo sera téléchargée en arrière-plan…',
      uploadMultiple: 'Vos {count} photos seront téléchargées en arrière-plan…',
    },
    transportation: {
      searchFlight: 'Vol non trouvé',
    },
    userAlreadyAdded: 'Participant déjà ajouté',
  },
  emptyState: {
    map: 'Recherchez de nouveaux arrêts en utilisant le champ de recherche ou la carte',
    documents: 'Aucun document',
    private_expenses: 'Aucune dépense personnelle',
    group_expenses: 'Aucune dépense partagée',
    billing: 'Équilibré',
    notes: 'Aucune note',
    photos: 'Aucune photo',
    chat: 'Aucun message',
    travelPlan: 'Aucun arrêt',
    packingLists: 'Aucune liste',
    transportation: 'Aucun transport',
    hint: {
      general: "N'hésitez pas à en ajouter!",
      travelPlan: 'Veuillez créer des arrêts et revenir ici !',
      expenses: 'Tout est équilibré. Appuyez sur + pour ajouter une dépense',
      chat: 'Écrivez quelque chose de sympa !',
      packingLists:
        'Assurez-vous de ne rien oublier ! Appuyez sur + pour ajouter une liste',
      accommodation:
        'Où séjournez-vous ? Appuyez sur + pour ajouter vos hôtels et appartements',
      search:
        '@:(noResults). Veuillez réessayer avec un terme de recherche différent. 🙌',
      transportation:
        'Comment voyagez-vous ? Appuyez sur + pour ajouter votre transport',
      notes: 'Si vide ! Appuyez sur + pour ajouter des notes ou des liens',
      documents:
        'Aucun document. Appuyez sur + pour ajouter vos documents de voyage',
      photos:
        'Aucune photo à afficher. Appuyez sur + pour enregistrer vos souvenirs',
    },
  },
  overview: 'Aperçu',
  copyTooltip: 'Cliquez pour copier',
  copySuccess: 'Copié dans le presse-papiers !',
  datePicker: {
    SPECIFIC: 'Date spécifique',
    WHOLE_MONTH: 'Mois complet',
  },
  datetimePicker: {
    DATE: 'Date',
    TIME: 'Heure',
  },
  dates: {
    title: 'Quand serez-vous là ? (facultatif)',
    hint: 'Sélectionner une date …',
  },
  joinTripPrompt:
    'Vous avez reçu une invitation pour un voyage ? Entrez le code PIN du voyage ci-dessous pour rejoindre.',
  joinTripNow: 'Rejoindre le voyage maintenant',
  navBar: {
    discover: 'Inspirations',
    newTrip: 'Nouveau voyage',
    joinTrip: 'Rejoindre un voyage',
    archive: 'Archives',
    activeTrips: 'Voyages actifs',
    searchPlaceholder: "Recherche d'inspirations ",
  },
  peoplePickerTitle: 'Sélectionnez une personne',
  youAreInvited: 'Vous avez été invité à rejoindre "{tripName}" !',
  acceptInvitation: "Accepter l'invitation ",
  decline: 'Refuser',
  destination: 'La destination',
  destinations: 'Destinations',
  suggestedTrips: 'Voyages suggérés',
  similarTopics: 'Sujets similaires',
  sendImage: 'Envoyer une image',
  dropHint: 'Déposez vos fichiers ici pour les télécharger.',
  iOwe: 'Je dois',
  owe: 'dois',
  iReceive: 'Je reçois',
  receiveFrom: 'reçu de',
  newPrivateExpense: 'Nouvelle dépense privée',
  newGroupExpense: 'Nouvelle dépense de groupe',
  notInvolved: 'Pas impliqué',
  addNote: 'Ajouter une note',
  newNote: 'Nouvelle note',
  saveNote: 'Enregistrer la note',
  billing: 'Facturation',
  openAccounts: 'Comptes ouverts',
  paidAccounts: 'Comptes payés',
  paid: 'Payé',
  received: 'Reçu',
  createExpense: 'Créer une dépense',
  updateExpense: 'Mettre à jour la dépense',
  addExpense: 'Ajouter une dépense',
  editExpense: 'Modifier la dépense',
  whoDoesItRelateTo: 'À qui cela concerne-t-il ?',
  whoPaid: 'Qui a payé ?',
  subject: 'Sujet',
  subjectPlaceholder: 'Saisir un sujet …',
  continue: 'Continuer',
  balance: 'Solde',
  total: 'Total',
  equal: 'également',
  exact: 'exactement',
  whenWasItPaid: 'Quand a-t-il été payé ?',
  deleteExpensePrompt: 'Voulez-vous supprimer cette dépense ?',
  deleteExpense: 'Supprimer la dépense',
  pingPrompt: 'Relancer {value} pour marquer ce solde comme payé.',
  pingAction: 'Envoyer un rappel',
  pingTitle: 'Vous avez déjà payé ?',
  enterAmount: 'Entrer le montant',
  enterAmountPlaceholder: 'Saisir le montant …',
  payWithPayPal: 'Payer avec PayPal',
  noPaypalAccountSubject: 'Détails PayPal non déposés, pour le moment !',
  noPaypalAccountText:
    "{person} n'a pas encore déposé ses informations PayPal.Vous pouvez lui demander d'ajouter ces détails à son compte et revenir ici.",
  markAsPaid: 'Marquer comme payé',
  alreadyPaid: 'Déjà payé ?',
  noPayPalInformation:
    "Cet utilisateur n'a pas encore renseigné ses informations PayPal.",
  group: 'Groupe',
  myExpenses: 'Mes dépenses',
  allExpenses: 'Toutes les dépenses',
  private: 'Privé',
  shared: 'Partagé',
  searchHint: 'Rechercher des lieux',
  noResults: 'Aucun résultat',
  airport: 'Aéroport',
  atm: 'Distributeur de billets',
  bank: 'Banque',
  bar: 'Bar',
  bus_station: 'Station de bus',
  cafe: 'Café',
  city_hall: 'Hôtel de ville',
  gas_station: 'Station-service',
  hotel: 'Hôtel',
  hospital: 'Hôpital',
  library: 'Bibliothèque',
  museum: 'Musée',
  night_club: 'Club de nuit',
  park: 'Parc',
  parking: 'Parking',
  pharmacy: 'Pharmacie',
  police: 'Police',
  post_office: 'Bureau de poste',
  restaurant: 'Restaurant',
  shopping_mall: 'Centre commercial',
  store: 'Magasin',
  subway_station: 'Station de métro',
  train_station: 'Gare',
  zoo: 'Zoo',
  showLess: 'Afficher moins',
  radius: 'Rayon',
  noPlacesFound:
    "Aucun endroit n'a été trouvé dans cette recherche.Veuillez essayer un autre terme de recherche ou augmenter le rayon ",
  noWorriesYouCanStillAddIt:
    "Mais ne vous inquiétez pas, vous pouvez toujours l'ajouter!",
  howWouldYouLikeToImportThisPlace:
    'Comment voulez-vous importer cet endroit ?',
  chooseWaypoint: "À quel arrêt souhaitez-vous ajouter l'emplacement ? ",
  selectWaypoint: 'Sélectionnez un arrêt …',
  importAsWaypoint: 'Importer comme arrêt',
  addPOIToWaypoint: 'Importer comme POI',
  whereToStartFrom: 'Où voulez-vous partir ?',
  mapSettings: 'Paramètres de la carte',
  mapTypes: {
    title: 'Type de carte',
    types: {
      normal: 'Normal',
      satellite: 'Satellite',
      terrain: 'Terrain',
    },
  },
  distanceUnits: {
    title: 'Unités de distance',
    kilometers: 'Kilomètres',
    miles: 'Miles',
  },
  weather: {
    title: 'Température',
    celsius: 'Celsius',
    fahrenheit: 'Fahrenheit',
  },
  connectWaypoints: 'Connecter les arrêts',
  showDistance: 'Afficher la distance',
  learnMore: 'En savoir plus',
  deleteNoteDescription: 'Êtes-vous sûr de vouloir supprimer cette note ?',
  deleteNoteTitle: 'Supprimer la note',
  noteName: 'Entrez un titre …',
  selectNote: 'Sélectionnez une note …',
  areYouSure: 'Êtes-vous sûr ?',
  removeAttendee: "Supprimer l'invité ",
  showPicture: 'Afficher la photo de profil',
  viewPhoto: 'Voir la photo',
  deletePhoto: 'Supprimer la photo',
  general: 'Général',
  restaurants: 'Restaurants',
  accommodations: 'Hébergements',
  entertainment: 'Divertissement',
  shopping: 'Shopping',
  activities: 'Activités',
  drinks: 'Boissons',
  fuel: 'Ravitaillement / Recharge',
  tickets: 'Billets',
  fees: 'Frais',
  usernameSearchHint:
    "Rechercher un nom d'utilisateur ou une adresse e - mail …",
  currency: 'Devise',
  currencyHeader: 'Quelle est votre devise ?',
  timePeriod: 'Période de voyage',
  datesHeader: 'Quand serez-vous là-bas ?',
  notifications: 'Notifications',
  choose: 'Sélectionner',
  selected: 'sélectionné',
  waypointSorting: {
    changeToManual: 'Passer à un tri manuel',
    title: 'Tri des arrêts',
    header: 'NOUVEAU : Triez les arrêts automatiquement',
    text: "Il est maintenant possible de trier les arrêts automatiquement en fonction de leur date. Si un arrêt n'a pas de date, il sera ajouté à la fin.Si vous avez déjà ajouté des transports ou des itinéraires qui pourraient être affectés par ce changement, nous vous en informerons bien sûr!",
  },
  byDate: 'Par date',
  manual: 'Manuel',
  preview: 'Aperçu',
  showPreview: "Afficher l'aperçu ",
  inviteYourFriends: 'Inviter des amis',
  manage: 'Gérer',
  tokenHint:
    'Vous pouvez partager le code PIN avec vos amis et ils peuvent rejoindre le voyage à travers celui-ci.',
  moreActions: "Plus d'actions ",
  saveTrip: 'Enregistrer le voyage',
  archiveTrip: 'Archiver le voyage',
  duplicateTrip: 'Dupliquer le voyage',
  duplicateTripConfirmMessage:
    'Voulez-vous dupliquer ce voyage? Une copie de ce voyage sera créée.',
  confirm: 'Confirmer',
  enterTripNamePrompt: 'Veuillez saisir le nouveau nom du voyage.',
  enterTripName: 'Entrez le nom du voyage …',
  leftTrip: 'a quitté le voyage',
  leaveTrip: 'Quitter le voyage',
  deleteTrip: 'Supprimer le voyage',
  leaveTripPrompt: 'Voulez-vous quitter ce voyage ?',
  deleteTripPrompt: 'Voulez-vous supprimer ce voyage ?',
  restoreTrip: 'Désarchiver le voyage',
  themeTooltip: 'Cliquez pour changer la photo de couverture (max 5 mb)',
  inviteNow: 'Inviter maintenant',
  inviteAttendee: 'Inviter le participant',
  search: 'Rechercher',
  skip: 'Passer',
  highlight: 'Lieux à proximité',
  expense: 'Dépenses',
  document: 'Documents',
  photo: 'Photos',
  note: 'Note',
  timeHint: 'Sélectionner une heure …',
  poi: 'Lieux locaux',
  deleteWaypointPrompt: 'Êtes-vous sûr ?',
  deleteWaypoint: 'Supprimer {type}',
  place: 'place',
  changePasswordAction: 'Changer le mot de passe',
  changePassword: 'Changer le mot de passe …',
  oldPassword: "Saisir l'ancien mot de passe …",
  newPassword: 'Saisir le nouveau mot de passe …',
  newPasswordConfirmation: 'Saisir le nouveau mot de passe à nouveau …',
  resetPasswordTitle: 'Réinitialiser le mot de passe',
  resetPasswordAction: 'Réinitialiser maintenant',
  resetHint:
    'Veuillez saisir votre adresse e-mail pour réinitialiser votre mot de passe.',
  enterEmail: "Saisir l'adresse e - mail …",
  forgotPassword: 'Mot de passe oublié?',
  invitePrompt: 'Voulez-vous inviter directement vos amis à votre voyage?',
  selectTrip: 'Sélectionner le voyage',
  inviteToLambus: 'Non, seulement à Lambus',
  yes: 'Oui',
  yesSelectTrip: 'Oui, sélectionner le voyage',
  profileTooltip: 'Cliquez pour changer la photo de profil',
  sendInvite: 'Envoyer une invitation',
  inviteAggreement:
    "Je confirme que la personne que j'ai invitée a accepté de recevoir l'e-mail d'invitation de Lambus GmbH",
  inviteHint:
    'Invitez vos amis maintenant et planifiez votre prochain voyage ensemble.',
  socialMedia: 'Médias sociaux',
  youCanFindUsOn: 'Vous pouvez nous trouver sur …',
  feedback: "Retour d'information ",
  howCanWeHelp: 'Comment pouvons-nous aider?',
  send: 'Envoyer',
  faq: 'Aide / FAQ',
  legal: 'Mentions légales',
  terms: "Conditions d'utilisation ",
  privacy: 'Confidentialité',
  imprint: 'Mentions légales',
  username: "Nom d'utilisateur ",
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  email: 'Adresse e-mail',
  company: 'Entreprise',
  gender: 'Genre',
  birthdate: 'Date de naissance',
  female: 'Femme',
  male: 'Homme',
  neutral: 'Neutre',
  country: 'Pays',
  paypalAddress: 'Adresse e-mail PayPal',
  paypalMeUsername: "Nom d'utilisateur PayPal.Me ",
  deleteAccountPrompt:
    "Cela effacera toutes vos données - y compris les voyages, les documents, les photos et l'historique de chat.",
  irrevocablyDelete: 'Supprimer de manière irrévocable',
  deleteAccount: 'Supprimer le compte',
  unknownPlace: 'Lieu inconnu',
  kilometersUnit: 'km',
  milesUnit: 'mi',
  createTrip: 'Créer un voyage',
  next: 'CONTINUER',
  back: 'RETOUR',
  navHint: 'Ou appuyez sur ENTREE',
  invite: 'Inviter',
  or: 'OU',
  newTripFields: {
    destination: {
      title: 'Où voulez-vous aller?',
      hint: 'par exemple, une ville, un pays ou une région',
    },
    origin: {
      title: 'Où partez-vous? (facultatif)',
      hint: 'z.B. adresse, gare ou aéroport',
    },
    attendees: {
      hint: 'Qui vous accompagne? (facultatif)',
    },
  },
  createNewTrip: 'Créer un nouveau voyage',
  joinTrip: 'Rejoindre le voyage',
  welcome: 'Bienvenue sur Lambus!',
  loginTitle: 'Bienvenue sur Lambus!',
  login: 'Se connecter',
  lambusAllInOneApp: 'Lambus - Votre nouvelle application de voyage tout-en-un',
  usernameLabel: "Nom d'utilisateur / E - mail ",
  passwordLabel: 'Mot de passe',
  notRegisteredYet: 'Créer un compte',
  loginFailed: {
    wrongInput:
      'Soit votre nom d\'utilisateur / adresse e - mail ou votre mot de passe était incorrect. Veuillez réessayer ou cliquer sur "Mot de passe oublié ?", merci!',
    alreadyRegisteredWithEmail:
      'Problème lors de votre connexion. Vous avez déjà un compte avec cette adresse e-mail. Voulez-vous réinitialiser votre mot de passe ?',
    isLinkedToSocialAccount:
      'Votre e-mail est lié à un compte de réseau social. Veuillez vous connecter avec votre compte "{social}".',
    appleSignInError:
      "Malheureusement, nous n'avons pas pu finaliser votre connexion avec Apple maintenant. Veuillez choisir une méthode de connexion alternative - entre - temps, nous travaillons déjà sur la résolution du problème!",
  },
  continueWithoutAccount: 'Continuer sans profil ?',
  usernameNotice:
    "Vous y êtes presque ! Veuillez entrer un nom d'utilisateur qui sera lié à votre compte Lambus.",
  tosAggreement:
    'Veuillez accepter nos conditions et notre politique de confidentialité pour compléter votre inscription.',
  continueWithApple: 'Continuer avec Apple',
  continueWithFacebook: 'Continuer avec Facebook',
  registration: 'Inscription',
  register: "S'inscrire ",
  tosAggreementPlaceholders:
    'Veuillez accepter notre {0} et notre {1} pour compléter votre inscription.',
  field: 'Champ',
  value: 'Valeur',
  noInspirationFound: 'Aucune inspiration trouvée.',
  importTrip: 'Importer un voyage',
  importedTrip: 'Voyage importé',
  myTransportation: 'Mon moyen de transport',
  addTransportation: 'Ajouter un moyen de transport',
  linkedWaypoint: 'Arrêts liés',
  unlinkTransportationPrompt:
    'Votre arrêt est lié à un moyen de transport. Voulez-vous le dissocier ?',
  unlink: 'Dissocier',
  departure: 'Départ',
  plusDepartureStation: '+ Station (Départ)',
  plusArrivalStation: '+ Station (Arrivée)',
  plusTime: '+ Heure',
  transportationCarDestinationTooltip:
    "Astuce : Modifiez l'arrêt de destination pour utiliser le transport pour d'autres trajets également !",
  sameLocationDescription:
    "Ensuite, nous ajoutons l'arrêt « {waypoint} » comme destination pour vous.",
  sameLocationTitle: 'Origine = Destination ?',
  plusDeparturePort: '+ Port (Départ)',
  plusArrivalPort: '+ Port (Arrivée)',
  plusPreciseLocation: '+ Lieu précis',
  port: 'Port',
  searchFlight: 'Rechercher un vol',
  flightNumber: 'Numéro de vol',
  flightNumberPlaceholder: 'par exemple, AF1234',
  FlightBooking: 'Vol',
  TrainBooking: 'Train',
  RentalCarBooking: 'Location de voiture',
  CarBooking: 'Voiture',
  OwnCar: 'Voiture personnelle',
  FerryBooking: 'Ferry',
  BusBooking: 'Bus',
  BicycleBooking: 'Vélo',
  WalkingBooking: 'Marche',
  GenericBooking: 'Autre',
  subTypePlaceholder: 'par ex. camping-car, moto, ...',
  taxi: 'Taxi',
  escooter: 'E-Scooter',
  rv: 'Camping-car',
  motorcycle: 'Moto',
  helicopter: 'Hélicoptère',
  cruise: 'Croisière',
  airportSearch: 'Rechercher un aéroport',
  airportActionPrompt: 'Que souhaitez-vous faire ?',
  showOnGoogleMaps: 'Afficher sur Google Maps',
  selectLocation: 'Sélectionner un emplacement',
  selectAirport: 'Sélectionner un aéroport',
  uploadNewDocument: 'Téléverser un nouveau document',
  selectDocument: 'Sélectionner un document',
  viewDocument: 'Voir le document',
  readOnWebsite: 'En savoir plus sur <a>{website}</a>',
  textfieldRules: {
    required: 'Requis.',
    counter: 'Max 50 caractères.',
    pin: 'Le code PIN doit comporter 6 caractères.',
    amount: 'Montant invalide.',
    email: 'Adresse e-mail invalide.',
    link: 'Lien invalide.',
    flightNumber: 'Numéro de vol invalide.',
    timeString: 'Format de temps invalide.',
    password: 'Le mot de passe doit contenir au moins 6 caractères.',
    noWhiteSpace: 'Ne doit contenir aucun espace.',
    checkbox: 'Le champ doit être accepté.',
  },
  maxFileSize: 'Taille maximale du fichier : {size}',
  tripOfTheDay: 'Voyage du jour',
  tripOfTheWeek: 'Voyage de la semaine',
  tripOfTheMonth: 'Voyage du mois',
  media: 'Média',
  searchResult: 'Résultats de recherche',
  noBookmarks: 'Pas de favoris',
  discoverTrips: 'Découvrir les voyages',
  date: 'Date',
  lastUpdated: 'Dernière mise à jour',
  sortTripsBy: 'Trier les voyages par …',
  adBlocker: {
    hey: 'Hé !',
    notice:
      'Il semble que vous utilisiez un bloqueur de publicités.Si vous souhaitez voir {contentType} plutôt que cette alerte, veuillez désactiver votre bloqueur de publicités.',
  },
  pro: {
    unlock: {
      title: '🔓 Débloquer cette fonctionnalité',
      description:
        'Cette fonctionnalité est disponible dans Lambus PRO ! Voulez-vous en savoir plus ?',
    },
    checkout: {
      title: 'Passer à Lambus PRO',
      description:
        'Débloquez des fonctionnalités premium supplémentaires pour rendre votre planification de voyage encore plus facile ! 🎉',
      payPerTrip: {
        title: 'Payer par voyage',
        caption: 'Mettre à niveau un voyage',
      },
      yearlySubscription: {
        title: 'Abonnement annuel',
        caption: 'Mettre à niveau tous vos voyages',
        freeTrial: 'ESSAYEZ GRATUITEMENT PENDANT 7 JOURS !',
      },
      success: {
        title: 'Lambus PRO : Achat réussi !',
      },
      learnMore: 'En savoir plus sur Lambus PRO',
      upgradeNowUpper: 'PASSER À PRO 🚀',
      thankYouNotice:
        'Merci d’avoir acheté Lambus PRO ! ❤️ Cette page se fermera automatiquement dans {time} secondes.',
    },
    subscription: {
      validUntil: "valable jusqu'au ",
      purchasedBy: 'acheté par',
      exploreProFeatures: 'Découvrez les fonctionnalités PRO',
      exploreLambusPro: 'Explorez Lambus PRO',
      manageSubscription: "Gérer l'abonnement ",
      mySubscriptions: 'Mes abonnements',
      noSubscriptions: 'Aucun abonnement',
      noActiveSubscriptions: "Vous n'avez aucun abonnement actif ",
      areYouTravelingAlot: 'Voyagez-vous beaucoup ?',
      upgradeToYearlySubscription: 'Alors passez à un abonnement annuel !',
      cancelSubscription: "Annuler l'abonnement ",
      nowWith: 'Maintenant avec',
      forever: 'Pour toujours',
      yearlySubscription: 'Abonnement annuel',
      monthlySubscription: 'Abonnement mensuel',
      lifetimeSubscription: 'Abonnement à vie',
    },
    features: {
      routePlanning: {
        title: 'Planification de trajet étendue',
        description:
          'Calculer la durée et la distance exactes entre tous vos arrêts',
      },
      googleMaps: {
        title: 'Recherche Google Maps',
        description:
          "Nous utilisons Google Maps pour trouver encore plus d'arrêts ",
      },
      videoChat: {
        title: 'Chat vidéo',
        description:
          "Discutez avec d'autres participants tout en planifiant votre voyage ",
      },
      moneyPool: {
        title: 'Cagnotte',
        description: "Gérez l'argent du voyage en un seul endroit central ",
      },
      exportTripGpx: {
        title: 'Exportation de voyage en GPX',
        description:
          "Exportez votre voyage en GPX et utilisez-le dans d'autres applications de suivi ",
      },
      currencies: {
        title: 'Devises automatiques',
        description:
          'Convertir les devises étrangères en votre devise en utilisant les taux de change actuels',
      },
      weather: {
        title: 'Mises à jour météorologiques en temps réel',
        description:
          'Afficher les conditions météorologiques actuelles pour tous vos arrêts',
      },
      exportExpenses: {
        title: 'Exportation des dépenses',
        description: 'Exportez automatiquement vos dépenses vers Excel',
      },
      flightStatus: {
        title: 'Mises à jour de vol en temps réel',
        description:
          'Soyez informé si votre vol est retardé, annulé ou rescheduled',
      },
      exportTrip: {
        title: 'Exportation de voyage en PDF',
        description:
          "Exportez votre voyage en PDF pour l'avoir sous la main même sans smartphone ",
      },
      support: {
        title: 'Soutenez-nous ❤️',
        description:
          'Avec votre aide, Lambus peut rester sans publicité et sans vendre de données',
      },
      packingList: {
        title: 'Listes (listes de bagages, listes de choses à faire)',
        description: 'Choisissez parmi des listes existantes ou créez la vôtre',
      },
      flightCompensation: {
        title: 'Compensation de vol',
        description:
          'Réclamez automatiquement une compensation si votre vol est annulé',
      },
      privateDocuments: {
        title: 'Documents privés',
        description:
          'Stockez en toute sécurité votre passeport, votre permis de conduire et plus encore sur votre appareil',
      },
      offlineMode: {
        title: 'Mode hors ligne',
        description:
          "Accède à l'ensemble de ton voyage même sans connexion Internet",
      },
      calendarSync: {
        title: 'Synchronisation du calendrier',
        description: 'Synchronise tes waypoints et tes POI avec ton calendrier',
      },
    },
    cancellation: {
      orderedWith: 'Acheté sur {store}',
      cancelWith:
        'Puisque vous avez acheté Lambus PRO sur {store}, votre annulation est également gérée par {store}',
      reasons: {
        label: {
          pro_cancelation_reason_1: "Je n'utilise pas les fonctionnalités",
          pro_cancelation_reason_2: 'Il me manque une fonctionnalité',
          pro_cancelation_reason_3: "J'ai trouvé une meilleure application",
          pro_cancelation_reason_4: 'Le prix était trop élevé',
          pro_cancelation_reason_5: 'Je teste pour un voyage ultérieur',
        },
        placeholder: {
          pro_cancelation_reason_2:
            'Quelles fonctionnalités vous manquent-elles ?',
        },
      },
      lossHint: {
        youCurrentlyHave: 'Vous avez actuellement',
        thatWillBeLost:
          'qui seront perdus en annulant votre abonnement annuel à Lambus PRO.',
        confirmation: 'Je veux toujours annuler mon abonnement.',
        tellUsWhy:
          "S'il vous plaît, faites-nous savoir ce que vous n'aimez pas et nous y travaillerons !",
      },
      talkToUs: 'Vous voulez nous parler ? Nous sommes heureux de vous aider !',
      chatWithUs: 'Discuter avec nous',
      sendUsAnEmail: 'Envoyez-nous un e-mail',
    },
  },
  routes: {
    myRoutes: 'Mes itinéraires',
    planRoute: "Planifier l'itinéraire ",
    calculate: "Calculer l'itinéraire ",
    save: "Enregistrer l'itinéraire ",
    delete: "Supprimer l'itinéraire ",
    recommendedRoute: 'Itinéraire recommandé',
    type: {
      driving: 'Voiture',
      transit: 'Train',
      bicycling: 'Vélo',
      flight: 'Vol',
      walking: 'Marche',
    },
  },
  realtimeFlightStatus: {
    isEligibleForCompensation:
      "Éligible pour un remboursement en cas d'annulation / délai ",
    activateFlightAlert: 'Recevoir des mises à jour de vol',
    flightStatus: 'Statut du vol',
    status: {
      active: 'En vol',
      canceled: 'Annulé',
      diverted: 'Détourné',
      landed: 'Atterri',
      not_operational: 'Non actif',
      redirected: 'Redirigé',
      scheduled: 'Prévu',
      now_tracking: 'Actuellement suivi',
      unknown: 'Inconnu',
    },
    compensation: {
      title: 'Réclamation de compensation de vol',
      subtitle: "Recevez jusqu'à 600€ si votre vol est retardé ou annulé!",
      howItWorks: 'Comment cela fonctionne',
      instructions: {
        step1: '1. Suivez votre vol avec Lambus PRO',
        step2:
          '2. Nous détectons si le vol est retardé ou annulé et vous contacterons si votre vol est éligible pour une compensation!',
      },
    },
  },
  selectDate: 'Sélectionner une date',
  distance: 'Distance',
  duration: 'Durée',
  later: 'Plus tard',
  exportExpensesPrompt:
    "Nous pouvons regrouper les dépenses de voyage de votre voyage dans un fichier Excel. Voulez-vous commencer l'exportation maintenant ? ",
  exportInfo:
    'Vous recevrez bientôt un e-mail avec le document. Important : Vérifiez également votre boîte de spam !',
  exportStarted: "L'exportation a commencé ",
  exportNow: 'Exporter maintenant',
  exportAsExcel: 'Exporter en fichier Excel',
  howItWorks: 'Comment ça marche',
  exportExpenses: 'Export Excel des frais de voyage',
  howItWorksInfo:
    "Avec Lambus, tous les frais de voyage sont automatiquement calculés entre les voyageurs, ce qui signifie que si vous devez quelque chose à une personne et que cette personne doit quelque chose à une autre personne, deux personnes n'ont rien à transférer, une seule ",
  howItWorksPrompt:
    'Vous avez des questions ou pensez que nous avons commis une erreur ?',
  contactUs: 'Nous contacter',
  export: 'Exporter',
  exportTrip: 'Exporter le voyage en PDF',
  exportTripPrompt: 'Voulez-vous exporter votre voyage en PDF ?',
  exportAsGPX: 'Exporter en fichier GPX',
  exportTripAsGPXPrompt: 'Voulez-vous exporter votre voyage en GPX ?',
  exportAsPDF: 'Exporter en PDF',
  gpxZoomPrompt:
    'Devrions-nous toujours zoomer sur votre itinéraire GPX en premier ?',
  showGpxRoutes: "Afficher l'itinéraire GPX ",
  expenseExport: 'Exporter les frais de voyage',
  packingLists: {
    useThisList: 'Utiliser cette liste',
    title: 'Liste',
    create: 'Créer une liste',
    add: 'Ajouter une liste',
    delete: 'Supprimer la liste',
    deleteSection: 'Supprimer la catégorie',
    deleteSectionPrompt: 'Voulez-vous supprimer cette catégorie ?',
    myPackingLists: 'Mes listes',
    templatesFromLambus: 'Modèles de Lambus',
    createNewList: 'Créer une nouvelle liste',
    myTemplates: 'Mes modèles',
    createNewTemplate: 'Créer un nouveau modèle',
    templateName: 'Nom du modèle',
    orCreateNew: 'Ou créer un nouveau',
  },
  createYourOwn: 'Créer sa liste',
  addCategory: 'Ajouter une catégorie',
  new: 'nouveau',
  soonUpper: 'BIENTÔT',
  gpsBasedCurrencies: {
    title: 'Trouver les devises automatiquement',
    info: 'Voulez-vous détecter automatiquement la devise ? Autorisez-nous à utiliser votre emplacement actuel pendant une courte période dans les paramètres du navigateur.',
  },
  packingListItemCount:
    'Aucun élément | {checkedCount}/{itemCount} élément | {checkedCount}/{itemCount} éléments',
  items: 'Aucun élément | Un élément | {count} éléments',
  lists: 'Listes',
  rename: 'Renommer',
  visibility: 'Visibilité',
  gpx: {
    files: 'Fichiers GPX',
    description:
      "Votre voyage a une adresse e-mail unique à laquelle vous envoyez vos fichiers GPX. Une fois que vous avez importé votre fichier .gpx via l'adresse e-mail ci-dessus, il sera dessiné sur votre carte d'arrêt.",
    visibility: {
      always: 'Oui',
      never: 'Non',
      askLater: 'Décider plus tard',
    },
  },
  savedPlaces: 'Lieux et activités enregistrés',
  recommendedPlaces: 'Lieux recommandés',
  pleaseEnterThePDFPassword: 'Veuillez saisir le mot de passe du fichier PDF :',
  cannotOpenPDF:
    "Le fichier PDF n'a pas pu être ouvert.Veuillez contacter notre support!",
  routeTo: 'Itinéraire vers',
  dailyPlan: 'Agenda quotidien',
  daily_plan: 'Agenda quotidien',
  openRoute: 'Ouvrir la connexion',
  manageTransportations: 'Gérer vos vols, trains, bus, etc.',
  showAll: 'Tout afficher',
  onboarding: {
    youHaveBeenInvited: 'Vous avez été invité ?',
    findInspirations: 'Trouvez des inspirations',
    howDoYouWantToStart: 'Sélectionnez comment vous souhaitez commencer !',
    allInOneSingleApp:
      'Planifiez, réservez et gérez votre voyage entier avec une seule application !',
    alreadyKnowWhereToGo: 'Vous savez déjà où vous voulez aller ?',
    notSureWhereToGoYet: 'Pas encore sûr(e) où aller ?',
    pleaseEnterThePinCodeBelow: 'Veuillez entrer le code PIN ci-dessous !',
    youDontHaveAnyCode: "Vous n'avez pas de code PIN ?",
    emailInviteNote:
      'Vous pouvez également être invité par e-mail. Voulez-vous créer un compte avec votre adresse e-mail maintenant ?',
    almostThereExclamation: 'Presque là !',
    registerToJoinTripText:
      'Pour rejoindre le voyage, nous vous demandons gentiment de créer un compte Lambus.Ne vous inquiétez pas, nous ne vendons aucune donnée et nous ne vous ennuyons pas🙂',
    registerToCreateTripText:
      'Pour sauvegarder votre voyage, nous vous demandons gentiment de créer un compte Lambus.Ne vous inquiétez pas, nous ne vendons aucune donnée et nous ne vous ennuyons pas🙂',
    keywords: 'Mots-clés',
    category: 'Catégories',
    hashtag: 'Hashtags',
    letsGo: 'Allons - y!',
    youAlreadyHaveAnAccount: 'Vous avez déjà un compte ?',
    searchHint: {
      keywords: 'Recherche d´inspiration…',
      category: 'Recherche de catégories…',
      hashtag: 'Recherche de hashtags…',
    },
    selectedInspiration: 'Inspiration sélectionnée',
    newTrip: 'Votre nouveau voyage',
  },
  enterNewName: 'Veuillez entrer le nouveau nom de votre arrêt',
  unnamedLocation: 'Lieu sans nom',
  dateFormat: 'Format de date',
  systemFormat: 'Système',
  callVerb: 'Appeler',
  visitWebsite: 'Visiter le site web',
  savePlace: 'Enregistrer le lieu',
  tapToAddSomething: 'taper pour ajouter quelque chose',
  nothingPlanedYet: "Rien de prévu pour l'instant",
  noAccommodationAddedYet: 'Aucun hébergement ajouté',
  tapToAddAnAccommodation: 'Appuyez pour ajouter un nouvel hébergement',
  noDateSet: 'Aucune date définie',
  planYourDaysPerWaypoint: 'Planifiez vos journées par arrêt',
  specifyDate: 'Spécifier la date',
  linkToWaypoint: "Lien avec l'arrêt ",
  unlinkFromWaypoint: 'Supprimer le lien',
  searchStopsPlaceholder: 'Rechercher des arrêts ...',
  findRentalCars: 'Trouver des voitures de location',
  egStartFrom: 'De {waypoint}',
  mobility: 'Mobilité',
  directions: 'Itinéraires',
  saveTransportationBeforeApplyingRoute:
    'Veuillez enregistrer votre transport pour calculer les itinéraires, merci!',
  colors: 'Couleurs',
  moreInfo: "Plus d'infos ",
  optional: 'optionnel',
  travelers: 'Voyageurs',
  pleaseEnterEitherExistingOrInvite:
    "Veuillez entrer le nom d'utilisateur ou l 'adresse e-mail de la personne que vous souhaitez inviter.",
  ifNoAccountReminderSent:
    "Si l'invité n 'a pas de compte, une invitation sera envoyée.",
  statistics: 'Statistiques',
  expensesCurrencyNotEditable:
    'La devise de ton voyage ne peut être changée si les dépenses ont déjà été créées',
  expensesBillingIntroTitle: 'À propos de la facturation dans Lambus',
  expensesBillingIntroSubtitle:
    'Nous minimisons le nombre de comptes ouverts en les compensant automatiquement. Exemple: vous devez $10 à {person1} et {person2} doit $10 à {person3}, alors vous payez {person4} $10 et vous êtes à égalité! 🙂',
  shareTrip: 'Partager le voyage',
  linkToYourMap: 'Lien vers votre carte',
  shareTripInfo:
    "Vous pouvez partager votre voyage avec d'autres personnes qui ne voyagent pas avec vous.Si vous souhaitez inviter d'autres voyageurs à rejoindre votre voyage, appuyez sur",
  activateIFrameInfo:
    'Activez pour intégrer la carte de votre voyage dans votre blog ou site web personnel (par exemple Wordpress, Notion ou Medium).',
  embedAsIFrame: 'Intégrer via <iframe>',
  here: 'ici',
  accommodation: 'Hébergement',
  addAccommodation: 'Ajouter un hébergement',
  searchAddress: 'Rechercher une adresse (hôtel, Airbnb, …)',
  addCheckInCheckOut: 'Ajouter Check-in / Check-out',
  plusBookingDocument: '+ Document de réservation',
  myAccommodation: 'Mon hébergement',
  deleteAccommodation: "Supprimer l'hébergement ",
  deleteAccommodationDescription:
    'Êtes-vous sûr de vouloir supprimer cet hébergement?',
  changeAddress: "Modifier l'adresse ",
  copyAddress: "Copier l'adresse ",
  copy: 'Copier',
  openWaypoint: "Ouvrir l'arrêt ",
  theAddressWasCopiedSuccessfully:
    "L'adresse a été copiée dans votre presse - papiers!",
  notLinkedYet: 'Pas encore lié',
  linkedWaypoints: 'Arrêts liés',
  addTime: 'Ajouter du temps',
  addedToDate: 'Ajouté à {date}',
  starts: 'Débute',
  ends: 'Se termine',
  linkedDays: 'Jour lié | Jours liés',
  sortBy: 'Trier par …',
  displayAs: 'Afficher en {mode}',
  grid: 'Grille',
  list: 'Liste',
  name: 'Nom',
  fileType: 'Type de fichier',
  confirmNoteDiscard:
    'Vous avez des modifications non enregistrées dans votre note. Voulez-vous vraiment les supprimer ?',
  manageFeatures: 'Gérer les fonctionnalités',
  whichDataShouldBeExported: 'Quelles données souhaitez-vous exporter ? 😇',
  selectAll: 'Tout sélectionner',
  deselectAll: 'Tout désélectionner',
  fileSection: {
    document: {
      new: 'Téléverser un nouveau document',
      link: 'Sélectionner un document',
      emptyState: {
        title: 'Aucun document ajouté',
        subtitle: 'Appuyez pour ajouter vos documents de voyage',
      },
    },
    photo: {
      new: 'Téléverser une nouvelle photo',
      link: 'Sélectionner une photo',
      emptyState: {
        title: 'Aucune photo ajoutée',
        subtitle: 'Appuyez pour enregistrer vos souvenirs',
      },
    },
  },
  noteSection: {
    emptyState: {
      title: 'Pas de note ajoutée',
      subtitle: 'Cliquez pour ajouter des pensées, des conseils, etc.',
    },
  },
  addADate: 'Ajouter une date',
  addDate: '+ Ajouter une date',
  addANote: 'Ajouter une note',
  addNotePlaceholder: 'Ajoute ta note..',
  address: 'Adresse',
  plannedFor: 'Prévu pour',
  collectThoughtsLinksTips: 'Recueillir des pensées, des liens, des astuces …',
  aSmallNote: 'Une petite note',
  shiftDatesAsk:
    'Vous avez modifié la date de votre voyage. Voulez-vous que votre itinéraire soit également décalé automatiquement de %s jour(s) ?',
  shiftDates: 'Date du déménagement',
  shiftDatesDiscard: 'Ne pas déplacer la date',
  addMoreInformationPlaceholder: 'Ajoute tes autres informations...',
  addActivity: 'Ajouter une activité',
  deleteActivity: "Supprimer l'activité",
  deleteActivityDescription:
    'Êtes-vous sûr de vouloir supprimer cette activité?',
  unlinkWaypointDayItem: 'Supprimer un élément du planning journalier',
  unlinkWaypointDayItemDescription:
    'Êtes-vous sûr de vouloir supprimer cet élément du plan journalier?',
  noAddress: 'Aucune adresse',
  noResultsFound: 'Aucun résultat trouvé',
  couponCodeMessage:
    "Initie l'année 2024 avec des aventures ! Obtiens 25% sur Lambus Pro avec :",
  searchForCurrencies: 'Rechercher devise ...',
  sortExpensesBy: 'Trier les dépenses par …',
  expenseCreated: 'Dépense créée',
  expenseDate: 'Date de la dépense',
  canoe_tour: 'Tour en canoë',
  hiking: 'Randonnée',
  camping: 'Camping',
  beach: 'Plage',
  swimming: 'Natation',
  boat_tour: 'Tour en bateau',
  campfire: 'Feu de camp',
  party: 'Fête',
  bike_tour: 'Tour à vélo',
  surfing: 'Surf',
  snorkeling: 'Plongée avec tuba',
  picknick: 'Pique-nique',
  food: 'Nourriture',
  horse_riding: 'Équitation',
  sport_event: 'Événement sportif',
  concert: 'Concert',
  cinema: 'Cinéma',
  winter_sports: "Sports d'hiver",
  searchActivitiesAndPlaces: 'Recherchez vos lieux et activités sauvegardés',
  signUp: {
    cta: 'Pas encore chez Lambus ?',
    action: "S'inscrire",
    businessCta: 'Vous êtes une entreprise ?',
    title: 'Bienvenue sur Lambus !',
    titleActionNewTrip: 'Inscrivez-vous pour sauvegarder votre voyage',
    titleActionJoinTrip: 'Inscrivez-vous pour rejoindre le voyage',
    titleActionInspiration: "Inscrivez-vous pour sauver l'inspiration",
  },
  signIn: {
    cta: 'Vous avez déjà un compte ?',
    action: 'Connectez-vous',
    title: 'Bienvenue sur Lambus !',
    titleActionNewTrip: "S'inscrire pour sauvegarder son voyage",
    titleActionJoinTrip: "S'inscrire pour rejoindre le voyage",
    titleActionInspiration: "S'inscrire pour sauvegarder l'inspiration !",
  },
  signUpTitle: 'Créer un compte',
  orContinueWith: 'Ou continuez avec',
  autosavingNote: 'Sauvegarde automatique ...',
  lastSaved: 'Dernière sauvegarde',
  justNow: "À l'instant",
  deletePackingList: 'Supprimer la liste',
  deletePackingListDescription:
    'Êtes-vous sûr de vouloir supprimer cette liste?',
  sidebar: {
    close: 'Fermer la barre latérale',
    collapse: 'Barre latérale réduite',
    expand: 'Développer la barre latérale',
    tripSection: {
      planning: 'Planification',
      general: 'Général',
      travelers: 'Voyageurs',
    },
  },
  startDate: 'Date de début',
  notSaved: 'Non enregistré',
  reset: 'Réinitialiser',
  updates: 'Mises à jour',
  markAllAsRead: 'Tout marquer comme lu',
  markAsRead: 'Marquer comme lu',
  hide: 'Cacher',
  noNewNotifications: 'Pas de nouvelles notifications',
  youAreUpToDate: 'Vous êtes à jour',
}
