
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'LIcon',
})
class LIcon extends Vue {
  @Prop() readonly img!: string
  @Prop({ default: '', type: String }) readonly color!: string
}
export default LIcon
