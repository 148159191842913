
import { Component, Vue } from 'vue-property-decorator'

import AttendeeInviteForm from '@/components/trip/settings/AttendeeInviteForm.vue'
import AttendeeListItem from '@/components/trip/settings/AttendeeListItem.vue'
import PictureViewer from '@/components/PictureViewer.vue'
import { tripModule } from '@/store'
import { Trip } from '@/types/trip'
import { User } from '@/types/user'

@Component({
  name: 'AttendeesExpansionPanel',
  components: {
    AttendeeInviteForm,
    PictureViewer,
    AttendeeListItem,
  },
})
class AttendeesExpansionPanel extends Vue {
  showLightBox = false
  imageIndex = 0
  attendeesExpansionPanel: number | null = null

  get trip(): Trip {
    return tripModule.trip!
  }

  get attendeePictureUrls(): string[] {
    return this.availableAttendees.map((attende) => attende.picture)
  }

  get availableAttendees(): User[] {
    return this.trip?.attendees || []
  }

  get imgIndex(): number {
    return this.imageIndex
  }

  public openInviteYourFriendsPanel() {
    this.attendeesExpansionPanel = 1
  }

  showAttendeePicture(index: number): void {
    this.showLightBox = true
    this.imageIndex = index
  }
}
export default AttendeesExpansionPanel
