
import { Component } from 'vue-property-decorator'

import Tooltip from '@/components/Tooltip.vue'
import RouteLocationField from '@/components/pro/directions/RouteLocationField.vue'
import DateTimeField from '@/components/trip/transportation/forms/generic/DateTimeField.vue'
import AddWaypointDialog from '@/components/trip/transportation/forms/AddWaypointDialog.vue'
import AddWaypointLoader from '@/components/trip/transportation/forms/generic/AddWaypointLoader.vue'
import WaypointForm from '@/components/trip/transportation/forms/generic/WaypointForm.vue'
import { transportationModule } from '@/store'
import { Waypoint } from '@/types/trip'
import { TransportationType } from '@/utils/enums'
@Component({
  name: 'CarForm',
  components: {
    Tooltip,
    RouteLocationField,
    DateTimeField,
    AddWaypointDialog,
    AddWaypointLoader,
  },
})
class CarForm extends WaypointForm {
  activeTab = 0
  rentalSearchTerm = ''
  rentalSelect: any = null
  lsKey = 'kLambusCarTransportationType'

  rentalCarCompanies = Object.entries(
    require('@/assets/json/rental_car_companies.json'),
  )

  dates: string[] = []

  get origin(): Waypoint | null {
    return transportationModule.currentOrigin
  }

  set origin(waypoint: Waypoint | null) {
    transportationModule.setOriginWaypoint(waypoint)
  }

  get destination(): Waypoint | null {
    return transportationModule.currentDestination
  }

  set destination(waypoint: Waypoint | null) {
    transportationModule.setDestinationWaypoint(waypoint)
  }

  created() {
    if (this.trip?.waypoints.length === 2) {
      this.origin = this.trip.waypoints[0]
      this.destination = this.trip.waypoints[1]
    }
  }

  mounted() {
    const persistedState = localStorage.getItem(this.lsKey)

    if (persistedState) {
      this.activeTab = parseInt(persistedState)
    }
  }

  saveBooking(): void {
    if (!this.trip || !this.origin) {
      return
    }

    this.isSaving = true

    const type =
      this.activeTab === 0
        ? TransportationType.RENTAL_CAR
        : TransportationType.CAR

    const booking = {
      type,
      trip: this.trip.id,
      departureDate: this.dates[0],
      arrivalDate: this.dates[1],
      originWaypoint: this.origin,
      destinationWaypoint: this.destination || this.origin,
    }

    if (this.activeTab === 0 && this.rentalSearchTerm?.trim().length >= 3) {
      Object.assign(booking, {
        rentalCarCompany: this.rentalSearchTerm,
      })
    }

    transportationModule.setBooking(booking)
    this.$emit('click:add', type)
    this.isSaving = false
  }

  persistTabState(index: number): void {
    localStorage.setItem(this.lsKey, index.toString())
  }
}
export default CarForm
