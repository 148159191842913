import { set } from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { User } from '@/types/user'
import { eventModule } from '@/store'

@Module({ name: 'user', stateFactory: true, namespaced: true })
export default class userModule extends VuexModule {
  private currentUserLocationPlace?: any
  private currentUserLocation: GeolocationPosition | null = null

  public get userLocationPlace(): any {
    return this.currentUserLocationPlace
  }

  public get userLocation(): GeolocationPosition | null {
    return this.currentUserLocation
  }

  @Mutation
  public setUserLocationPlace(place: any) {
    set(this, 'currentUserLocationPlace', place)
  }

  @Mutation
  public setUserLocation(location: GeolocationPosition) {
    set(this, 'currentUserLocation', location)
  }

  @Action({ rawError: true })
  public async searchUser(username: string): Promise<User | null> {
    try {
      const response = await this.$axios.post('/api/users/search', {
        value: username,
      })

      return response.data.user || null
    } catch (error) {
      eventModule.newError('error.user.search')
      return null
    }
  }

  @Action({ rawError: true })
  public getUserLocation(callback: Function): void {
    if (!navigator.geolocation) {
      eventModule.newInfo('error.location_support')
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          callback(position)
        },
        (error) => {
          if (error.code !== 1) {
            // code 1 = User denied geolocation prompt
            eventModule.newError('error.location')
          }
        },
      )
    }
  }
}
