
import { Component, Vue } from 'vue-property-decorator'

import TravelPlanList from '@/components/trip/travelPlan/TravelPlanList.vue'
import { tripModule, waypointModule } from '@/store'
import { WaypointAlgorithm } from '@/utils/enums'
import { Trip } from '@/types/trip'
import LConfirmDialog from '@/global-components/LConfirmDialog.vue'
@Component({
  name: 'WaypointSortingDialog',
  components: {
    TravelPlanList,
  },
})
class WaypointSortingDialog extends Vue {
  isFetching = false
  isSaving = false
  waypointAlgorithm = WaypointAlgorithm
  currentWaypointAlgorithm: number = this.trip.waypointAlgorithm

  get trip(): Trip {
    return tripModule.trip!
  }

  get isSortedManually(): boolean {
    return this.currentWaypointAlgorithm === WaypointAlgorithm.NONE
  }

  get title(): string {
    return this.isSortedManually
      ? this.$t('waypointSorting.header').toString()
      : this.$t('waypointSorting.changeToManual').toString()
  }

  get content(): string | undefined {
    return this.isSortedManually
      ? this.$t('waypointSorting.text').toString()
      : undefined
  }

  get image(): string | undefined {
    return this.isSortedManually ? 'wand-icon.png' : undefined
  }

  get secondaryAction(): string | undefined {
    return this.isSortedManually
      ? this.$t('later').toString()
      : this.$t('cancel').toString()
  }

  async fetchOptimizedWaypoints(): Promise<void> {
    this.isFetching = true
    await waypointModule.fetchOptimizationPreview()
    this.isFetching = false
  }

  async updateTrip(algorithm: number): Promise<void> {
    this.isSaving = true
    await tripModule.updateTrip({
      waypointAlgorithm: algorithm,
    })
    this.toggleDialog()
    this.isSaving = false
  }

  toggleDialog(): void {
    const dialog = this.$refs.waypointSortingConfirmDialog as LConfirmDialog

    if (dialog) {
      dialog.toggleDialog()
      setTimeout(() => {
        this.currentWaypointAlgorithm = this.trip.waypointAlgorithm
      }, 300)
    }
  }
}
export default WaypointSortingDialog
