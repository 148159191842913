
import { Component, Vue, Watch } from 'vue-property-decorator'
import LDialog from '@/global-components/LDialog.vue'
import TravelPlanList from '@/components/trip/travelPlan/TravelPlanList.vue'
import NearbyPlacesModal from '@/components/trip/map/NearbyPlacesModal.vue'
import {
  eventModule,
  waypointModule,
  transportationModule,
  tripModule,
} from '@/store'
import { Waypoint } from '@/types/trip'
import { LatitudeLongitudeLiteral } from '@/types/types'
import { WaypointModalType } from '@/utils/enums'

@Component({
  name: 'SelectWaypointModal',
  components: {
    TravelPlanList,
    NearbyPlacesModal,
  },
})
class SelectWaypointModal extends Vue {
  isActive = false
  noWaypointSetYet = false

  get waypoints(): Waypoint[] {
    return tripModule.trip!.waypoints
  }

  get currentWaypointModalType(): string {
    return eventModule.waypointModalType
  }

  get currentWaypoint(): Waypoint | null {
    return waypointModule.waypoint
  }

  get modalTitle(): string {
    if (
      this.currentWaypointModalType === WaypointModalType.NEARBY_PLACES_MODAL
    ) {
      return this.$t('chooseWaypoint') as string
    } else if (
      this.currentWaypointModalType === WaypointModalType.TRANSPORTATION_MODAL
    ) {
      return this.$t('whereToStartFrom') as string
    } else {
      return ''
    }
  }

  get currentLocation(): LatitudeLongitudeLiteral {
    if (!this.currentWaypoint) {
      return { latitude: 0, longitude: 0 }
    }
    return {
      latitude: this.currentWaypoint.location.latitude,
      longitude: this.currentWaypoint.location.longitude,
    }
  }

  @Watch('currentWaypoint')
  showSelectedModal() {
    if (this.noWaypointSetYet) {
      this.noWaypointSetYet = false
      this.toggleSelectedModal()
    }
  }

  public toggleDialog(): void {
    const dialog = this.$refs.lDialog as LDialog
    dialog.toggleDialog()
    this.isActive = true
  }

  // toggle the modal according to selection
  // currentWaypointModalType will be set during selection in TravelPlanCard & TripTravelPlan
  async toggleSelectedModal(): Promise<void> {
    if (this.currentWaypoint) {
      if (
        this.currentWaypointModalType === WaypointModalType.NEARBY_PLACES_MODAL
      ) {
        // toggle nearbyPlacesModal if currentWaypointType is NEARBY_PLACES_MODAL

        eventModule.setWaypointModalType('')
        await eventModule.toggleSelectWaypointModal()
        const dialog = this.$refs.nearbyPlacesModal as LDialog
        dialog.toggleDialog()
      } else if (
        this.currentWaypointModalType === WaypointModalType.TRANSPORTATION_MODAL
      ) {
        // toggle transportationModal if currentWaypointType is TRANSPORTATION_MODAL

        const originIndex = this.waypoints.findIndex(
          (waypoint) => waypoint._id === this.currentWaypoint!._id,
        )
        const destination = this.waypoints[originIndex + 1]
        transportationModule.setOriginWaypoint(this.currentWaypoint)
        transportationModule.setDestinationWaypoint(destination)
        eventModule.setWaypointModalType('')
        await eventModule.toggleSelectWaypointModal()
        setTimeout(() => {
          eventModule.toggleTransportationModal()
        }, 100)
      }
    } else {
      this.noWaypointSetYet = true
    }
  }
}

export default SelectWaypointModal
