
import { Vue } from 'vue-property-decorator'
import { tripModule, waypointModule } from '@/store'
import { Trip, Waypoint, PointOfInterest } from '@/types/trip'

class DatePicker extends Vue {
  pickerDates: string[] = []
  pickerMonths: string[] = []
  activePickerDates: Element[] = []

  get trip(): Trip | null {
    return tripModule.trip
  }

  get waypoint(): Waypoint | null {
    return waypointModule.waypoint
  }

  get poi(): PointOfInterest | null {
    return waypointModule.pointOfInterest
  }

  get dates(): string[] {
    return this.pickerDates
  }

  set dates(dates: string[]) {
    this.pickerDates = dates
  }

  get months(): string[] {
    return this.pickerMonths
  }

  set months(months: string[]) {
    this.pickerMonths = months
  }

  get activeDates(): Element[] {
    return this.activePickerDates
  }

  set activeDates(dates: Element[]) {
    this.activePickerDates = dates
  }

  setMinimumDate() {
    if (this.poi?.startDate?.value) {
      this.pickerDates.push(this.poi.startDate.value)
      this.pickerMonths.push(this.poi.startDate.value.substring(0, 7))

      if (this.poi.endDate?.value) {
        this.pickerDates.push(this.poi.endDate.value)
        this.pickerMonths.push(this.poi.endDate.value.substring(0, 7))
      }
    } else if (this.waypoint?.startDate?.value) {
      this.pickerDates.push(this.waypoint.startDate.value)
      this.pickerMonths.push(this.waypoint.startDate.value.substring(0, 7))

      if (this.waypoint.endDate?.value) {
        this.pickerDates.push(this.waypoint.endDate.value)
        this.pickerMonths.push(this.waypoint.endDate.value.substring(0, 7))
      }
    } else if (this.trip?.startDate?.value) {
      this.pickerDates.push(this.trip.startDate.value)
      this.pickerMonths.push(this.trip.startDate.value.substring(0, 7))

      if (this.trip.endDate?.value) {
        this.pickerDates.push(this.trip.endDate.value)
        this.pickerMonths.push(this.trip.endDate.value.substring(0, 7))
      }
    }
  }

  setDateClasses(): void {
    // get dates in last viewed month (relevant when switching month, which have dates set). Cannot access the active buttons any other way, sorry.
    const currentDates = Array.from(
      document.getElementsByClassName(
        'v-btn v-btn--active v-btn--rounded theme--light primary',
      ),
    )

    // set classes after month has been switched
    this.$nextTick(() => {
      // get all active buttons (this will get the dates from current month and the month we switched from)
      this.activeDates = Array.from(
        document.getElementsByClassName(
          'v-btn v-btn--active v-btn--rounded theme--light primary',
        ),
      )

      // remove dates from other month
      if (currentDates.length > 1) {
        this.activeDates.splice(0, currentDates.length)
      }

      if (this.activeDates.length <= 1) {
        // keep default classes if only one date is set
        return
      }

      // set classes
      this.activeDates.forEach((element, index) => {
        element.classList.remove('start-date')
        element.classList.remove('end-date')
        element.classList.add('v-btn--tile')
        element.classList.add('v-btn--block')

        // round corners if start or end of set dates
        if (index === 0) {
          element.classList.add('start-date')
        }
        if (index === this.activeDates.length - 1) {
          element.classList.add('end-date')
        }
      })
    })
  }

  ascendingSort(a: string, b: string) {
    return new Date(a).getTime() - new Date(b).getTime()
  }
}
export default DatePicker
