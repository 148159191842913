import currency from 'currency.js'
import { formattedPrice } from './utility-manager'
import { ExpenseSortType } from './enums'
import { i18n } from '@/plugins/i18n'
import {
  BillingPosition,
  ExpenseType,
  FormattedExpense,
  TripSummaryBilling,
} from '@/types/expense'

export function convertToBillingPosition(openPositions: TripSummaryBilling[]) {
  const openCells: BillingPosition[] = []
  openPositions.forEach((position) => {
    const isBroke = position.type === ExpenseType.OWE

    openCells.push({
      type: position.type,
      expense: position._id,
      stateLabel: {
        text: formattedState(isBroke),
        color: isBroke ? 'red' : 'primary',
      },
      amountLabel: {
        text: formattedPrice(
          window.navigator.language,
          position.currency,
          parseFloat(position.amount),
        ),
        color: isBroke ? 'red' : 'primary',
      },
      fromNameLabel: `${i18n.t('i')} (${position.from.name})`,
      fromPicture: position.from.picture,
      toNameLabel: position.to.name,
      toPicture: position.to.picture,
    })
  })
  return openCells
}

function formattedState(isBroke: boolean): string {
  if (isBroke) {
    return i18n.t('owe').toString()
  }

  return i18n.t('receiveFrom').toString()
}

export function createExpenseSortCallback(sortType: ExpenseSortType) {
  return (a: FormattedExpense, b: FormattedExpense) => {
    let aValue, bValue

    switch (sortType) {
      case ExpenseSortType.DATE_UP:
        aValue = a.dateLabel ?? new Date(0).toISOString()
        bValue = b.dateLabel ?? new Date(0).toISOString()
        break
      case ExpenseSortType.CREATED:
        aValue = a.created_at ?? new Date(0).toISOString()
        bValue = b.created_at ?? new Date(0).toISOString()
        break
      default:
        return 0
    }

    return aValue < bValue ? 1 : -1
  }
}

export const isValidSortType = (
  type: string | null,
): type is ExpenseSortType => {
  return (
    !!type && Object.values(ExpenseSortType).includes(type as ExpenseSortType)
  )
}

export function convertToCurrency(val: number) {
  return currency(val).value
}
