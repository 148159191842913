const validation = require('vee-validate/dist/locale/pt_BR.json')

module.exports = {
  i: 'Eu',
  me: 'Eu',
  you: 'Você',
  someone: 'Alguém',
  other: 'outro',
  others: 'outros',
  otherReason: 'Outras razões',
  clickToEdit: 'Clique para editar',
  fromNumber: 'de',
  by: 'De',
  save: 'Salvar',
  edit: 'Editar',
  add: 'Adicione',
  addItem: 'Adicionar {item}',
  done: 'Feito',
  delete: 'Apagar',
  clear: 'Elimina',
  download: 'Download', // this is the phrase
  cancel: 'Cancelar',
  update: 'Atualização',
  ok: 'OK',
  gotIt: 'Entendi!',
  no: 'Nenhum',
  and: 'e',
  myTrips: 'Minhas Viagens',
  map: 'Mapa',
  waypoints: 'Pontos de parada',
  waypoint: 'Ponto de parada',
  waypointCount: 'Um ponto de parada | {count} Pontos de paradas',
  routeCount: 'uma rota | {count} rotas',
  packingListCount: 'uma lista | {count} listas',
  packingListTemplateCount: 'um modelo de lista | {count} Modelos de lista',
  documents: 'Documentos',
  expenses: 'Despesas',
  notes: 'Notas',
  photos: 'Fotos',
  chat: 'Chat',
  transportation: 'Transporte',
  subscription: 'Assinatura',
  myProfile: 'Meu Perfil',
  logout: 'Sair',
  editProfile: 'Editar Perfil',
  travelPlan: 'Plano de viagem',
  settings: 'Configurações',
  showMore: 'Mostrar mais',
  suggestions: 'Sugestões',
  photoDeletePrompt: 'Você quer apagar esta foto?',
  documentDeletePrompt: 'Você quer apagar este documento?',
  directoryDeletePrompt: 'Você quer apagar este diretório?',
  transportationDeletePrompt: 'Você quer apagar este transporte?',
  changePhoto: 'Alterar foto',
  documentDelete: 'Apagar documento',
  directoryDelete: 'Apagar pasta',
  enterName: 'Inserir nome',
  enterNamePlaceholder: 'Inserir nome…',
  untitled: 'Sem titúlo',
  createFolder: 'Nova pasta',
  uploadFile: 'Carregar foto',
  uploadDocument: 'Carregar documento',
  addDocument: 'Adicionar Documento',
  documentEmailHint:
    'Sua viagem possui um endereço de e-mail exclusivo para o qual você encaminha os documentos de reserva. Os formatos atualmente suportados são PDF, JPG e PNG!',
  addWaypoint: 'Adicionar ponto de parada',
  saveWaypoint: 'ponto de passagem seguro',
  addingWaypoint: 'Adicionando Ponto de Parada …',
  addPOI: 'Adicionar local próximo',
  nearbyPlace: 'Lugar próximo',
  nearbyPlaces: 'Locais próximos',
  nearby: 'Próximo a',
  attendees: 'Participantes',
  pendingAttendees: 'Participantes Pendentes',
  hours: 'Horas',
  hoursShort: 'h',
  minutes: 'Minutos',
  minutesShort: 'min',
  noDate: 'Sem data',
  noTitle: 'Sem título',
  days: 'Dias',
  editDate: 'Data de edição',
  changeDate: 'Alterar Data',
  deleteDate: 'Apagar Data',
  changeTime: 'Tempo de mudança',
  changeToPoi: 'Mudar para "Lugar próximo"',
  actionPrompt: 'O que você quer fazer',
  ratings: 'Avaliações',
  reviews: 'Revisões',
  registrationFields: {
    username: 'Nome de usuário',
    password: 'Senha',
    passwordConfirm: 'Confirmar senha',
    email: 'Email',
    firstName: 'Primeiro nome',
    lastName: 'Sobrenome',
    summery: 'Verão',
    tosAccepted: 'Aceitação dos termos e condições',
    company: 'Empresa',
  },
  validation: {
    ...validation.messages,
    usernameAvailable: 'O nome de usuário {_value_} não está disponível',
  },
  socket: {
    IS_ONLINE: '{subject} está online agora!',
    LEFT_TRIP: '{subject} deixou a viagem!',
    DELETED_TRIP: '{subject} apagou a viagem!',
    RENAMED_TRIP: '{subject} renomeou a viagem!',
    UPDATED_TRIP: '{subject} atualizou a viagem!',
    UPDATED_TRIP_COVER_PHOTO: '{subject} atualizou a foto de capa!',
    INVITED_ATTENDEE: '{subject} convidou {object} para a viagem!',
    REMOVED_ATTENDEE: '{subject} removeu {object} da viagem!',
    JOINED_ATTENDEE: '{subject} entrou na sua viagem!',
    ADDED_WAYPOINT: '{subject} adicionou um novo ponto de parada!',
    REARRANGED_WAYPOINT: '{subject} moveu um ponto de parada!',
    UPDATED_WAYPOINT: '{subject} atualizou um ponto de parada!',
    REMOVED_WAYPOINT: '{subject} removeu um ponto de parada!',
    ADDED_HIGHLIGHT: '{subject} adicionou um local!',
    REARRANGED_HIGHLIGHT: '{subject} moveu um local!',
    UPDATED_HIGHLIGHT: '{subject} atualizou um local!',
    REMOVED_HIGHLIGHT: '{subject} removeu um local!',
    ADDED_DOCUMENT: '{subject} adicionou um novo documento!',
    UPDATED_DOCUMENT: '{subject} atualizou um documento!',
    REMOVED_DOCUMENT: '{subject} removeu um documento!',
    ADDED_EXPENSE: '{subject} adicionou uma nova despesa!',
    UPDATED_EXPENSE: '{subject} atualizou uma despesa!',
    REMOVED_EXPENSE: '{subject} removeu uma despesa!',
    ADDED_CHAT_MESSAGE: '{subject}: {object}',
    STARTED_LIVE_LOCATION:
      '{subject} começou a compartilhar sua localização em tempo real!',
    UPDATED_LIVE_LOCATION: '{subject} atualizou um local em tempo real!',
    STOPPED_LIVE_LOCATION:
      '{subject} parou de compartilhar a localização em tempo real!',
    ADDED_GPX: 'A importação GPX está concluída!',
    FORCE_RELOAD: 'A viagem foi atualizada',
    PURCHASED_LAMBUS_PRO: '{subject} adquiriu o Lambus PRO',
    FLIGHT_UPDATE: 'As notificações estão ativadas para o voo {subject}',
  },
  success: {
    user: {
      password_change: 'Sua senha foi alterada com sucesso!',
      password_reset:
        'Por favor verifique sua caixa de email e siga as instruções para alterar sua senha.',
      invite: 'Seu convite foi enviado com sucesso!',
      picture: 'Sua foto foi carregada com sucesso!',
      update: 'Perfil atualizado com sucesso!',
    },
    trip: {
      create: 'Sua viagem foi criada com sucesso!',
      update: 'Sua viagem foi atualizada com sucesso!',
      delete: 'Sua viagem foi apagada com sucesso!',
      duplicate: 'Sua viagem foi duplicada com sucesso!',
      invite: 'Seu convite foi enviado com sucesso!',
      archive: 'Sua viagem foi arquivada com sucesso!',
      unarchive: 'Sua viagem foi desarquivada com sucesso!',
    },
    accommodation: {
      create: 'A acomodação foi salva com sucesso!',
      update: 'A acomodação foi atualizado com sucesso!',
      delete: 'A acomodação foi excluída com sucesso!',
    },
    waypoint: {
      create: 'Ponto de parada criado com sucesso!',
      update: 'Ponto de parada atualizado com sucesso!',
      delete: 'Ponto de parada apagado com sucesso!',
      migrate: 'Oponto de parada foi migrado com sucesso',
      dailyPlan: {
        create: 'O elemento foi adicionado ao plano diário!',
        delete: 'O elemento foi excluído do plano diário!',
        update: 'O elemento foi atualizado com sucesso!',
        rearrange: 'O elemento foi movido com sucesso!',
      },
    },
    poi: {
      create: 'Local inserido com sucesso!',
      update: 'Local atualizado com sucesso!',
      delete: 'Local apagado com sucesso!',
    },
    activity: {
      create: 'A atividade foi adicionada com sucesso à parada!',
      update: 'A atividade foi atualizada com sucesso!',
      delete: 'A atividade foi removida com sucesso!',
    },
    document: {
      create: 'Seu documento foi criado com sucesso!',
      createMultiple:
        '{successCount} de {allCount} documentos foram carregados com sucesso!',
      delete: 'Seu documento foi apagado com sucesso!',
    },
    expense: {
      create: 'A despesa foi criada com sucesso!',
      update: 'A despesa foi atualizada com sucesso!',
      delete: 'A despesa foi apagada com sucesso!',
      exportExpense:
        'Suas despesas foram exportadas. Por favor, verifique seu e-mail.',
      exportTrip: 'Sua viagem foi exportada. Por favor, verifique seu e-mail.',
    },
    amount: {
      delete: 'Valor apagado com sucesso!',
      paid: 'O valor foi marcado como pago!',
      ping: 'Seu lembrete acaba de ser enviado!',
    },
    note: {
      create: 'Nota criada com sucesso!',
      update: 'Nota carregada com sucesso!',
      delete: 'Nota apagada com sucesso!',
    },
    photo: {
      create: 'Sua foto foi carregada com sucesso!',
      createMultiple: '{count} fotos foram carregadas com sucesso!',
      delete: 'Sua foto foi apagada com sucesso!',
    },
    feedback: 'Seu feedback foi enviado com sucesso!',
    transportation: {
      create: 'O transporte foi criado com sucesso',
      update: 'O transporte foi atualizado com sucesso!',
      delete: 'O transporte foi apagado com sucesso!',
      flightStatus: {
        register: 'As notificações estão ativadas para o voo {flightNumber}!',
      },
    },
    routes: {
      create: 'A rota foi criada com sucesso!',
      update: 'A rota foi atualizada com sucesso!',
      delete: 'A rota foi apagada com sucesso!',
    },
    packingLists: {
      create: 'Sua lista foi criada com sucesso!',
      delete: 'Sua lista foi excluída com sucesso!',
    },
    gpx: {
      delete: 'A rota GPX foi apagada com sucesso!',
    },
    pro: {
      cancellation: 'Sua assinatura Lambus PRO foi cancelada com sucesso!',
    },
  },
  error: {
    login: {
      apple: 'Não foi possível fazer login com Apple ID.',
      facebook: 'Não foi possível fazer login com Facebook.',
      google: 'Não foi possível fazer login com Google.',
    },
    user: {
      password_change: {
        default: 'Não foi possível alterar sua senha!',
        currentPasswordWrong: 'Sua senha atual não está correta.',
      },
      password_reset: 'Não foi possível redefinir a senha!',
      password_reset_social:
        'Seu email está vinculado a uma conta de mídia social. Faça login com sua conta Apple/Google/Facebook 😇',
      invite: 'Seu convite não foi enviado!',
      picture: 'Não foi possível salvar sua foto!',
      update: {
        default:
          'Não foi possível salvar o perfil! Por favor, tente novamente.',
        usernameNotAvailable:
          'Não foi possível salvar o perfil! O nome de usuário inserido não está disponível.',
        emailNotAvailable:
          'Não foi possível salvar o perfil! O endereço de e-mail já está em uso.',
      },
      delete: 'Não foi possível apagar a conta!',
      create: 'Não foi possível concluir seu registro!',
      search: 'Usuário não encontrado!',
      emailAlreadyExists:
        'Já existe um usuário com este endereço de e-mail. Por favor, faça o login ou tente outro endereço de e-mail.',
    },
    trip: {
      create: 'Não foi possível criar sua viagem!',
      update: 'Não foi possível atualizar sua viagem!',
      delete: 'Não foi possível apagar sua viagem!',
      duplicate: 'Não foi possível duplicar sua viagem!',
      invite: 'Não foi possível enviar seu convite!',
      join: 'Não foi possível adicionar a viagem!',
      validatePinCode: 'O código PIN inserido não é válido!',
      leave: 'Não foi possível apagar o participante!',
      archive: 'Não foi possível arquivar sua viagem!',
      unarchive: 'Não foi possível desarquivar sua viagem!',
      fetch: 'Não foi possível capturar a viagem!',
      notification_update: 'Não foi possível atualizar suas configurações.',
      notification_fetch: 'Não foi possível capturar suas configurações.',
    },
    accommodation: {
      fetch: 'Não foi possível obter suas acomodaçãos.',
      create: 'Não foi possível salvar o acomodação.',
      update: 'Não foi possível atualizar o acomodação.',
      delete: 'Não foi possível apagar o acomodação.',
    },
    gpx: {
      fetch: 'Não foi possível capturar suas rotas GPX.',
      delete: 'Não foi possível apagar as rotas GPX.',
    },
    theme: {
      size: 'Sua imagem excede o tamanho máximo de arquivo de 5mb.',
    },
    waypoint: {
      rearrange: 'Não foi possível reorganizar os detalhes!',
      fetch: 'Não foi possível carregar o ponto de parada!',
      create: 'Não foi possível salvar o ponto de parada!',
      update: 'Não foi possível atualizar o ponto de parada!',
      delete: 'Não foi possível apagar o ponto de parada!',
      optimize: 'Seu itinerário não pôde ser classificado.',
      weather: 'Não foi possível carregar informações do clima!',
      migrate: 'Não pode migrar ponto de parada',
      dailyPlan: {
        fetch: 'Não foi possível carregar o plano diário!',
        create: 'Não poderia acrescentar um item ao plano diário!',
        delete: 'Não foi possível apagar um item do plano diário!',
        update: 'Não era possível atualizar a programação diária!',
        rearrange: 'O item não pôde ser movido!',
      },
      poi: {
        fetch: 'Não foi possível obter os pontos de parada!',
      },
    },
    poi: {
      fetch: 'Não foi possível obter o ponto de parada!',
      create: 'Não foi possível adicionar um lugar próximo ao ponto de parada!',
      update: 'Não foi possível atualizar o local!',
      delete: 'Não foi possível apagar o local!',
      preview: 'Não foi possível carregar a visualização do local',
    },
    activity: {
      fetch: 'Não foi possível carregar a atividade!',
      create: 'Não foi possível adicionar a atividade à parada!',
      update: 'Não foi possível atualizar a atividade!',
      delete: 'Não foi possível remover a atividade!',
    },
    document: {
      fetch: 'Não foi possível obter o documento!',
      get: 'Não foi possível carregar o documento!',
      create: 'Não foi possível salvar o documento!',
      update: 'Não foi possível atualizar o documento!',
      delete: 'Não foi possível apagar o documento!',
      download: 'Não foi possível baixar o documento!',
    },
    expense: {
      create: 'Não foi possível salvar a despesa!',
      update: 'Não foi possível atualizar a despesa!',
      delete: 'Não foi possível apagar a despesa!',
      rate: 'Não foi possível calcular as taxas de câmbio!',
      findCurrency: 'Não foi possível encontrar a moeda!',
    },
    amount: {
      delete: 'Não foi possível apagar a despesa!',
      paid: 'Não foi possível marcar a despesa como paga!',
      ping: 'Não foi possível enviar seu lembrete!',
    },
    note: {
      fetch: 'Não foi possível obter as notas!',
      create: 'Não foi possível salvar a nota!',
      update: 'Não foi possível atualizar a nota!',
      delete: 'Não foi possível apagar a nota!',
    },
    photo: {
      fetch: 'Não foi possível obter as fotos!',
      create: 'Não foi possível salvar sua foto!',
      update: 'Não foi possível atualizar sua foto!',
      delete: 'Não foi possível apagar a foto!',
      download: 'Não foi possível baixar sua foto!',
    },
    message: {
      fetch: 'Não foi possível carregar as mensagens!',
      send: 'Não foi possível enviar sua mensagem!',
    },
    inspiration: {
      fetch: 'Não foi possível obter inspiração!',
      fetchAll: 'Não foi possível carregar as inspirações',
    },
    cateogry: {
      fetchAll: 'Não foi possível carregar as categorias',
    },
    bookmark: {
      fetch: 'Não foi possível carregar os favoritos!',
      create: 'Não foi possível adicionar um favorito!',
      delete: 'Não foi possível apagar o favorito!',
    },
    import: {
      create: 'Não foi possível importar a viagem!',
    },
    category: {
      fetchAll: 'Não foi possível carregar categorias',
    },
    directory_create: 'Não foi possível criar uma pasta!',
    feedback: 'Não foi possível enviar seu feedback!',
    payment_details: 'Os detalhes de pagamentos não foram recebidos!',
    email_invalid: 'O endereço de e-mail é inválido.',
    email_retry:
      'Por favor, verifique se você inseriu o email correto e tente novamente.',
    location_support: 'Seu navegador não suporta localização por GPS.',
    location:
      'Sua localização não está disponível. Por favor, tente novamente.',
    transportation: {
      fetch: 'Não foi possível carregar transporte.',
      search: 'Não foi possível realizar buscas de transporte.',
      post: 'Não foi possível adicionar transporte.',
      update: 'Não foi possível atualizar o transporte.',
      delete: 'Não foi possível apagar o transporte.',
      searchFlight: 'O voo não foi encontrado.',
      flightStatus: {
        missingFields:
          'Precisamos dos seguintes detalhes para rastrear seu voo: {missingFields}',
        register: 'Não foi possível ativar as noticações de voo.',
        fetch: 'Não foi possível obter a situação do voo.',
      },
    },
    routes: {
      fetchAll: 'Não foi possível capturar as rotas.',
      fetch: 'Não foi possível carregar a rota.',
      calculate: 'Não foi possível calcular a rota.',
      update: 'Não foi possível atualizar a rota.',
      create: 'Não foi possível salvar sua rota.',
      delete: 'Não foi possível apagar a rota.',
    },
    export: {
      trip: 'Não foi possível exportar sua viagem.',
      expenses: 'Não foi possível exportar suas despesas de viagem.',
    },
    packingLists: {
      fetch: 'Sua lista não poderia ser carregada.',
      fetchTemplates: 'Os modelos da lista não puderam ser carregados.',
      create: 'Sua lista não pôde ser salva.',
      update: 'Sua lista não pôde ser atualizada.',
      delete: 'Sua lista não pôde ser apagada.',
    },
    subscription: {
      fetch: 'Não foi possível carregar as assinaturas.',
      cancel:
        'Não foi possível cancelar sua assinatura. Por favor, tente novamente mais tarde ou entre em contato com nosso suporte.',
    },
    payment: {
      prices: {
        fetch: 'Não foi possível capturar os preços.',
      },
      session: {
        fetch: 'Não foi possível iniciar o processo de pagamento.',
      },
    },
    placeRecommendations: {
      fetch: 'Os lugares recomendados não poderiam ser carregados.',
    },
    uploadFileSize: 'O arquivo não pode ser maior que 25 MB.',
  },
  info: {
    document: {
      upload: 'Seu documento será carregado em segundo plano…',
      uploadMultiple:
        'Seus {count} documentos serão carregados em segundo plano…',
    },
    photo: {
      upload: 'Sua foto será carregada em segundo plano…',
      uploadMultiple: 'Suas {count} fotos serão carregadas em segundo plano…',
    },
    transportation: {
      searchFlight: 'Vôo não encontrado',
    },
    userAlreadyAdded: 'Participante já adicionado',
  },
  emptyState: {
    map: 'Procurar por novos pontos de parada, usando o campo de pesquisa ou o mapa',
    documents: 'Sem Documentos',
    private_expenses: 'Sem despesas particulares',
    group_expenses: 'Sem despesas compartilhadas',
    billing: 'Equilibrada',
    notes: 'Sem notas',
    photos: 'Sem fotos',
    chat: 'Sem mensagens',
    travelPlan: 'Sem pontos de parada',
    packingLists: 'Sem listas',
    transportation: 'Sem meios de transporte',
    hint: {
      general: 'Fique a vontade para adicionar!',
      travelPlan: 'Por favor, crie algum ponto de parada e retorne aqui!',
      expenses: 'Tudo equilibrado. Toque no + para adicionar uma despesa',
      chat: 'Escreva alguma coisa legal!',
      packingLists:
        'Certifique-se de não esquecer nada! Toque no + para adicionar uma lista',
      accommodation:
        'Onde você fica? Toque no + para adicionar seus hotéis e apartamentos',
      search:
        '@:(noResults). Tente novamente com um termo de pesquisa diferente. 🙌',
      transportation:
        'Como você viaja? Toque no + para adicionar seu transporte',
      notes: 'Tão vazio! Toque no + para adicionar algumas notas ou links',
      documents:
        'Sem documentos. Toque no + para adicionar seus documentos de viagem',
      photos: 'Sem fotos para mostrar. Toque no + para salvar suas lembranças.',
    },
  },
  overview: 'Visão geral',
  copyTooltip: 'Clique para copiar',
  copySuccess: 'Copiado para área de transferência!',
  datePicker: {
    SPECIFIC: 'Data espicífera',
    WHOLE_MONTH: 'Mês inteiro',
  },
  datetimePicker: {
    DATE: 'Data',
    TIME: 'Hora',
  },
  dates: {
    title: 'Quando você vai estar lá? (opcional)',
    hint: 'Selecione uma data…',
  },
  joinTripPrompt:
    'Você recebeu um convite para uma viagem? Insira o código da viagem abaixo para ingressar.',
  joinTripNow: 'Ingressar na viagem agora',
  navBar: {
    discover: 'Discover',
    newTrip: 'Nova Viagem',
    joinTrip: 'Ingressar Viagem',
    archive: 'Arquivo',
    activeTrips: 'Viagens Ativas',
    searchPlaceholder: 'Procurar por inspirações',
  },
  peoplePickerTitle: 'Selecione uma pessoa',
  youAreInvited:
    'Você foi convidado(a) para participar da viagem "{tripName}"!',
  acceptInvitation: 'Aceitar convite',
  decline: 'Rejeitar',
  destination: 'O Destino',
  destinations: 'Destinos',
  suggestedTrips: 'Sugestões de viagens',
  similarTopics: 'Relacionados',
  sendImage: 'Enviar imagem',
  dropHint: 'Arraste seus arquivos aqui para carregar.',
  iOwe: 'Eu devo',
  owe: 'devo',
  iReceive: 'Eu recebo',
  receiveFrom: 'receber de',
  newPrivateExpense: 'Nova despesa particular',
  newGroupExpense: 'Nova despesa em grupo',
  notInvolved: 'Não envolvido',
  addNote: 'Adicionar Nota',
  newNote: 'Nova nota',
  saveNote: 'Salvar nota',
  billing: 'Acerto de contas',
  openAccounts: 'Contas abertas',
  paidAccounts: 'Contas pagas',
  paid: 'Pago',
  received: 'Recebido',
  createExpense: 'Criar despesa',
  updateExpense: 'Editar despesa',
  addExpense: 'Adicionar despesa',
  editExpense: 'Editar despesa',
  whoDoesItRelateTo: 'A quem isto é relacionado?',
  whoPaid: 'Quem pagou?',
  subject: 'Assunto',
  subjectPlaceholder: 'Insira o assunto…',
  continue: 'Continuar',
  balance: 'Saldo',
  total: 'Total',
  equal: 'Igual',
  exact: 'exatamente',
  whenWasItPaid: 'Quando isto foi pago?',
  deleteExpensePrompt: 'Você quer apagar esta despesa?',
  deleteExpense: 'Apagar despesa',
  pingPrompt: 'Selecione {_value_} para marcar esta despesa como paga.',
  pingAction: 'Enviar lembrete',
  pingTitle: 'Você já pagou?',
  enterAmount: 'Insira o valor',
  enterAmountPlaceholder: 'Insira o valor…',
  payWithPayPal: 'Pago com Paypal',
  noPaypalAccountSubject: 'Os detalhes do PayPal ainda não foram depositados!',
  noPaypalAccountText:
    '{person} ainda não depositou suas informações do PayPal. Você pode solicitar que ele(a) adicione os detalhes à sua conta e retorne aqui.',
  markAsPaid: 'Marcar como pago',
  alreadyPaid: 'Já pagou?',
  noPayPalInformation:
    'Este usuário ainda não definiu suas informações do PayPal',
  group: 'Grupo',
  myExpenses: 'Minhas despesas',
  allExpenses: 'Todas as depesas',
  private: 'Particular',
  shared: 'Compartilhado',
  searchHint: 'Procurar por locais',
  noResults: 'Sem resultados',
  airport: 'Aeroporto',
  atm: 'ATM',
  bank: 'Banco',
  bar: 'Bar',
  bus_station: 'Ponto de ônibus',
  cafe: 'Café',
  city_hall: 'Prefeitura',
  gas_station: 'Posto de gasolina',
  hotel: 'Hotel',
  hospital: 'Hospital',
  library: 'Biblioteca',
  museum: 'Museu',
  night_club: 'Boate',
  park: 'Parque',
  parking: 'Estacionamento',
  pharmacy: 'Farmácia',
  police: 'Policia',
  post_office: 'Correios',
  restaurant: 'Restaurante',
  shopping_mall: 'Shopping',
  store: 'Loja',
  subway_station: 'Estação de metrô',
  train_station: 'Estação de trem',
  zoo: 'Zoológico',
  showLess: 'Mostrar menos',
  radius: 'Raio',
  noPlacesFound:
    'Nenhum lugar foi encontrado. Tente outro termo de pesquisa ou aumente o raio de distância',
  noWorriesYouCanStillAddIt:
    'Mas não se preocupe, você ainda pode adicioná-lo!',
  howWouldYouLikeToImportThisPlace:
    'Como você gostaria de importar este lugar?',
  chooseWaypoint:
    'Para qual Ponto de Parada você gostaria de adicionar a localização?',
  selectWaypoint: 'selecione o ponto de passagem',
  addPOIToWaypoint: 'Importar como Local Próximo',
  importAsWaypoint: 'Importar como ponto de parada',
  whereToStartFrom: 'Onde você quer começar?',
  mapSettings: 'Configurações do mapa',
  mapTypes: {
    title: 'Tipo de mapa',
    types: {
      normal: 'Normal',
      satellite: 'Satélite',
      terrain: 'Terreno',
    },
  },
  distanceUnits: {
    title: 'Unidades de distância',
    kilometers: 'Quilômetros',
    miles: 'Milhas',
  },
  weather: {
    title: 'Temperatura',
    celsius: 'Celsius',
    fahrenheit: 'Fahrenheit',
  },
  connectWaypoints: 'Conectar pontos de parada',
  showDistance: 'Mostrar distância',
  learnMore: 'Saiba mais',
  deleteNoteDescription: 'Tem certeza de que deseja excluir a nota?',
  deleteNoteTitle: 'Apagar nota',
  noteName: 'Insira um título…',
  selectNote: 'Selecione uma nota…',
  areYouSure: 'Você tem certeza?',
  removeAttendee: 'Remover participante',
  showPicture: 'Mostrar foto do perfil',
  viewPhoto: 'Visualizar fotos',
  deletePhoto: 'Excluir foto',
  general: 'Geral',
  restaurants: 'Restaurantes',
  accommodations: 'Acomodações',
  entertainment: 'Entretenimento',
  shopping: 'Compras',
  activities: 'Atividades',
  drinks: 'Bebidas',
  fuel: 'Abastecimento / Carregamento',
  tickets: 'Ingressos',
  fees: 'Taxas',
  usernameSearchHint: 'Procure por nome de usuário ou e-mail…',
  currency: 'Moeda',
  currencyHeader: 'Qual moeda você usa?',
  timePeriod: 'Período de viagem',
  datesHeader: 'Quando você estará lá?',
  notifications: 'Notificações',
  choose: 'Selecionar',
  selected: 'Selecionado',
  waypointSorting: {
    changeToManual: 'Mudar a classificação para "Manual"',
    title: 'Classificação de Waypoints',
    header: 'NOVO: Ordenar pontos de passagem automaticamente',
    text: 'Agora é possível classificar os waypoints automaticamente, com base em sua data. Se um waypoint não tiver data, ele será anexado no final. Se você já tiver adicionado transporte ou rotas que possam ser afetadas por esta mudança, é claro que nós o informaremos!',
  },
  byDate: 'Por data',
  manual: 'Manual',
  preview: 'Pré-visualização',
  showPreview: 'Mostrar visualização',
  inviteYourFriends: 'Convidar amigos',
  manage: 'Gerenciar',
  tokenHint:
    'Você pode compartilhar o código PIN com seus amigos e eles podem participar da viagem.',
  moreActions: 'Mais ações',
  saveTrip: 'Economize viagem',
  archiveTrip: 'Arquivar viagem',
  duplicateTrip: 'Duplicar viagem',
  duplicateTripConfirmMessage:
    'Você quer duplicar esta viagem? Uma cópia desta viagem será criada.',
  confirm: 'Confirmar',
  enterTripNamePrompt: 'Digite o novo nome da viagem.',
  enterTripName: 'Insira um nome para a viagem…',
  leftTrip: 'Deixar viagem',
  leaveTrip: 'Sair da viagem',
  deleteTrip: 'Apagar viagem',
  leaveTripPrompt: 'Você quer sair desta viagem?',
  deleteTripPrompt: 'Você quer apagar esta viagem?',
  restoreTrip: 'Desarquivar viagem',
  themeTooltip: 'Clique para mudar o tema (max 5 mb)',
  inviteNow: 'Convidar agora',
  inviteAttendee: 'Convidar participante',
  search: 'Pesquisar',
  skip: 'Pular',
  highlight: 'Locais próximos',
  expense: 'Despesas',
  document: 'Documentos',
  photo: 'Fotos',
  note: 'Nota',
  timeHint: 'Selecionar horário…',
  poi: 'Pontos de interesse',
  deleteWaypointPrompt: 'Você tem certeza?',
  deleteWaypoint: 'Apagar {type}',
  place: 'local',
  changePasswordAction: 'Alterar senha',
  changePassword: 'Alterar senha…',
  oldPassword: 'Insira sua senha antiga…',
  newPassword: 'Insira a senha…',
  newPasswordConfirmation: 'Insira a senha novamente…',
  resetPasswordTitle: 'Redefinir senha',
  resetPasswordAction: 'Alterar agora',
  resetHint:
    'Por favor, digite seu endereço de e-mail para redefinir sua senha.',
  enterEmail: 'Insira seu endereço de email…',
  forgotPassword: 'Esqueceu a senha?',
  invitePrompt:
    'Você gostaria de convidar seus amigos diretamente para sua viagem?',
  selectTrip: 'Selecionar viagem',
  inviteToLambus: 'Não, apenas para o Lambus',
  yes: 'Sim',
  yesSelectTrip: 'Sim, selecionar viagem',
  profileTooltip: 'Clique para alterar a imagem do perfil',
  sendInvite: 'Enviar convite',
  inviteAggreement:
    'Confirmo que a pessoa convidada por mim concordou em receber o e-mail de convite da Lambus GmbH',
  inviteHint: 'Convide seus amigos agora e planejem sua próxima viagem juntos.',
  socialMedia: 'Redes Sociais',
  youCanFindUsOn: 'Você pode nos encontrar em…',
  feedback: 'Feedback',
  howCanWeHelp: 'Como podemos ajudar?',
  send: 'Enviar',
  faq: 'Ajuda / FAQ',
  legal: 'Legal',
  terms: 'Termos',
  privacy: 'Privacidade',
  imprint: 'Imprimir',
  username: 'Usuário',
  firstName: 'Primeiro nome',
  lastName: 'Último nome',
  email: 'Endereço de email',
  company: 'Empresa',
  gender: 'Gênero',
  birthdate: 'Data de nascimento',
  female: 'Feminino',
  male: 'Masculino',
  neutral: 'Neutro',
  country: 'País',
  paypalAddress: 'Endereço de email Paypal',
  paypalMeUsername: 'Usuário PayPal.Me',
  deleteAccountPrompt:
    'Isso apagará todos os seus dados - incluindo viagens, documentos, fotos e histórico de bate-papo.',
  irrevocablyDelete: 'Apagar definitivamente',
  deleteAccount: 'Apagar conta',
  unknownPlace: 'Local desocnhecido',
  kilometersUnit: 'KM',
  milesUnit: 'MI',
  createTrip: 'Criar Viagem',
  next: 'CONTINUAR',
  back: 'VOLTAR',
  navHint: 'ou pressione ENTER',
  invite: 'Convite',
  or: 'OU',
  newTripFields: {
    destination: {
      title: 'Onde você quer ir?',
      hint: 'Uma cidade, país ou região',
    },
    origin: {
      title: 'De onde você está saindo? (Opcional)',
      hint: 'z.B. endereço, estação de trem ou aeroporto',
    },
    attendees: {
      hint: 'Quem vai com você? (Opcional)',
    },
  },
  createNewTrip: 'Criar nova viagem',
  joinTrip: 'Ingressar Viagem',
  welcome: 'Bem-vindo ao Lambus!',
  loginTitle: 'Bem-vindo ao Lambus!',
  login: 'Entrar',
  lambusAllInOneApp: 'Lambus - Seu novo aplicativo de viagem All-In-One',
  usernameLabel: 'Usuário / Email',
  passwordLabel: 'Senha',
  notRegisteredYet: 'Criar Conta',

  loginFailed: {
    wrongInput:
      'Seu nome de usuário / e-mail ou senha estavam incorretos. Por favor, tente novamente ou clique em "Esqueceu a senha?", Obrigado!',
    alreadyRegisteredWithEmail:
      'Problema com seu login. Você já tem uma conta com este endereço de e-mail. Você quer redefinir sua senha?',
    isLinkedToSocialAccount:
      'Seu email está vinculado a uma conta de mídia social. Faça login com sua conta "{social}"',
    appleSignInError:
      'Infelizmente, não foi possível completar seu login com a Apple no momento. Por favor, escolha um método alternativo de login - enquanto isso, já estamos trabalhando na correção!',
  },
  continueWithoutAccount: 'Continuar sem uma conta?',
  usernameNotice:
    'Você está quase pronto! Digite um nome de usuário que será vinculado à sua conta Lambus.',
  tosAggreement:
    'Aceite nossos termos e política de privacidade para concluir seu registro.',
  continueWithApple: 'Continuar com o Apple',
  continueWithFacebook: 'Continuar com o Facebook',
  registration: 'Registrar',
  register: 'Inscrever-se',
  tosAggreementPlaceholders:
    'Por favor aceite nossos {0} e {1} para completar seu registro.',
  registrationFailed: 'Oh oh, algo deu errado. Por favor, tente novamente!',
  field: 'Campo',
  value: 'Valor',
  noInspirationFound: 'Nenhuma inspiração encontrada.',
  importTrip: 'Importar viagem',
  importedTrip: 'Viagem importada',
  myTransportation: 'Meu transporte',
  addTransportation: 'Acrescentar transporte',
  linkedWaypoint: 'Ponto de passagem vinculado',
  unlinkTransportationPrompt:
    'Seu ponto de passagem está ligado a um transporte. Você quer desvinculá-lo?',
  unlink: 'Desbloqueio',
  departure: 'Saída',
  plusDepartureStation: '+ Estação (Partida)',
  plusArrivalStation: '+ Estação (Chegada)',
  plusTime: '+ Tempo',
  transportationCarDestinationTooltip:
    'Pro-tip: Mude o waypoint de destino para usar o transporte também para outras rotas!',
  sameLocationDescription:
    'Então nós adicionamos o waypoint »{waypoint}« como destino para você.',
  sameLocationTitle: 'Origem = Destino?',
  plusDeparturePort: '+ Porto (Saída)',
  plusArrivalPort: '+ Porto (Chegada)',
  plusPreciseLocation: '+ Localização precisa',
  port: 'Porto',
  searchFlight: 'Busca de voo',
  flightNumber: 'Código do voo',
  flightNumberPlaceholder: 'por exemplo, JJ1234',
  FlightBooking: 'Voo',
  TrainBooking: 'Trem',
  RentalCarBooking: 'Aluguel de carro',
  CarBooking: 'Carro',
  OwnCar: 'Carro próprio',
  FerryBooking: 'Ferry',
  BusBooking: 'Ônibus',
  BicycleBooking: 'Bicicleta',
  WalkingBooking: 'A pé',
  GenericBooking: 'Outro',
  subTypePlaceholder: 'por exemplo, motorhome, motorbike, ...',
  taxi: 'Taxi',
  escooter: 'E-Scooter',
  rv: 'Autocaravana',
  motorcycle: 'Motocicleta',
  helicopter: 'Helicóptero',
  cruise: 'Crucero',
  airportSearch: 'Pesquisar aeroporto',
  airportActionPrompt: 'O que você quer fazer?',
  showOnGoogleMaps: 'Mostrar no google maps',
  selectLocation: 'Selecione o local',
  selectAirport: 'Selecione o aeroporto',
  uploadNewDocument: 'Carregar novo documento',
  selectDocument: 'Selecione o documento',
  viewDocument: 'Ver documento',
  readOnWebsite: 'Leia mais em <a>{website}</a>',
  textfieldRules: {
    required: 'Necessário.',
    counter: 'Máx. 50 caracteres.',
    pin: 'O PIN deve ter 6 caracteres.',
    amount: 'Valor inválido.',
    email: 'E-mail inválido.',
    link: 'Link inválido.',
    flightNumber: 'Código do voo inválido.',
    password: 'A senha deve ter pelo menos 6 caracteres.',
    noWhiteSpace: 'Não deve conter espaços.',
    checkbox: 'O campo deve ser aceito.',
  },
  maxFileSize: 'tamanho máximo do arquivo: {size}',
  tripOfTheDay: 'Viagem do dia',
  tripOfTheWeek: 'Viagem da semana',
  tripOfTheMonth: 'Viagem do mês',
  media: 'mídia',
  searchResult: 'Resultados da busca',
  noBookmarks: 'Sem favoritos',
  discoverTrips: 'Descobrir viagens',
  date: 'Data',
  lastUpdated: 'última atualização',
  sortTripsBy: 'Classificar viagens por …',
  adBlocker: {
    hey: 'Hey!',
    notice: `Parece que você está usando um bloqueador de anúncios. Se você gostaria de ver {contentType} ao invés deste alerta, por favor, desative seu bloqueador de anúncios. `,
  },
  pro: {
    unlock: {
      title: '🔓 Desbloqueie este recurso',
      description:
        'Este recurso está disponível no Lambus PRO! Você quer saber mais?',
    },
    checkout: {
      title: 'Atualização para Lambus PRO',
      description:
        'Desbloqueie recursos premium adicionais para tornar o planejamento de sua viagem ainda mais fácil! 🎉',
      payPerTrip: {
        title: 'Pague por viagem',
        caption: 'Atualize uma viagem',
      },
      yearlySubscription: {
        title: 'Assinatura anual',
        caption: 'Atualize todas as suas viagens',
        freeTrial: 'TESTE 7 DIAS DE GRAÇA!',
      },
      success: {
        title: 'Lambus PRO: Compre com sucesso!',
      },
      learnMore: 'Saiba mais sobre Lambus PRO',
      upgradeNowUpper: 'ATUALIZE AGORA 🚀',
      thankYouNotice:
        'Obrigado por comprar Lambus PRO! ❤️ Essa página vai fechar automaticamente em {time} segundos.',
    },
    subscription: {
      validUntil: 'VÁLIDO ATÉ',
      purchasedBy: 'COMPRADO POR',
      exploreProFeatures: 'Explore os recursos do PRO',
      exploreLambusPro: 'Explore Lambus PRO',
      manageSubscription: 'Gerenciar assinatura',
      mySubscriptions: 'Minhas assinaturas',
      noSubscriptions: 'Sem assinaturas',
      noActiveSubscriptions: 'Você não tem assinaturas ativas',
      areYouTravelingAlot: 'Você está viajando muito?',
      upgradeToYearlySubscription: 'Então, atualize para uma assinatura anual!',
      cancelSubscription: 'Cancelar assinatura',
      nowWith: 'Agora com',
      forever: 'Para sempre',
      yearlySubscription: 'Assinatura anual',
      monthlySubscription: 'Assinatura mensal',
      lifetimeSubscription: 'Assinatura vitalícia',
    },
    features: {
      routePlanning: {
        title: 'Planejamento de Rota Estendida',
        description:
          'Calcule a duração exata e a distância entre todos os seus pontos de parada',
      },
      googleMaps: {
        title: 'Pesquisa no Google Maps',
        description:
          'Nós usamos o Google Maps para encontrar ainda mais pontos de passagem',
      },
      videoChat: {
        title: 'Chat de vídeo',
        description:
          'Converse com outros participantes enquanto planeja sua viagem',
      },
      moneyPool: {
        title: 'Pool de Dinheiro',
        description: 'Gerencie o dinheiro da viagem em um lugar central',
      },
      exportTripGpx: {
        title: 'Exportar plano da viagem em GPX',
        description:
          'Exporte sua viagem como GPX e use-a em aplicativos de rastreamento',
      },
      currencies: {
        title: 'Moedas Automáticas',
        description:
          'Converta moedas estrangeiras para a sua moeda usando a taxa de câmbio atual',
      },
      weather: {
        title: 'Atualizações de clima em tempo real',
        description:
          'Mostra as condições climáticas atuais para todos os seus pontos de parada',
      },
      exportExpenses: {
        title: 'Exportar despesas',
        description: 'Exporte automaticamente suas despesas para o Excel',
      },
      flightStatus: {
        title: 'Atualizações de voos em tempo real',
        description:
          'Seja notificado se o seu voo atrasar, for cancelado ou reagendado',
      },
      exportTrip: {
        title: 'Exportar viagem como PDF',
        description:
          'Exporte sua viagem como um PDF para tê-la em mãos mesmo sem um smartphone',
      },
      support: {
        title: 'Apoie-nos ❤️',
        description:
          'Com sua ajuda, a Lambus pode ficar livre de anúncios e não vender dados',
      },
      packingList: {
        title: 'Listas (listas de embalagem, a fazer)',
        description:
          'Escolha a partir de listas existentes ou crie a sua própria',
      },
      flightCompensation: {
        title: 'Indenização de Voo',
        description: 'Indenização automática se o seu voo for cancelado',
      },
      privateDocuments: {
        title: 'Documentos particulares',
        description:
          'Guarde seu passaporte, carteira de motorista e muito mais em segurança',
      },
      offlineMode: {
        title: 'Modo offline',
        description:
          'Acesse sua viagem inteira mesmo sem uma conexão de internet',
      },
      calendarSync: {
        title: 'Sincronização de calendário',
        description: 'Sincronize seus waypoints e POIs com seu calendário',
      },
    },
    cancellation: {
      orderedWith: 'Comprado na {store}',
      cancelWith:
        'Como você adquiriu a Lambus PRO através da {store}, o cancelamento também ocorrerá através da {store}.',
      reasons: {
        label: {
          pro_cancelation_reason_1: 'Eu não uso os recursos',
          pro_cancelation_reason_2: 'Estou perdendo uma característica',
          pro_cancelation_reason_3: 'Eu encontrei um aplicativo melhor',
          pro_cancelation_reason_4: 'O preço era muito caro',
          pro_cancelation_reason_5:
            'Eu estou testando para uma viagem posterior',
        },
        placeholder: {
          pro_cancelation_reason_2: 'Qual característica você está faltando?',
        },
      },
      lossHint: {
        youCurrentlyHave: 'Atualmente você tem',
        thatWillBeLost:
          'embalagem que serão perdidas pelo cancelamento da sua assinatura anual da Lambus PRO.',
        confirmation: 'Eu ainda gostaria de cancelar minha assinatura.',
        tellUsWhy:
          'Por favor, nos deixe saber o que você não gosta e nós podemos trabalhar nisso!',
      },
      talkToUs: 'Quer falar conosco? Nós estamos felizes em ajudar!',
      chatWithUs: 'Converse conosco',
      sendUsAnEmail: 'Envie-nos um e-mail',
    },
  },
  routes: {
    myRoutes: 'Minhas rotas',
    planRoute: 'Plano de rota',
    calculate: 'Calcular rota',
    save: 'Salvar rota',
    delete: 'Excluir rota',
    recommendedRoute: 'Rota recomendada',
    type: {
      driving: 'Carro',
      transit: 'Trem',
      bicycling: 'Bicicleta',
      flight: 'Voo',
      walking: 'A pé',
    },
  },
  realtimeFlightStatus: {
    isEligibleForCompensation: 'Elegível para reembolso se cancelado/atendido',
    activateFlightAlert: 'Receba Alertas de Voo',
    flightStatus: 'Status do voo',
    status: {
      active: 'No ar',
      canceled: 'Cancelado',
      diverted: 'Desviado',
      landed: 'Pousou',
      not_operational: 'Não ativo',
      redirected: 'Redirecionado',
      scheduled: 'Agendado',
      now_tracking: 'Rastreando',
      unknown: 'Desconhecido',
    },
    compensation: {
      title: 'Reinvidicar indenização de voo',
      subtitle: 'Receba até 600 euros se o seu atrasar ou for cancelado!',
      howItWorks: 'Como funciona',
      instructions: {
        step1: '1. Acompanhe seu voo com Lambus PRO',
        step2:
          '2. Nós detectamos quando o voo atrasa ou é cancelado e entramos em contato se você tiver direito a uma indenização!',
      },
    },
  },
  selectDate: 'Selecione a data',
  distance: 'Distância',
  duration: 'Duração',
  later: 'Mais tarde',
  exportExpensesPrompt:
    'Podemos combinar as despesas de viagem de sua viagem em um arquivo Excel. Você gostaria de começar a exportação agora?',
  exportInfo:
    'Você receberá um e-mail com o documento em breve. Importante: Verifique também sua caixa de spam',
  exportStarted: 'A exportação já começou',
  exportNow: 'Exportar agora',
  exportAsExcel: 'Exportar como arquivo Excel',
  howItWorks: 'Como funciona',
  exportExpenses: 'Exportação de despesas de viagem para o Excel',
  howItWorksInfo:
    'Com a Lambus, todas as despesas de viagem são automaticamente liquidadas entre companheiros de viagem. Nós calculamos os pagamentos pendentes entre todos os participantes, para ficar mais fácil acertar as contas.',
  howItWorksPrompt: 'Você tem dúvidas ou acha que cometemos um erro?',
  contactUs: 'Fale conosco',
  export: 'Exportar',
  exportTrip: 'Exportar a viagem',
  exportTripPrompt: 'Você quer exportar sua viagem como PDF?',
  exportAsGPX: 'Exportar como arquivo GPX',
  exportTripAsGPXPrompt: 'Você quer exportar sua viagem como PDF?',
  exportAsPDF: 'Exportar como PDF',
  gpxZoomPrompt: 'Devemos sempre ampliar primeiro a sua rota GPX?',
  showGpxRoutes: 'Mostrar rota GPX',
  expenseExport: 'Despesas de viagem de exportação',
  packingLists: {
    useThisList: 'Usa esta lista',
    title: 'Lista',
    create: 'Criar lista',
    add: 'Adicionar lista',
    delete: 'Apagar lista',
    deleteSection: 'Apagar categoria',
    deleteSectionPrompt: 'Você quer apagar esta categoria?',
    myPackingLists: 'Minhas listas',
    templatesFromLambus: 'Modelos do Lambus',
    createNewList: 'Criar nova lista',
    myTemplates: 'Meus modelos',
    createNewTemplate: 'Criar novo modelo',
    templateName: 'Nome do modelo',
    orCreateNew: 'Ou criar novo',
  },
  createYourOwn: 'Crie o seu próprio',
  addCategory: 'Adicionar categoria',
  new: 'novo',
  soonUpper: 'EM BREVE',
  gpsBasedCurrencies: {
    title: 'Encontre moedas automaticamente',
    info: 'Você quer detectar a moeda automaticamente? Permita-nos usar sua localização atual por um curto período de tempo nas configurações',
  },
  packingListItemCount:
    'Sem objetos | {checkedCount}/{itemCount} objeto | {checkedCount}/{itemCount} objetos',
  items: 'Sem objetos | Um objeto | {count} objetos',
  lists: 'Listas',
  rename: 'Renomear',
  visibility: 'Visibilidade',
  gpx: {
    files: 'Arquivos GPX',
    description:
      'Sua viagem tem um endereço de e-mail exclusivo para o qual você encaminha seus arquivos GPX. Depois de importar seu arquivo .gpx por meio do endereço de e-mail acima, ele será desenhado em seu mapa de pontos de parada.',
    visibility: {
      always: 'Sim',
      never: 'Não',
      askLater: 'Decidir depois',
    },
  },
  savedPlaces: 'Locais e atividades salvos',
  recommendedPlaces: 'Lugares Recomendados',
  pleaseEnterThePDFPassword: 'Por favor, digite a senha do arquivo PDF:',
  cannotOpenPDF:
    'O arquivo PDF não pôde ser aberto. Por favor, entre em contato com nosso suporte!',
  routeTo: 'Rota para',
  dailyPlan: 'Plano Diário',
  daily_plan: 'Plano Diário',
  openRoute: 'Conexão de ponto de passagem aberto',
  manageTransportations: 'Todos os displays',
  showAll: 'Mostrar tudo',
  onboarding: {
    youHaveBeenInvited: `Você foi convidado?`,
    findInspirations: 'Encontre Inspirações',
    howDoYouWantToStart: 'Por favor, selecione como você quer começar!',
    allInOneSingleApp:
      'Planeje, reserve e gerencie sua viagem inteira em um único aplicativo!',
    alreadyKnowWhereToGo: 'Você já sabe onde você quer ir?',
    notSureWhereToGoYet: 'Ainda não tem certeza para onde ir?',
    pleaseEnterThePinCodeBelow: 'Por favor, digite o código PIN abaixo!',
    youDontHaveAnyCode: `Não tem um código PIN?`,
    emailInviteNote:
      'Você também pode ser convidado por e-mail. Você gostaria de criar uma conta com seu endereço de e-mail agora?',
    almostThereExclamation: 'Quase lá!',
    registerToJoinTripText: `Para participar da viagem, você precisa de uma conta Lambus. Não se preocupe, nós não vendemos nenhum dado e não vamos incomodá-lo 🙂`,
    registerToCreateTripText: `ara salvar a viagem, você precisa de uma conta Lambus. Não se preocupe, nós não vendemos dados e não vamos incomodá-lo 🙂`,
    keywords: 'Palavras-chave',
    category: 'Categorias',
    hashtag: 'Hashtags',
    letsGo: 'Começar',
    youAlreadyHaveAnAccount: 'Você já tem uma conta?',
    searchHint: {
      keywords: 'Procurar inspirações …',
      category: 'Pesquisar por categorias …',
      hashtag: 'Pesquisar por hashtags …',
    },
    selectedInspiration: 'Inspiração selecionada',
    newTrip: 'Sua nova viagem',
  },
  enterNewName: 'Por favor, digite o novo nome do seu ponto de passagem',
  unnamedLocation: 'Localização sem nome',
  dateFormat: 'Formato da data',
  systemFormat: 'Sistema',
  callVerb: 'Telefonar',
  visitWebsite: 'Visite o site',
  savePlace: 'Salvar local',
  tapToAddSomething: 'Toque para adicionar algo',
  nothingPlanedYet: 'Nada planejado ainda',
  noAccommodationAddedYet: 'Sem alojamento adicionado',
  tapToAddAnAccommodation: 'Toque para adicionar um novo alojamento',
  noDateSet: 'Nenhuma data definida',
  planYourDaysPerWaypoint: 'Planeje seus dias por parada!',
  specifyDate: 'Especifique o período',
  linkToWaypoint: 'Link para o waypoint',
  unlinkFromWaypoint: 'Cancelar link',
  searchStopsPlaceholder: 'Pesquisar paradas ...',
  findRentalCars: 'Encontre carros de aluguel',
  egStartFrom: 'De {waypoint}',
  mobility: 'Mobilidade',
  directions: 'Rotas',
  saveTransportationBeforeApplyingRoute:
    'Por favor, guarde seu transporte para calcular as rotas, obrigado!',
  colors: 'Cores',
  moreInfo: 'Mais informações',
  optional: 'opcional',
  travelers: 'Viajante',
  pleaseEnterEitherExistingOrInvite:
    'Por favor, digite o nome de usuário ou endereço de e-mail da pessoa que você gostaria de convidar.',
  ifNoAccountReminderSent:
    'Se o participante não tiver uma conta, um convite será enviado.',
  statistics: 'Estatísticas',
  expensesCurrencyNotEditable:
    'A moeda da sua viagem não pode ser alterada se as despesas de viagem já tiverem sido criadas.',
  expensesBillingIntroTitle: 'Sobre o assentamento em Lambus',
  expensesBillingIntroSubtitle:
    'Nós minimizamos o número de valores pendentes, liquidando-os automaticamente uns contra os outros. Exemplo: Você deve 50 R$ ao {person1} e {person2} deve 50 R$ à {person3}, então você paga 50 R$ à {person4} e você está quites! 🙂',
  shareTrip: 'Compartilhe sua viagem',
  linkToYourMap: 'Link para seu mapa',
  shareTripInfo:
    'Você pode compartilhar sua viagem com outras pessoas que não estão viajando com você. Se você quiser convidar outros viajantes para participar de sua viagem, digite',
  activateIFrameInfo:
    'Ativar para incorporar o mapa de sua viagem em seu blog ou site pessoal (por exemplo, Wordpress, Notion ou Medium)',
  embedAsIFrame: 'Embutir via <iframe>',
  here: 'aqui',
  accommodation: 'Acomodação',
  addAccommodation: 'Adicionar acomodação',
  searchAddress: 'Endereço de busca (hotel, Airbnb, …)',
  addCheckInCheckOut: 'Adicionar check-in / check-out',
  plusBookingDocument: '+ Documento de reserva',
  myAccommodation: 'Minha acomodação',
  deleteAccommodation: 'Excluir acomodação',
  deleteAccommodationDescription:
    'Tem a certeza de que pretende apagar o alojamento?',
  changeAddress: 'Alterar endereço',
  copyAddress: 'Endereço para cópia',
  copy: 'Cópia',
  openWaypoint: 'Ponto de passagem aberto',
  theAddressWasCopiedSuccessfully: 'O endereço foi copiado para sua prancheta!',
  notLinkedYet: 'Não vinculado, ainda',
  linkedWaypoints: 'Pontos de parada interligados',
  addTime: 'Adicionar tempo',
  addedToDate: 'Adicionado em {date}',
  starts: 'Início',
  ends: 'Fim',
  linkedDays: 'Dia vinculado | Dias vinculados',
  sortBy: 'Ordenar por …',
  displayAs: 'Mostrar como {mode}',
  grid: 'Grade',
  list: 'Lista',
  name: 'Nome',
  fileType: 'Tipo',
  confirmNoteDiscard:
    'Você tem mudanças não salvas em sua nota. Você realmente quer descartar isso?',
  manageFeatures: 'Gerenciar características',
  whichDataShouldBeExported: 'Que dados você quer exportar? 😇',
  selectAll: 'Selecione todos',
  deselectAll: 'Desmarcar todos',
  fileSection: {
    document: {
      new: 'Carregar novo documento',
      link: 'Selecione o documento',
      emptyState: {
        title: 'Sem documentos adicionados',
        subtitle: 'Toque para adicionar seus documentos de viagem',
      },
    },
    photo: {
      new: 'Carregar novo foto',
      link: 'Selecione o foto',
      emptyState: {
        title: 'Sem fotos adicionadas',
        subtitle: 'Toque para salvar suas memórias',
      },
    },
  },
  noteSection: {
    emptyState: {
      title: 'Sem notas adicionadas',
      subtitle: 'Toque para coletar pensamentos, links, dicas, …',
    },
  },
  addADate: 'Adicionar data',
  addDate: '+ Adicionar data',
  addANote: 'Adicione uma nota',
  addNotePlaceholder: 'Adicione sua nota...',
  address: 'Endereço',
  plannedFor: 'Planejado para',
  collectThoughtsLinksTips: 'Colete pensamentos, links, dicas …',
  selectPosition: 'Selecione a posição',
  insertHere: 'Inserir aqui',
  appendToEnd: 'Inserir no final',
  appendToStart: 'Inserir no início',
  appendAfter: 'Inserir em "{waypoint}"',
  aSmallNote: 'Uma pequena nota',
  shiftDatesAsk:
    'Alterou a data da sua viagem. Deseja que o seu itinerário seja automaticamente alterado por {days} dia(s) também?',
  shiftDates: 'Data da mudança',
  shiftDatesDiscard: 'Não mudar a data',
  addMoreInformationPlaceholder: 'Adicione suas informações adicionais...',
  addActivity: 'Adicionar atividade',
  deleteActivity: 'Excluir atividade',
  deleteActivityDescription:
    'Tem certeza de que deseja excluir essa atividade?',
  unlinkWaypointDayItem: 'Excluir item da programação do dia',
  unlinkWaypointDayItemDescription:
    'Tem certeza de que deseja excluir o item da programação diária?',
  noAddress: 'Nenhum endereço',
  noResultsFound: 'Nenhum resultado encontrado',
  couponCodeMessage:
    'Você vai entrar no ano de 2024 com aventuras! Ganhe 25% de desconto no Lambus Pro com:',
  searchForCurrencies: 'Pesquisar moedas ...',
  sortExpensesBy: 'Ordenar despesas por …',
  expenseCreated: 'Despesa criada',
  expenseDate: 'Data da despesa',
  canoe_tour: 'Passeio de canoa',
  hiking: 'Trilha',
  camping: 'Acampamento',
  beach: 'Praia',
  swimming: 'Natação',
  boat_tour: 'Passeio de barco',
  campfire: 'Fogueira',
  party: 'Festa',
  bike_tour: 'Passeio de bicicleta',
  surfing: 'Surfe',
  snorkeling: 'Mergulho com snorkel',
  picknick: 'Piquenique',
  food: 'Comida',
  horse_riding: 'Cavalgada',
  sport_event: 'Evento esportivo',
  concert: 'Concerto',
  cinema: 'Cinema',
  winter_sports: 'Esportes de inverno',
  searchActivitiesAndPlaces: 'Pesquise seus locais e atividades salvos',
  signUp: {
    cta: 'Ainda não está no Lambus?',
    action: 'Entrar',
    businessCta: 'Você é uma empresa?',
    title: 'Bem-vindo ao Lambus!',
    titleActionNewTrip: 'Registre-se para salvar sua viagem!',
    titleActionJoinTrip: 'Registre-se para participar da viagem!',
    titleActionInspiration: 'Registre-se para salvar a inspiração!',
  },
  signIn: {
    cta: 'Já tem uma conta?',
    action: 'Entrar',
    title: 'Bem-vindo ao Lambus!',
    titleActionNewTrip: 'Entre para salvar sua viagem!',
    titleActionJoinTrip: 'Entre para participar da viagem!',
    titleActionInspiration: 'Entre para salvar a inspiração!',
  },
  signUpTitle: 'Criar conta',
  orContinueWith: 'Ou continue com',
  autosavingNote: 'Nota salva automaticamente ...',
  lastSaved: 'Última salva',
  justNow: 'Agora mesmo',
  deletePackingList: 'Excluir lista',
  deletePackingListDescription: 'Tem certeza de que deseja excluir esta lista?',
  sidebar: {
    close: 'Fechar barra lateral',
    collapse: 'Colapsar a barra lateral',
    expand: 'Expandir a barra lateral',
    tripSection: {
      planning: 'Planejamento',
      general: 'Geral',
      travelers: 'Viajantes',
    },
  },
  startDate: 'Data de início',
  notSaved: 'Não salvo',
  reset: 'Redefinir',
  updates: 'Atualizações',
  markAllAsRead: 'Marcar tudo como lido',
  markAsRead: 'Marcar como Lido',
  hide: 'Esconder',
  noNewNotifications: 'Sem novas notificações',
  youAreUpToDate: 'Você está atualizado',
}
