
import { Component, Prop, Vue } from 'vue-property-decorator'

import { waypointModule } from '@/store'
import { LinkedDay } from '@/types/trip'
import { TimeRange } from '@/types/types'
import { formattedTimeRange, parsedDate } from '@/utils/date-utils'

@Component({
  name: 'LinkedDayItem',
})
class LinkedDayItem extends Vue {
  @Prop() readonly day?: LinkedDay

  get itemTime(): TimeRange {
    return { startTime: this.day?.startTime, endTime: this.day?.endTime }
  }

  get formattedDate(): string {
    let dateString = this.$t('addDate').toString()

    if (this.day?.date) {
      dateString = new Intl.DateTimeFormat(this.$i18n.locale, {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
      }).format(parsedDate(this.day.date))

      if (this.itemTime.startTime) {
        const timerange = formattedTimeRange(
          this.itemTime.startTime,
          this.itemTime.endTime,
        )

        dateString = dateString.concat(` ${timerange}`)
      }
    }

    return dateString
  }

  updateTime(times: TimeRange): void {
    waypointModule.updateWaypointDayItem({
      itemId: this.day?.itemId,
      item: times,
    })
  }

  unlinkDay(): void {
    if (!this.day || !waypointModule.waypoint) {
      return
    }

    waypointModule.unlinkNearbyPlaceToDay({
      waypointId: waypointModule.waypoint._id,
      waypointDayId: this.day.dayId,
      itemId: this.day.itemId,
    })
  }
}
export default LinkedDayItem
