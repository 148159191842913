
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { TextFieldRules } from '@/utils/enums'
import { extractFlightNumberComponents } from '@/utils/utility-manager'

@Component({
  name: 'FlightSearchField',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
class FlightSearchField extends Vue {
  @Prop() readonly flightNumber?: string
  @Prop() readonly textFieldClass?: string

  textFieldRules = TextFieldRules
  matchedAirline: string | null = null
  isValidForm = false

  airlines = require('@/assets/json/airlines.json')

  get formattedFlightNumber(): string | null {
    if (!this.flightNumber || this.flightNumber.trim().length === 0) {
      return null
    }

    return this.flightNumber
  }

  set formattedFlightNumber(value: string | null) {
    this.$emit('update:flightNumber', value?.toUpperCase() || '')
    this.$nextTick(() => {
      this.searchAirline()
    })
  }

  @Watch('flightNumber')
  resetAirling() {
    if (!this.formattedFlightNumber) {
      this.matchedAirline = null
    }
  }

  searchAirline(): void {
    // Find airline
    if (this.formattedFlightNumber && this.formattedFlightNumber.length >= 2) {
      const flightNumberComponents = extractFlightNumberComponents(
        this.formattedFlightNumber,
      )
      if (
        flightNumberComponents?.airline &&
        this.airlines[flightNumberComponents.airline]
      ) {
        this.matchedAirline = this.airlines[flightNumberComponents.airline]
      } else {
        this.matchedAirline = null
      }
    } else {
      this.matchedAirline = null
    }
  }

  public resetValidation(): void {
    const form = this.$refs.flightNumberValidationObserver as
      | InstanceType<typeof ValidationObserver>
      | undefined

    if (form) {
      form.reset()
    }
  }
}
export default FlightSearchField
