
import { Component, Watch, Prop, Vue } from 'vue-property-decorator'
import debounce from 'lodash/debounce'
import LDialog from '@/global-components/LDialog.vue'
import { tripModule, waypointModule, eventModule } from '@/store'
import {
  NearbyPlaceAutocompleteResult,
  PointOfInterest,
  WaypointDay,
} from '@/types/trip'
import { HighlightCategories } from '@/utils/enums'
import { mappedCategoryByValue } from '@/utils/utility-manager'

interface SearchParams {
  query: string
  radius: number
}

@Component({
  name: 'NearbyPlacesModal',
})
class NearbyPlacesModal extends Vue {
  @Prop() readonly location!: any
  @Prop() readonly waypointId!: string
  @Prop() readonly waypointDay?: WaypointDay
  @Prop({ default: true, type: Boolean }) readonly fullscreenOnMobile!: boolean

  categories = HighlightCategories
  loading = false
  showMoreBtn = true
  searchTerm = ''
  searchDistance = 10
  places: NearbyPlaceAutocompleteResult[] = []

  searchGoogle = debounce(
    async (query: string, radius: number, context: Vue): Promise<void> => {
      this.places.splice(0, this.places.length) // Deletes the array elements. Needs to be done this way, for vue to notice and render
      if (query) {
        const params = {
          query,
          radius: radius * 1000,
          categoryId: mappedCategoryByValue(query),
        }

        const result = await waypointModule.getNearbyPlaces(params)

        result.forEach((el: any) => {
          this.places.push(el)
        })
        console.log('Places:', this.places)
      }
      this.$set(context, 'loading', false)
    },
    1000,
  )

  @Watch('searchParams')
  searchParamsHandler(val: SearchParams) {
    this.loading = true
    this.searchGoogle(val.query, val.radius, this)
  }

  get placePoiPreview(): PointOfInterest | null {
    return waypointModule.poiPlacePreview
  }

  get searchParams(): SearchParams {
    return { query: this.searchTerm, radius: this.searchDistance }
  }

  get formattedSearchTerm(): string {
    return this.searchTerm
  }

  get distanceUnit(): string {
    return waypointModule.distanceUnit
  }

  set formattedSearchTerm(val: string) {
    this.searchTerm = val
  }

  get trip() {
    return tripModule.trip
  }

  async showPoiPreview(place: any): Promise<void> {
    await waypointModule.fetchPoiPlacePreview({
      waypointId: this.waypointId,
      externalId: place.id,
      dataSource: place.dataSource,
    })
    if (this.placePoiPreview) {
      waypointModule.setPointOfInterest(this.placePoiPreview)
      eventModule.togglePoiDetails(true)
    }
    if (this.waypointDay) {
      waypointModule.setCurrentWaypointDay(this.waypointDay)
    }
    this.toggleDialog()
  }

  async addPOI(index: number): Promise<void> {
    const place = this.places[index]
    if (place.dataSource === 'GOOGLE' && waypointModule.waypoint) {
      const waypoint = waypointModule.waypoint
      const newPOI: any = {
        name: place.title,
        startDate: null,
        endDate: null,
        location: place.location,
      }
      const poi = await waypointModule.createPOI({
        waypointId: waypoint._id,
        payload: newPOI,
      })
      if (this.waypointDay && poi) {
        await waypointModule.linkNearbyPlaceToDay({
          waypointId: waypoint._id,
          waypointDayId: this.waypointDay._id,
          payload: { nearbyPlace: poi._id },
        })
        waypointModule.setCurrentWaypointDay(null)
      }

      await tripModule.fetch(this.trip!.id)
      this.toggleDialog()
    } else if (place.dataSource === 'FOURSQUARE') {
      await this.showPoiPreview(place)
    } else {
      this.$sentry.captureException(
        new Error(`Unsuported place data source ${place.dataSource}`),
      )
      eventModule.newError('error.poi.create')
      this.toggleDialog()
    }
  }

  formattedDistance(distance: number): string {
    const distanceInKm = distance / 1000
    if (distanceInKm < 1) {
      return `${Math.round(distanceInKm * 1000)} m`
    } else {
      return `${distanceInKm.toFixed(1)} km`
    }
  }

  toggleCategories(): void {
    this.showMoreBtn = !this.showMoreBtn
    const el = this.$refs.categories as any
    el.style['max-height'] =
      el.style['max-height'] === '100vh' ? '110px' : '100vh'
  }

  closeDialog(): void {
    this.$emit('close')
    this.searchTerm = ''
  }

  public toggleDialog(): void {
    const dialog = this.$refs.lDialog as LDialog
    if (dialog) {
      dialog.toggleDialog()
    }
  }
}

export default NearbyPlacesModal
