
import { Component, Vue } from 'vue-property-decorator'

import ProfileHeader from '@/components/user/settings/ProfileHeader.vue'
import ProfileExpansionPanel from '@/components/user/settings/expansionPanels/ProfileExpansionPanel.vue'
import ProSubscriptionExpansionPanel from '@/components/user/settings/expansionPanels/ProSubscriptionExpansionPanel.vue'
import PackingListsExpansionPanel from '@/components/user/settings/expansionPanels/PackingListsExpansionPanel.vue'
import InvitationExpansionPanel from '@/components/user/settings/expansionPanels/InvitationExpansionPanel.vue'
import SocialExpansionPanel from '@/components/user/settings/expansionPanels/SocialExpansionPanel.vue'
import FeedbackExpansionPanel from '@/components/user/settings/expansionPanels/FeedbackExpansionPanel.vue'
import FaqExpansionPanel from '@/components/user/settings/expansionPanels/FaqExpansionPanel.vue'
import LegalExpansionPanel from '@/components/user/settings/expansionPanels/LegalExpansionPanel.vue'

@Component({
  name: 'UserSettings',
  components: {
    ProfileHeader,
    ProfileExpansionPanel,
    ProSubscriptionExpansionPanel,
    PackingListsExpansionPanel,
    InvitationExpansionPanel,
    SocialExpansionPanel,
    FeedbackExpansionPanel,
    FaqExpansionPanel,
    LegalExpansionPanel,
  },
})
class UserSettings extends Vue {}

export default UserSettings
