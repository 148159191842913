
import { Vue, Component } from 'vue-property-decorator'
import { tripModule, eventModule } from '@/store'

@Component({
  name: 'GpxExportModal',
})
class GpxExportModal extends Vue {
  get trip() {
    return tripModule.trip!
  }

  exportTripAsGpx() {
    tripModule.exportTripAsGpx(this.trip.id)
    eventModule.toggleExportStartedPrompt()
  }
}
export default GpxExportModal
