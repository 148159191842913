import Vue from 'vue'
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query'
import { Context } from '@nuxt/types'

export default (context: Context) => {
  const queryClient = new QueryClient()
  const options = { queryClient }

  Vue.use(VueQueryPlugin, options)

  if (process.client) {
    if (context.nuxtState && context.nuxtState.vueQueryState) {
      // FIXME: Disable for now until the quaryCache issue is solved
      // see: https://github.com/TanStack/query/issues/7877
      // hydrate(queryClient, context.nuxtState.vueQueryState)
    }
  }
}
