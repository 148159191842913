
import { QueryClient, useQueryClient } from '@tanstack/vue-query'
import { Component, Prop, Vue } from 'vue-property-decorator'

import CarForm from '@/components/trip/transportation/forms/CarForm.vue'
import FlightForm from '@/components/trip/transportation/forms/FlightForm.vue'
import TrainForm from '@/components/trip/transportation/forms/TrainForm.vue'
import BusForm from '@/components/trip/transportation/forms/BusForm.vue'
import FerryForm from '@/components/trip/transportation/forms/FerryForm.vue'
import BicycleForm from '@/components/trip/transportation/forms/BicycleForm.vue'
import WalkingForm from '@/components/trip/transportation/forms/WalkingForm.vue'
import GenericForm from '@/components/trip/transportation/forms/GenericForm.vue'
import { eventModule, transportationModule, tripModule } from '@/store'
import { tripQueryKeys } from '@/composables/trip'

@Component({
  name: 'FormDialog',
  components: {
    CarForm,
    FlightForm,
    TrainForm,
    BusForm,
    FerryForm,
    BicycleForm,
    WalkingForm,
    GenericForm,
  },
  setup() {
    const queryClient = useQueryClient()
    return { queryClient }
  },
})
class FormDialog extends Vue {
  @Prop() readonly title?: string
  @Prop() readonly component?: string
  @Prop({ default: false, type: Boolean }) readonly value!: boolean
  @Prop({ default: true, type: Boolean }) readonly resetOnClose!: boolean
  queryClient!: QueryClient

  close(): void {
    this.$emit('update:value', false)

    if (this.resetOnClose) {
      transportationModule.setOriginWaypoint(null)
      transportationModule.setDestinationWaypoint(null)
    }
  }

  saveTransportationsForm(): void {
    transportationModule.createBooking(transportationModule.booking)

    if (tripModule.trip) {
      this.queryClient.invalidateQueries({
        queryKey: tripQueryKeys.summary(tripModule.trip.id),
      })
    }
    this.$emit('update:value', false)
  }

  openTransportationsForm(type: string): void {
    eventModule.toggleTransportationForm(type)
    this.$emit('update:value', false)
  }
}
export default FormDialog
