import { set } from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { cloneDeep } from 'lodash'

import { waypointModule } from '.'
import { Photo } from '@/types/photo'
import {
  downloadFile,
  isBrowser,
  nameBasedSorting,
  sortByCreatedAt,
} from '@/utils/utility-manager'
import { DocumentSortMode, GridContentType } from '@/utils/enums'

@Module({ name: 'photo', stateFactory: true, namespaced: true })
export default class photoModule extends VuexModule {
  private allPhotos: Photo[] = []
  private currentPhoto: Photo | null = null
  private currentDirectoryId: string | null = null
  private currentSortingMode = DocumentSortMode.BY_DATE

  public get file() {
    return this.currentPhoto
  }

  public get files() {
    const files = cloneDeep(this.allPhotos)
    if (this.sortingMode === DocumentSortMode.BY_DATE) {
      files.sort((a, b) => {
        return sortByCreatedAt(a, b)
      })
    } else if (this.sortingMode === DocumentSortMode.BY_FILE_TYPE) {
      files.sort((a, b) => {
        if (a.type && b.type) {
          return nameBasedSorting(a.type, b.type)
        }

        return -1
      })
    }

    return files
  }

  public get currentDirectory() {
    return this.currentDirectoryId
  }

  public get filesByWaypoint() {
    if (!waypointModule.waypoint) {
      return []
    }

    return this.allPhotos.filter(
      (photo) =>
        photo.reference && photo.reference === waypointModule.waypoint?._id,
    )
  }

  public get sortingMode(): string {
    return this.currentSortingMode
  }

  @Mutation
  private setPhoto(payload: Photo) {
    set(this, 'currentPhoto', payload)
  }

  @Mutation
  private setParentDirectoryId(payload: {
    documentId: string
    newParentDirectoryId: string
  }) {
    const photoIndex = this.allPhotos.findIndex(
      (file) => file._id === payload.documentId,
    )
    if (photoIndex > -1) {
      set(
        this.allPhotos[photoIndex],
        'parentDirectoryId',
        payload.newParentDirectoryId,
      )
    }
  }

  @Mutation
  private updatePhoto(photo: Photo) {
    const photoIndex = this.allPhotos.findIndex(
      (file) => file._id === photo._id,
    )

    if (photoIndex > -1) {
      this.allPhotos.splice(photoIndex, 1, photo)
    }
  }

  @Mutation
  private removePhoto(fileId: string) {
    const photoIndex = this.allPhotos.findIndex((file) => file._id === fileId)

    if (photoIndex > -1) {
      this.allPhotos.splice(photoIndex, 1)
    }
  }

  @Mutation
  private setPhotos(payload: Photo[]) {
    set(this, 'allPhotos', [...payload])
  }

  @Mutation
  private setDirectory(payload: string | null) {
    set(this, 'currentDirectoryId', payload)
  }

  @Mutation
  private addPhoto(photo: Photo) {
    this.allPhotos.push(photo)
  }

  @Mutation
  public setSortingMode(mode: string) {
    set(this, 'currentSortingMode', mode)

    if (isBrowser()) {
      localStorage.setItem(
        `kLambusPreferredDocumentSortMode_${GridContentType.PHOTO}`,
        mode,
      )
    }
  }

  @Action({ rawError: true })
  public async fetch({ id, fileId }: any) {
    try {
      const { data } = await this.$axios.get(`/api/trips/${id}/photos`)
      this.context.commit(
        'setPhoto',
        data.photos.find((el: Photo) => el._id === fileId),
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.photo.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchByTrip(id: string) {
    try {
      const { data } = await this.$axios.get(`/api/trips/${id}/photos`)
      this.context.commit('setPhotos', data.photos)
    } catch (error) {
      this.changeDir()
      this.context.dispatch('event/newError', 'error.photo.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async create({ id, params, showSuccessMessage = true }: any) {
    try {
      const { data } = await this.$axios.post(`/api/trips/${id}/photos`, params)
      this.context.commit('addPhoto', data.photo)
      this.context.commit('setPhoto', data.photo)

      if (showSuccessMessage) {
        this.context.dispatch('event/newMessage', 'success.photo.create', {
          root: true,
        })
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.photo.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async createNewFolder({ id, params }: any) {
    try {
      const { data } = await this.$axios.post(`/api/trips/${id}/photos`, params)
      this.context.commit('setPhoto', data.photo)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.directory_create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async update({ id, fileId, params }: any) {
    try {
      const { data } = await this.$axios.put(
        `/api/trips/${id}/photos/${fileId}`,
        params,
      )
      this.context.commit('updatePhoto', data.photo)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.photo.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async move({ id, params }: any) {
    try {
      await this.$axios.put(`/api/trips/${id}/photos/move/bulk`, params)

      for (const documentId of params.documentIds) {
        this.context.commit('setParentDirectoryId', {
          documentId,
          newParentDirectoryId: params.parentDirectoryId,
        })
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.photo.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async delete({ id, fileId }: any) {
    try {
      await this.$axios.delete(`/api/trips/${id}/photos/${fileId}`)

      this.context.commit('removePhoto', fileId)

      if (this.files.length > 0) {
        this.context.commit('setPhoto', this.files[0])
      } else {
        this.context.commit('setPhoto', {})
      }
      this.context.dispatch('event/newMessage', 'success.photo.delete', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.photo.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public changeDir(id?: string) {
    id
      ? this.context.commit('setDirectory', id)
      : this.context.commit('setDirectory', null)
  }

  @Action({ rawError: true })
  public setDummy(dummy: Photo) {
    this.context.commit('setPhoto', dummy)
  }

  @Action({ rawError: true })
  public setFile(file: Photo) {
    this.context.commit('setPhoto', file)
  }

  @Action({ rawError: true })
  public download() {
    downloadFile(this.$axios, 'photos', this.currentPhoto!)
  }
}
