
import { Component, Vue } from 'vue-property-decorator'
import { format } from 'date-fns'

import PasswordPrompt from '@/components/user/settings/PasswordPrompt.vue'
import LConfirmDialog from '@/global-components/LConfirmDialog.vue'
import { authModule, eventModule } from '@/store'
import { User } from '@/types/user'
import { TextFieldRules } from '@/utils/enums'
import { getLocalStorageString, isBrowser } from '@/utils/utility-manager'
import { formattedDate } from '@/utils/date-utils'
@Component({
  name: 'ProfileExpansionPanel',
  components: {
    PasswordPrompt,
    LConfirmDialog,
  },
})
class ProfileExpansionPanel extends Vue {
  rules = TextFieldRules
  localUser: User = JSON.parse(JSON.stringify(authModule.user))
  showCalendar = false
  selectedDateFormat = ''

  countries = Object.keys(require('~/assets/json/_countries/en.json')).map(
    (el) => {
      return { code: el, text: require('~/assets/json/_countries/en.json')[el] }
    },
  )

  dateFormats: { text: string; value: string }[] = [
    { text: 'DD.MM.YYYY', value: 'dd.MM.yyyy' },
    { text: 'DD/MM/YYYY', value: 'dd/MM/yyyy' },
    { text: 'MM/DD/YYYY', value: 'MM/dd/yyyy' },
    { text: 'DD/MM/YYYY', value: 'dd/MM/yyyy' },
    { text: 'YYYY/MM/DD', value: 'yyyy/MM/dd' },
  ]

  get dateFormatItems() {
    const today = new Date()
    const values = this.dateFormats.map((_format) => {
      return {
        text: `${_format.text} (${format(today, _format.value)})`,
        value: _format.value,
      }
    })

    values.splice(0, 0, {
      text: `${this.$t('systemFormat')} (${formattedDate(today)})`,
      value: 'system',
    })

    return values
  }

  get user() {
    this.localUser = JSON.parse(JSON.stringify(authModule.user))
    return authModule.user
  }

  get userBirthDate() {
    return this.localUser.birthDate
      ? formattedDate(this.localUser.birthDate)
      : ''
  }

  get userCountryCode() {
    return this.localUser.country?.code || ''
  }

  set userCountryCode(code: string) {
    if (this.localUser.country) {
      Object.assign(this.localUser.country, { code })
    } else {
      Object.assign(this.localUser, { country: { code } })
    }
  }

  mounted() {
    this.selectedDateFormat = getLocalStorageString(
      'kLambusPreferredDateFormat',
      'system',
    )
  }

  setSelectedDateFormat(format: string): void {
    if (isBrowser()) {
      localStorage.setItem('kLambusPreferredDateFormat', format)
    }
  }

  showPasswordPrompt(): void {
    const dialog = this.$refs.passwordPrompt as PasswordPrompt
    setTimeout(() => {
      if (dialog) dialog.toggleDialog()
    })
  }

  showDeleteAccountPrompt(): void {
    const dialog = this.$refs.confirmDialog as LConfirmDialog
    if (dialog) {
      dialog.toggleDialog()
    }
  }

  async deleteAccount(): Promise<void> {
    try {
      const result = await this.$axios.delete(`/api/users/${this.user.id}`, {
        params: { userId: this.user.id },
      })

      if (result.data.success) {
        authModule.logout()
      }
    } catch (error) {
      eventModule.newError('error.user.delete')
    }
  }

  async updateProfile() {
    // Use picture identifier instead of cdn link
    const pictureIdentifier = this.localUser.picture.split('/').pop()
    if (pictureIdentifier) {
      this.localUser.picture = pictureIdentifier
    }

    // Use country code instead of object
    if (this.localUser.country.code) {
      ;(this.localUser.country as any) = this.localUser.country.code
    }

    // password is updated with a prompt
    this.$delete(this.localUser, 'password')

    // update profile
    await authModule.updateProfile(this.localUser)
  }
}
export default ProfileExpansionPanel
