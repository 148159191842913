
import { Component, Vue, Prop } from 'vue-property-decorator'
import { userModule } from '@/store'
import LConfirmDialog from '@/global-components/LConfirmDialog.vue'

@Component({
  name: 'GpsBasedCurrenciesModal',
  components: { LConfirmDialog },
})
class GpsBasedCurrenciesModal extends Vue {
  @Prop({ default: false, type: Boolean }) readonly value!: boolean

  showDialog = false

  get dialogValue(): boolean {
    return this.value || this.showDialog
  }

  set dialogValue(value: boolean) {
    this.$emit('update:value', value)
    this.showDialog = value
  }

  emitGeoLocation(): void {
    userModule.getUserLocation((position: GeolocationPosition) => {
      this.$emit('click:confirm', position)
      userModule.setUserLocation(position)
    })
  }
}

export default GpsBasedCurrenciesModal
