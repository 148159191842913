module.exports = {
  pro: {
    checkout: {
      title: 'Aggiornamento a Lambus PRO',
      description:
        'Sblocca la funzionalità premium per rendere la tua organizzazione dei viaggi ancora più semplice! 🎉',
      payPerTrip: {
        title: 'Paga per ogni viaggio',
        caption: 'Aggiorna un viaggi (per tutti)',
      },
      yearlySubscription: {
        title: 'Abbonamento annuale',
        caption: 'Aggiorna tutti i tuoi viaggi',
        freeTrial: 'PROVA 7 GIORNI GRATIS!',
      },
      success: {
        title: 'Lambus PRO: Acquisto riuscito!',
      },
      learnMore: 'Scopri di più su Lambus PRO',
      upgradeNowUpper: 'AGGIORNA ADESSO 🚀',
      thankYouNotice:
        'Grazie per aver acquistato Lambus PRO! ❤️ Questa pagina si chiuderà automaticamente tra {time} secondi.',
    },
    features: {
      routePlanning: {
        title: 'Estendi la organizzazione del viaggio',
        description:
          'Calcola il tempo e la distanza esatta tra tutti i tuoi punti di via',
      },
      googleMaps: {
        title: 'Ricerca su Google Maps',
        description: 'Usiamo Google Maps per trovare ancora più waypoint',
      },
      videoChat: {
        title: 'Video Chat',
        description:
          'Chatta con gli altri partecipanti mentre organizzi il tuo viaggio',
      },
      moneyPool: {
        title: 'Soldi in Comune',
        description: 'Gestisci i soldi del viaggio in un posto centrale',
      },
      exportTripGpx: {
        title: 'Esportazione come GPX',
        description:
          'Esporta il tuo viaggio come GPX e usalo nelle app di monitoraggio',
      },
      currencies: {
        title: 'Valuta automatica',
        description:
          'Converti le valute straniere nella tua valuta utilizzando il tasso di cambio corrente',
      },
      weather: {
        title: 'Aggiornamenti meteo in diretta',
        description: 'Mostra il meteo attuale per tutti i tuoi punti di via',
      },
      exportExpenses: {
        title: 'Esporta le spese',
        description: 'Esporta automaticamente le tue spese in Excel',
      },
      flightStatus: {
        title: 'Aggiornamenti di volo in diretta',
        description:
          'Ricevi una notifica in caso di ritardo, annullamento o modificazione del volo',
      },
      exportTrip: {
        title: 'Esportazione come PDF',
        description:
          'Esporta il tuo viaggio come PDF per averlo sempre a portata di mano',
      },
      support: {
        title: 'Sostienici ❤️',
        description:
          'Con il tuo aiuto, Lambus può rimanere senza pubblicità e sicuro a lungo termine',
      },
      packingList: {
        title: 'Liste (imballaggio, cose da fare)',
        description: 'Scegli tra le liste esistenti o crea le tue',
      },
      flightCompensation: {
        title: 'Compensazione del Volo',
        description:
          'Chiedi automaticamente un risarcimento se il tuo volo viene cancellato',
      },
      privateDocuments: {
        title: 'Documenti privati',
        description:
          'Conserva in modo sicuro passaporto, patente di guida e molto altro',
      },
      offlineMode: {
        title: 'Modalità Offline',
        description:
          'Accedi a tutto il tuo viaggio anche senza una connessione internet',
      },
      calendarSync: {
        title: 'Sincronizzazione del calendario',
        description: 'Sincronizza i waypoint e i POI con il tuo calendario',
      },
    },
  },
  sortExpensesBy: 'Ordina le spese per …',
  expenseCreated: 'Spesa creata',
  expenseDate: 'Data della spesa',
  canoe_tour: 'Giro in canoa',
  hiking: 'Escursionismo',
  camping: 'Campeggio',
  beach: 'Spiaggia',
  swimming: 'Nuoto',
  boat_tour: 'Giro in barca',
  campfire: 'Falo',
  party: 'Festa',
  bike_tour: 'Giro in bicicletta',
  surfing: 'Surf',
  snorkeling: 'Snorkeling',
  picknick: 'Picnic',
  food: 'Cibo',
  horse_riding: 'Equitazione',
  sport_event: 'Evento sportivo',
  concert: 'Concerto',
  cinema: 'Cinema',
  winter_sports: 'Sport invernali',
  searchActivitiesAndPlaces: 'Ricerca nei luoghi e nelle attività salvate',
}
