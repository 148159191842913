
import { Vue, Component } from 'vue-property-decorator'

import { tripModule, eventModule } from '@/store'
import { DistanceUnitMap, DistanceUnit } from '@/utils/enums'

@Component({
  name: 'TripExportModal',
})
class TripExportModal extends Vue {
  allFeaturesSelected = true

  features = [
    { name: 'transportation', selected: true },
    { name: 'accommodation', selected: true },
    { name: 'dailyPlan', selected: true },
    { name: 'lists', selected: true },
    { name: 'expenses', selected: true },
    { name: 'notes', selected: true },
  ]

  get trip() {
    return tripModule.trip!
  }

  get selectAllBtnText(): string {
    return this.allFeaturesSelected
      ? this.$t('deselectAll').toString()
      : this.$t('selectAll').toString()
  }

  exportTrip() {
    const fields = this.features.reduce((items: any, feature) => {
      items[feature.name] = feature.selected
      return items
    }, {})

    const distanceUnit = localStorage.getItem(
      `kLambusShowPolylineDistanceMetric_${this.trip.id}`,
    )

    const isDistanceUnit = (
      unit: string | null,
    ): unit is keyof typeof DistanceUnitMap => {
      return !!unit && Object.values(DistanceUnit).includes(unit)
    }

    const unit = isDistanceUnit(distanceUnit)
      ? DistanceUnitMap[distanceUnit]
      : DistanceUnitMap.kilometers

    fields.documents = false

    tripModule.exportTrip({
      tripId: this.trip.id,
      params: { fields, distanceUnit: unit },
    })
    this.closeDialog()

    setTimeout(() => {
      eventModule.toggleExportStartedPrompt()
    }, 500)
  }

  closeDialog(): void {
    this.$emit('update:value', false)
  }

  setAllCheckboxes(): void {
    this.features.forEach((feature) => {
      feature.selected = !this.allFeaturesSelected
    })

    this.allFeaturesSelected = !this.allFeaturesSelected
  }
}
export default TripExportModal
