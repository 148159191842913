
import { Component, Vue, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import { transportationModule } from '@/store'
import { Airport } from '@/types/transportation'

@Component({
  name: 'AirportSearch',
})
class AirportSearch extends Vue {
  airports: Airport[] = []
  searchTerm = ''
  select: any = null
  loading = false
  debounceSearch = _.debounce(
    async (val: string, context: Vue): Promise<void> => {
      if (val) {
        await transportationModule.airportsBySearchTerm({
          q: val,
        })
        const obj = transportationModule.airports
        const result = Object.keys(obj).map((key: string) => {
          return {
            code: key,
            name: obj[key],
          }
        })
        this.$set(context, 'airports', result)
      }
      this.$set(context, 'loading', false)
    },
    1000,
  )

  @Watch('searchTerm')
  searchAirport(val: string): void {
    if (val?.length > 0) {
      this.loading = true
      this.debounceSearch(val, this)
    } else {
      this.airports.splice(0, this.airports.length)
    }
  }

  @Watch('select')
  selectAirport(): void {
    this.$emit('select', this.select)
    this.searchTerm = ''
  }
}
export default AirportSearch
