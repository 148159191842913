
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'
// eslint-disable-next-line import/named
import { cloneDeep } from 'lodash'

import { computed } from 'vue'
import LDialog from '@/global-components/LDialog.vue'
import LConfirmDialog from '@/global-components/LConfirmDialog.vue'

import AccommodationSlideOver from '@/components/trip/accommodation/AccommodationSlideOver.vue'
import AddTransportationAlert from '@/components/trip/transportation/AddTransportationAlert.vue'
import TransportationPlanItem from '@/components/trip/transportation/TransportationPlanItem.vue'

import IndexAvatar from '@/components/trip/travelPlan/IndexAvatar.vue'
import AccommodationPlanItem from '@/components/trip/travelPlan/AccommodationPlanItem.vue'
import POIListItem from '@/components/trip/travelPlan/waypoint/pointOfInterest/POIListItem.vue'
import ActivityTravelPlanListItem from '@/components/trip/travelPlan/waypoint/activity/ActivityTravelPlanListItem.vue'
import ActivityDialog from '@/components/trip/travelPlan/waypoint/activity/ActivityDialog.vue'
import { useDeleteActivity } from '@/composables/waypoint'
import {
  tripModule,
  discoverModule,
  waypointModule,
  eventModule,
  transportationModule,
  accommodationModule,
} from '@/store'
import { Waypoint, DateRange, Trip } from '@/types/trip'
import { Inspiration } from '@/types/discover'
import { Accommodation } from '@/types/accommodation'
import { logEvent, findRelatedBookings } from '@/utils/utility-manager'
import { localizedDateRange } from '@/utils/date-utils'
import { EmptyStateType, WaypointModalType } from '@/utils/enums'

@Component({
  components: {
    POIListItem,
    IndexAvatar,
    AccommodationSlideOver,
    TransportationPlanItem,
    AccommodationPlanItem,
    LDialog,
    draggable,
    AddTransportationAlert,
    ActivityTravelPlanListItem,
    ActivityDialog,
  },
  setup(props) {
    const trip = computed(() => {
      if (props.isInspiration) {
        return discoverModule.currentInspiration!
      } else if (!tripModule.trip) {
        throw new Error('No trip selected')
      }
      return tripModule.trip
    })

    const deleteActivityMutation = useDeleteActivity(trip.value.id)

    return { deleteActivityMutation }
  },
})
class TravelPlanList extends Vue {
  @Prop({ default: false, type: Boolean }) readonly showEditor!: boolean
  @Prop({ default: false, type: Boolean }) readonly dragAndDrop!: boolean
  @Prop({ default: false, type: Boolean }) readonly waypointsPreview!: boolean
  @Prop({ default: false, type: Boolean }) readonly waypointsOnly!: boolean
  @Prop({ default: false, type: Boolean }) readonly isInspiration!: boolean
  @Prop({ default: true, type: Boolean }) readonly waypointCardBorder!: boolean
  @Prop({ default: false, type: Boolean }) readonly showAllPlaces!: boolean

  emptyState: string = EmptyStateType.TRAVELPLAN
  timestamp: number = new Date().getTime()
  currentConnectionIndices: number[] = []
  currentPayload: any = {}
  travelplan: Waypoint[] = this.trip ? this.trip.waypoints : []
  travelPlanBookings: any[] = []
  showMorePlaces: boolean[] = []
  waypointModalType = WaypointModalType

  currentAccommodation: Accommodation | null = null
  showAccommodationDialog = false

  deleteActivityMutation!: ReturnType<typeof useDeleteActivity>

  get trip(): Trip | Inspiration | null {
    if (this.isInspiration) {
      return discoverModule.currentInspiration!
    }
    return tripModule.trip
  }

  get formattedBookings(): any {
    return transportationModule.formattedAllBookings
  }

  get accommodations(): Accommodation[] {
    return accommodationModule.accommodations
  }

  get currentTravelPlan(): Waypoint[] {
    return this.waypointsPreview
      ? waypointModule.optimizedWaypoints
      : this.travelplan
  }

  get currentWaypointModalType(): string {
    return eventModule.waypointModalType
  }

  get isDiscoverRoute(): boolean {
    return this.$route?.name?.startsWith('discover') || false
  }

  get showDottedLine(): boolean {
    return (
      (this.trip?.waypoints || []).length >= 2 &&
      !this.waypointsOnly &&
      this.showEditor
    )
  }

  get waypointDateClass(): string {
    if (this.showEditor) {
      return 'body-2 grey--text waypoint-date-shift mt-n6'
    } else {
      return 'body-2 grey--text pl-3 ml-12 mt-n6'
    }
  }

  set currentTravelPlan(value: Waypoint[]) {
    this.travelplan = value
  }

  get planObjects() {
    return [this.trip, this.formattedBookings]
  }

  @Watch('planObjects')
  resetTravelplan() {
    if (this.trip) {
      this.currentTravelPlan = this.trip.waypoints
      this.travelPlanBookings = []

      for (const waypoint of this.travelplan) {
        this.findtravelPlanBookings(waypoint._id)
      }

      this.rerenderTravelplan()
    }
  }

  created() {
    for (const waypoint of this.travelplan) {
      this.showMorePlaces.push(false)
      this.findtravelPlanBookings(waypoint._id)
    }
  }

  rerenderTravelplan() {
    this.timestamp = new Date().getTime()
  }

  async dropWaypoint(evt: any): Promise<void> {
    if (!this.trip || evt.newIndex === null || evt.oldIndex === evt.newIndex) {
      return
    }

    if (evt.newIndex < 0) {
      evt.newIndex = 0
    } else if (evt.newIndex >= this.trip.waypoints.length) {
      evt.newIndex = this.trip.waypoints.length - 1
    }

    this.currentPayload = {
      sourceSectionIndex: evt.oldIndex,
      sourceItemIndex: 0,
      targetSectionIndex: evt.newIndex,
      targetItemIndex: 0,
    }

    const movedWaypointId =
      this.trip.waypoints[this.currentPayload.sourceSectionIndex]._id

    // check if moved waypoint was part of a booking
    const bookingIndex = this.formattedBookings.findIndex(
      (booking: any) =>
        booking.origin?._id === movedWaypointId ||
        booking.destination?._id === movedWaypointId,
    )

    if (bookingIndex !== -1) {
      // if moved waypoint is part of a booking, ask to remove the booking and proceed with rearranging
      this.toogleUnlinkPrompt()
    } else {
      // if moved waypoint is not part of a booking, proceed with rearranging
      await this.rearrangeWaypoints(this.currentPayload)
    }
  }

  async rearrangePois(event: any, waypointIndex: number): Promise<void> {
    if (event.oldIndex === event.newIndex) {
      return
    }

    const payload = {
      sourceSectionIndex: waypointIndex,
      sourceItemIndex: event.oldIndex! + 1,
      targetSectionIndex: waypointIndex,
      targetItemIndex: event.newIndex! + 1,
    }

    await waypointModule.rearrange(payload)
  }

  async rearrangeWaypoints(payload: any): Promise<void> {
    await waypointModule.rearrange(payload)
    if (this.trip) {
      await tripModule.fetch(this.trip.id)

      if (tripModule.trip) {
        transportationModule.fetchAllBookings(tripModule.trip)
      }
      this.currentTravelPlan = this.trip.waypoints
      this.rerenderTravelplan()
    }
  }

  async deleteConnnection(): Promise<void> {
    if (this.trip) {
      for (const index of this.currentConnectionIndices) {
        await transportationModule.deleteConnection({
          trip: this.trip.id,
          booking: this.trip.waypoints[index],
        })
      }
      this.currentConnectionIndices = []
      await this.rearrangeWaypoints(this.currentPayload)
    }
  }

  async deleteWaypoint(index: number): Promise<void> {
    if (this.trip) {
      const waypointId = this.trip.waypoints[index]._id
      await waypointModule.delete(waypointId)

      if (this.trip.waypoints.length === 0) {
        this.$emit('update:showEditor', false)
      }
    }
  }

  async deletePoi(wIndex: number, pIndex: number): Promise<void> {
    if (this.trip) {
      const waypointId = this.trip.waypoints[wIndex]._id
      const pointOfInterestsId =
        this.trip.waypoints[wIndex].pointOfInterests[pIndex]._id
      await waypointModule.deletePOI({ waypointId, pointOfInterestsId })
      tripModule.fetch(this.trip!.id)

      logEvent('removed_highlight')
    }
  }

  deleteActivityById(waypointId: string, activityId: string) {
    if (this.trip) {
      this.deleteActivityMutation.mutate(
        { waypointId, activityId },
        {
          onSuccess: () => {
            tripModule.fetch(this.trip!.id)
            logEvent('removed_highlight')
          },
        },
      )
    }
  }

  setWaypoint(waypoint: Waypoint): void {
    logEvent('current_screen_waypoint_details')

    waypointModule.setWaypoint(waypoint)

    if (!this.waypointsOnly) {
      eventModule.toggleWaypointDetails(true)
    }

    this.$emit('select')
  }

  toogleUnlinkPrompt(): void {
    const dialog = this.$refs.unlinkPrompt as LConfirmDialog
    this.$nextTick(() => {
      if (dialog) dialog.toggleDialog()
    })
  }

  findtravelPlanBookings(waypointId: string): void {
    const relatedBookings: any[] = findRelatedBookings(
      waypointId,
      this.currentTravelPlan,
      this.formattedBookings,
    )

    const waypointIndex = this.currentTravelPlan.findIndex(
      (waypoint) => waypoint._id === waypointId,
    )

    this.travelPlanBookings.push({
      origin: this.currentTravelPlan[waypointIndex],
      destination: this.currentTravelPlan[waypointIndex + 1],
      data: relatedBookings,
    })
  }

  hasLinkedAccommodations(waypointId: string): boolean {
    const linkedAccommodation = this.accommodations.find(
      (accommodation) => accommodation.waypoint?._id === waypointId,
    )

    return !!linkedAccommodation
  }

  findAccommodations(waypointId: string): Accommodation[] {
    const relatedAccommodation = this.accommodations.filter(
      (accommodation) => accommodation.waypoint?._id === waypointId,
    )

    return relatedAccommodation || null
  }

  openAccommodationDialog(accommodation: Accommodation): void {
    this.currentAccommodation = accommodation
    this.showAccommodationDialog = true
  }

  togglePlacesList(waypoint: number): void {
    const showMorePlacesCopy = cloneDeep(this.showMorePlaces)
    showMorePlacesCopy[waypoint] = !showMorePlacesCopy[waypoint]
    Vue.set(this, 'showMorePlaces', showMorePlacesCopy)
  }

  hideWaypointInList(index: number): boolean {
    if (index === this.currentTravelPlan.length - 1) {
      if (
        this.currentWaypointModalType ===
        this.waypointModalType.TRANSPORTATION_MODAL
      ) {
        return true
      }
    }
    return false
  }

  dateString(startDate?: DateRange, endDate?: DateRange): string {
    return localizedDateRange(startDate, endDate)
  }
}

export default TravelPlanList
