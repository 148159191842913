import { Organization, User } from '@/types/user.js'

/**
 * Returns the last active organization of the given user.
 *
 * If none can be found it returns the default organization.
 */
export function getLastActiveOrganization(user: User) {
  const organizations = user.memberships.map((om) => om.organization)
  const organization = organizations.find(
    (o) => o._id === user.lastActiveOrganizationId,
  )

  return organization ?? defaultOrganization(organizations)
}

/**
 * Returns the default organization from the given list of organizations.
 *
 * If no default organization is found it throws.
 */
export function defaultOrganization(organizations: Organization[]) {
  const defaultOrg = organizations.find((o) => o.default)
  if (!defaultOrg) {
    throw new Error('Could not find default organization')
  }

  return defaultOrg
}
