
import { Component, Vue, Watch } from 'vue-property-decorator'
import { eventModule } from '@/store'
import LConfirmDialog from '@/global-components/LConfirmDialog.vue'

@Component({
  name: 'ExportStartedPrompt',
})
class ExportStartedPrompt extends Vue {
  get showExportStartedPrompt(): boolean {
    return eventModule.exportStartedPrompt
  }

  @Watch('showExportStartedPrompt')
  toggleShowExportStartedPrompt(): void {
    if (!this.showExportStartedPrompt) {
      return
    }
    this.toggleDialog()
  }

  toggleDialog(): void {
    const dialog = this.$refs.exportStarted as LConfirmDialog
    dialog.toggleDialog()
  }
}
export default ExportStartedPrompt
