
import { Component, Vue } from 'vue-property-decorator'
import LDialog from '@/global-components/LDialog.vue'
import { authModule } from '@/store'
import { TextFieldRules } from '@/utils/enums'
import ClipboardTextField from '@/components/ClipboardTextField.vue'

@Component({
  name: 'UserSettings',
  components: {
    ClipboardTextField,
  },
})
class UserSettings extends Vue {
  oldPassword = ''
  newPassword = ''
  newPasswordConfirmation = ''
  showPasswordPrompt = false
  rules = TextFieldRules

  get user() {
    return authModule.user
  }

  public toggleDialog(): void {
    const dialog = this.$refs.lDialog as LDialog
    if (dialog) dialog.toggleDialog()
  }

  updatePassword() {
    authModule.updatePassword({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
    })
  }
}

export default UserSettings
