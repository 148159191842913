
import { differenceInDays } from 'date-fns'
import { isEmpty } from 'lodash'
import { Component, Watch, Vue } from 'vue-property-decorator'

import LButton from '@/components/core/Button.vue'
import LCombobox from '@/components/core/Combobox.vue'
import DateRangePickerModal from '@/components/datepicker/DateRangePickerModal.vue'
import WaypointSortingDialog from '@/components/trip/settings/WaypointSortingDialog.vue'
import ManageFeatureExpansion from '@/components/trip/settings/expansionPanels/ManageFeatureExpansion.vue'
import ShareTripContent from '@/components/trip/settings/ShareTripContent.vue'
import { eventModule, tripModule } from '@/store'
import { DatePicker } from '@/types/trip'
import { KeyValueBoolean } from '@/types/types'
import { dateRange, formattedDateRange } from '@/utils/date-utils'
import { DatePickerType, WaypointAlgorithm } from '@/utils/enums'
import { getCurrencyArray, logEvent } from '@/utils/utility-manager'

@Component({
  name: 'GeneralExpansionPanel',
  components: {
    ManageFeatureExpansion,
    DateRangePickerModal,
    WaypointSortingDialog,
    ShareTripContent,
    LCombobox,
    LButton,
  },
})
class GeneralExpansionPanel extends Vue {
  showWaypointSortingDialog = false
  showDateShiftDialog = false
  generalExpansionPanel: number | null = null
  dates: DatePicker | null = null
  startDateDifference = 0

  notificationItems: KeyValueBoolean[] = [
    {
      key: 'invite',
      value: true,
    },
    {
      key: 'waypoint',
      value: true,
    },
    {
      key: 'daily_plan',
      value: true,
    },
    {
      key: 'highlight',
      value: true,
    },
    {
      key: 'expense',
      value: true,
    },
    {
      key: 'document',
      value: true,
    },
    {
      key: 'photo',
      value: true,
    },
    {
      key: 'note',
      value: true,
    },
    {
      key: 'chat',
      value: true,
    },
  ]

  currencies = getCurrencyArray()

  tripCurrency: string = this.trip.currency

  get trip() {
    return tripModule.trip!
  }

  get waypointAlgorithm(): string {
    return this.trip.waypointAlgorithm === WaypointAlgorithm.NONE
      ? this.$t('manual').toString()
      : this.$t('byDate').toString()
  }

  get formattedDateRange(): string {
    if (!this.trip.startDate) {
      return this.$t('noDate').toString()
    }

    const dates = [this.trip.startDate.value]

    if (this.trip.endDate?.value) {
      dates.push(this.trip.endDate.value)
    }

    return formattedDateRange(dates, this.trip.startDate.type)
  }

  currencyLabel(countryCode: string): string {
    const currency = this.currencies.find((c) => c.code === countryCode)
    return currency ? `${currency.name} (${currency.symbol})` : ''
  }

  mounted() {
    tripModule.getTripShare()
  }

  async created() {
    const settings = await tripModule.notifications()
    if (isEmpty(settings)) {
      return
    }

    this.notificationItems.forEach((el) => {
      if (settings[el.key] && el.value !== settings[el.key].added) {
        el.value = settings[el.key].added
      }
    })
  }

  saveNotificationSettings(): void {
    const notificationSetting = this.notificationItems.reduce(
      (accum, currentVal) => {
        accum[currentVal.key] = currentVal.value
        return accum
      },
      {} as any, // TODO: find the right type for this
    )
    tripModule.updateNotifications({
      settings: notificationSetting,
    })
  }

  @Watch('tripCurrency')
  updateTripCurrency(): void {
    tripModule.updateTrip({ currency: this.tripCurrency })
  }

  openDateShiftDialog(data: DatePicker): void {
    this.dates = data

    if (this.trip?.startDate?.value) {
      const sourceDates =
        this.dates.type === DatePickerType.SPECIFIC
          ? this.dates.dates
          : this.dates.months

      this.startDateDifference = Math.abs(
        differenceInDays(
          new Date(this.trip.startDate.value),
          new Date(sourceDates[0]),
        ),
      )

      if (this.startDateDifference > 0) {
        this.showDateShiftDialog = true
      } else {
        this.saveDates()
      }
    } else {
      this.saveDates()
    }
  }

  closeDateShiftDialog(): void {
    this.resetDates()
    logEvent('shift_waypoint_dates_cancelled')
  }

  saveDates(shiftDates = false): void {
    if (!this.dates) {
      eventModule.newError('error.trip.update')
      return
    }

    if (shiftDates) {
      logEvent('shift_waypoint_dates_approved')
    } else {
      logEvent('shift_waypoint_dates_declined')
    }

    const date = dateRange({
      dates:
        this.dates.type === DatePickerType.SPECIFIC
          ? this.dates.dates
          : this.dates.months,
      type: this.dates.type,
    })

    tripModule.updateTrip({ date, shiftDates })

    this.resetDates()
  }

  deleteDates(): void {
    tripModule.updateTrip({
      date: {
        startDate: null,
        endDate: null,
      },
    })

    this.resetDates()
  }

  resetDates(): void {
    this.showDateShiftDialog = false
    this.dates = null
    this.startDateDifference = 0
  }

  openInviteYourFriendsPanel(): void {
    this.$emit('click:invite-btn')
    this.generalExpansionPanel = null
  }
}
export default GeneralExpansionPanel
