
import { Component, Vue } from 'vue-property-decorator'

import SettingsHeader from '@/components/trip/settings/SettingsHeader.vue'
import ProExpansionPanel from '@/components/trip/settings/expansionPanels/ProExpansionPanel.vue'
import GeneralExpansionPanel from '@/components/trip/settings/expansionPanels/GeneralExpansionPanel.vue'
import ExportExpansionPanel from '@/components/trip/settings/expansionPanels/ExportExpansionPanel.vue'
import AttendeesExpansionPanel from '@/components/trip/settings/expansionPanels/AttendeesExpansionPanel.vue'
import ActionsExpansionPanel from '@/components/trip/settings/expansionPanels/ActionsExpansionPanel.vue'

@Component({
  name: 'TripSettings',
  components: {
    SettingsHeader,
    ProExpansionPanel,
    GeneralExpansionPanel,
    ExportExpansionPanel,
    AttendeesExpansionPanel,
    ActionsExpansionPanel,
  },
})
class TripSettings extends Vue {
  openInviteYourFriendsPanel(): void {
    const panel = this.$refs.attendeesExpansionPanel as AttendeesExpansionPanel
    panel.openInviteYourFriendsPanel()
  }

  mounted() {
    this.$root.$on('openInviteYourFriendsPanel', () => {
      this.openInviteYourFriendsPanel()
      setTimeout(() => {
        const panel = this.$refs
          .attendeesExpansionPanel as AttendeesExpansionPanel
        panel.$el.scrollIntoView()
      }, 300)
    })
  }
}

export default TripSettings
