
import { Vue, Component, Prop } from 'vue-property-decorator'

import CardListItem from '@/components/trip/dashboard/CardListItem.vue'
import {
  eventModule,
  transportationModule,
  tripModule,
  routesModule,
} from '@/store'
import { distanceBetweenCoordinates } from '@/utils/utility-manager'
import { formattedDuration } from '@/utils/date-utils'
import { TransportationType } from '@/utils/enums'
import { Trip, Route } from '@/types/trip'
import { GroupedBooking } from '@/types/transportation'

interface BookingDescription {
  booking: any
  originWaypoint: string
  destinationWaypoint: string
  description: string
}

@Component({
  name: 'TransportationPlanItem',
  components: {
    CardListItem,
  },
})
class TransportationPlanItem extends Vue {
  @Prop({ default: false, type: Boolean }) readonly list!: boolean
  @Prop({ default: false, type: Boolean }) readonly editMode!: boolean
  @Prop() readonly originWaypointIndex?: number
  @Prop() readonly bookings?: GroupedBooking
  @Prop({ default: false, type: Boolean }) readonly useSummary!: boolean

  timestamp: number = new Date().getTime()

  bookingDescriptions: BookingDescription[] = []

  routeTransportationType = [
    TransportationType.CAR,
    TransportationType.RENTAL_CAR,
    TransportationType.BUS,
    TransportationType.TRAIN,
    TransportationType.BICYCLE,
    TransportationType.WALKING,
  ]

  get trip(): Trip | null {
    return tripModule.trip
  }

  get displayedBookings() {
    if (!this.bookings) {
      return []
    }
    return this.bookings.data
  }

  transportationIcon(data: any): string {
    return `transport_${data.subType ?? data.type}.png`
  }

  mounted() {
    this.displayedBookings.forEach((booking: any) => {
      this.setBookingDescription(booking)
    })
  }

  async setBookingDescription(booking: any) {
    if (this.originWaypointIndex || this.originWaypointIndex === 0) {
      const isQualifiedForRouteCalculation =
        this.routeTransportationType.includes(booking.type)

      const originWaypoint = this.trip?.waypoints[this.originWaypointIndex]
      const destinationWaypoint =
        this.trip?.waypoints[this.originWaypointIndex + 1]

      if (originWaypoint && destinationWaypoint) {
        const route = (booking.routes || []).find(
          (route: Route) =>
            route.origin === originWaypoint._id &&
            route.destination === destinationWaypoint._id,
        )

        if (
          this.trip?.activeProSubscription &&
          route?.properties.distance &&
          route.properties.duration
        ) {
          const distance = await routesModule.formattedDistance(
            route.properties.distance,
          )

          const duration = formattedDuration(route.properties.duration)

          const description = `${this.$t(
            booking.subType ?? booking.type,
          )} (${distance}, ${duration})`

          this.bookingDescriptions.push({
            booking,
            originWaypoint: originWaypoint._id,
            destinationWaypoint: destinationWaypoint._id,
            description,
          })
        } else if (isQualifiedForRouteCalculation) {
          const estimatedDistance = distanceBetweenCoordinates(
            originWaypoint.location,
            destinationWaypoint.location,
          )

          const distance = await routesModule.formattedDistance(
            estimatedDistance * 1000,
          )

          this.bookingDescriptions.push({
            booking,
            originWaypoint: originWaypoint._id,
            destinationWaypoint: destinationWaypoint._id,
            description: `${this.$t(booking.type)} (~ ${distance})`,
          })
        } else if (booking.summary) {
          this.bookingDescriptions.push({
            booking,
            originWaypoint: originWaypoint._id,
            destinationWaypoint: destinationWaypoint._id,
            description: booking.summary,
          })
        }
      }
    }
  }

  getBookingDescription(booking: any): string {
    if (this.originWaypointIndex || this.originWaypointIndex === 0) {
      const originWaypoint = this.trip?.waypoints[this.originWaypointIndex]
      const destinationWaypoint =
        this.trip?.waypoints[this.originWaypointIndex + 1]

      const bookingDescription = this.bookingDescriptions.find(
        (element) =>
          element.booking._id === booking._id &&
          element.originWaypoint === originWaypoint?._id &&
          element.destinationWaypoint === destinationWaypoint?._id,
      )

      if (bookingDescription) {
        return bookingDescription.description
      }
    }

    return this.$t(booking.subType ?? booking.type).toString()
  }

  onClick(data: any): void {
    transportationModule.setBooking(data)
    eventModule.toggleTransportationForm(data.type)
  }

  showTransportationModal(): void {
    transportationModule.setOriginWaypoint(this.bookings?.origin ?? null)
    transportationModule.setDestinationWaypoint(
      this.bookings?.destination ?? null,
    )
    this.$nextTick(() => {
      eventModule.toggleTransportationModal()
    })
  }
}

export default TransportationPlanItem
