
import { Component, Vue, Prop } from 'vue-property-decorator'
import CardListItem from '@/components/trip/dashboard/CardListItem.vue'
import { Document } from '@/types/document'
import { formattedDate } from '@/utils/date-utils'
import { formatFileName } from '@/utils/utility-manager'

@Component({
  name: 'DocumentListItem',
  components: {
    CardListItem,
  },
})
class DocumentListItem extends Vue {
  @Prop() readonly document!: Document

  localizedUpdateString(date: string): string {
    return formattedDate(date)
  }

  formatName(name: string): string {
    return formatFileName(name)
  }
}
export default DocumentListItem
