import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { set } from 'vue'
import isEqual from 'lodash/isEqual'

import { i18n } from '@/plugins/i18n'
import { tripModule, authModule } from '@/store'
import {
  DatePicker,
  Waypoint,
  PointOfInterest,
  Trip,
  Weather,
  WeatherRecord,
  PlaceRecommendation,
  WaypointSearchResult,
  Location,
  Metadata,
  WaypointDay,
  WaypointDayItem,
  POIDay,
  WaypointDashboardFeature,
  POILinkObject,
  LocationAutocompleteResult,
} from '@/types/trip'
import { LatitudeLongitudeLiteral, LatLngLiteral } from '@/types/types'
import {
  MapType,
  DistanceUnit,
  TripRelation,
  NearbySearchDataSource,
  WaypointDayItemType,
} from '@/utils/enums'
import {
  hasLocalStorageBoolean,
  setLocalStorageBoolean,
  isBrowser,
  getLocalStorageString,
  logEvent,
} from '@/utils/utility-manager'
import { dateRange, formattedDate } from '@/utils/date-utils'
import GoogleAPIManager from '@/utils/google-api-manager'

@Module({ name: 'waypoint', stateFactory: true, namespaced: true })
export default class WaypointModule extends VuexModule {
  private allWaypoints: Waypoint[] = []
  private optimizedWaypointsPreview: Waypoint[] = []
  private currentWaypoint: Waypoint | null = null
  private lastWaypointId = ''
  private allPOIs: PointOfInterest[] = []
  private currentPOI: PointOfInterest | null = null
  private currentPOILinkedDays: POIDay[] = []
  private currentWeather: Weather | null = null
  private fetchedWeatherHistory: WeatherRecord[] = []
  private allPlaceRecommendations: PlaceRecommendation[] = []
  private currentPoiPlacePreview: PointOfInterest | null = null
  private allWaypointDays: WaypointDay[] = []
  private currentWaypointDay: WaypointDay | null = null
  private currentWaypointDetailActiveTab = 0
  private resettingWaypointDays = false

  // map control elements
  private currentMapType: string = MapType.ROADMAP
  private currentDistanceUnit: string = DistanceUnit.KILOMETERS
  private currentDistanceSystem = 'metric'
  private currentWeatherUnit = 'celsius'
  private currentUserLocation: LatLngLiteral | null = null
  private doCenterUserLocation = false
  private doShowDistance = true
  private doConnectWaypoints = true
  private waypointDataSource: string = process.env.WAYPOINTS_DATA_SOURCE!
  private poiDataSource: string = process.env.NEARBY_PLACES_DATA_SOURCE!

  // waypoint detail overview sections
  private defaultOverviewSections: WaypointDashboardFeature[] = [
    {
      name: 'accommodation-section',
      title: 'accommodation',
      isDisplayed: true,
    },
    {
      name: 'places-section',
      title: 'nearbyPlaces',
      isDisplayed: true,
    },
    {
      name: 'booking-section',
      title: 'transportation',
      isDisplayed: true,
    },
    {
      name: 'activities-section',
      title: 'activities',
      isDisplayed: true,
    },
    {
      name: 'document-section',
      title: 'documents',
      isDisplayed: true,
    },
    {
      name: 'photo-section',
      title: 'photos',
      isDisplayed: true,
    },
    {
      name: 'note-section',
      title: 'notes',
      isDisplayed: true,
    },
  ]

  private overviewSections: WaypointDashboardFeature[] = []

  get detailSections(): WaypointDashboardFeature[] {
    return this.overviewSections
  }

  get userLocation(): LatLngLiteral | null {
    return this.currentUserLocation
  }

  get centerUserLocation(): boolean {
    return this.doCenterUserLocation
  }

  get mapType(): string {
    return this.currentMapType
  }

  get distanceUnit(): string {
    return this.currentDistanceUnit
  }

  get distanceSystem(): string {
    return this.currentDistanceSystem
  }

  get weatherUnit(): string {
    return this.currentWeatherUnit
  }

  get showDistance(): boolean {
    return this.doShowDistance
  }

  get connectWaypoints(): boolean {
    return this.doConnectWaypoints
  }

  get trip(): Trip | null {
    return tripModule.trip
  }

  get waypoints(): Waypoint[] {
    return this.allWaypoints
  }

  get optimizedWaypoints(): Waypoint[] {
    return this.optimizedWaypointsPreview
  }

  get waypoint(): Waypoint | null {
    return this.currentWaypoint
  }

  get lastWaypoint(): string {
    return this.lastWaypointId
  }

  get pointsOfInteres(): PointOfInterest[] {
    return this.allPOIs
  }

  get pointOfInterest(): PointOfInterest | null {
    return this.currentPOI
  }

  get linkedDays(): POIDay[] {
    return this.currentPOILinkedDays
  }

  get waypointSource(): number {
    return parseInt(this.waypointDataSource)
  }

  get pointOfInterestSource(): number {
    return NearbySearchDataSource.GOOGLE
    // FIXME: Restore once https://github.com/lambus-platform/lambus-web-app/pull/373 is merged
    // or we have Foursquare credits again
    //
    // return parseInt(this.poiDataSource)
  }

  get weather(): Weather | null {
    return this.currentWeather
  }

  get fetchedWeatherRecord(): WeatherRecord | undefined {
    return this.fetchedWeatherHistory.find(
      (record) => record.waypointId === this.waypoint?._id,
    )
  }

  get placeRecommendations(): PlaceRecommendation[] {
    return this.allPlaceRecommendations
  }

  get poiPlacePreview(): PointOfInterest | null {
    return this.currentPoiPlacePreview
  }

  get waypointDays(): WaypointDay[] {
    return this.allWaypointDays
  }

  get waypointDay(): WaypointDay | null {
    return this.currentWaypointDay
  }

  get waypointDetailsActiveTab(): number {
    return this.currentWaypointDetailActiveTab
  }

  get isResettingWaypointDays(): boolean {
    return this.resettingWaypointDays
  }

  @Mutation
  private setCurrentMapType(payload: string) {
    this.currentMapType = payload
  }

  @Mutation
  private setCurrentDistanceUnit(distanceUnit: string) {
    this.currentDistanceUnit = distanceUnit

    // Save in local storage
    isBrowser() &&
      localStorage.setItem(
        `kLambusShowPolylineDistanceMetric_${tripModule.trip!.id}`,
        distanceUnit,
      )
  }

  @Mutation
  private setCurrentDistanceSystem(distanceSystem: string) {
    this.currentDistanceSystem = distanceSystem

    // Save in local strorage
    isBrowser() &&
      localStorage.setItem(
        `kLambusDistanceSystem_${tripModule.trip!.id}`,
        distanceSystem,
      )
  }

  @Mutation
  private setCurrentWeatherUnit(weatherUnit: string) {
    this.currentWeatherUnit = weatherUnit

    // Save in local storage
    isBrowser() && localStorage.setItem('kLambusWeatherMetric', weatherUnit)
  }

  @Mutation
  private setDoShowDistance(showDistance: boolean) {
    this.doShowDistance = showDistance

    // Save in local storage
    setLocalStorageBoolean(
      `kLambusShowPolylineShowDistance_${tripModule.trip!.id}`,
      showDistance,
    )
  }

  @Mutation
  private setDoConnectWaypoints(connectWaypoints: boolean) {
    this.doConnectWaypoints = connectWaypoints

    // Save in local storage
    setLocalStorageBoolean(
      `kLambusShowPolyline_${tripModule.trip!.id}`,
      connectWaypoints,
    )
  }

  @Mutation
  private setDoCenterUserLocation(payload?: boolean) {
    if (payload === undefined) {
      // i have to make the explicit comparison here, since payload kann be true, false or undefined
      this.doCenterUserLocation = !this.doCenterUserLocation
    } else {
      this.doCenterUserLocation = payload
    }
  }

  @Mutation
  public setCurrentWeather(payload: Weather) {
    this.currentWeather = payload
  }

  @Mutation
  private setCurrentUserLocation(payload: LatLngLiteral) {
    this.currentUserLocation = payload
  }

  @Mutation
  private setWaypoints(payload: Waypoint[]) {
    set(this, 'allWaypoints', [...payload])
  }

  @Mutation
  private setOptimizedWaypointsPreview(payload: Waypoint[]) {
    set(this, 'optimizedWaypointsPreview', [...payload])
  }

  @Mutation
  private setCurrentWaypoint(payload: Waypoint | null) {
    set(this, 'currentWaypoint', payload)
  }

  @Mutation
  private setLastWaypointId(payload: string) {
    this.lastWaypointId = payload
  }

  @Mutation
  private setPOIs(payload: PointOfInterest[]) {
    set(this, 'allPOIs', [...payload])
  }

  @Mutation
  private setCurrentPOI(payload: PointOfInterest | null) {
    set(this, 'currentPOI', payload)
  }

  @Mutation
  private updateCurrentPOI(payload: PointOfInterest) {
    const poiIndex = this.allPOIs.findIndex((poi) => poi._id === payload?._id)
    if (poiIndex >= 0) {
      this.allPOIs.splice(poiIndex, 1, payload)
    }
    set(this, 'currentPOI', payload)
  }

  @Mutation
  private setCurrentPOILinkedDays(payload: POIDay[]) {
    set(this, 'currentPOILinkedDays', payload)
  }

  @Mutation
  private addWaypoint(waypoint: Waypoint) {
    this.allWaypoints.push(waypoint)
  }

  @Mutation
  public addFetchedWeatherRecord(record: WeatherRecord) {
    this.fetchedWeatherHistory.push(record)

    // remove expired records
    set(
      this,
      'fetchedWeatherHistory',
      this.fetchedWeatherHistory.filter(
        (record) => record.expiresAt > new Date().getTime(),
      ),
    )
  }

  @Mutation
  private setPlaceRecommendations(payload: PlaceRecommendation[]) {
    set(this, 'allPlaceRecommendations', [...payload])
  }

  @Mutation
  private setPoiPlacePreview(payload: PointOfInterest) {
    this.currentPoiPlacePreview = payload
  }

  @Mutation
  private setWaypointDays(payload: WaypointDay[]) {
    this.allWaypointDays = payload
  }

  @Mutation
  public setCurrentWaypointDay(payload: WaypointDay | null) {
    this.currentWaypointDay = payload
  }

  @Mutation
  private setResettingWaypointDays(value: boolean) {
    this.resettingWaypointDays = value
  }

  @Mutation
  public setWaypointDayVisibility({ dayId, value }: any) {
    const index = this.allWaypointDays.findIndex(
      (el: WaypointDay) => el._id === dayId,
    )
    if (index !== -1) {
      const waypointDay = this.allWaypointDays[index]
      waypointDay.isVisible = value
      set(this.allWaypointDays, index, waypointDay)
    }
  }

  @Mutation
  public setWaypointDetailsActiveTab(value: number) {
    this.currentWaypointDetailActiveTab = value
  }

  @Mutation
  public setOverviewSections(sections: WaypointDashboardFeature[]) {
    set(this, 'overviewSections', sections)
  }

  @Action({ rawError: true })
  async rearrange(payload: {
    sourceSectionIndex: number
    sourceItemIndex: number
    targetSectionIndex: number
    targetItemIndex: number
  }) {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      await this.$axios.put(`/api/trips/${this.trip.id}/rearrange`, payload)
      this.context.dispatch('routes/fetchAll', undefined, {
        root: true,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.REARRANGED_WAYPOINT },
        {
          root: true,
        },
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.waypoint.rearrange', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async fetchOptimizationPreview() {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      const { data } = await this.$axios.get(
        `/api/trips/${this.trip.id}/waypoints/optimize/preview`,
      )

      const sortedWaypoints = data.waypoints.map((waypointId: string) =>
        this.trip!.waypoints.find((waypoint) => waypoint._id === waypointId),
      )

      this.context.commit('setOptimizedWaypointsPreview', sortedWaypoints)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.waypoint.optimize', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async create(payload: {
    waypoint: Partial<Waypoint> | LocationAutocompleteResult
    index?: number
  }): Promise<Waypoint | undefined> {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      const { waypoint, index = this.trip.waypoints.length } = payload

      const { data } = await this.$axios.post(
        `/api/trips/${this.trip.id}/waypoints`,
        {
          index,
          waypoint,
        },
      )

      await tripModule.fetch(this.trip.id)
      const currentWaypoint = this.trip.waypoints.find(
        (element: Waypoint) => element._id === data.waypoint._id,
      )

      this.context.commit('addWaypoint', currentWaypoint)
      this.context.commit('setCurrentWaypoint', currentWaypoint)
      this.context.dispatch('event/newMessage', 'success.waypoint.create', {
        root: true,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.ADDED_WAYPOINT },
        {
          root: true,
        },
      )

      return data.waypoint
    } catch (error) {
      this.context.dispatch('event/newError', 'error.waypoint.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async update({ waypointId, payload }: any) {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      await this.$axios.put(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}`,
        payload,
      )

      logEvent('updated_waypoint', {
        type: TripRelation.UPDATED_WAYPOINT,
      })
      this.context.dispatch('event/newMessage', 'success.waypoint.update', {
        root: true,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.UPDATED_WAYPOINT },
        {
          root: true,
        },
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.waypoint.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async fetch(waypointId: string) {
    try {
      if (!this.trip) {
        throw new Error('Cannot fetch waypoint. Trip is undefined')
      }

      const { data } = await this.$axios.get(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}`,
      )

      this.context.commit('setCurrentWaypoint', data.data.waypoint)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.waypoint.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async search(payload: {
    searchTerm: string
    screenSource?: string
  }): Promise<WaypointSearchResult> {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      const sessionToken = GoogleAPIManager.generateSessionToken()

      const { data } = await this.$axios.get(
        `api/trips/${this.trip.id}/waypoints/search`,
        {
          params: {
            token: sessionToken,
            q: payload.searchTerm,
            screen_source: payload.screenSource,
          },
        },
      )

      return data.data
    } catch (error) {
      return { source: -1, predictions: [] }
    }
  }

  @Action({ rawError: true })
  async delete(waypointId: string) {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      const waypointIndex = this.trip.waypoints.findIndex(
        (waypoint) => waypoint._id === waypointId,
      )

      if (waypointIndex < 0) {
        throw new Error('Waypoint not found')
      }

      const isCurrentWaypoint: boolean = waypointId === this.waypoint?._id

      await this.$axios.delete(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}`,
        {
          params: {
            waypointId,
            tripId: this.trip.id,
          },
        },
      )

      logEvent('removed_waypoint')

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.REMOVED_WAYPOINT },
        {
          root: true,
        },
      )

      await tripModule.fetch(this.trip.id)

      // handle next current waypoint before possible trip refetch outside of the store
      if (isCurrentWaypoint && this.trip.waypoints.length === 0) {
        // if deleted waypoint was the only waypoint
        this.resetWaypoint()
      } else if (isCurrentWaypoint && this.trip.waypoints[waypointIndex]) {
        // if deleted waypoint had a successor
        this.setWaypoint(this.trip.waypoints[waypointIndex])
      } else if (isCurrentWaypoint && this.trip.waypoints[waypointIndex - 1]) {
        // if deleted waypoint had no successor but a predecessor
        this.setWaypoint(this.trip.waypoints[waypointIndex - 1])
      }

      this.context.dispatch('event/toggleWaypointDetails', false, {
        root: true,
      })

      this.context.dispatch('event/newMessage', 'success.waypoint.delete', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.waypoint.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async createPOI({
    waypointId,
    payload,
  }: any): Promise<PointOfInterest | null> {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      const response = await this.$axios.post(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}/pointOfInterests`,
        { pointOfInterest: payload },
      )

      this.context.dispatch('event/newMessage', 'success.poi.create', {
        root: true,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.ADDED_HIGHLIGHT },
        {
          root: true,
        },
      )

      return response.data.pointOfInterest
    } catch (error) {
      this.context.dispatch('event/newError', 'error.poi.create', {
        root: true,
      })
      return null
    }
  }

  @Action({ rawError: true })
  async fetchPOI(poiId: string) {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      const { data } = await this.$axios.get(
        `/api/trips/${this.trip.id}/pointOfInterests/${poiId}`,
      )
      this.context.commit('setCurrentPOI', data.pointOfInterest)
      this.context.commit('setCurrentPOILinkedDays', data.linkedDays)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.poi.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async updatePOI({ waypointId, poiId, payload }: any) {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      await this.$axios.put(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}/pointOfInterests/${poiId}`,
        payload,
      )

      this.context.dispatch('fetchPOI', poiId)
      this.context.dispatch('event/newMessage', 'success.poi.update', {
        root: true,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.UPDATED_HIGHLIGHT },
        {
          root: true,
        },
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.poi.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async deletePOI({ waypointId, pointOfInterestsId }: any) {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      await this.$axios.delete(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}/pointOfInterests/${pointOfInterestsId}`,
        {
          params: {
            pointOfInterestsId,
            waypointId,
            tripId: this.trip.id,
          },
        },
      )
      this.context.dispatch('event/newMessage', 'success.poi.delete', {
        root: true,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.REMOVED_HIGHLIGHT },
        {
          root: true,
        },
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.poi.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  setWaypoint(payload: Waypoint | null) {
    if (!authModule.user || !payload) {
      this.resetWaypoint()
      return
    }

    const isSameWaypoint = isEqual(this.currentWaypoint, payload)

    if (isSameWaypoint) {
      return
    }

    if (this.currentWaypoint && !isSameWaypoint) {
      this.resetPointOfInterest()
    }

    if (this.waypoint?._id) {
      this.context.commit('setLastWaypointId', this.waypoint._id)
    }

    if (!payload._id) {
      this.context.commit('setCurrentWaypoint', payload)
      return
    }

    this.fetch(payload._id)
  }

  @Action({ rawError: true })
  resetWaypoint() {
    this.resetPointOfInterest()
    this.context.commit('setCurrentWaypoint', null)
    this.context.commit('setLastWaypointId', '')
  }

  @Action({ rawError: true })
  async fetchPoiPlacePreview({ waypointId, externalId }: any): Promise<void> {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }
      const { data } = await this.$axios.get(
        `api/trips/${this.trip.id}/waypoints/${waypointId}/pointOfInterests/preview/foursquare/${externalId}`,
      )
      const metaData: Metadata = {
        categories: data.place.categories,
        contact: data.place.contact,
        dataSource: data.place.dataSource,
        externalId: data.place.externalId,
        location: data.place.location,
        metaURL: data.place.metaURL,
        photos: data.place.photos,
        rating: data.place.rating,
        reviews: data.place.reviews,
        website: data.place.website,
      }
      const placePoi: PointOfInterest = {
        name: data.place.name,
        metadata: metaData,
      }
      this.context.commit('setPoiPlacePreview', placePoi)
    } catch (error) {
      this.store.$sentry.captureException(error)
      this.context.dispatch('event/newError', 'error.poi.preview', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  placeToWaypoint(place: Location): Partial<Waypoint> {
    const newWaypoint: Partial<Waypoint> = {
      name:
        place.name ||
        place.city ||
        place.state ||
        place.country ||
        i18n.t('unnamedLocation').toString(),
      startDate: undefined,
      endDate: undefined,
      location: place,
      pointOfInterests: [],
      bookings: [],
    }

    this.context.commit('setCurrentWaypoint', newWaypoint)

    return newWaypoint
  }

  @Action({ rawError: true })
  async getPlaceGeocode(location: LatitudeLongitudeLiteral): Promise<any> {
    try {
      const response = await this.$axios.get('/api/meta/locations/geocode', {
        params: { ll: `${location.latitude},${location.longitude}` },
      })

      return response.data.data.location
    } catch (error) {
      this.store.$sentry.captureException(error)
      this.context.dispatch('event/newError', 'error.poi.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async getNearbyPlaces(params: {
    query: string
    radius: number
    categoryId: string | null
  }): Promise<Location[]> {
    try {
      const sessionToken = GoogleAPIManager.generateSessionToken()

      const response = await this.$axios.get(
        `/api/trips/${this.trip?.id}/waypoints/${this.waypoint?._id}/pointOfInterests/search`,
        {
          params: {
            q: params.query.trim(),
            radius: params.radius,
            category_id: params.categoryId,
            unit: this.distanceSystem,
            waypointId: this.waypoint?._id,
            lat: this.waypoint?.location.latitude,
            lon: this.waypoint?.location.longitude,
            token: sessionToken,
          },
        },
      )

      return response.data.data.predictions
    } catch (error) {
      this.store.$sentry.captureException(error)
      this.context.dispatch('event/newError', 'error.waypoint.poi.fetch')
      return []
    }
  }

  @Action({ rawError: true })
  async getPlaceDetails(opts: {
    dataSource: number | string
    placeId: string
  }): Promise<any> {
    try {
      const sessionToken = GoogleAPIManager.generateSessionToken()

      const { data } = await this.$axios.get(
        `/api/meta/locations/search/details?ds=${opts.dataSource}&place_id=${opts.placeId}&token="${sessionToken}"`,
      )

      GoogleAPIManager.removeSessionToken()
      return data.data.location
    } catch (error) {
      this.context.dispatch('event/newError', 'error.waypoint.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async getPlaceLinks(): Promise<POILinkObject> {
    try {
      if (!this.trip) {
        throw new Error('Could not fetch POI links. No trip set')
      }

      if (!this.pointOfInterest) {
        throw new Error('Could not fetch POI links. No point of interest set')
      }

      const { data } = await this.$axios.get(
        `/api/trips/${this.trip.id}/pointOfInterests/${this.pointOfInterest._id}/links`,
      )

      return data.links
    } catch (error) {
      return {}
    }
  }

  @Action({ rawError: true })
  setPointOfInterest(payload: PointOfInterest) {
    this.context.commit('setCurrentPOI', payload)
  }

  @Action({ rawError: true })
  resetPointOfInterest() {
    this.context.commit('setCurrentPOI', null)
  }

  @Action({ rawError: true })
  resetMapControl() {
    // Get distance unit (e.g. km/mi) from local storage
    const distanceUnitKey = `kLambusShowPolylineDistanceMetric_${
      tripModule.trip!.id
    }`

    const distanceUnit = getLocalStorageString(
      distanceUnitKey,
      DistanceUnit.KILOMETERS,
    )

    const distanceSystem = getLocalStorageString(
      `kLambusDistanceSystem_${tripModule.trip!.id}`,
      authModule.user.country?.code === 'US' ? 'imperial' : 'metric',
    )

    const weatherUnit = getLocalStorageString(
      'kLambusWeatherMetric',
      authModule.user.country?.code === 'US' ? 'imperial' : 'metric',
    )

    // Get distance and connect waypoints opt-ins (true/false) from local storage
    const showDistanceKey = `kLambusShowPolylineShowDistance_${
      tripModule.trip!.id
    }`
    const showDistance =
      !localStorage.getItem(showDistanceKey) ||
      hasLocalStorageBoolean(showDistanceKey)

    const connectWaypointsKey = `kLambusShowPolyline_${tripModule.trip!.id}`

    const connectWaypoints =
      !localStorage.getItem(connectWaypointsKey) ||
      hasLocalStorageBoolean(connectWaypointsKey)

    this.context.commit('setCurrentMapType', MapType.ROADMAP)
    this.context.commit('setCurrentDistanceUnit', distanceUnit)
    this.context.commit('setCurrentDistanceSystem', distanceSystem)
    this.context.commit('setCurrentWeatherUnit', weatherUnit)
    this.context.commit('setDoShowDistance', showDistance)
    this.context.commit('setDoConnectWaypoints', connectWaypoints)
    this.context.commit('setDoCenterUserLocation', false)
  }

  @Action({ rawError: true })
  setMapType(payload: string) {
    this.context.commit('setCurrentMapType', payload)
  }

  @Action({ rawError: true })
  setDistanceUnit(payload: string) {
    this.context.commit('setCurrentDistanceUnit', payload)
  }

  @Action({ rawError: true })
  setDistanceSystem(payload: string) {
    this.context.commit(
      'setCurrentDistanceSystem',
      payload === DistanceUnit.KILOMETERS ? 'metric' : 'imperial',
    )
  }

  @Action({ rawError: true })
  setWeatherUnit(payload: string) {
    this.context.commit('setCurrentWeatherUnit', payload)
  }

  @Action({ rawError: true })
  setShowDistance(payload: boolean) {
    this.context.commit('setDoShowDistance', payload)
  }

  @Action({ rawError: true })
  setConnectWaypoints(payload: boolean) {
    this.context.commit('setDoConnectWaypoints', payload)
  }

  @Action({ rawError: true })
  setUserLocation(payload: LatLngLiteral) {
    this.context.commit('setCurrentUserLocation', payload)
  }

  @Action({ rawError: true })
  setCenterUserLocation(payload?: boolean) {
    this.context.commit('setDoCenterUserLocation', payload)
  }

  @Action({ rawError: true })
  async getWeatherInfo({ trip, waypoint, params }: any) {
    try {
      const { data } = await this.$axios.get(
        `/api/trips/${trip}/waypoints/${waypoint}/weather`,
        { params },
      )
      this.context.commit('setCurrentWeather', data.weather)
    } catch {
      this.context.dispatch('event/newError', 'error.waypoint.weather', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async getWaypointNearbyPlacesRecommendations({ trip, waypoint }: any) {
    try {
      const { data } = await this.$axios.get(
        `api/trips/${trip}/waypoints/${waypoint}/recommendations`,
      )
      this.context.commit('setPlaceRecommendations', data.places)
    } catch {
      this.context.dispatch(
        'event/newError',
        'error.placeRecommendations.fetch',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  async changeWaypointToPoi({
    trip,
    sourceWaypoint,
    destinationWaypoint,
  }: any) {
    try {
      await this.$axios.post(
        `/api/trips/${trip}/waypoints/${sourceWaypoint}/migrate`,
        {
          destinationWaypoint,
        },
      )
      this.context.dispatch('event/newMessage', 'success.waypoint.migrate', {
        root: true,
      })
    } catch {
      this.context.dispatch('event/newError', 'error.waypoint.migrate', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchWaypointDays(opts: {
    waypointId: string
    setResettingDays?: boolean
  }): Promise<void> {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      if (opts.setResettingDays) {
        this.context.commit('setResettingWaypointDays', true)
        this.context.commit('setWaypointDays', [])
      }

      const { data } = await this.$axios.get(
        `/api/trips/${this.trip.id}/waypoints/${opts.waypointId}/days`,
      )

      data.days.forEach((day: WaypointDay) => {
        day.isVisible = true
      })

      this.context.commit('setWaypointDays', data.days)

      if (opts.setResettingDays) {
        this.context.commit('setResettingWaypointDays', false)
      }
    } catch {
      this.context.dispatch(
        'event/newError',
        'error.waypoint.dailyPlan.fetch',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public async updateWaypointDay({
    waypointId,
    waypointDayId,
    payload,
  }: any): Promise<void> {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }
      await this.$axios.put(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}/days/${waypointDayId}`,
        payload,
      )
      this.context.dispatch('fetchWaypointDays', { waypointId })
    } catch {
      this.context.dispatch(
        'event/newError',
        'error.waypoint.dailyPlan.update',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public async linkNearbyPlaceToDay({
    waypointId,
    waypointDayId,
    payload,
  }: any): Promise<void> {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }
      await this.$axios.post(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}/days/${waypointDayId}/item`,
        { ...payload, type: WaypointDayItemType.NEARBY_PLACE },
      )
      this.context.dispatch('trip/fetch', this.trip.id, { root: true })

      const waypointDay = this.waypointDays.find(
        (day) => day._id === waypointDayId,
      )

      if (waypointDay) {
        this.context.dispatch(
          'event/setValues',
          { date: formattedDate(waypointDay.date) },
          { root: true },
        )
        this.context.dispatch('event/newMessage', 'addedToDate', { root: true })
      }

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.UPDATED_HIGHLIGHT },
        {
          root: true,
        },
      )
    } catch {
      this.context.dispatch(
        'event/newError',
        'error.waypoint.dailyPlan.create',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public async unlinkNearbyPlaceToDay({
    waypointId,
    waypointDayId,
    itemId,
  }: any): Promise<void> {
    try {
      if (!this.trip) {
        throw new Error('Invalid parameter')
      }

      await this.$axios.delete(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}/days/${waypointDayId}/item/${itemId}`,
      )

      this.context.dispatch(
        'event/newMessage',
        'success.waypoint.dailyPlan.delete',
        {
          root: true,
        },
      )

      this.context.dispatch('trip/fetch', this.trip.id, { root: true })
      this.context.commit(
        'setCurrentPOILinkedDays',
        this.linkedDays.filter((day) => day.itemId !== itemId),
      )

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.UPDATED_HIGHLIGHT },
        {
          root: true,
        },
      )
    } catch {
      this.context.dispatch(
        'event/newError',
        'error.waypoint.dailyPlan.delete',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public async rearrangeNearbyPlaceInWaypointDay({
    waypointId,
    payload,
  }: any): Promise<void> {
    try {
      if (!this.trip) {
        throw new Error(
          `Failed to rearrange place in waypoint day because trip is missing. Payload: ${payload}`,
        )
      }

      await this.$axios.put(
        `/api/trips/${this.trip.id}/waypoints/${waypointId}/days/rearrange`,
        payload,
      )

      this.context.dispatch(
        'event/newMessage',
        'success.waypoint.dailyPlan.rearrange',
        {
          root: true,
        },
      )

      this.context.dispatch('fetchWaypointDays', { waypointId })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.REARRANGED_HIGHLIGHT },
        {
          root: true,
        },
      )
    } catch {
      this.context.dispatch(
        'event/newError',
        'error.waypoint.dailyPlan.rearrange',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public dateRangeToWaypoint(data: DatePicker): Waypoint {
    const payload = JSON.parse(JSON.stringify(this.waypoint))

    const date = dateRange({
      dates: data.type === 'SPECIFIC' ? data.dates : data.months,
      type: data.type,
    })

    if (date) {
      set(payload, 'startDate', date.startDate)
      set(payload, 'endDate', date.endDate || null)
    } else {
      set(payload, 'startDate', null)
      set(payload, 'endDate', null)
    }

    return payload
  }

  @Action({ rawError: true })
  public async findWaypointByPOI(poiId: string): Promise<Waypoint | null> {
    const waypointByPOI = await this.trip?.waypoints.find((waypoint) => {
      return waypoint.pointOfInterests.map((poi) => poi._id).includes(poiId)
    })

    return waypointByPOI || null
  }

  @Action({ rawError: true })
  public async findWaypointById(waypointId: string): Promise<Waypoint | null> {
    const waypointById = await this.trip?.waypoints.find((waypoint) => {
      return waypoint._id === waypointId
    })

    return waypointById || null
  }

  @Action({ rawError: true })
  public async updateWaypointDayItem(payload: {
    itemId?: string
    item: Partial<WaypointDayItem>
  }): Promise<void> {
    try {
      if (!payload.itemId) {
        throw new Error(
          `Failed to update waypoint day item because item id is missing. Payload: ${payload}`,
        )
      }

      if (!this.trip) {
        throw new Error(
          `Failed to update waypoint day item because trip is missing. Payload: ${payload}`,
        )
      }

      if (!this.waypoint) {
        throw new Error(
          `Failed to update waypoint day item because waypoint is missing. Payload: ${payload}`,
        )
      }

      const waypointDay = this.waypointDays.find((day) =>
        day.items?.map((item) => item._id).includes(payload.itemId!),
      )

      if (!waypointDay) {
        throw new Error(
          `Failed to update waypoint day item because waypoint day is missing. Payload: ${payload}`,
        )
      }

      if (payload.item.endTime && !payload.item.startTime) {
        payload.item.startTime = payload.item.endTime
        payload.item.endTime = null
      }

      await this.$axios.put(
        `/api/trips/${this.trip.id}/waypoints/${this.waypoint._id}/days/${waypointDay._id}/item/${payload.itemId}`,
        payload.item,
      )
      this.context.dispatch(
        'event/newMessage',
        'success.waypoint.dailyPlan.update',
        {
          root: true,
        },
      )

      this.context.dispatch('fetchWaypointDays', {
        waypointId: this.waypoint._id,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.UPDATED_HIGHLIGHT },
        {
          root: true,
        },
      )
    } catch {
      this.context.dispatch(
        'event/newError',
        'error.waypoint.dailyPlan.update',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public resetSectionOrder(): void {
    this.context.commit('setOverviewSections', this.defaultOverviewSections)
  }
}
