
import { Component, Prop, Vue } from 'vue-property-decorator'
import { waypointModule } from '@/store'
import { Waypoint } from '@/types/trip'
@Component({
  name: 'AddWaypointDialog',
})
class AddWaypointDialog extends Vue {
  @Prop() readonly waypoint?: Waypoint
  isLoading = false

  skip(): void {
    this.$emit('update:waypoint', null)
    this.$emit('update:value', false)
  }

  async saveWaypoint(): Promise<void> {
    this.isLoading = true

    const waypointCopy = JSON.parse(JSON.stringify(this.waypoint))
    delete waypointCopy.location._id

    const newWaypoint = {
      name: waypointCopy.name,
      location: waypointCopy.location,
    }

    const savedWaypoint = await waypointModule.create({ waypoint: newWaypoint })

    this.$emit('update:waypoint', savedWaypoint)

    this.isLoading = false
    this.$emit('update:value', false)
  }
}
export default AddWaypointDialog
