
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { tripModule, waypointModule } from '@/store'
import { Location, Waypoint } from '@/types/trip'
import { DistanceUnit } from '@/utils/enums'
import {
  distanceBetweenCoordinates,
  formattedDistance,
  getLocalStorageString,
} from '@/utils/utility-manager'

interface WaypointDistance {
  _id: string
  name: string
  distance?: number
}

@Component({
  name: 'WaypointList',
})
class WaypointList extends Vue {
  @Prop({ default: false, type: Boolean })
  readonly showCurrentWaypoint?: boolean

  @Prop({ default: false, type: Boolean }) readonly showDistance!: boolean
  @Prop() readonly location?: Location

  waypoints: Waypoint[] = this.trip.waypoints
  waypointDistances: WaypointDistance[] = []
  distanceMetric: string = DistanceUnit.KILOMETERS
  timestamp: number = new Date().getTime()

  get trip() {
    return tripModule.trip!
  }

  get currentWaypoint(): Waypoint | null {
    return waypointModule.waypoint
  }

  get sortedWaypoints() {
    if (this.showDistance && this.location) {
      return this.waypointDistances
    }
    return this.waypoints.map((waypoint) => ({
      _id: waypoint._id,
      name: waypoint.name,
      distance: undefined,
    }))
  }

  @Watch('location')
  calculateDistances() {
    if (!this.location || !this.showDistance) {
      return
    }

    this.distanceMetric = getLocalStorageString(
      `kLambusShowPolylineDistanceMetric_${this.trip.id}`,
      DistanceUnit.KILOMETERS,
    )

    this.waypointDistances = this.trip.waypoints
      .map((waypoint) => ({
        _id: waypoint._id,
        name: waypoint.name,
        distance: distanceBetweenCoordinates(waypoint.location, {
          latitude: this.location?.latitude!,
          longitude: this.location?.longitude!,
        }),
      }))
      .sort((a, b) => a.distance - b.distance)
  }

  mounted() {
    this.calculateDistances()
  }

  formattedDistance(distance: number): string {
    return formattedDistance(distance, this.distanceMetric)
  }

  hideCurrentWaypoint(waypointId: string): boolean {
    if (this.currentWaypoint) {
      if (
        waypointId === this.currentWaypoint?._id &&
        !this.showCurrentWaypoint
      ) {
        return true
      }
    }
    return false
  }
}
export default WaypointList
