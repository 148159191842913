
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { debounce } from 'lodash'

import InspirationModal from '@/components/onboarding/discover/InspirationModal.vue'
import { tripModule, discoverModule } from '@/store'
import { LocationAutocompleteResult, TripPredictions } from '@/types/trip'
import { Inspiration, OnboardingInspiration } from '@/types/discover'
import { generatedGUID } from '@/utils/utility-manager'
@Component({
  name: 'LocationSearch',
  components: {
    InspirationModal,
  },
})
class LocationSearch extends Vue {
  @Prop() title!: string
  @Prop({ default: '', type: String }) placeholder?: string

  loading = false
  selectedPlace: LocationAutocompleteResult | null = null
  selectedInspiration: OnboardingInspiration | Inspiration | null = null

  searchTerm = ''
  searchResults: TripPredictions | null = null
  sessionToken = generatedGUID()

  debouncedSearch = debounce(async (value: string, context: LocationSearch) => {
    if (value.trim().length === 0) {
      context.resetSearch()
      context.resetSelection()
    }

    if (
      value.trim().length > 0 &&
      !context.selectedPlace &&
      !context.selectedInspiration
    ) {
      context.searchResults = await tripModule.fetchCreateTripLocationSearch({
        sessionToken: context.sessionToken,
        query: value,
      })
      context.loading = false

      if (
        !context.searchResults ||
        (context.searchResults.lambus.length === 0 &&
          context.searchResults.external.length === 0)
      ) {
        context.$emit('update:is-searching', false)
      } else {
        context.$emit('update:is-searching', true)
      }
    }
  }, 1000)

  debouncedLoading = debounce((value: string, context: LocationSearch) => {
    if (value.trim().length > 0) {
      context.loading = !context.selectedPlace && !context.selectedInspiration
    }
  }, 300)

  get currentInspiration(): Inspiration | null {
    return discoverModule.currentInspiration
  }

  get showResults(): boolean {
    return (
      !(this.selectedPlace || this.selectedInspiration) &&
      this.searchTerm.trim().length > 0
    )
  }

  @Watch('searchTerm')
  searchForLocations(value?: string): void {
    if (!value) {
      this.resetSearch()
      this.resetSelection()
      return
    }

    this.$emit('update:has-name', true)

    this.debouncedSearch(value, this)
    this.debouncedLoading(value, this)
  }

  getSelection(): {
    place: LocationAutocompleteResult | null
    inspiration: OnboardingInspiration | Inspiration | null
    name: string
  } {
    return {
      place: this.selectedPlace,
      inspiration: this.selectedInspiration,
      name: this.searchTerm,
    }
  }

  formatCategories(categories: string[]): string {
    if (categories.length <= 2) {
      return categories.join(', ')
    }

    const firstTwoCategories = categories.slice(0, 2).join(', ')
    return `${firstTwoCategories} +${categories.length - 2}`
  }

  resetSelection(): void {
    this.selectedPlace = null
    this.selectedInspiration = null
    this.$emit('update:has-location', false)
  }

  resetSearch(): void {
    Vue.set(this, 'searchTerm', '')
    Vue.set(this, 'searchResults', null)
    this.loading = false

    this.$emit('update:has-name', false)
    this.$emit('update:is-searching', false)
  }

  setSelectedPlace(place: any): void {
    this.selectedInspiration = null
    this.selectedPlace = place
    this.searchTerm = place.title

    this.updateSettings()
  }

  async setSelectedInspiration(inspirationId: string): Promise<void> {
    this.selectedPlace = null

    if (
      !this.currentInspiration ||
      inspirationId !== this.currentInspiration.id
    ) {
      await discoverModule.fetchInspiration(inspirationId)
    }

    if (this.currentInspiration) {
      this.selectedInspiration = this.currentInspiration
      this.searchTerm = this.currentInspiration.name

      this.updateSettings()
    }
  }

  updateSettings() {
    this.$emit('update:has-name', true)
    this.$emit('update:has-location', true)
    this.$emit('update:is-searching', false)
  }

  async showInspirationDetails(
    inspiration: OnboardingInspiration,
  ): Promise<void> {
    this.selectedPlace = null
    await discoverModule.fetchInspiration(inspiration.id)
    const dialog = this.$refs['new-trip-inspiration-modal'] as InspirationModal
    if (dialog) {
      dialog.toggleDialog()
    }
  }
}
export default LocationSearch
