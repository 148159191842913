import pLimit from 'p-limit'
import { v4 as uuidv4 } from 'uuid'

import { eventModule } from '@/store'
import DocumentModule from '@/store/document'
import PhotoModule from '@/store/photo'
import { Document } from '@/types/document'
import { Photo } from '@/types/photo'
import AWSManager from '@/utils/aws-manager'
import { DocumentType, GridContentType, UploadError } from '@/utils/enums'

export interface UseUploadFilesOptions {
  files: File[]
  contentType: GridContentType
  storeModule: DocumentModule | PhotoModule
  tripId: string
  currentDirectory?: string | null
  referenceId?: string
}

export async function useUploadFiles(options: UseUploadFilesOptions) {
  const {
    files,
    tripId,
    contentType,
    storeModule,
    currentDirectory,
    referenceId,
  } = options

  if (files.length === 0) {
    return
  }

  const limit = pLimit(4)
  const input = files.map((file) => {
    return limit(() =>
      uploadFileToAWS({
        file,
        tripId,
        contentType,
        storeModule,
        currentDirectory,
        referenceId,
      }),
    )
  })

  if (input.length > 1) {
    eventModule.setValues({ count: input.length })
    eventModule.newInfo(`info.${contentType}.uploadMultiple`)
  } else {
    eventModule.newInfo(`info.${contentType}.upload`)
  }

  const uploadResults = await Promise.all(input)

  if (uploadResults.length > 1) {
    // check if some of the files exceeded the maximum file size
    const failureCount = uploadResults.filter(
      (result) => result === UploadError.MAXIMUM_FILE_SIZE_EXCEEDED,
    ).length

    eventModule.setValues({
      successCount: uploadResults.length - failureCount,
      allCount: uploadResults.length,
    })

    if (failureCount > 0) {
      eventModule.newError([
        'error.uploadFileSize',
        `success.${contentType}.createMultiple`,
      ])
    } else {
      eventModule.newMessage(`success.${contentType}.createMultiple`)
    }
  } else if (uploadResults[0] === UploadError.MAXIMUM_FILE_SIZE_EXCEEDED) {
    eventModule.newError(`error.uploadFileSize`)
  } else {
    eventModule.newMessage(`success.${contentType}.create`)
  }
}

interface UploadFileToAWSOptions {
  file: File
  tripId: string
  contentType: GridContentType
  storeModule: DocumentModule | PhotoModule
  currentDirectory?: string | null
  referenceId?: string
}

async function uploadFileToAWS(options: UploadFileToAWSOptions) {
  const {
    file,
    tripId,
    contentType,
    storeModule,
    currentDirectory,
    referenceId,
  } = options

  const fileName = `${uuidv4()}.${file.name.split('.').pop()!.toLowerCase()}`
  return await AWSManager.uploadFile(
    `trips/${tripId}/${contentType}s/${fileName}`,
    file,
    () => {
      const params: Partial<Document | Photo> = {
        type: DocumentType.FILE,
        parentDirectoryId: currentDirectory || undefined,
        originName: file.name,
        url: fileName,
      }

      if (referenceId) {
        params.reference = referenceId
        params.onModel = 'Waypoint'
      }

      storeModule.create({ id: tripId, params, showSuccessMessage: false })
    },
  )
}
