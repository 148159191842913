
import { Component, Vue } from 'vue-property-decorator'

import { eventModule, expenseModule, tripModule } from '@/store'
@Component({
  name: 'ExpenseExportModal',
})
class ExpenseExportModal extends Vue {
  get trip() {
    return tripModule.trip!
  }

  exportExpenses(): void {
    expenseModule.exportExpense(this.trip.id)
    eventModule.toggleExportStartedPrompt()
  }
}
export default ExpenseExportModal
