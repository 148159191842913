var render, staticRenderFns
import script from "./ContentView.vue?vue&type=script&lang=ts"
export * from "./ContentView.vue?vue&type=script&lang=ts"
import style0 from "./ContentView.vue?vue&type=style&index=0&id=5a96f7a2&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a96f7a2",
  null
  
)

export default component.exports