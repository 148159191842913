import { i18n } from '@/plugins/i18n'
export const TextFieldRules = {
  REQUIRED: (value: string) => !!value || i18n.tc('textfieldRules.required'),
  TEXT: (value: string) => value === '' || i18n.tc('textfieldRules.required'),
  COUNTER: (value: string) =>
    value.length <= 50 || i18n.tc('textfieldRules.counter'),
  PIN: (value: string) => value.length === 6 || i18n.tc('textfieldRules.pin'),
  AMOUNT: (value: string) => {
    const pattern = /^[0-9]+([.|,][0-9]{1,2})?$/
    return pattern.test(value) || i18n.tc('textfieldRules.amount')
  },
  EMAIL: (value: string) => {
    // eslint-disable-next-line
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || i18n.tc('textfieldRules.email')
  },
  LINK: (value: string) => {
    const pattern =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gim
    return pattern.test(value) || i18n.tc('textfieldRules.link')
  },
  PASSWORD: (value: string) =>
    value.length >= 6 || i18n.tc('textfieldRules.password'),
  FLIGHT_NUMBER: (value: string) => {
    const pattern = /^[A-Z0-9]{2,3}[0-9]{1,4}$/
    return pattern.test(value) || i18n.tc('textfieldRules.flightNumber')
  },
  TIME_STRING: (value: string) => {
    const pattern =
      /^(([0-1]?[0-9]|2[0-4])(?!.*(\sam|\spm)))?((0?[1-9]|1[0-2])(?=.*(\sam|\spm)))?[:.]([0-5][0-9])([:.][0-5][0-9])?(?!\S)(\sam|\spm)?$/i
    return (
      pattern.test(value) ||
      value.trim().length === 0 ||
      i18n.tc('textfieldRules.timeString')
    )
  },
}

export const EmptyStateImages = {
  PRIVATE_EXPENSES: 'cash',
  GROUP_EXPENSES: 'cash',
  BILLING: 'cash',
  EXPENSES: 'expenses',
  DOCUMENTS: 'documents',
  NOTES: 'note-book',
  PHOTOS: 'photos',
  TRAVELPLAN: 'pin',
  MAP: 'pin',
  CHAT: 'chat',
  PACKINGLISTS: 'suitcase',
  TRANSPORTATION: 'hot-air-balloon',
  ACCOMMODATION: 'accommodation',
}

export const EmptyStateType = {
  PRIVATE_EXPENSES: 'private_expenses',
  GROUP_EXPENSES: 'group_expenses',
  BILLING: 'cash',
  EXPENSES: 'expenses',
  DOCUMENTS: 'documents',
  NOTES: 'notes',
  PHOTOS: 'photos',
  TRAVELPLAN: 'travelPlan',
  MAP: 'map',
  CHAT: 'chat',
  PACKINGLISTS: 'packingLists',
  SEARCH: 'search',
  TRANSPORTATION: 'transportation',
  ACCOMMODATION: 'accommodation',
}

export const TripRelation = {
  IS_ONLINE: 0,
  LEFT_TRIP: 11,
  DELETED_TRIP: 12,
  RENAMED_TRIP: 13,
  UPDATED_TRIP_COVER_PHOTO: 14,
  UPDATED_TRIP: 15,
  ARCHIVED_TRIP: 16,
  UPDATED_BOOKING: 17,
  ADDED_WAYPOINT: 21,
  UPDATED_WAYPOINT: 22,
  REARRANGED_WAYPOINT: 23,
  REMOVED_WAYPOINT: 24,
  ADDED_HIGHLIGHT: 31,
  UPDATED_HIGHLIGHT: 32,
  REARRANGED_HIGHLIGHT: 33,
  REMOVED_HIGHLIGHT: 34,
  INVITED_ATTENDEE: 41,
  REMOVED_ATTENDEE: 42,
  JOINED_ATTENDEE: 43,
  ADDED_DOCUMENT: 51,
  UPDATED_DOCUMENT: 52,
  REMOVED_DOCUMENT: 53,
  ADDED_NOTE: 54,
  UPDATED_NOTE: 55,
  REMOVED_NOTE: 56,
  ADDED_NOTE_TO_WAYPOINT: 57,
  ADDED_NOTE_TO_HIGHLIGHT: 58,
  ADDED_GPX: 59,
  ADDED_EXPENSE: 61,
  UPDATED_EXPENSE: 62,
  REMOVED_EXPENSE: 63,
  ADDED_CHAT_MESSAGE: 71,
  STARTED_LIVE_LOCATION: 81,
  UPDATED_LIVE_LOCATION: 82,
  STOPPED_LIVE_LOCATION: 83,
  FORCE_RELOAD: 91,
  FOUND_OFFER: 92,
  PURCHASED_LAMBUS_PRO: 93,
  FLIGHT_UPDATE: 94,
}

export const PushMessageType = {
  CHAT_GROUP_MESSAGE: 'NEW_CHAT_GROUP_MESSAGE',
  EXPENSE_PING: 'EXPENSE_PING',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  GPX_UPLOADED: 'GPX_UPLOADED',
}

export enum GridContentType {
  PHOTO = 'photo',
  DOCUMENT = 'document',
  CHAT = 'chat',
}

export const ChatContentType = {
  LOCATION: 'Location',
  PHOTO: 'Photo',
  TEXT: 'Text',
}

export const ReviewStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
}

export const DocumentType = {
  FILE: 'file',
  DIRECTORY: 'directory',
}

export const UserRole = {
  USER: 'user',
  DELETED: 'deleted',
  ADMIN: 'admin',
  DELETED_ADMIN: 'deleted-admin',
  MAIL_PARSER: 'mailparser',
  INVESTOR: 'investor',
  ANALYST: 'analyst',
}

export const AccountType = {
  RECEIVE: 'receive',
  OWE: 'owe',
}

export const TripOfThe = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
}

export const FeatureType = {
  TRIP_OF: 'tripOf',
}

export const TripOfHierarchy = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
}

export const TopListStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export const TopListType = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
}

export const InspirationMediaType = {
  IMAGE: 'image',
  VIDEO: 'video',
}

export const InspirationFootprintType = {
  IMPORTED: 'imported',
  WATCHED: 'watched',
  BOOKMARKED: 'bookmarked',
}

export const MapEditorType = {
  WAYPOINT: 'waypoint',
  POI: 'poi',
}

export const NoteEditorCommand = {
  BOLD: 'bold',
  UNDERLINE: 'underline',
  STRIKETHROUGH: 'StrikeThrough',
  INSERTORDEREDLIST: 'insertOrderedList',
  INSERTUNORDEREDLIST: 'insertUnorderedList',
  JUSTIFYLEFT: 'justifyLeft',
  JUSTIFYCENTER: 'justifyCenter',
  JUSTIFYRIGHT: 'justifyRight',
  UNDO: 'undo',
}

export const DistributionType = {
  EXACT: 'exact',
  EQUAL: 'equal',
}

export const ExpenseMenuOptions = {
  PRIVATE: 'private',
  GROUP: 'group',
  OPEN_RECEIVE: 'openReceive',
  OPEN_OWE: 'openOwe',
  PAID: 'paid',
}

export const HighlightCategories = [
  'airport',
  'atm',
  'bank',
  'bar',
  'bus_station',
  'cafe',
  'city_hall',
  'gas_station',
  'hotel',
  'hospital',
  'library',
  'museum',
  'night_club',
  'park',
  'parking',
  'pharmacy',
  'police',
  'post_office',
  'restaurant',
  'shopping_mall',
  'store',
  'subway_station',
  'train_station',
  'zoo',
]

export enum LoginType {
  FORM = 'form',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
  UNKNOWN = 'unknown',
}

export enum SocialLoginType {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
}

export const MapType = {
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  TERRAIN: 'terrain',
}

export const DistanceUnit = {
  KILOMETERS: 'kilometers',
  MILES: 'miles',
}

export const DistanceUnitMap = {
  kilometers: 0,
  miles: 1,
}

export const NearbySearchDataSource = {
  FOURSQUARE: 0,
  GOOGLE: 1,
}

export const WaypointDataSource = {
  MAPBOX: 0,
  GOOGLE: 1,
  APPLE: 2,
  GPS_COORDINATES: 3,
}

export const FieldType = {
  TEXT: 'Text',
  DATETIME: 'DateTime',
  AIRPORTCODE: 'AirportCode',
  REFERENCE: 'Reference',
  LOCATION: 'Location',
  DOCUMENTS: 'Documents',
  TEXTAREA: 'TextArea',
}

export const LocationSearchType = {
  LOCATION: 'Location',
  AIRPORT: 'AirportCode',
}

export const TransportationType = {
  FLIGHT: 'FlightBooking',
  HOTEL: 'HotelBooking',
  FERRY: 'FerryBooking',
  CAR: 'CarBooking',
  RENTAL_CAR: 'RentalCarBooking',
  TRAIN: 'TrainBooking',
  BUS: 'BusBooking',
  DIRECTIONS: 'Directions',
  WALKING: 'WalkingBooking',
  MOTORCYCLE: 'MotorcycleBooking',
  BICYCLE: 'BicycleBooking',
  GENERIC: 'GenericBooking',
}

// NOTE: If you add another one here, make sure to sync with the server
export enum TransportationSubType {
  RV = 'rv',
  MOTORCYCLE = 'motorcycle',
  CRUISE = 'cruise',
  TAXI = 'taxi',
  ESCOOTER = 'escooter',
  HELICOPTER = 'helicopter',
}

export const DirectionsOption = {
  DRIVING: 'driving',
  TRANSIT: 'transit',
  BICYCLING: 'bicycling',
  WALKING: 'walking',
  //  FLIGHT: 'flight',
}

export const DirectionIcon = {
  DRIVING: 'transport_CarBooking.png',
  TRANSIT: 'transport_TrainBooking.png',
  FLIGHT: 'transport_FlightBooking.png',
  BICYCLING: 'transport_BicycleBooking.png',
  WALKING: 'transport_WalkingBooking.png',
}

export const RouteModes = {
  DIRECTIONS: 'directions',
  ROUTES: 'routes',
}

export const FlightStatus = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  DIVERTED: 'DIVERTED',
  LANDED: 'LANDED',
  NOT_OPERATIONAL: 'NOT_OPERATIONAL',
  REDIRECTED: 'REDIRECTED',
  SCHEDULED: 'SCHEDULED',
  NOW_TRACKING: 'NOW_TRACKING',
  UNKNOWN: 'UNKNOWN',
}

export const InAppPurchaseProduct = {
  LAMBUS_PRO_PER_TRIP: 'io.lambus.app.product.lambuspro',
  LAMBUS_PRO_MONTHLY: 'io.lambus.app.product.lambuspro_monthly',
  LAMBUS_PRO_12M_SUBSCRIPTION: 'io.lambus.app.product.lambuspro_12m',
  LAMBUS_PRO_12M_SUBSCRIPTION_FREE_TRIAL:
    'io.lambus.app.product.lambuspro_12m_free_trial',
  LAMBUS_PRO_LIFETIME: 'io.lambus.app.product.lambuspro_lifetime_upgrade',
}

export const PackingListVisibility = {
  PRIVATE: 'private',
  SHARED: 'shared',
}

export const GPXVisibilitySetting = {
  ASK_LATER: 'askLater',
  ALWAYS: 'always',
  NEVER: 'never',
}

export enum TripSortType {
  LAST_UPDATED = 'LastUpdated',
  DATE = 'Date',
}

export const WaypointModalType = {
  NEARBY_PLACES_MODAL: 'NearbyPlacesModal',
  TRANSPORTATION_MODAL: 'TransportationModal',
}

export const WaypointAlgorithm = {
  NONE: 0,
  BY_DATE: 1,
}

export const PurchasePlatform = {
  PAYPAL: 'paypal',
  STRIPE: 'stripe',
  GOOGLE: 'google',
  APPLE: 'apple',
}

export const DiscoverSearchType = {
  KEYWORDS: 0,
  CATEGORY: 1,
  HASHTAG: 2,
}

export const DiscoverSearchQueryType = {
  KEYWORDS: 'q',
  CATEGORY: 'cat',
  HASHTAG: 'ht',
  TOP_LIST: 'tl',
}

export const DatePickerType = {
  SPECIFIC: 'SPECIFIC',
  WHOLE_MONTH: 'WHOLE_MONTH',
  BOTH: 'BOTH',
}

export const UploadError = {
  MAXIMUM_FILE_SIZE_EXCEEDED: -1,
}

export const DocumentSortMode = {
  BY_DATE: 'date',
  BY_NAME: 'name',
  BY_FILE_TYPE: 'file_type',
}

export enum WaypointDayItemType {
  NEARBY_PLACE = 'nearby_place',
  NOTE = 'note',
  ACTIVITY = 'activity',
}

export enum ExpenseSortType {
  CREATED = 'Created',
  DATE_UP = 'Date',
}

export enum LocationDataSource {
  GOOGLE = 'GOOGLE',
  FOURSQUARE = 'FOURSQUARE',
  TRIP_ADVISOR = 'TRIP_ADVISOR',
  MAPBOX = 'MAPBOX',
  APPLE = 'APPLE',
  DB = 'DB',
  GPX = 'GPX',
  OTHER = 'OTHER',
}

export enum LocationDataSourceSlug {
  GOOGLE = 'google',
  FOURSQUARE = 'foursquare',
  TRIP_ADVISOR = 'trip-advisor',
  MAPBOX = 'mapbox',
  APPLE = 'apple',
  DB = 'db',
  GPX = 'gpx',
}

export enum NotificationType {
  GENERAL = 'GENERAL',
  ADDED_WAYPOINT = 'ADDED_WAYPOINT',
  ADDED_HIGHLIGHT = 'ADDED_HIGHLIGHT',
  ADDED_DOCUMENT = 'ADDED_DOCUMENT',
  ADDED_NOTE = 'ADDED_NOTE',
  ADDED_NOTE_TO_WAYPOINT = 'ADDED_NOTE_TO_WAYPOINT',
  ADDED_NOTE_TO_HIGHLIGHT = 'ADDED_NOTE_TO_HIGHLIGHT',
  ADDED_EXPENSE = 'ADDED_EXPENSE',
  REMOVED_WAYPOINT = 'REMOVED_WAYPOINT',
  REMOVED_HIGHLIGHT = 'REMOVED_HIGHLIGHT',
  INVITED_ATTENDEE_YOU = 'INVITED_ATTENDEE_YOU',
}
