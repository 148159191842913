import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { getAnalytics, logEvent as _logEvent } from '@firebase/analytics'

import { waypointModule } from '@/store'
import { i18n } from '@/plugins/i18n'
import { Name, Inspiration } from '@/types/discover'
import {
  LatLngLiteral,
  LatitudeLongitudeLiteral,
  FormattedAddressOptions,
  KVLiteral,
} from '@/types/types'
import { Photo } from '@/types/photo'
import { Document } from '@/types/document'
import { DateRange, Location, Trip, Waypoint } from '@/types/trip'
import {
  DirectionsOption,
  DistanceUnit,
  TransportationSubType,
  TransportationType,
  UserRole,
  WaypointDataSource,
  TripSortType,
  DatePickerType,
} from '@/utils/enums'
import { User } from '@/types/user'
import { Currency } from '@/types/expense'
import { Accommodation } from '@/types/accommodation'

/**
 * Set of external script ids that have been loaded already
 */
const loadedScripts: Set<string> = new Set()

/**
 * SVG Path values for a circle shape
 */
export const circlePath =
  'M 0, 0 m -13, 0 a 13,13 0 0,0 26,0 a 13,13 0 0,0 -26,0'

/**
 * Default content Quill.js inserts on a newly instantiated editor
 */
export const defaultQuillContent = '<p><br></p>'

export function navigateToItem(
  route: string,
  node: string,
  itemId: string,
  pushState?: boolean,
): void {
  const formattedRoute =
    route.endsWith(node) || route.endsWith(`${node}/`)
      ? route
      : route.substring(0, route.indexOf(node) + node.length)

  const id = formattedRoute.endsWith('/') ? itemId : '/' + itemId

  if (pushState) {
    history.pushState({}, '', `${formattedRoute}${id}`)
  } else {
    history.replaceState({}, '', `${formattedRoute}${id}`)
  }
}

export function thumbnailImage(url?: string, width = 320): string {
  if (!url) {
    return ''
  }

  return `${url}?width=${width}`
}

export function isBrowser() {
  return typeof window !== 'undefined'
}

export function findName(nameArray: Name[], locale: string): string {
  const result = nameArray.find((name) => name.k === locale)
  if (result) {
    return result.v
  } else if (nameArray.length === 1) {
    return nameArray[0].v
  }

  return ''
}

export function isValidUsername(username: string) {
  // eslint-disable-next-line prefer-regex-literals
  return new RegExp(/^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/).test(username)
}

export function formattedCountriesText(
  inspiration: Inspiration,
  locale: string,
  singleWord: string,
  pluralWord: string,
): string {
  try {
    const { countries, isoCountries } = inspiration
    let countriesMap: { [key: string]: string } = {}
    let countriesToUse = []

    try {
      countriesMap = require(`~/assets/json/_countries/${locale}.json`)
    } catch (_) {
      countriesMap = require('~/assets/json/_countries/en.json')
    }

    if (!countries || countries.length === 0) {
      return ''
    }

    if (isoCountries.length > 0) {
      countriesToUse = isoCountries.map((value, index) => {
        if (Object.prototype.hasOwnProperty.call(countriesMap, value)) {
          return countriesMap[value]
        } else {
          return countries[index]
        }
      })
    } else {
      countriesToUse = countries
    }

    const firstCountry = countriesToUse[0]

    if (countriesToUse.length > 1) {
      if (countriesToUse.length === 2) {
        return `${firstCountry} & ${countriesToUse[1]}`
      }

      return `${firstCountry} & ${pluralize(
        countriesToUse.length - 1,
        singleWord,
        pluralWord,
      )}`
    }

    return firstCountry
  } catch (_) {
    return ''
  }
}

export function pluralize(value: number, singular: string, plural: string) {
  return value === 0
    ? `${i18n.t('no')} ${plural}`
    : value === 1
      ? `${i18n.t('one')} ${singular}`
      : `${value} ${plural}`
}

export function deg2rad(degrees: number): number {
  return degrees * (Math.PI / 180)
}

export function rad2deg(radians: number): number {
  return radians * (180 / Math.PI)
}

/*
 * credit: http://www.movable-type.co.uk/scripts/latlong.html
 */
export function midPoint(markers: any[]): LatLngLiteral {
  let sumX = 0
  let sumY = 0
  let sumZ = 0

  for (let i = 0; i < markers.length; i++) {
    const lat = deg2rad(markers[i].position.lat())
    const lng = deg2rad(markers[i].position.lng())
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng)
    sumY += Math.cos(lat) * Math.sin(lng)
    sumZ += Math.sin(lat)
  }

  const avgX = sumX / markers.length
  const avgY = sumY / markers.length
  const avgZ = sumZ / markers.length

  // convert average x, y, z coordinate to latitude and longtitude
  const lng = Math.atan2(avgY, avgX)
  const hyp = Math.sqrt(avgX * avgX + avgY * avgY)
  const lat = Math.atan2(avgZ, hyp)

  return { lat: rad2deg(lat), lng: rad2deg(lng) }
}

export function mappedCategoryByValue(value: string): string | null {
  const allCategories = Object.keys(
    require('~/assets/json/categories.json'),
  ).map((el) => {
    return { value: el, id: require('~/assets/json/categories.json')[el] }
  })

  for (const category of allCategories) {
    if (category.value === value) {
      return category.id
    }
  }

  return null
}

export function extractFlightNumberComponents(
  flightNumber: string,
): { airline: string; flightNumber: string; formattedValue: string } | null {
  if (!flightNumber) {
    return null
  }

  const flightNumberMatch = flightNumber
    .toUpperCase()
    .match(/([A-Z]{2,3}|[A-Z]\d|\d[A-Z])[\s-]?(\d{1,4})/)

  if (!flightNumberMatch) {
    return null
  }

  return {
    airline: flightNumberMatch[1],
    flightNumber: flightNumberMatch[2],
    formattedValue: `${flightNumberMatch[1]}${flightNumberMatch[2]}`,
  }
}

export async function formattedLocation(
  place: any,
  dataSource: number,
): Promise<Location> {
  if (dataSource === WaypointDataSource.MAPBOX) {
    return place.location
  } else if (dataSource === WaypointDataSource.GOOGLE) {
    return await waypointModule.getPlaceDetails({
      placeId: place.id,
      dataSource,
    })
  } else if (dataSource === WaypointDataSource.GPS_COORDINATES) {
    return await waypointModule.getPlaceGeocode({
      latitude: place.location.latitude,
      longitude: place.location.longitude,
    })
  } else {
    throw new Error('Cannot format Location. Unknown data source')
  }
}

export function formattedLocationFromCoordinates(
  coordinates: LatitudeLongitudeLiteral,
): Location {
  return {
    name: i18n.t('unnamedLocation').toString(),
    placeId: '',
    latitude: coordinates.latitude,
    longitude: coordinates.longitude,
    dataSource: WaypointDataSource.GPS_COORDINATES,
  }
}

export function formattedLocationFromGooglePlacesPlace(place: any) {
  const location: Partial<Location> = {
    name: place.name,
    dataSource: 'GOOGLE',
    placeId: place.place_id,
    latitude: place.geometry.location.lat,
    longitude: place.geometry.location.lng,
  }

  place.address_components.forEach((component: any) => {
    if (
      component.types.includes('locality') ||
      component.types.includes('postal_town')
    ) {
      location.city = component.long_name
    } else if (
      component.types.includes('street_address') ||
      component.types.includes('route')
    ) {
      location.street = component.long_name
    } else if (component.types.includes('street_number')) {
      location.streetNumber = component.long_name
    } else if (component.types.includes('administrative_area_level_1')) {
      location.state = component.long_name
    } else if (component.types.includes('postal_code')) {
      location.postalCode = component.long_name
    } else if (component.types.includes('country')) {
      location.country = component.long_name
      if (component.short_name) {
        location.countryCode = component.short_name
      }
    }
  })

  if (place.icon) {
    location.icon = place.icon
  }

  if (location.street && location.streetNumber) {
    location.street = `${location.street} ${location.streetNumber}`
    delete location.streetNumber
  }

  if (!location.name) {
    location.name =
      location.city ||
      location.state ||
      location.country ||
      i18n.t('unnamedLocation').toString()
  }

  return location
}

export function formattedLocationFromFoursquarePlace(
  place: any,
  name?: string,
) {
  const {
    longitude,
    latitude,
    lat,
    lng,
    postalCode,
    city,
    state,
    country,
    cc,
    address,
  } = place.location

  const location: Location = {
    name: place.title || name,
    dataSource: 'FOURSQUARE',
    placeId: place.id || place.externalId,
    latitude: lat || latitude,
    longitude: lng || longitude,
  }

  if (postalCode) {
    location.postalCode = postalCode
  }
  if (address) {
    location.street = address
  }
  if (city) {
    location.city = city
  }
  if (state) {
    location.state = state
  }
  if (country) {
    location.country = country
  }
  if (cc) {
    location.countryCode = cc
  }
  if (place.categories && place.categories.length > 0) {
    const category = place.categories[0]
    if (category.icon.prefix && category.icon.suffix) {
      location.icon = `${category.icon.prefix}90${category.icon.suffix}`
    } else {
      location.icon = category.icon
    }
  }

  return location
}

export function emojiForBooking(type: string): string {
  switch (type) {
    case TransportationType.FLIGHT:
      return '✈️'
    case TransportationType.TRAIN:
    case DirectionsOption.TRANSIT:
      return '🚄'
    case TransportationType.CAR:
    case TransportationType.RENTAL_CAR:
    case DirectionsOption.DRIVING:
      return '🚗'
    case TransportationType.FERRY:
      return '⛴️'
    case TransportationType.DIRECTIONS:
      return '⤴️'
    case DirectionsOption.BICYCLING:
    case TransportationType.BICYCLE:
      return '🚲'
    case TransportationType.BUS:
      return '🚌'
    case TransportationType.WALKING:
      return '🥾'
    default:
      return '⤴️'
  }
}

export function emojiForBookingSubType(subType: string) {
  switch (subType) {
    case TransportationSubType.CRUISE:
      return '🛳️'
    case TransportationSubType.ESCOOTER:
      return '🛴'
    case TransportationSubType.HELICOPTER:
      return '🚁'
    case TransportationSubType.MOTORCYCLE:
      return '🏍️'
    case TransportationSubType.RV:
      return '🚐'
    case TransportationSubType.TAXI:
      return '🚖'
    default:
      return '⤴️'
  }
}

export function findRelatedBookings(
  waypointId: string,
  waypointList: Waypoint[],
  bookings: any[],
): any[] {
  const relatedBookings: any[] = []

  const waypointIndex = waypointList.findIndex(
    (waypoint: Waypoint) => waypoint._id === waypointId,
  )

  for (const booking of bookings) {
    const originIndex = waypointList.findIndex(
      (waypoint: Waypoint) => waypoint._id === booking.origin?._id,
    )

    const destinationIndex = waypointList.findIndex(
      (waypoint: Waypoint) => waypoint._id === booking.destination?._id,
    )

    if (
      (originIndex < waypointIndex && destinationIndex > waypointIndex) ||
      originIndex === waypointIndex
    ) {
      relatedBookings.push(...booking.data)
    }
  }

  return relatedBookings
}

export function isPDF(url?: string): boolean {
  if (url) {
    return url.includes('.pdf')
  } else {
    return false
  }
}

export function openInGoogleMaps(location: Location | string) {
  if (typeof location === 'string') {
    window.open(
      'https://www.google.com/maps/search/?api=1&query=' + location,
      '_blank',
    )
    return
  }

  const placeIdString = isGoogleLocation(location)
    ? `&query_place_id=${location.placeId}`
    : ''

  const placeURL = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    getFullAddress({ location }),
  )}${placeIdString}`

  window.open(placeURL, '_blank')
}

export function openRouteInGoogleMaps(
  origin: Location,
  destination?: Location,
): void {
  const fromPlaceIdString = isGoogleLocation(origin)
    ? `&destination_place_id=${origin.placeId}`
    : ''
  let url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    getFullAddress({ location: origin }),
  )}${fromPlaceIdString}`

  if (destination) {
    const toPlaceIdString = isGoogleLocation(destination)
      ? `&destination_place_id=${destination.placeId}`
      : ''
    url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      getFullAddress({ location: destination }),
    )}${toPlaceIdString}&origin=${encodeURIComponent(
      getFullAddress({ location: origin }),
    )}${fromPlaceIdString}`
  }

  window.open(url, '_blank')
}

function isGoogleLocation(location: Location): boolean {
  return location.dataSource === 'GOOGLE'
}

export function containerMaxWidth(breakpoint: string): number {
  switch (breakpoint) {
    case 'xs':
      return 600
    case 'sm':
      return 960
    case 'md':
      return 1264
    case 'lg':
      return 1904
    case 'xl':
      return 1904
  }
  return 1280
}

// CREDITS: https://gist.github.com/ismaels/6636986
export function decodedPolyline(encoded: string): LatLngLiteral[] {
  const points = []
  let index = 0
  const len = encoded.length
  let lat = 0
  let lng = 0
  while (index < len) {
    let b
    let shift = 0
    let result = 0
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)

    const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1
    lat += dlat
    shift = 0
    result = 0
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)
    const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1
    lng += dlng

    points.push({ lat: lat / 1e5, lng: lng / 1e5 })
  }
  return points
}

export function escapeTemplateString(templateString: string): string {
  // Avoid running script in v-html (CREDIT: https://stackoverflow.com/a/4419541/11129782)
  return templateString
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;') // it's not neccessary to escape >
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export function getFullAddress(highlight: any, opts?: FormattedAddressOptions) {
  const country = opts?.excludeCountry
    ? ''
    : highlight.location.country
      ? `, ${highlight.location.country}`
      : ''

  const shouldAppendName = !opts || !opts?.excludeName

  if (!highlight.location.street) {
    if (!highlight.location.city && !highlight.location.name) {
      return `${highlight.location.latitude},${highlight.location.longitude}`
    }
    if (shouldAppendName) {
      if (highlight.location.city) {
        return `${highlight.location.name}, ${highlight.location.city}${country}`
      }
      return `${highlight.location.name}${country}`
    }
    return `${highlight.location.city || highlight.location.name}${country}`
  }

  if (!highlight.location.postalCode || !highlight.location.city) {
    if (!highlight.location.postalCode && !highlight.location.city) {
      return highlight.location.street + country
    }
    if (!highlight.location.postalCode) {
      if (shouldAppendName) {
        return `${highlight.location.name}, ${highlight.location.street}, ${highlight.location.city}${country}`
      }
      return `${highlight.location.street}, ${highlight.location.city}${country}`
    } else if (!highlight.location.city) {
      if (shouldAppendName) {
        return `${highlight.location.name}, ${highlight.location.street}, ${highlight.location.postalCode}${country}`
      }
      return `${highlight.location.street}, ${highlight.location.postalCode}${country}`
    }
  }

  if (shouldAppendName) {
    return `${highlight.location.name}, ${highlight.location.street}, ${highlight.location.postalCode} ${highlight.location.city}${country}`
  }

  return `${highlight.location.street}, ${highlight.location.postalCode} ${highlight.location.city}${country}`
}

/**
 * Returns the corrosponding currency symbol to a 3 character long currency code
 */
export function getCurrencySymbol(currencyCode: string): string {
  return require('~/assets/json/currencies.json')[currencyCode].symbol
}

/**
 * Returns the formatted currency array, holding all available currencies as Object
 */
export function getCurrencyArray(): Currency[] {
  const rawCurrencies = require('~/assets/json/currencies.json')

  return Object.keys(rawCurrencies).map((key) => rawCurrencies[key])
}

/**
 * Returns a currency string, formatted based on the language parameter
 */
export function formattedPrice(
  language: string,
  currency: string,
  value?: number,
): string {
  const formattedPrice = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(value !== undefined ? value : 1)

  return formattedPrice
}

export function formattedDistance(
  distanceInKm: number,
  unit: string = DistanceUnit.KILOMETERS,
): string {
  const MILE_FACTOR = 1.609344
  // Calculate air distance
  const distanceValueCalculated =
    unit === DistanceUnit.KILOMETERS ? distanceInKm : distanceInKm / MILE_FACTOR
  const decimalNumbers = distanceValueCalculated > 10 ? 0 : 1
  return `${numberWithDot(
    distanceValueCalculated.toFixed(decimalNumbers),
  )} ${i18n.t(`${unit}Unit`)}`
}

// CREDITS: https://stackoverflow.com/a/27943/5537752
export function distanceBetweenCoordinates(
  location1: LatitudeLongitudeLiteral,
  location2: LatitudeLongitudeLiteral,
) {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(location2.latitude - location1.latitude) // deg2rad below
  const dLon = deg2rad(location2.longitude - location1.longitude)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(location1.latitude)) *
      Math.cos(deg2rad(location2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c // Distance in km
}

// CREDITS: https://stackoverflow.com/a/2901298/5537752
export function numberWithDot(x: string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export async function downloadFile(
  axios: NuxtAxiosInstance,
  path: string,
  file: Document | Photo,
) {
  if (!isBrowser()) {
    return
  }

  const { data } = await axios({
    url: `/api/trips/${file.tripId}/${path}/${file._id}/download`,
    method: 'GET',
    responseType: 'blob',
  })

  if (!data) {
    return
  }

  const fileURL = window.URL.createObjectURL(new Blob([data]))
  const fileLink = document.createElement('a')

  fileLink.href = fileURL
  fileLink.setAttribute('download', file.url!.split('/')!.pop()!)
  document.body.appendChild(fileLink)

  fileLink.click()
}

export function generatedGUID(): string {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}

export function logEvent(eventName: string, eventParamater?: any) {
  if (!isBrowser()) {
    return
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('[Firebase Analytics] ' + eventName)
    return
  }

  if (eventParamater) {
    _logEvent(getAnalytics(), eventName, eventParamater)
  } else {
    _logEvent(getAnalytics(), eventName)
  }
}

export function getLocalStorageString(key: string, def: string): string {
  return (isBrowser() && localStorage.getItem(key)) || def
}

export function hasLocalStorageBoolean(key: string): boolean {
  return isBrowser() && localStorage.getItem(key) === 'true'
}

export function setLocalStorageBoolean(key: string, value: boolean) {
  return isBrowser() && localStorage.setItem(key, value ? 'true' : 'false')
}

export function getUserFirstName(profile: User): string {
  if (profile.role !== UserRole.DELETED) {
    return profile.firstName
  }

  return i18n.t('deleted_user').toString()
}

export function formatFileName(name: string): string {
  return isValidGUID(name) ? i18n.t('untitled').toString() : name
}

export function isValidGUID(guid?: string): boolean {
  if (!guid) {
    return false
  }

  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    guid,
  )
}

/**
 * Convert a single digit number to a two digit string (e.g. 8 -> "08")
 * @returns value of num as string, padded with a zero if necessary
 */
export function padTo2Digits(num: number): string {
  return num.toString().padStart(2, '0')
}

/**
 * tests for common file extensions that match accepted image formats
 * @param value image URL including the file extension
 */
export function isImage(value: string): boolean {
  return /\.(jpe?g||png|gif)$/i.test(value)
}

/**
 * tests for common file extensions that match accepted document formats
 * @param value document URL including the file extension
 */
export function isDocument(value: string): boolean {
  return /\.(doc|pdf)$/i.test(value)
}

/**
 * Compares two strings alphabetically
 * @param name1 first name
 * @param name2 second name
 * @returns 0 if name1 and name2 are identical, -1 if name2 is alphabetically behind name1, 1 if name2 is alphabetically before name1
 */
export function nameBasedSorting(name1: string, name2: string): number {
  if (name1.toLowerCase() < name2.toLowerCase()) {
    return -1
  } else if (name1.toLowerCase() > name2.toLowerCase()) {
    return 1
  }

  return 0
}

/**
 * Compares two documents or photos by their creation date
 * @param document1 first document or photo
 * @param document1 second document or photo
 * @returns -1, 0 or 1 depending on wich date exists comes before the other
 */
export function sortByCreatedAt(
  document1: Document | Photo,
  document2: Document | Photo,
): number {
  if (!document1.createdAt) {
    return -1
  }

  if (!document2.createdAt) {
    return 1
  }

  return (
    new Date(document1.createdAt).getTime() -
    new Date(document2.createdAt).getTime()
  )
}

/**
 * Gets the title from an accomodation.
 *
 * @param accommodation to get the title for
 * @returns Returns either the custom accommodation name, name of the location or an empty string if both a not set.
 */
export function getAccommodationTitle(
  accommodation: Accommodation | undefined,
) {
  return accommodation?.name || accommodation?.location?.name || ''
}

/**
 * Adds a script to the document and calls the callback function on the 'load' event
 * @param id unique id of the script tag
 * @param src CDN link
 * @param cb callback function, is called with the 'load' event
 * @param customProps properties that should be set on the script tag
 */
export function loadScript(
  id: string,
  src: string,
  cb?: any,
  customProps?: KVLiteral[],
): void {
  if (!isBrowser()) {
    return
  }

  // check if script tag with same id exists already
  let script = document.getElementById(id)

  if (!script) {
    // get first script tag
    const fjs = document.getElementsByTagName('script')[0]
    // create script tag with id and src
    const js = document.createElement('script') as any
    js.id = id
    js.src = src

    if (customProps) {
      // add custom props to script tag
      for (const prop of customProps) {
        js[prop.k] = prop.v
      }
    }

    // insert as first script tag
    fjs.parentNode?.insertBefore(js, fjs)

    script = document.getElementById(id)
  }

  if (script && !!cb) {
    if (loadedScripts.has(id)) {
      // if script is already fully loaded, call cb directly
      cb()
    } else {
      // call callback method on load event
      script.addEventListener('load', () => {
        // add script id to set of loaded scripts
        loadedScripts.add(id)
        cb()
      })
    }
  }
}

/**
 * Converts a dateRange object to a unix timestamp
 * If the dateRange is a whole month, the timestamp will be the last date of the month
 *
 * @param dateRange the dateRange object to get the timestamp from
 * @param endOfDay specifies if the timestamp should be the last second of the day
 */
export function getTimestampFromDate(dateRange: DateRange, endOfDay: boolean) {
  if (dateRange.type === DatePickerType.WHOLE_MONTH) {
    const date = new Date(dateRange.value)
    if (endOfDay) {
      return new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0,
        23,
        59,
        59,
      ).getTime()
    } else {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime()
    }
  }
  return new Date(dateRange.value).getTime()
}

/**
 * Function to sort trips by date
 * The trips are sorted by the following rules:
 * - Upcoming trips are sorted by their start date
 * - Currently active trips are sorted by their start date
 * - Past trips are sorted by their start date
 */
export function sortByDate(trip1: Trip, trip2: Trip): number {
  const currentDate = new Date().getTime()

  if (!trip1.startDate) {
    return 1
  }
  if (!trip2.startDate) {
    return -1
  }

  // Convert to UNIX Timestamp
  const startDateATimestamp = getTimestampFromDate(trip1.startDate, false)
  const startDateBTimestamp = getTimestampFromDate(trip2.startDate, false)
  const endDateATimestamp = getTimestampFromDate(
    trip1.endDate ?? trip1.startDate,
    true,
  )
  const endDateBTimestamp = getTimestampFromDate(
    trip2.endDate ?? trip2.startDate,
    true,
  )

  // As every trip is compared randomly (not really but it depends on the size of the array, sometimes an element is never tripA)
  // we have to check tripA and tripB against all conditions

  // A upcoming
  if (startDateATimestamp > currentDate) {
    // B Upcoming
    if (startDateBTimestamp > currentDate) {
      return startDateBTimestamp - startDateATimestamp
    }
    // B Currently Active
    if (
      startDateBTimestamp <= currentDate &&
      currentDate <= endDateBTimestamp
    ) {
      return 1
    }
    // B Past
    if (startDateBTimestamp < currentDate) {
      return -1
    }
  }

  // A is currently active
  if (startDateATimestamp <= currentDate && currentDate <= endDateATimestamp) {
    // B currently active
    if (
      startDateBTimestamp <= currentDate &&
      currentDate <= endDateBTimestamp
    ) {
      return startDateBTimestamp - startDateATimestamp
    }

    // B past and upcoming
    return -1
  }

  // A Past
  if (startDateATimestamp < currentDate) {
    // B past
    if (startDateBTimestamp < currentDate) {
      return startDateBTimestamp - startDateATimestamp
    }

    // B active or upcoming
    return 1
  }

  return 0
}

/**
 * Sorts the trips inplace based on the current user's role and the selected sort type.
 * If the user is invited into a trip, the trip will be sorted to the top of the list.
 *
 * @param sortBy type of sorting
 * @param trips trips to sort
 * @param currentUserId id of the current user
 * @returns sorted trips
 */
export function sortTrips(
  sortBy: TripSortType,
  trips: Trip[],
  currentUserId: String,
) {
  return trips.sort((trip1, trip2) => {
    if (
      trip1.pendingAttendees.findIndex((user) => user.id === currentUserId) >
      trip2.pendingAttendees.findIndex((user) => user.id === currentUserId)
    ) {
      return -1
    }
    if (sortBy === TripSortType.DATE) {
      return sortByDate(trip1, trip2)
    }

    return (
      new Date(trip2.updatedAt).getTime() - new Date(trip1.updatedAt).getTime()
    )
  })
}
