
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormDialog from '@/components/trip/transportation/forms/generic/FormDialog.vue'
import { transportationModule, tripModule } from '@/store'
import { Waypoint } from '@/types/trip'

@Component({
  name: 'AddTransportationTabs',
  components: {
    FormDialog,
  },
})
class AddTransportationTabs extends Vue {
  @Prop() readonly waypointIndex?: number
  showFormModal = false
  activeTab = 0
  tabItems = transportationModule.transportationItems

  get waypoints(): Waypoint[] {
    return tripModule.trip?.waypoints || []
  }

  openActiveTab(index: number): void {
    if (this.waypointIndex === undefined) {
      // javascript reads value 0 as false, so we have to explicitly compare to undefined
      return
    }

    this.activeTab = index
    transportationModule.setOriginWaypoint(this.waypoints[this.waypointIndex])
    transportationModule.setDestinationWaypoint(
      this.waypoints[this.waypointIndex + 1],
    )
    this.showFormModal = true
  }
}
export default AddTransportationTabs
