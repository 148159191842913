
import { Component, Vue } from 'vue-property-decorator'
import { tripModule, waypointModule } from '@/store'
import { Trip, Waypoint } from '@/types/trip'

@Component({
  name: 'WaypointForm',
})
class WaypointForm extends Vue {
  isSaving = false
  isAddingNewWaypoint = false
  showAddWaypointDialog = false
  duplicatedWaypoint: Waypoint | null = null
  localOrigin: Waypoint | null = null
  localDestination: Waypoint | null = null

  get origin(): Waypoint | null {
    return this.localOrigin
  }

  set origin(value: Waypoint | null) {
    Vue.set(this, 'localOrigin', value)
  }

  get destination(): Waypoint | null {
    return this.localDestination
  }

  set destination(value: Waypoint | null) {
    Vue.set(this, 'localDestination', value)
  }

  get trip(): Trip | null {
    return tripModule.trip
  }

  async setOriginWaypoint(waypoint: Waypoint): Promise<void> {
    if (!waypoint._id) {
      this.isAddingNewWaypoint = true
      const savedWaypoint = await waypointModule.create({ waypoint })

      if (savedWaypoint) {
        waypoint = savedWaypoint
      }
      this.isAddingNewWaypoint = false
    }

    if (this.origin?._id === waypoint._id) {
      this.setDuplicatedWaypoint(waypoint)
    } else if (
      this.trip &&
      this.destination &&
      this.trip.waypoints.findIndex((wp) => wp._id === waypoint._id) >
        this.trip.waypoints.findIndex((wp) => wp._id === this.destination!._id)
    ) {
      const tmp = JSON.parse(JSON.stringify(waypoint))
      this.origin = this.destination
      this.destination = tmp
    } else {
      this.origin = waypoint
    }
  }

  async setDestinationWaypoint(waypoint: Waypoint): Promise<void> {
    if (!waypoint._id) {
      this.isAddingNewWaypoint = true
      const savedWaypoint = await waypointModule.create({ waypoint })

      if (savedWaypoint) {
        waypoint = savedWaypoint
      }
      this.isAddingNewWaypoint = false
    }

    if (this.destination?._id === waypoint._id) {
      this.setDuplicatedWaypoint(waypoint)
    } else if (
      this.trip &&
      this.origin &&
      this.trip.waypoints.findIndex((wp) => wp._id === this.origin!._id) >
        this.trip.waypoints.findIndex((wp) => wp._id === waypoint._id)
    ) {
      const tmp = JSON.parse(JSON.stringify(waypoint))
      this.destination = this.origin
      this.origin = tmp
    } else {
      this.destination = waypoint
    }
  }

  setDuplicatedWaypoint(waypoint: Waypoint): void {
    Vue.set(this, 'duplicatedWaypoint', waypoint)
    this.$nextTick(() => {
      this.showAddWaypointDialog = true
    })
  }

  resetWaypoints(): void {
    this.origin = null
    this.destination = null
    Vue.set(this, 'duplicatedWaypoint', null)
  }

  setUnsetWaypoint(waypoint: Waypoint): void {
    if (!this.origin) {
      this.origin = waypoint
    } else {
      this.destination = waypoint
    }
  }
}
export default WaypointForm
