import locale77427e78 from '../../i18n/messages/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentFallbackWarn":true},
  vueI18nLoader: true,
  locales: [{"code":"de","iso":"de-DE","file":"de.js"},{"code":"en","iso":"en-US","file":"en.js"},{"code":"es","iso":"es-ES","file":"es.js"},{"code":"fr","iso":"fr-FR","file":"fr.js"},{"code":"id","iso":"id-ID","file":"id.js"},{"code":"it","iso":"it-IT","file":"it.js"},{"code":"pt","iso":"pt-BR","file":"pt-br.js"},{"code":"zh","iso":"zh-CN","file":"zh.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/Users/hans/Documents/dev/lambus/lambus-web-app/i18n/messages",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":false},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"de","iso":"de-DE","file":"de.js"},{"code":"en","iso":"en-US","file":"en.js"},{"code":"es","iso":"es-ES","file":"es.js"},{"code":"fr","iso":"fr-FR","file":"fr.js"},{"code":"id","iso":"id-ID","file":"id.js"},{"code":"it","iso":"it-IT","file":"it.js"},{"code":"pt","iso":"pt-BR","file":"pt-br.js"},{"code":"zh","iso":"zh-CN","file":"zh.js"}],
  localeCodes: ["de","en","es","fr","id","it","pt","zh"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'de.js': () => import('../../i18n/messages/de.js' /* webpackChunkName: "lang-de.js" */),
  'es.js': () => import('../../i18n/messages/es.js' /* webpackChunkName: "lang-es.js" */),
  'fr.js': () => import('../../i18n/messages/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'id.js': () => import('../../i18n/messages/id.js' /* webpackChunkName: "lang-id.js" */),
  'it.js': () => import('../../i18n/messages/it.js' /* webpackChunkName: "lang-it.js" */),
  'pt-br.js': () => import('../../i18n/messages/pt-br.js' /* webpackChunkName: "lang-pt-br.js" */),
  'zh.js': () => import('../../i18n/messages/zh.js' /* webpackChunkName: "lang-zh.js" */),
}
