import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { set } from 'vue'

import { Trip } from '@/types/trip'
import {
  BillingPosition,
  Expense,
  ExpenseFraction,
  ExpenseType,
} from '@/types/expense'
import { authModule, tripModule } from '@/store'
import { i18n } from '@/plugins/i18n'
import { formattedPrice, getUserFirstName } from '@/utils/utility-manager'
import { TripRelation } from '@/utils/enums'

@Module({ name: 'expense', stateFactory: true, namespaced: true })
export default class ExpenseModule extends VuexModule {
  private currentExpense: Expense | null = null
  private defaultTripCurrency = {
    symbol: '€',
    name: 'Euro',
    symbol_native: '€',
    decimal_digits: 2,
    rounding: 0,
    code: 'EUR',
    name_plural: 'euros',
  }

  get user() {
    return authModule.user
  }

  private get trip(): Trip | null {
    return tripModule.trip
  }

  get expense() {
    return this.currentExpense
  }

  get defaultCurrency() {
    return this.defaultTripCurrency
  }

  get expenses(): Expense[] {
    const trip = this.context.rootGetters['trip/trip']
    if (!trip) {
      return []
    }

    return trip.expenses || []
  }

  get relatedExpenses(): Expense[] {
    if (!this.user || !this.user.id) return []

    const relatedExpenses = []

    for (const expense of this.expenses) {
      if (
        expense.from.some((fraction) =>
          doesAffectMe(fraction, this.user.id!),
        ) ||
        expense.to.some((fraction) => doesAffectMe(fraction, this.user.id!))
      ) {
        relatedExpenses.push(expense)
      }
    }

    return relatedExpenses
  }

  get totalBalance(): { amount: number; type: string } {
    const account = tripModule.myExpenseAccount
    const relatedExpenses = this.relatedExpenses

    const hasBalance =
      relatedExpenses.length > 0 && account && account.amount.length > 0

    if (!hasBalance || !account) {
      return { amount: 0, type: ExpenseType.RECEIVE }
    } else {
      let totalBalance = 0.0

      for (const amount of account.amount) {
        totalBalance += amount.amount
      }

      return { amount: totalBalance, type: account.type }
    }
  }

  get tripBalance(): number {
    let value = 0.0

    for (const expense of this.expenses) {
      for (const fraction of expense.from) {
        value += fraction.amount
      }
    }

    return value
  }

  get privateBalance(): number {
    let value = 0.0
    this.expenses.forEach((expense) => {
      if (!expense._id || !expense.isPrivate) {
        return
      }

      const fromProfile = expense.from.find(
        (amount) => amount.userId === authModule.user.id,
      )

      if (!fromProfile) {
        return
      }

      const amount = fromProfile.amount
      value += amount
    })

    return value
  }

  get paidPositions(): BillingPosition[] {
    if (!this.trip || !this.user?.id) {
      return []
    }

    const paidCells: BillingPosition[] = []
    const accounts = this.trip.accounts
    const paid = this.trip.paid

    const ownOpenAccounts = accounts.find(
      (account) => account.userId.id === this.user.id,
    )
    const ownPaidAccounts = paid.filter(
      (account) =>
        account.from.id === this.user.id || account.to.id === this.user.id,
    )

    // User user no balance
    if (!ownOpenAccounts && ownPaidAccounts.length === 0) {
      return []
    }

    ownPaidAccounts.forEach((position) => {
      const hasPaid = position.to.id === this.user.id

      paidCells.push({
        expense: position._id,
        amountLabel: {
          text: formattedPrice(
            window.navigator.language,
            this.trip!.currency,
            position.amount,
          ),
          color: !hasPaid ? 'red' : 'primary',
        },
        fromNameLabel:
          position.from.id === this.user.id
            ? i18n.t('you').toString()
            : getUserFirstName(position.from),
        fromPicture: position.from.picture,
        toNameLabel:
          position.to.id === this.user.id
            ? i18n.t('you').toString()
            : getUserFirstName(position.to),
        toPicture: position.to.picture,
      })
    })

    return paidCells
  }

  @Mutation
  setAllExpenses(payload: Expense[]) {
    set(this, 'allExpenses', [...payload])
  }

  @Mutation
  addExpense(payload: Expense) {
    this.expenses.push(payload)
  }

  @Mutation
  setCurrentExpense(payload: Expense | null) {
    set(this, 'currentExpense', payload)
  }

  @Action({ rawError: true })
  setExpenses(payload: Expense[]) {
    this.context.commit('setAllExpenses', payload)
  }

  @Action({ rawError: true })
  setExpense(payload: Expense) {
    this.context.commit('setCurrentExpense', payload)
  }

  @Action({ rawError: true })
  setExpenseById(expenseId: string) {
    const expense = this.expenses.find((expense) => expense._id === expenseId)
    if (expense) {
      this.context.commit('setCurrentExpense', expense)
    }
  }

  @Action({ rawError: true })
  resetExpense() {
    this.context.commit('setCurrentExpense', null)
  }

  @Action({ rawError: true })
  async createExpense({ tripId, payload }: any): Promise<Expense | void> {
    try {
      const {
        data: { expense },
      } = await this.$axios.post(`/api/trips/${tripId}/expenses`, payload)

      this.context.dispatch('addExpense', expense)

      this.context.dispatch('event/newMessage', 'success.expense.create', {
        root: true,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        {
          type: TripRelation.ADDED_EXPENSE,
          object: payload.isPrivate
            ? {
                identifier: this.user.id,
                name: this.user.firstName,
              }
            : null,
        },
        {
          root: true,
        },
      )

      return expense
    } catch (error) {
      this.context.dispatch('event/newError', 'error.expense.create', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async updateExpense({ tripId, expenseId, payload }: any) {
    try {
      const { data } = await this.$axios.put(
        `/api/trips/${tripId}/expenses/${expenseId}`,
        payload,
      )

      this.context.dispatch('event/newMessage', 'success.expense.update', {
        root: true,
      })

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.UPDATED_EXPENSE },
        {
          root: true,
        },
      )

      return data.expense
    } catch (error) {
      this.context.dispatch('event/newError', 'error.expense.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async deleteExpense({ tripId, expenseId }: any) {
    try {
      await this.$axios.delete(`/api/trips/${tripId}/expenses/${expenseId}`)
      this.context.dispatch('event/newMessage', 'success.expense.delete', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.expense.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async deleteAmount({ tripId, amountId }: any) {
    try {
      await this.$axios.delete(`/api/trips/${tripId}/paid/${amountId}`)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.amount.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async paymentDetails({ tripId, amountId }: any) {
    try {
      const { data } = await this.$axios.get(
        `/api/trips/${tripId}/amounts/${amountId}/paymentDetails`,
      )
      return data
    } catch (error) {
      this.context.dispatch('event/newError', 'error.payment_details', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async markAsPaid({ tripId, from, amount }: any) {
    try {
      await this.$axios.post(`/api/trips/${tripId}/paid`, {
        from,
        amount,
      })
      this.context.dispatch('event/newMessage', 'success.amount.paid', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.amount.paid', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  ping({ tripId, userId, amount }: any) {
    try {
      this.$axios.post(`/api/trips/${tripId}/expenses/ping`, {
        userId,
        amount,
      })
      this.context.dispatch('event/newMessage', 'success.amount.ping', {
        root: true,
      })
    } catch (error) {
      this.context.dispatch('event/newError', 'error.amount.ping', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async exchangeRate({ tripCurrency, currency }: any) {
    try {
      const { data } = await this.$axios.get(
        `/api/meta/currency-converter?from=${currency}&to=${tripCurrency}`,
      )

      return data.data.rate
    } catch (error) {
      this.context.dispatch('event/newError', 'error.expense.rate', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  async findCurrency({ trip, params }: any): Promise<string> {
    try {
      const { data } = await this.$axios.get(
        `/api/trips/${trip}/expenses/currencyFinder`,
        {
          params,
        },
      )
      return data.currency
    } catch (error) {
      this.context.dispatch('event/newError', 'error.expense.findCurrency', {
        root: true,
      })
      return ''
    }
  }

  @Action({ rawError: true })
  async exportExpense(trip: string) {
    try {
      await this.$axios(`/api/trips/${trip}/expenses/export`)
      this.context.dispatch(
        'event/newMessage',
        'success.expense.exportExpense',
        {
          root: true,
        },
      )
    } catch (error) {
      this.context.dispatch('event/newError', 'error.export.expense', {
        root: true,
      })
    }
  }
}

// utils that are exclusivly used in this store
function doesAffectMe(expense: ExpenseFraction, userId: string) {
  return expense.userId === userId
}
