
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'IndexAvatar',
})
class IndexAvatar extends Vue {
  @Prop({ default: 1, type: Number }) readonly index!: number

  /**
   * Shows an edit icon on hover
   * Enables the index editor text field on click
   */
  @Prop({ default: false, type: Boolean }) readonly editable!: boolean

  expand = false

  localIndex: number | string = this.index || 1

  get indicesAreEqual(): boolean {
    if (typeof this.localIndex === 'string') {
      if (this.localIndex.trim().length === 0) {
        return true
      }

      return parseInt(this.localIndex) === this.index
    }

    return this.localIndex === this.index
  }

  open(event: any): void {
    if (this.editable) {
      event.stopPropagation()
      this.expand = true
    }
  }

  close(): void {
    this.expand = false
    this.localIndex = this.index || 1
  }

  closeAndUpdate(): void {
    this.expand = false

    if (typeof this.localIndex === 'string') {
      if (this.localIndex.trim().length === 0) {
        return
      }

      this.$emit('update:index', parseInt(this.localIndex))
      return
    }

    if (this.localIndex > 0) {
      this.$emit('update:index', this.localIndex)
    }
  }
}
export default IndexAvatar
