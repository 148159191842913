import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import { set } from 'vue'

import { tripModule, routesModule } from '@/store'
import { Waypoint, Trip } from '@/types/trip'
import {
  FlightStatus,
  FlightDetails,
  TransportationField,
} from '@/types/transportation'
import { TransportationType, TripRelation } from '@/utils/enums'
import { groupBookings } from '@/utils/transportation'

@Module({ name: 'transportation', stateFactory: true, namespaced: true })
export default class TransportationModule extends VuexModule {
  private originWaypoint: Waypoint | null = null
  private destinationWaypoint: Waypoint | null = null
  private formTemplate: any[] = []
  private currentBooking?: any
  private currentBookings: any[] = []
  private bookingsSummary: any[] = []
  private formattedBookingsSummary: any[] = []
  private currentWaypointConnectionDetails: any[] = []
  private searchedAirports: string[] = []
  private currentFlightDetails: FlightDetails | null = null
  private currentFlightStatus: FlightStatus | null = null
  private missingFieldsTitle: string[] = [] // at this moment, this is exclusivly for flight alert registration

  public transportationItems = [
    {
      img: 'transport_CarBooking.png',
      label: 'CarBooking',
      component: 'CarForm',
    },
    {
      img: 'transport_FlightBooking.png',
      label: 'FlightBooking',
      component: 'FlightForm',
    },
    {
      img: 'transport_TrainBooking.png',
      label: 'TrainBooking',
      component: 'TrainForm',
    },
    {
      img: 'transport_BusBooking.png',
      label: 'BusBooking',
      component: 'BusForm',
    },
    {
      img: 'transport_FerryBooking.png',
      label: 'FerryBooking',
      component: 'FerryForm',
    },
    {
      img: 'transport_BicycleBooking.png',
      label: 'BicycleBooking',
      component: 'BicycleForm',
    },
    {
      img: 'transport_WalkingBooking.png',
      label: 'WalkingBooking',
      component: 'WalkingForm',
    },
    {
      img: 'transport_GenericBooking.png',
      label: 'GenericBooking',
      component: 'GenericForm',
    },
  ]

  public get currentOrigin(): Waypoint | null {
    return this.originWaypoint
  }

  public get currentDestination(): Waypoint | null {
    return this.destinationWaypoint
  }

  public get form(): any {
    return this.formTemplate
  }

  public get airports(): any {
    return this.searchedAirports
  }

  public get booking(): any | undefined {
    return this.currentBooking
  }

  public get bookings(): any[] {
    return this.currentBookings
  }

  public get allBookings(): any[] {
    return this.bookingsSummary
  }

  public get formattedAllBookings(): any[] {
    return this.formattedBookingsSummary
  }

  public get flightDetails(): FlightDetails | null {
    return this.currentFlightDetails
  }

  public get flightStatus(): FlightStatus | null {
    return this.currentFlightStatus
  }

  public get missingFields(): string[] {
    return this.missingFieldsTitle
  }

  public get waypointConnectionDetails(): any[] {
    return this.currentWaypointConnectionDetails
  }

  @Mutation
  private setCurrentOrigin(payload: Waypoint) {
    set(this, 'originWaypoint', payload)
  }

  @Mutation
  private setCurrentDestination(payload: Waypoint) {
    set(this, 'destinationWaypoint', payload)
  }

  @Mutation
  private setSearchedAirports(payload: string[]) {
    set(this, 'searchedAirports', payload)
  }

  @Mutation
  public setForm(payload: any) {
    set(this, 'formTemplate', payload)
  }

  @Mutation
  private setCurrentBooking(payload: any | undefined) {
    set(this, 'currentBooking', payload)
  }

  @Mutation
  private setBookings(payload: any[]) {
    set(this, 'currentBookings', payload)
  }

  @Mutation
  private setBookingsSummary(payload: any[]) {
    set(this, 'bookingsSummary', payload)
  }

  @Mutation
  private setFormattedBookingsSummary(payload: any[]) {
    set(this, 'formattedBookingsSummary', payload)
  }

  @Mutation
  public setCurrentFlightDetails(payload: FlightDetails | null) {
    set(this, 'currentFlightDetails', payload)
  }

  @Mutation
  private setCurrentFlightStatus(payload: FlightStatus | null) {
    set(this, 'currentFlightStatus', payload)
  }

  @Mutation
  private setMissingFieldsTitle(payload: string[]) {
    set(this, 'missingFieldsTitle', payload)
  }

  @Mutation
  private setWaypointConnectionDetails(payload: any[]) {
    set(this, 'currentWaypointConnectionDetails', payload)
  }

  @Action({ rawError: true })
  public async fetchWaypointConnectionDetails({
    trip,
    origin,
    destination,
  }: any) {
    try {
      const { data } = await this.$axios.get(
        `api/trips/${trip}/bookings/waypoints/${origin}/${destination}`,
      )
      this.context.commit('setWaypointConnectionDetails', data.bookings)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.transportation.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public setOriginWaypoint(origin: Partial<Waypoint> | null): void {
    this.context.commit('setCurrentOrigin', origin)
    routesModule.setOrigin(origin)
  }

  @Action({ rawError: true })
  public setDestinationWaypoint(destination: Waypoint | null): void {
    this.context.commit('setCurrentDestination', destination)
    routesModule.setDestination(destination)
  }

  @Action({ rawError: true })
  public async fetchBookingOptions() {
    try {
      const { data } = await this.$axios.get('/api/meta/bookings/fields?v=1_12')
      this.context.commit('setForm', data.options)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.transportation.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async createBooking(booking: any) {
    try {
      if (!tripModule.trip) {
        throw new Error(
          'Unable to create transportation booking, no trip selected',
        )
      }

      const { data } = await this.$axios.post(
        `/api/trips/${tripModule.trip.id}/bookings`,
        { booking },
      )

      this.context.dispatch('setBooking', data.booking)
      this.context.dispatch('fetchAllBookings', tripModule.trip)
      this.context.dispatch(
        'event/newMessage',
        'success.transportation.create',
        {
          root: true,
        },
      )
    } catch {
      this.context.dispatch('event/newError', 'error.transportation.post', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async updateBooking(booking: any) {
    try {
      if (!tripModule.trip) {
        throw new Error(
          'Unable to update transportation booking, no trip selected',
        )
      }

      const { data } = await this.$axios.put(
        `/api/trips/${tripModule.trip.id}/bookings/${booking._id}`,
        { booking },
      )

      this.context.dispatch('setBooking', data.booking)
      this.context.dispatch('fetchAllBookings', tripModule.trip)
      this.context.dispatch(
        'event/newMessage',
        'success.transportation.update',
        { root: true },
      )

      this.context.dispatch(
        'trip/postSocketTrigger',
        { type: TripRelation.UPDATED_BOOKING },
        {
          root: true,
        },
      )
    } catch {
      this.context.dispatch('event/newError', 'error.transportation.update', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchAllBookings(trip: Trip) {
    try {
      const { data } = await this.$axios.get(`api/trips/${trip.id}/bookings`)
      this.context.commit('setBookingsSummary', data.bookings)

      this.context.commit(
        'setFormattedBookingsSummary',
        groupBookings(data.bookings, trip),
      )

      if (this.booking) {
        const updatedBooking = data.bookings.find((booking: any) => {
          return booking.id === this.booking.id
        })
        if (updatedBooking) {
          this.setBooking(updatedBooking)
        }
      }
    } catch (error) {
      this.context.dispatch('event/newError', 'error.transportation.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async fetchBookings({ trip, origin, destination }: any) {
    try {
      const { data } = await this.$axios.get(
        `/api/trips/${trip}/bookings/waypoints/${origin}/${destination}`,
      )
      this.context.commit('setBookings', data.bookings)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.transportation.fetch', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public postBookingSummary(bookings: any[]) {
    this.context.commit('setBookingsSummary', bookings)
  }

  @Action({ rawError: true })
  public async deleteBooking({ trip, booking }: any) {
    try {
      await this.$axios.delete(`api/trips/${trip}/bookings/${booking.id}`)
      this.context.dispatch(
        'event/newMessage',
        'success.transportation.delete',
        {
          root: true,
        },
      )

      // Guard origin and destination. These are undefined for flights
      if (booking.originWaypoint && booking.destinationWaypoint) {
        const originId: string =
          typeof booking.originWaypoint === 'string'
            ? booking.originWaypoint
            : booking.originWaypoint._id

        const destinationId: string =
          typeof booking.destinationWaypoint === 'string'
            ? booking.destinationWaypoint
            : booking.destinationWaypoint._id

        this.context.dispatch('fetchBookings', {
          trip,
          origin: originId,
          destination: destinationId,
        })
      }

      this.context.dispatch('fetchAllBookings', tripModule.trip)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.transportation.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async deleteConnection({ trip, booking }: any) {
    try {
      await this.$axios.delete(`api/trips/${trip}/connections/${booking._id}`)
      this.context.dispatch('fetchAllBookings', tripModule.trip)
    } catch (error) {
      this.context.dispatch('event/newError', 'error.transportation.delete', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async airportsBySearchTerm(params: { q?: string }) {
    try {
      const { data } = await this.$axios.get(
        `api/meta/bookings/airports/search`,
        {
          params,
        },
      )
      this.context.commit('setSearchedAirports', data.airports)
    } catch {
      this.context.dispatch('event/newError', 'error.transportation.search', {
        root: true,
      })
    }
  }

  @Action({ rawError: true })
  public async searchFlight(params: {
    flightNumber: string
    departureDate: string
  }) {
    try {
      const { data } = await this.$axios.get(
        `api/meta/bookings/flights/search`,
        {
          params: {
            fn: params.flightNumber,
            dd: params.departureDate,
          },
        },
      )

      this.context.commit('setCurrentFlightDetails', data.details)
    } catch (err) {
      this.context.dispatch(
        'event/newInfo',
        ['info.transportation.searchFlight', 'noWorriesYouCanStillAddIt'],
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public async registerFlightAlert() {
    try {
      if (!tripModule.trip) {
        throw new Error('Unable to register flight alert, no trip selected')
      }

      if (this.booking?.type !== TransportationType.FLIGHT) {
        throw new Error(
          'Unable to register flight alert, no booking of type FLIGHT selected',
        )
      }

      const response = await this.$axios.post(
        `/api/trips/${tripModule.trip.id}/bookings/${this.booking._id}/flights`,
      )

      if (response.data?.success) {
        this.context.dispatch(
          'event/setValues',
          {
            flightNumber: this.flightDetails?.flightNumber || '',
          },
          { root: true },
        )
        this.context.dispatch(
          'event/newMessage',
          'success.transportation.flightStatus.register',
          {
            root: true,
          },
        )
      } else if ((response as any).response?.data?.missingFields) {
        /**
         * This is a special case to be handled
         * Becaus of how this endpoint is built, the OPTIONS preflight can get a 200 response, but the
         * POST request itself can still get a 500 response. So (response as any) is actually a cast to
         * a regular axios error and the catch block is never triggered here
         **/

        const missingFields = (response as any).response.data.missingFields
        this.context.commit('setMissingFieldsTitle', missingFields)

        this.context.dispatch(
          'event/setValues',
          {
            missingFields: missingFields.join(', '),
          },
          {
            root: true,
          },
        )
        this.context.dispatch(
          'event/newError',
          'error.transportation.flightStatus.missingFields',
          {
            root: true,
          },
        )
      }
    } catch (error) {
      this.context.dispatch(
        'event/newError',
        'error.transportation.flightStatus.register',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public async fetchFlightStatus() {
    try {
      if (!tripModule.trip) {
        throw new Error('Unable to fetch flight status, no trip selected')
      }

      if (this.booking?.type !== TransportationType.FLIGHT) {
        throw new Error(
          'Unable to fetch flight status, no booking of type FLIGHT selected',
        )
      }

      const response = await this.$axios.get(
        `/api/trips/${tripModule.trip.id}/bookings/${this.booking._id}/flights/status`,
      )

      if (response.data?.flightStatus) {
        this.context.commit(
          'setCurrentFlightStatus',
          response.data.flightStatus,
        )
      } else if ((response as any).response?.data?.missingFields) {
        /**
         * This is a special case to be handled
         * Becaus of how this endpoint is built, the OPTIONS preflight can get a 200 response, but the
         * POST request itself can still get a 500 response. So (response as any) is actually a cast to
         * a regular axios error and the catch block is never triggered here
         **/
        const missingFields = (response as any).response.data.missingFields
        this.context.commit('setMissingFieldsTitle', missingFields)

        this.context.dispatch(
          'event/setValues',
          {
            missingFields: missingFields.join(', '),
          },
          {
            root: true,
          },
        )
        this.context.dispatch(
          'event/newError',
          'error.transportation.flightStatus.missingFields',
          {
            root: true,
          },
        )
      }
    } catch (error) {
      this.context.dispatch(
        'event/newError',
        'error.transportation.flightStatus.fetch',
        {
          root: true,
        },
      )
    }
  }

  @Action({ rawError: true })
  public async findField(
    fields: TransportationField[],
    identifier: string,
  ): Promise<TransportationField | null> {
    const field = await fields.find(
      (element) => element.identifier === identifier,
    )
    if (field) {
      return field
    } else {
      return null
    }
  }

  @Action({ rawError: true })
  public resetAirportSearch(): void {
    this.context.commit('setSearchedAirports', [])
  }

  @Action({ rawError: true })
  public resetBookings(): void {
    this.context.commit('setBookings', [])
  }

  @Action({ rawError: true })
  public setBooking(booking: any): void {
    if (booking.originWaypoint) {
      const originWaypoint = tripModule.trip?.waypoints.find(
        (waypoint) =>
          waypoint._id === booking.originWaypoint._id ||
          waypoint._id === booking.originWaypoint,
      )

      if (originWaypoint) {
        this.context.commit('setCurrentOrigin', originWaypoint)
      }

      if (booking.destinationWaypoint) {
        const destinationWaypoint = tripModule.trip?.waypoints.find(
          (waypoint) =>
            waypoint._id === booking.destinationWaypoint._id ||
            waypoint._id === booking.destinationWaypoint,
        )

        if (destinationWaypoint) {
          this.context.commit('setCurrentDestination', destinationWaypoint)
        }
      }
    }

    this.context.commit('setCurrentBooking', booking)
  }

  @Action({ rawError: true })
  public resetBooking(): void {
    this.context.commit('setCurrentBooking', undefined)
  }

  @Action({ rawError: true })
  public resetFlightDetails(): void {
    this.context.commit('setCurrentFlightDetails', undefined)
  }

  @Action({ rawError: true })
  public resetFlightStatus(): void {
    this.context.commit('setCurrentFlightStatus', undefined)
  }

  @Action({ rawError: true })
  public resetMissingFields(): void {
    this.context.commit('setMissingFieldsTitle', [])
  }
}
