module.exports = {
  pro: {
    checkout: {
      title: 'Pásate ya a Lambus PRO',
      description:
        '¡Desbloquea funciones premium adicionales para planear tu viaje con mayor facilidad! 🎉',
      payPerTrip: {
        title: 'Paga por viaje',
        caption: 'Actualiza un viaje (para todos)',
      },
      yearlySubscription: {
        title: 'Suscripción anual',
        caption: 'Actualiza todos tus viajes',
        freeTrial: '¡PRUEBA 7 DÍAS GRATIS!',
      },
      success: {
        title: 'Lambus PRO: ¡Compra exitosa!',
      },
      learnMore: 'Descubre más sobre Lambus PRO',
      upgradeNowUpper: 'PÁSATE YA A PRO 🚀',
      thankYouNotice:
        '¡Gracias por comprar un Lambus PRO! ❤️Esta página se cerrará automáticamente en {time} segundos.',
    },
    features: {
      routePlanning: {
        title: 'Ampliación de planificación de ruta',
        description:
          'Calcula la duración y distancia exacta entre todas tus puntos de ruta',
      },
      googleMaps: {
        title: 'Búsqueda en Google Maps',
        description:
          'Utilizamos Google Maps para encontrar aún más puntos de ruta',
      },
      videoChat: {
        title: 'Video Chat',
        description:
          'Chatea con el resto de asistentes mientras planeáis el viaje',
      },
      moneyPool: {
        title: 'Fondo de Dinero',
        description: 'Gestiona el dinero del viaje en un lugar centralizado',
      },
      exportTripGpx: {
        title: 'Exportar el viaje en GPX',
        description:
          'Exporta tu viaje como GPX y utilízalo en aplicaciones de seguimiento',
      },
      currencies: {
        title: 'Cambio de moneda automático',
        description:
          'Convierte las monedas extranjeras a tu moneda utilizando el tipo de cambio actual',
      },
      weather: {
        title: 'Actualización del tiempo en tiempo real',
        description:
          'Muestra las condiciones climatológicas actuales en todas tus puntos de ruta',
      },
      exportExpenses: {
        title: 'Exporta tus gastos',
        description: 'Exporta automáticamente tus gastos a Excel',
      },
      flightStatus: {
        title: 'Actualización del vuelo en tiempo real',
        description:
          'Recibe notifiaciones si tu vuelo se ha retrasado, cancelado o reprogramado',
      },
      exportTrip: {
        title: 'Exportar el viaje en PDF',
        description:
          'Exporta tu viaje a PDF para acceder a él incluso sin tu smartphone',
      },
      support: {
        title: 'Apóyanos ❤️',
        description:
          'Con tu ayuda Lambus puede seguir sin publicidad y prescindir de la venta de datos',
      },
      packingList: {
        title: 'Listas (equipaje, cosas por hacer)',
        description:
          'Elige entre las listas existentes o crea las tuyas propias',
      },
      flightCompensation: {
        title: 'Compensación del Vuelo',
        description:
          'Reclamar automáticamente una indemnización si se cancela tu vuelo',
      },
      privateDocuments: {
        title: 'Documentos privados',
        description:
          'Guarda de forma segura el pasaporte / permiso de conducir en tu dispositivo',
      },
      offlineMode: {
        title: 'Modo sin conexión',
        description: 'Accede a todo tu viaje incluso sin conexión a Internet',
      },
      calendarSync: {
        title: 'Sincronización con el Calendario',
        description: 'Sincroniza tus waypoints y POIs con tu calendario',
      },
    },
  },
  sortExpensesBy: 'Ordenar gastos por …',
  expenseCreated: 'Gasto creado',
  expenseDate: 'Fecha del gasto',
  canoe_tour: 'Tour en canoa',
  hiking: 'Senderismo',
  camping: 'Acampada',
  beach: 'Playa',
  swimming: 'Natación',
  boat_tour: 'Tour en barco',
  campfire: 'Fogata',
  party: 'Fiesta',
  bike_tour: 'Tour en bicicleta',
  surfing: 'Surf',
  snorkeling: 'Snorkel',
  picknick: 'Picnic',
  food: 'Comida',
  horse_riding: 'Montar a caballo',
  sport_event: 'Evento deportivo',
  concert: 'Concierto',
  cinema: 'Cine',
  winter_sports: 'Deportes de invierno',
  searchActivitiesAndPlaces: 'Busca tus lugares y actividades guardados',
}
