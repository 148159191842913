
import { Vue, Prop, Component } from 'vue-property-decorator'

import InspirationCompanyBadge from '@/components/discover/InspirationCompanyBadge.vue'
import InspirationDescription from '@/components/discover/InspirationDescription.vue'
import ImportBtn from '@/components/discover/ImportBtn.vue'
import BookmarkBtn from '@/components/discover/BookmarkBtn.vue'
import InspirationList from '@/components/onboarding/discover/InspirationList.vue'
import { authModule, discoverModule } from '@/store'
import { Inspiration, Category, Company } from '@/types/discover'

@Component({
  name: 'InspirationDetails',
  components: {
    InspirationCompanyBadge,
    InspirationDescription,
    ImportBtn,
    BookmarkBtn,
    InspirationList,
  },
})
class InspirationDetails extends Vue {
  /**
   * Disables the router navigation of the hashtag menu in the inspiration description
   */
  @Prop({ default: false, type: Boolean })
  readonly disableHashtagNavigation?: boolean

  @Prop({ default: true, type: Boolean }) readonly autoImport!: boolean

  @Prop() readonly importLabel?: string

  get isLoggedIn(): boolean {
    return authModule.loggedIn
  }

  get companyURL(): string {
    if (this.currentInspiration?.referralURL) {
      const firstSplit =
        this.currentInspiration.referralURL.originalURL.split('://')
      const secondSplit = firstSplit[1].split('/')
      return secondSplit[0]
    }
    return ''
  }

  get company(): Company | null {
    return (
      this.currentInspiration?.company ||
      this.currentInspiration?.createdBy?.company ||
      null
    )
  }

  get currentInspiration(): Inspiration {
    if (!discoverModule.currentInspiration) {
      throw new Error('No Inspiration set')
    }
    return discoverModule.currentInspiration
  }

  get isLambusContent(): boolean {
    return this.company?.name === 'Lambus'
  }

  onCategoryClick(category: Category): void {
    if (this.isLoggedIn) {
      this.$emit('click:category', category)
    } else {
      this.$router.push({
        name: 'discover-categories-categories',
        params: {
          categories: category.id,
          name: category.name,
        },
      })
    }
  }

  openPartnerURL(): void {
    if (this.currentInspiration) {
      window.open(this.currentInspiration.referralURL.originalURL, '_blank')
    }
  }
}
export default InspirationDetails
