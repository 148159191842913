
import { Component, Vue } from 'vue-property-decorator'
import { subscriptionModule } from '@/store'
import { ProSubscription } from '@/types/subscription'
import { isBrowser } from '@/utils/utility-manager'
@Component({
  name: 'ProSubscriptionExpansionPanel',
})
class ProSubscriptionExpansionPanel extends Vue {
  get subscriptions(): ProSubscription[] {
    return subscriptionModule.subscriptions
  }

  created() {
    if (this.subscriptions.length === 0) {
      subscriptionModule.fetchUserSubscriptions()
    }
  }

  openSubscriptions(): void {
    if (isBrowser() && this.subscriptions.length === 0) {
      window.open(`${process.env.DASHBOARD_HOME}/pro/features`, '_blank')
    } else {
      this.$router.push({
        name: 'pro-subscriptions',
      })
    }
  }
}
export default ProSubscriptionExpansionPanel
