import { Context } from '@nuxt/types'
import { authModule } from '@/store'

export default function ({ redirect, route }: Context) {
  // change old routes to new routes
  if (route.path === '/login') {
    return redirect('/sign-in')
  } else if (route.path === '/register') {
    return redirect('/sign-up')
  }

  if (route.path === '/error') {
    return
  }
  if (route.path.startsWith('/assistant/onboarding')) {
    return
  }

  // Redirect to sign-in page if the user is not authenticated append the
  // current route as a redirect parameter
  if (!authModule.loggedIn) {
    if (route.path === '/') {
      return redirect('/sign-in')
    }

    return redirect('/sign-in', { redirectTo: route.fullPath })
  }
}
