
import { Component, Vue } from 'vue-property-decorator'
import InspirationListCard from '@/components/discover/InspirationListCard.vue'
import { discoverModule } from '@/store'
import { Inspiration } from '@/types/discover'
import { EmptyStateType } from '@/utils/enums'
@Component({
  name: 'InspirationList',
  components: {
    InspirationListCard,
  },
})
class InspirationList extends Vue {
  emptyState: string = EmptyStateType.SEARCH
  get inspirations(): Inspiration[] {
    return discoverModule.inspirationsBySearch
  }
}
export default InspirationList
